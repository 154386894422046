import { StoreService } from "./store.service";
import axios from "axios";
import { Constants } from "./constants.service";

let serverURL = Constants.SERVER;

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response;
        if (status === UNAUTHORIZED) {
            StoreService.clearStoreData();
            // @ts-ignore
            window.location = "/";
        }
        return Promise.reject(error);
    }
);

const ApiService = {
    login: (email: string, password: string) => {
        return axios({
            url: `${serverURL}/login`,
            method: "POST",
            data: {
                email,
                password,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    updateFCM: (id, fcm_token, token) => {
        return axios({
            url: `${serverURL}/fcm`,
            method: "POST",
            data: {
                id,
                fcm_token,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    pin: (pin: string, token: string) => {
        return axios({
            url: `${serverURL}/pin`,
            method: "POST",
            data: {
                pin,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createNewAccount: (data, customers, token) => {
        return axios({
            url: `${serverURL}/users/store`,
            method: "POST",
            data: {
                ...data,
                customers,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getExpenses: (data, token) => {
        return axios({
            url: `${serverURL}/users/getExpenses`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updatePersonalAccount: (data, customers, token) => {
        return axios({
            url: `${serverURL}/users/update`,
            method: "PUT",
            data: {
                ...data,
                customers,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createClient: (data, token) => {
        return axios({
            url: `${serverURL}/customers/store`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateClient: (data, token) => {
        return axios({
            url: `${serverURL}/customers/update`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteAccount: (data, token) => {
        return axios({
            url: `${serverURL}/users/destroy`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteAction: (data, token) => {
        return axios({
            url: `${serverURL}/actions/destroy`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteTemplate: (data, token) => {
        return axios({
            url: `${serverURL}/templates/destroy`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    showAction: (data, token) => {
        return axios({
            url: `${serverURL}/getAllActions`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    showTemplates: (data, token) => {
        return axios({
            url: `${serverURL}/templates/show`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateAction: (template, actions, action_customers, users, action_groups, labels, token) => {
        return axios({
            url: `${serverURL}/actions/update`,
            method: "PUT",
            data: { template, actions, action_customers, users, action_groups, labels },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createNewAction: (
        template,
        actions,
        action_customers,
        users,
        action_groups,
        steps,
        costs,
        files,
        labels,
        column_id,
        token
    ) => {
        return axios({
            url: `${serverURL}/actions/store`,
            method: "POST",
            data: {
                template,
                actions,
                users,
                action_customers,
                action_groups,
                steps,
                costs,
                files,
                labels,
                column_id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    findActionByID: (id, token) => {
        return axios({
            url: `${serverURL}/actions/findActionByID`,
            method: "POST",
            data: id,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updatePin: (data, token) => {
        return axios({
            url: `${serverURL}/users/updatePin`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updatePassword: (data, token) => {
        return axios({
            url: `${serverURL}/users/updatePas`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    resetPassword: (email) => {
        return axios({
            url: `${serverURL}/resetPassword`,
            method: "POST",
            data: {
                email,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateData: (data, token, url) => {
        return axios({
            url: `${serverURL}/${url}`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    logout: (token) => {
        return axios({
            url: `${serverURL}/logout`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    dailyOverviewActions: (data, token) => {
        return axios({
            url: `${serverURL}/actions/dailyOverview`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },

    monthlyActions: (data, token) => {
        return axios({
            url: `${serverURL}/actions/monthlyOverview`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    filterActions: (data, token) => {
        return axios({
            url: `${serverURL}/getAllActions`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getAllUsers: (data, token) => {
        return axios({
            url: `${serverURL}/getAllUsers`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    filterCustomers: (data, token) => {
        return axios({
            url: `${serverURL}/customers/getAllCustomers`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getFolderByID: (id, token) => {
        return axios({
            url: `${serverURL}/files/getDirectoryByID`,
            method: "POST",
            data: { id },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteClient: (data, token) => {
        return axios({
            url: `${serverURL}/customers/destroy`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    uploadUserImage: (data, token, config) => {
        const onUploadProgress = config.onUploadProgress;
        return axios({
            method: "post",
            url: `${serverURL}/users/uploadUserImage`,
            data,
            onUploadProgress,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => res.data);
    },
    uploadFile: (data, token, config) => {
        const onUploadProgress = config.onUploadProgress;
        return axios({
            method: "post",
            url: `${serverURL}/files/store`,
            data,
            onUploadProgress,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => res.data);
    },
    replaceFile: (data, token, config) => {
        const onUploadProgress = config.onUploadProgress;
        return axios({
            method: "post",
            url: `${serverURL}/files/replaceFile`,
            data,
            onUploadProgress,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => res.data);
    },
    deleteFile: (data, token) => {
        return axios({
            url: `${serverURL}/files/removeItemFromAction`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createFolder: (data, token) => {
        return axios({
            url: `${serverURL}/files/createFolder`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    cloneFile: (data, token) => {
        return axios({
            url: `${serverURL}/files/cloneFile`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createStep: (data, token) => {
        return axios({
            url: `${serverURL}/steps/store`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateStep: (data, token) => {
        return axios({
            url: `${serverURL}/steps/update`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteStep: (data, token) => {
        return axios({
            url: `${serverURL}/steps/destroy`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createCost: (data, token) => {
        return axios({
            url: `${serverURL}/expenses/store`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateCost: (data, token) => {
        return axios({
            url: `${serverURL}/expenses/update`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteCost: (data, token) => {
        return axios({
            url: `${serverURL}/expenses/destroy`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    renameItem: (data, token) => {
        return axios({
            url: `${serverURL}/files/renameItem`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    moveItem: (data, token) => {
        return axios({
            url: `${serverURL}/files/moveItem`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteItem: (id, token, confirmation?) => {
        return axios({
            url: `${serverURL}/files/deleteItem`,
            method: "POST",
            data: { id, confirmation },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getStructure: (token) => {
        return axios({
            url: `${serverURL}/files/getStructure`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    downloadFile: (id, config, token) => {
        const onDownloadProgress = config.onDownloadProgress;
        return axios({
            url: `${serverURL}/files/downloadFile`,
            method: "POST",
            data: { id },
            onDownloadProgress,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    checkResetToken: (data) => {
        return axios({
            url: `${serverURL}/checkResetToken`,
            method: "POST",
            data,
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    newPasswordWithToken: (data) => {
        return axios({
            url: `${serverURL}/newPasswordWithToken`,
            method: "POST",
            data,
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    sortSteps: (data, token) => {
        return axios({
            url: `${serverURL}/steps/sortSteps`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    zipItem: (id, token) => {
        return axios({
            url: `${serverURL}/files/zipItem`,
            method: "POST",
            data: { id },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    backupDataToZip: (token) => {
        return axios({
            url: `${serverURL}/files/backupData`,
            method: "POST",
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    searchFolder: (data, token) => {
        return axios({
            url: `${serverURL}/files/searchFolder`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    managePermissions: (
        data: {
            items_id: any;
            user_id: number;
            read: number;
            write: number;
        },
        token
    ) => {
        return axios({
            url: `${serverURL}/files/managePermissions`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    changeNewUsersFilePermission: (
        data: { files_id: number[]; new_users_read: number; new_users_write: number },
        token
    ) => {
        return axios({
            url: `${serverURL}/files/changeNewUsersFilePermission`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getCustomerByID: (id, token) => {
        return axios({
            url: `${serverURL}/customers/getCustomerByID`,
            method: "POST",
            data: id,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getActivityLogs: (data, token) => {
        return axios({
            url: `${serverURL}/logs/getLogs`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    fetchFromCrps: (data, token) => {
        return axios({
            url: `${serverURL}/customers/fetchClientFromCRPS`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    fetchClientStaffFromCRPS: (data, token) => {
        return axios({
            url: `${serverURL}/customers/fetchClientStaffFromCRPS`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createCustomerStaff: (data, token) => {
        return axios({
            url: `${serverURL}/customers/createCustomerStaff`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateCustomerStaff: (data, token) => {
        return axios({
            url: `${serverURL}/customers/updateCustomerStaff`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteCustomerStaff: (data, token) => {
        return axios({
            url: `${serverURL}/customers/deleteCustomerStaff`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    changeLanguage: (data, token) => {
        return axios({
            url: `${serverURL}/changeLanguage`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    generatePDF: (data, token) => {
        return axios({
            url: `${serverURL}/actions/pdf`,
            method: "POST",
            data,
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createSalary: (data, token) => {
        return axios({
            url: `${serverURL}/salary/store`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateSalary: (data, token) => {
        return axios({
            url: `${serverURL}/salary/update`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteSalary: (data, token) => {
        return axios({
            url: `${serverURL}/salary/destroy`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createColumn: (data, token) => {
        return axios({
            url: `${serverURL}/columns/store`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateColumn: (data, token) => {
        return axios({
            url: `${serverURL}/columns/update`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteColumn: (id, token) => {
        return axios({
            url: `${serverURL}/columns/destroy`,
            method: "POST",
            data: { id },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    sortColumns: (data, token) => {
        return axios({
            url: `${serverURL}/columns/updateOrders`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    addToColumn: (data, token) => {
        return axios({
            url: `${serverURL}/actions/addToColumn`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getDefaultPathOfAttachments: (token) => {
        return axios({
            url: `${serverURL}/actions/getDefaultPathOfAttachments`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    bulkPermissions: (data: { users_with_permissions: any[]; files_id: number[] }, token) => {
        return axios({
            url: `${serverURL}/files/bulkPermissions`,
            method: "POST",
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getDefaultFolderStructure: (action_id, token) => {
        return axios({
            url: `${serverURL}/actions/actionDefaultFolderStructure`,
            method: "POST",
            data: { action_id },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getAllGroups: (token) => {
        return axios({
            url: `${serverURL}/groups/getAllGroups`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },

    createGroup: (data, token) => {
        return axios({
            url: `${serverURL}/groups/store`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },

    updateGroup: (data, token) => {
        return axios({
            url: `${serverURL}/groups/update`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteGroup: (id, token) => {
        return axios({
            url: `${serverURL}/groups/destroy`,
            method: "POST",
            data: { id },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    manageGroupFilePermissions: (
        data: { files_id: number; group_id?: number; read?: number; write?: number },
        token
    ) => {
        return axios({
            url: `${serverURL}/groups/manageGroupFilePermissions`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    uploadThumbnail: (data, token, config) => {
        const onUploadProgress = config.onUploadProgress;
        return axios({
            url: `${serverURL}/groups/uploadThumbnail`,
            method: "POST",
            data,
            onUploadProgress,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    destroyThumbnail: (data, token) => {
        return axios({
            url: `${serverURL}/groups/destroyThumbnail`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createTimeTrack: (
        data: {
            title: string | number;
            description: string;
            status: number;
            start_date;
            end_date;
            hours: number;
            minutes: number;
            action_id?: number;
        },
        token
    ) => {
        return axios({
            url: `${serverURL}/timeTracks/store`,
            method: "POST",
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getAllTimeTracks: (
        data: {
            start_date;
            end_date;
            limit: number;
            offset: number;
            group_id?: number | null;
            employee_id?: number | null;
        },
        token
    ) => {
        return axios({
            url: `${serverURL}/timeTracks/getAllTimeTracks`,
            method: "POST",
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getTimeTrackById: (
        data: {
            id: number;
        },
        token
    ) => {
        return axios({
            url: `${serverURL}/timeTracks/getTimeTrack`,
            method: "POST",
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateTimeTrack: (
        data: {
            id: number;
            title: string | number;
            description: string;
            status: number;
            start_date;
            end_date;
            hours: number;
            minutes: number;
            action_id?: number;
        },
        token
    ) => {
        return axios({
            url: `${serverURL}/timeTracks/update`,
            method: "POST",
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteTimeTrack: (
        data: {
            id: number;
        },
        token
    ) => {
        return axios({
            url: `${serverURL}/timeTracks/destroy`,
            method: "POST",
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    exportTimeTrackPDF: (
        data: {
            start_date;
            end_date;
            group_id?: number;
            employee_id?: number;
        },
        token
    ) => {
        return axios({
            url: `${serverURL}/timeTracks/exportPDF`,
            method: "POST",
            data: data,
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getRealEstate: (
        data: {
            jmbg: number;
            customer_id: number;
        },
        token
    ) => {
        return axios({
            url: `${serverURL}/customers/getRealEstate`,
            method: "POST",
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    refreshRealEstate: (
        data: {
            jmbg: number;
            customer_id: number;
        },
        token
    ) => {
        return axios({
            url: `${serverURL}/customers/refreshRealEstate`,
            method: "POST",
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getAllLabels: (data, token) => {
        return axios({
            url: `${serverURL}/labelsForFile/getAllLabels`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    storeLabel: (data, token) => {
        return axios({
            url: `${serverURL}/labelsForFile/store`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateLabel: (data, token) => {
        return axios({
            url: `${serverURL}/labelsForFile/update`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteLabel: (data, token) => {
        return axios({
            url: `${serverURL}/labelsForFile/destroy`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    addLabelToFile: (data, token) => {
        return axios({
            url: `${serverURL}/files/addLabel`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    removeLabelFromFile: (data, token) => {
        return axios({
            url: `${serverURL}/files/removeLabel`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    downloadMultipleFiles: (files_id, config, token) => {
        const onDownloadProgress = config.onDownloadProgress;
        return axios({
            url: `${serverURL}/files/downloadMultipleFiles`,
            method: "POST",
            data: { files_id },
            onDownloadProgress,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createActionComment: (
        data: {
            text: string;
            user_id: number;
            action_id: number;
        },
        token
    ) => {
        return axios({
            url: `${serverURL}/actionComments/store`,
            method: "POST",
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateActionComment: (
        data: {
            text: string;
            id: number;
        },
        token
    ) => {
        return axios({
            url: `${serverURL}/actionComments/update`,
            method: "PUT",
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteActionComment: (
        data: {
            id: number;
        },
        token
    ) => {
        return axios({
            url: `${serverURL}/actionComments/destroy`,
            method: "PUT",
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getGroupActions: (data, token) => {
        return axios({
            url: `${serverURL}/groups/getActions`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getSpecificLogs: (data, token) => {
        return axios({
            url: `${serverURL}/logs/getSpecificLogs`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteLog: (data, token) => {
        return axios({
            url: `${serverURL}/logs/destroy`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
};

export default ApiService;
