import React, { Component } from "react";
import { parseDateForMySQL, parseDatetime } from "functions/utilFunctions/parsingFunctions";
import ApiService from "services/api.service";
import EditClientAccount from "pages/Clients/EditClientAccount";
import ClientAdditionalInfo from "pages/Clients/ClientAdditionalInfo";
import ClientActions from "pages/Clients/ClientActions";
import ClientFiles from "pages/Clients/ClientFiles";
import Modal from "../../devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import { StoreService } from "services/store.service";
import { UtilService } from "services/util.service";
import { BackIcon } from "components/Icons/Icons";
import { CustomButton, CustomInput, CustomForm } from "devkit/Form/Form";
import Translate from "functions/utilFunctions/translate";
import FolderStructure from "components/FolderStructure/FolderStructure";
import { Loader } from "devkit/Loader/Loader";
import { ScreenContext } from "components/ScreenContext/ScreenContext";
import RealEstates from "./RealEstates";

class ClientsDetails extends Component<any, any> {
    state = {
        goToNewAction: false,
        noviStaffs: [],
        form: {
            id: {
                value: "",
                errors: [],
            },
            name: {
                value: "",
                errors: [],
            },
            address: {
                value: "",
                errors: [],
            },
            email: {
                value: "",
                errors: [],
            },
            phone: {
                value: "",
                errors: [],
            },
            PDVno: {
                value: "",
                errors: [],
            },
            bank_account: {
                value: "",
                errors: [],
            },
            PIB: {
                value: "",
                errors: [],
            },
            created_at: {
                value: null,
                errors: [],
            },
            activity_code: {
                value: "",
                errors: [],
            },
            activity_name: {
                value: "",
                errors: [],
            },
            capital: {
                value: "",
                errors: [],
            },
            change_number: {
                value: "",
                errors: [],
            },
            city: {
                value: "",
                errors: [],
            },
            founding_date: {
                value: null,
                errors: [],
            },
            update_date: {
                value: null,
                errors: [],
            },
            full_name: {
                value: "",
                errors: [],
            },
            mailing_address: {
                value: "",
                errors: [],
            },
            mailing_city: {
                value: "",
                errors: [],
            },
            status: {
                value: "",
                errors: [],
            },
            website: {
                value: "",
                errors: [],
            },
            users: {
                value: [{ value: 0, label: "" }],
                errors: [""],
            },
            default_path: {
                value: "",
                errors: [],
            },
            parent_folder_id: {
                value: 0,
                errors: [],
            },
            default_path_level: {
                value: "",
                errors: [],
            },
            founders: {
                value: "",
                errors: [],
            },
            ceo: {
                value: "",
                errors: [],
            },
            representative: {
                value: "",
                errors: [],
            },
            id_number: {
                value: "",
                errors: [],
            },
            search: {
                value: "",
                errors: [],
            },
            pibSearch: {
                value: "",
                errors: [],
            },
            files: [],
        },
        ceo: {
            id: "",
            first_name: "",
            last_name: "",
            identity_number: "",
            bank_account: "",
            position: "",
            responsibility: "",
            share: "",
            customer_id: "",
            created_at: "",
            updated_at: "",
            crps: "",
        },
        founders: [
            {
                id: "",
                first_name: "",
                last_name: "",
                identity_number: "",
                bank_account: "",
                position: "",
                responsibility: "",
                share: "",
                customer_id: "",
                created_at: "",
                updated_at: "",
                crps: "",
            },
        ],
        representatives: [
            {
                id: "",
                first_name: "",
                last_name: "",
                identity_number: "",
                bank_account: "",
                position: "",
                responsibility: "",
                share: "",
                customer_id: "",
                created_at: "",
                updated_at: "",
                crps: "",
            },
        ],
        staffData: [
            {
                id: 0,
                first_name: "",
                last_name: "",
                position: "",
                responsibility: "",
                share: "",
                customer_id: 0,
                created_at: "",
                updated_at: "",
            },
        ],
        actionsTableHeads: [
            {
                value: "actionName",
                sortable: true,
                sortableType: "string",
                sortableName: "title",
            },
            {
                value: "START_DATE",
                sortable: true,
                sortableType: "date",
                sortableName: "startDate",
            },
            {
                value: "END_DATE",
                sortable: true,
                sortableType: "date",
                sortableName: "endDate",
            },
            {
                value: "CLIENT",
                sortable: true,
                sortableType: "string",
                sortableName: "customer_name",
            },
            {
                value: "EMPLOYEES",
                sortable: true,
                sortableType: "string",
                sortableName: "firstName",
            },
            {
                value: "PRIORITY",
                sortable: true,
                sortableType: "number",
                sortableName: "priority",
            },
            {
                value: "STATE",
                sortable: true,
                sortableType: "number",
                sortableName: "status",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        filesTableHeads: [
            {
                value: "NAME",
                sortable: true,
                sortableType: "string",
                sortableName: "name",
            },
            {
                value: "SIZE",
                sortable: true,
                sortableType: "string",
                sortableName: "size",
            },
            {
                value: "TYPE",
                sortable: true,
                sortableType: "string",
                sortableName: "type",
            },
            {
                value: "DATE_CREATED",
                sortable: true,
                sortableType: "date",
                sortableName: "dateAdded",
            },
        ],
        filters: {
            search: {
                value: "",
                errors: [],
            },
            startDate: {
                value: null,
                errors: [],
            },
            endDate: {
                value: null,
                errors: [],
            },
            user_id: {
                value: 0,
                errors: [],
            },
        },
        folderStructure: {
            sub: [
                {
                    sub: [],
                    created_at: null,
                    file_size: null,
                    id: 0,
                    level: 0,
                    parent_id: 0,
                    path: "",
                    title: "",
                    type: "",
                    updated_at: null,
                },
            ],
            created_at: null,
            file_size: null,
            id: 0,
            level: 0,
            parent_id: null,
            path: "",
            title: "",
            type: "",
            updated_at: null,
        },
        customer: {},
        customers: [],
        actions: [],
        usersArray: [],
        showUpdateSuccessModal: false,
        showUpdateErrorModal: false,
        showDeleteModal: false,
        showDeleteFailure: false,
        showDeleteSuccess: false,
        showCrpsModal: false,
        showUpdateCrpsErrorModal: false,
        showUpdateCrpsSuccessModal: false,
        handleChange: false,
        id: "",
        activeTab: "basicInfo",
        changeFolderModal: false,
        contextItemID: 0,
        contextItemName: {
            value: "",
            errors: [],
        },
        contextItemExt: "",
        destinationID: 1,
        destinationPath: "",
        destinationLevel: 0,
        crpsData: [
            {
                adresA_PRIJEM: "",
                adresA_SJEDISTE: "",
                broJ_PROMJENE: 0,
                datuM_PROMJENE: "",
                datuM_REGISTRACIJE: "",
                djelatnost: "",
                iD_PREDMET: 0,
                maticnI_BROJ_DRUSTVA: "",
                naziV_DRUSTVA: "",
                obliK_ORGANIZOVANJA: "",
                opstinA_PRIJEM: "",
                opstinA_SJEDISTE: "",
                rbr: 0,
                reG_BROJ: "",
                sifrA_DJELATNOSTI: 0,
                skracenI_NAZIV_DRUSTVA: "",
                statuS_DRUSTVA: 0,
                ukupaN_KAPITAL: 0,
            },
        ],
        crpsClientStaff: [
            {
                iD_PREDMET: null,
                ime: "",
                odgovornost: "",
                prezime: "",
                rbr: 0,
                udio: null,
                uloga: "",
            },
        ],
        showModalLoader: false,
        showLoader: false,
        updateCRPS: false,
        fetchedFromCrps: false,
        showRealEstate: false,
        realEstates: [] as any,
    };

    static contextType = ScreenContext;

    componentDidMount() {
        const users = StoreService.getStoreProperty("users");
        const usersArray = users.map((e) => ({
            value: e.id,
            label: `${e.firstName} ${e.lastName}`,
        }));

        usersArray.unshift({
            value: 0,
            label: <Translate text="CHOOSE_EMPLOYEE" />,
        });

        if (!this.props.newClient) {
            const token = StoreService.getStoreProperty("token");

            ApiService.getCustomerByID(
                {
                    id: this.props.match.params.id,
                },
                token
            ).then((response) => {
                if (response.success) {
                    const files = response.data.files;
                    const customer = response.data.customer;
                    const staff = response.data.stafs;
                    const ceo: any = staff.find((e) => e.position === "Izvršni direktor");
                    const founders: any = staff.filter((e) => e.position === "Osnivač");
                    const representatives: any = staff.filter((e) => e.position === "Ovlašćeni zastupnik");
                    const realEstates = response.data.customer.real_estates;
                    const userFormated = {};

                    let usersFormated =
                        customer.users &&
                        customer.users.map((e: any) => ({
                            value: e.id,
                            label: `${e.firstName} ${e.lastName}`,
                        }));

                    Object.keys(customer).forEach((e) => {
                        if (e === "created_at" || e === "update_date" || e === "founding_date") {
                            userFormated[e] = {
                                value: new Date(customer[e]),
                                errors: [],
                            };
                        } else if (e === "users") {
                            userFormated[e] = {
                                value: usersFormated,
                                errors: [],
                            };
                        } else if (e === "default_path") {
                            userFormated[e] = {
                                value: customer.default_path ? customer.default_path : `/klijenti/${customer.name}`,
                                errors: [],
                            };
                        } else {
                            userFormated[e] = {
                                value: customer[e],
                                errors: [],
                            };
                        }
                    });
                    ApiService.filterActions(
                        { customer_id: parseInt(this.props.match.params.id), limit: 1000, offset: 0 },
                        token
                    ).then((res) => {
                        if (res.status === "OK") {
                            this.setState({ actions: res.data.actions });
                        }
                    });

                    this.setState((prevState: any) => ({
                        form: {
                            ...prevState.form,
                            ...userFormated,
                            files,
                        },
                        realEstates,
                        ceo,
                        founders,
                        representatives,
                        usersArray,
                    }));
                }
            });

            this.handleGetStructure();
        } else {
            this.handleGetStructure();
        }
    }

    handleChange = () => {
        this.setState((prevState: any) => ({
            handleChange: !prevState.handleChange,
        }));
    };

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    handleCeoInput = (name, data) => {
        this.setState(
            (prevState: any) => ({
                ceo: {
                    ...prevState.ceo,
                    [name]: data.value,
                    position: "Izvršni direktor",
                },
            }),
            () => {
                if (!this.props.newClient) {
                    // let ceo2 = {};
                    const token = StoreService.getStoreProperty("token");
                    // const customers = StoreService.getStoreProperty("customers");
                    // const customer: any = Object.values(customers).find(
                    //     (u: any) => u.id.toString() === this.props.match.params.id
                    // );
                    // ApiService.getCustomerByID(
                    //     {
                    //         id: customer.id,
                    //     },
                    //     token
                    // ).then((response) => {
                    //     if (response.success) {
                    //         const staffs = response.data.stafs;
                    //         console.log(staffs);

                    //     }})

                    const id = this.state.ceo.id;
                    const first_name = this.state.ceo.first_name;
                    const last_name = this.state.ceo.last_name;
                    const position = this.state.ceo.position;
                    const bank_account = this.state.ceo.bank_account;
                    const identity_number = this.state.ceo.identity_number;
                    UtilService.handleDebouncing(
                        "updateCeoDebouncing",
                        () =>
                            ApiService.updateCustomerStaff(
                                {
                                    id,
                                    first_name,
                                    last_name,
                                    position,
                                    bank_account,
                                    identity_number,
                                },
                                token
                            ).then((response) => {
                                if (!response.success) {
                                    alert("Something went wrong bre!");
                                }
                            }),
                        300
                    );
                }
            }
        );
    };

    handleRepresentativesInput = (name, data, index) => {
        if (index !== undefined) {
            this.setState(
                (prevState: any) => ({
                    representatives: [
                        ...prevState.representatives.slice(0, index),
                        {
                            ...prevState.representatives[index],
                            [name]: data.value,
                            position: "Ovlašćeni zastupnik",
                        },
                        ...prevState.representatives.slice(index + 1),
                    ],
                }),
                () => {
                    if (!this.props.newClient) {
                        const id = this.state.representatives[index].id;
                        const first_name = this.state.representatives[index].first_name;
                        const last_name = this.state.representatives[index].last_name;
                        const position = this.state.representatives[index].position;
                        const bank_account = this.state.representatives[index].bank_account;
                        const identity_number = this.state.representatives[index].identity_number;
                        const token = StoreService.getStoreProperty("token");
                        UtilService.handleDebouncing(
                            "updateRepresentativeDebouncing",
                            () =>
                                ApiService.updateCustomerStaff(
                                    {
                                        id,
                                        first_name,
                                        last_name,
                                        position,
                                        bank_account,
                                        identity_number,
                                    },
                                    token
                                ).then((response) => {
                                    if (!response.success) {
                                        alert("Something went wrong!");
                                    }
                                }),
                            300
                        );
                    }
                }
            );
        }
    };

    handleFoundersInput = (name, data, index) => {
        if (index !== undefined) {
            this.setState(
                (prevState: any) => ({
                    founders: [
                        ...prevState.founders.slice(0, index),
                        {
                            ...prevState.founders[index],
                            [name]: data.value,
                            position: "Osnivač",
                        },
                        ...prevState.founders.slice(index + 1),
                    ],
                }),
                () => {
                    if (!this.props.newClient) {
                        const id = this.state.founders[index].id;
                        const first_name = this.state.founders[index].first_name;
                        const last_name = this.state.founders[index].last_name;
                        const position = this.state.founders[index].position;
                        const bank_account = this.state.founders[index].bank_account;
                        const identity_number = this.state.founders[index].identity_number;
                        const token = StoreService.getStoreProperty("token");
                        UtilService.handleDebouncing(
                            "updateFounderDebouncing",
                            () =>
                                ApiService.updateCustomerStaff(
                                    {
                                        id,
                                        first_name,
                                        last_name,
                                        position,
                                        bank_account,
                                        identity_number,
                                    },
                                    token
                                ).then((response) => {
                                    if (!response.success) {
                                        alert("Something went wrong!");
                                    }
                                }),
                            300
                        );
                    }
                }
            );
        }
    };

    handleAddStaff = (name) => {
        if (this.props.newClient) {
            this.setState((prevState: any) => ({
                [name]: [
                    ...prevState[name],
                    {
                        id: "",
                        first_name: "",
                        last_name: "",
                        bank_account: "",
                        identity_number: "",
                        position: name === "founders" ? "Osnivač" : "Ovlašćeni zastupnik",
                        responsibility: "",
                        share: "",
                        customer_id: "",
                        created_at: "",
                        updated_at: "",
                    },
                ],
            }));
        } else {
            const token = StoreService.getStoreProperty("token");
            const staff = [
                {
                    first_name: "",
                    last_name: "",
                    bank_account: "",
                    identity_number: "",
                    position: name === "founders" ? "Osnivač" : "Ovlašćeni zastupnik",
                },
            ];
            ApiService.createCustomerStaff(
                {
                    customer_id: this.state.form.id.value,
                    staff: JSON.stringify(staff),
                },
                token
            ).then((res) => {
                if (res.success) {
                    const newStaff = res.data[0];
                    this.setState((prevState: any) => ({
                        [name]: [
                            ...prevState[name],
                            {
                                id: newStaff.id,
                                first_name: "",
                                last_name: "",
                                position: newStaff.position,
                                responsibility: "",
                                bank_account: "",
                                identity_number: "",
                                share: "",
                                customer_id: newStaff.customer_id,
                                created_at: "",
                                updated_at: "",
                            },
                        ],
                    }));
                }
            });
        }
    };

    handleDeleteStaff = (index, name) => {
        const token = StoreService.getStoreProperty("token");
        if (this.props.newClient) {
            const staff = this.state[name].filter((e, i) => i !== index);
            this.setState({
                [name]: staff,
            });
        } else {
            const staff = this.state[name];

            ApiService.deleteCustomerStaff(
                {
                    id: staff[index].id,
                },
                token
            ).then((response) => {
                if (response.status === "OK") {
                    const newStaff = this.state[name].filter((e, i) => i !== index);
                    this.setState({
                        [name]: newStaff,
                    });
                }
            });
        }
    };

    handleFilterInput = (name, data) => {
        this.setState((prevState: any) => ({
            filters: {
                ...prevState.filters,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    toggleModal = (name) => {
        if (name === "showUpdateCrpsSuccessModal") {
            this.setState({
                showUpdateCrpsSuccessModal: !this.state.showUpdateCrpsSuccessModal,
                updateCRPS: false,
            });
        } else {
            this.setState({ [name]: !this.state[name] });
        }
    };

    handleDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
        });
    };

    handleSuccessModal = (name) => {
        this.setState((prevState: any) => ({
            [name]: !prevState[name],
        }));
        this.redirectToClients();
    };

    handleFailureModal = (name) => {
        this.setState((prevState: any) => ({
            [name]: !prevState[name],
        }));
    };

    redirectToClients = () => {
        this.props.history.goBack();
    };

    redirectToFiles = (id) => {
        this.props.history.push(`/file-manager/${id}`);
    };

    handleSaveEmployee = () => {
        const {
            phone,
            email,
            PIB,
            PDVno,
            name,
            address,
            id,
            bank_account,
            activity_code,
            activity_name,
            created_at,
            capital,
            change_number,
            city,
            founding_date,
            update_date,
            full_name,
            mailing_address,
            mailing_city,
            status,
            website,
            users,
            parent_folder_id,
            default_path_level,
            default_path,
            id_number,
            ceo,
            founders,
            representative,
        } = this.state.form;
        const updateCRPS = this.state.updateCRPS;
        const form = {
            PIB,
            name,
        };

        const errors = {};
        Object.keys(form).forEach((e) => {
            if (!form[e].value) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
        });

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...errors,
                },
                showFiles: false,
                showActions: false,
                showBasicInfo: true,
                showAdditionalInfo: false,
            }));
        } else {
            const token = StoreService.getStoreProperty("token");

            if (this.props.newClient) {
                ApiService.createClient(
                    {
                        phone: phone.value,
                        email: email.value,
                        PIB: PIB.value,
                        PDVno: PDVno.value,
                        name: name.value,
                        address: address.value,
                        bank_account: bank_account.value,
                        activity_code: activity_code.value,
                        activity_name: activity_name.value,
                        created_at: parseDateForMySQL(created_at.value, true),
                        capital: capital.value,
                        change_number: change_number.value,
                        city: city.value,
                        founding_date: founding_date.value,
                        update_date: update_date.value,
                        full_name: full_name.value,
                        mailing_address: mailing_address.value,
                        mailing_city: mailing_city.value,
                        status: status.value,
                        website: website.value,
                        parent_folder_id: parent_folder_id.value ? parent_folder_id.value : null,
                        default_path_level: default_path_level ? default_path_level.value : null,
                        default_path: default_path ? default_path.value : `/klijenti/${name.value}`,
                        users: users.value[0].value !== 0 ? users.value.map((e) => ({ user_id: e.value })) : null,
                        id_number: id_number.value,
                        ceo: ceo.value,
                        founders: founders.value,
                        representative: representative.value,
                    },
                    token
                ).then((response) => {
                    if (response.status === "OK") {
                        let founders: any = [];
                        let representatives: any = [];
                        let ceo: any = false;

                        if (this.state.ceo.first_name !== "" && this.state.ceo.last_name !== "") {
                            ceo = {
                                crps: this.state.ceo.crps,
                                first_name: this.state.ceo.first_name,
                                last_name: this.state.ceo.last_name,
                                position: "Izvršni direktor",
                                bank_account: this.state.ceo.bank_account,
                                identity_number: this.state.ceo.identity_number,
                            };
                        }

                        this.state.founders.forEach((e) => {
                            if (e.first_name !== "" && e.last_name !== "") {
                                founders.push({
                                    crps: e.crps,
                                    first_name: e.first_name,
                                    last_name: e.last_name,
                                    position: "Osnivač",
                                    bank_account: e.bank_account,
                                    identity_number: e.identity_number,
                                });
                            }
                        });
                        this.state.representatives.forEach((e) => {
                            if (e.first_name !== "" && e.last_name !== "") {
                                representatives.push({
                                    crps: e.crps,
                                    first_name: e.first_name,
                                    last_name: e.last_name,
                                    position: "Ovlašćeni zastupnik",
                                    bank_account: e.bank_account,
                                    identity_number: e.identity_number,
                                });
                            }
                        });

                        let staff = [ceo, ...founders, ...representatives];

                        staff = staff.filter((item) => item && item !== []);

                        ApiService.createCustomerStaff(
                            {
                                customer_id: response.customer.id,
                                staff: JSON.stringify(staff),
                            },
                            token
                        );
                        // radim
                        this.setState({
                            // showNewEmployeeModal: true,
                            // successNewEmployee: true,
                            showUpdateSuccessModal: true,
                            showUpdateErrorModal: false,
                        });
                        const customers = StoreService.getStoreProperty("customers");
                        customers.push(response.customer);
                        StoreService.updateStoreProperty("customers", customers);
                        if (this.state.goToNewAction) {
                            this.handlePush(response.customer.id, response.customer.name);
                        }
                    } else {
                        this.setState({
                            // showNewEmployeeModal: true,
                            // successNewEmployee: false,
                            showUpdateSuccessModal: false,
                            showUpdateErrorModal: true,
                        });
                    }
                });
            } else {
                ApiService.updateClient(
                    {
                        id: id.value,
                        phone: phone.value,
                        email: email.value,
                        PIB: PIB.value,
                        PDVno: PDVno.value,
                        name: name.value,
                        address: address.value,
                        bank_account: bank_account.value,
                        activity_code: activity_code.value,
                        activity_name: activity_name.value,
                        created_at: parseDateForMySQL(created_at.value, true),
                        capital: capital.value,
                        change_number: change_number.value,
                        city: city.value,
                        founding_date: founding_date.value,
                        update_date: update_date.value,
                        full_name: full_name.value,
                        mailing_address: mailing_address.value,
                        mailing_city: mailing_city.value,
                        status: status.value,
                        website: website.value,
                        parent_folder_id: parent_folder_id.value,
                        default_path_level: default_path_level.value,
                        default_path: default_path.value,
                        users: users.value.map((e) => ({ user_id: e.value })),
                        id_number: id_number.value,
                        ceo: ceo.value,
                        founders: founders.value,
                        representative: representative.value,
                    },
                    token
                ).then((response) => {
                    if (response.status === "OK") {
                        // if (fetchedFromCrps) {
                        //     this.createCustomerStaff();
                        // }
                        if (updateCRPS) {
                            this.setState({
                                showUpdateCrpsSuccessModal: true,
                            });
                        } else {
                            this.setState({
                                showUpdateSuccessModal: true,
                                showUpdateErrorModal: false,
                            });
                        }
                        const customers = StoreService.getStoreProperty("customers");

                        const updatedCustomer = response.customer;
                        const newCustomers = customers.map((e) => {
                            if (e.id === this.state.form.id.value) {
                                return {
                                    ...e,
                                    phone: phone.value,
                                    email: email.value,
                                    PIB: PIB.value,
                                    PDVno: PDVno.value,
                                    name: name.value,
                                    address: address.value,
                                    bank_account: bank_account.value,
                                    activity_code: activity_code.value,
                                    activity_name: activity_name.value,
                                    created_at: parseDateForMySQL(created_at.value, true),
                                    capital: capital.value,
                                    change_number: change_number.value,
                                    city: city.value,
                                    founding_date: founding_date.value,
                                    update_date: update_date.value,
                                    full_name: full_name.value,
                                    mailing_address: mailing_address.value,
                                    mailing_city: mailing_city.value,
                                    status: status.value,
                                    website: website.value,
                                    id_number: id_number.value,
                                    ceo: ceo.value,
                                    founders: founders.value,
                                    representative: representative.value,
                                    parent_folder_id: updatedCustomer.parent_folder_id,
                                    default_path_level: updatedCustomer.default_path_level,
                                    default_path: updatedCustomer.default_path,
                                    users: response.customer.users,
                                };
                            } else {
                                return e;
                            }
                        });
                        StoreService.updateStoreProperty("customers", newCustomers);
                    } else {
                        this.setState({
                            showEmployeeModal: true,
                            showUpdateErrorModal: true,
                        });
                    }
                });
            }
        }
    };

    handleDeleteEmployee = () => {
        const deleteID = this.state.form.id.value;
        const token = StoreService.getStoreProperty("token");
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            deleteID: "",
        });
        ApiService.deleteClient(
            {
                id: deleteID,
            },
            token
        ).then((response) => {
            if (response.status === "OK") {
                const customers = StoreService.getStoreProperty("customers");
                const newCustomers = Object.values(customers).filter((item: any) => {
                    return parseInt(item.id) !== parseInt(deleteID);
                });
                this.setState({
                    customers: newCustomers,
                    showDeleteSuccess: true,
                });
                StoreService.updateStoreProperty("customers", newCustomers);
            } else {
                this.setState({
                    showDeleteFailure: true,
                });
            }
        });
    };

    handleActionRedirect = (id) => {
        this.props.history.push(`/action/${id}`);
    };

    tabChange = (tab) => {
        this.setState({ activeTab: tab });
    };

    sortActions = (sortableName, direction, type, array?) => {
        const arr = array || this.state.actions;
        const sortedActions = UtilService.sortArray(arr, sortableName, direction, type);
        this.setState({
            actions: sortedActions,
            sortName: sortableName,
            sortType: type,
            sortDirection: direction,
        });
    };

    changeFolder = () => {
        const parent_folder_id = this.state.destinationID;
        const default_path = this.state.destinationPath;
        const default_path_level = this.state.destinationLevel;
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                parent_folder_id: {
                    value: parent_folder_id,
                    errors: [],
                },
                default_path: {
                    value: default_path,
                    errors: [],
                },
                default_path_level: {
                    value: default_path_level,
                    errors: [],
                },
            },
            changeFolderModal: false,
        }));
    };

    handleDestinationID = (destinationID, destinationPath, destinationLevel) => {
        this.setState({ destinationID, destinationPath, destinationLevel });
    };

    handleGetStructure = () => {
        const token = StoreService.getStoreProperty("token");
        ApiService.getStructure(token).then((res) => {
            if ((res.status = "OK")) {
                this.setState({ folderStructure: res.data[0] });
            }
        });
    };

    fetchFromCRPS = () => {
        this.setState({
            showCrpsModal: true,
            showModalLoader: true,
        });
        const token = StoreService.getStoreProperty("token");
        const data = {};
        if (this.state.form.search.value) {
            data["clientName"] = this.state.form.search.value;
        }
        if (this.state.form.pibSearch.value) {
            data["PIB"] = this.state.form.pibSearch.value;
        }
        ApiService.fetchFromCrps(data, token).then((res) => {
            if ((res.status = "OK")) {
                this.setState((prevState: any) => ({
                    crpsData: res.data,
                    showModalLoader: false,
                    fetchedFromCrps: true,
                    form: {
                        ...prevState.form,
                        search: {
                            value: "",
                            errors: [],
                        },
                        pibSearch: {
                            value: "",
                            errors: [],
                        },
                    },
                }));
            }
        });
    };

    fillCrpsData = (index?) => {
        this.setState({
            showCrpsModal: false,
            showLoader: true,
        });
        const token = StoreService.getStoreProperty("token");
        let allData = this.state.crpsData;
        let selectedItem = index ? allData[index] : allData[0];
        let data = { ID_PREDMET: selectedItem.iD_PREDMET };
        if (this.state.form.id.value) {
            data["customer_id"] = this.state.form.id.value;
        }

        ApiService.fetchClientStaffFromCRPS(data, token).then((res) => {
            const ceo: any = res.data.find((e: any) => {
                if (e && e.position) {
                    return e.position.toLowerCase().indexOf("direktor") >= 0;
                }

                return false;
            });

            const founders: any = res.data.filter((e) => e.position === "Osnivač");
            const representatives: any = res.data.filter((e) => e.position === "Ovlašćeni zastupnik");
            // const formatedID = {}
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    name: {
                        value: selectedItem.skracenI_NAZIV_DRUSTVA,
                        errors: [],
                    },
                    address: {
                        value: selectedItem.adresA_SJEDISTE,
                        errors: [],
                    },
                    PDVno: {
                        value: "",
                        errors: [],
                    },
                    PIB: {
                        value: selectedItem.maticnI_BROJ_DRUSTVA,
                        errors: [],
                    },
                    activity_code: {
                        value: selectedItem.sifrA_DJELATNOSTI,
                        errors: [],
                    },
                    activity_name: {
                        value: selectedItem.djelatnost,
                        errors: [],
                    },
                    capital: {
                        value: selectedItem.ukupaN_KAPITAL,
                        errors: [],
                    },
                    change_number: {
                        value: selectedItem.broJ_PROMJENE,
                        errors: [],
                    },
                    city: {
                        value: selectedItem.opstinA_SJEDISTE,
                        errors: [],
                    },
                    founding_date: {
                        value: selectedItem.datuM_REGISTRACIJE,
                        errors: [],
                    },
                    update_date: {
                        value: selectedItem.datuM_PROMJENE,
                        errors: [],
                    },
                    full_name: {
                        value: selectedItem.naziV_DRUSTVA,
                        errors: [],
                    },
                    mailing_address: {
                        value: selectedItem.adresA_PRIJEM,
                        errors: [],
                    },
                    mailing_city: {
                        value: selectedItem.opstinA_PRIJEM,
                        errors: [],
                    },
                    status: {
                        value: selectedItem.skracenI_NAZIV_DRUSTVA,
                        errors: [],
                    },
                },
                showCrpsModal: false,
                showLoader: false,
                crpsClientStaff: res.data,
                ceo: {
                    // formatedID,
                    id: ceo.id,
                    crps: ceo.crps,
                    first_name: ceo.first_name,
                    last_name: ceo.last_name,
                    position: ceo.position,
                    bank_account: ceo.bank_account,
                    identity_number: ceo.identity_number,
                    customer_id: this.state.form.id.value,
                    responsibility: "",
                    share: "",
                    created_at: parseDateForMySQL(ceo.created_at, true),
                    updated_at: parseDateForMySQL(ceo.updated_at, true),
                },
                founders: founders.map((e) => {
                    return {
                        id: e.id,
                        crps: e.crps,
                        first_name: e.first_name,
                        last_name: e.last_name,
                        position: e.position,
                        bank_account: e.bank_account,
                        identity_number: e.identity_number,
                        customer_id: this.state.form.id.value,
                        responsibility: "",
                        share: "",
                        created_at: parseDateForMySQL(e.created_at, true),
                        updated_at: parseDateForMySQL(e.updated_at, true),
                    };
                }),
                representatives: representatives.map((e) => {
                    return {
                        id: e.id,
                        crps: e.crps,
                        first_name: e.first_name,
                        last_name: e.last_name,
                        position: e.position,
                        bank_account: e.bank_account,
                        identity_number: e.identity_number,
                        customer_id: this.state.form.id.value,
                        responsibility: "",
                        share: "",
                        created_at: e.created_at,
                        updated_at: e.updated_at,
                    };
                }),
                staffData: res.data.map((e) => {
                    return {
                        id: "",
                        crps: e.crps,
                        first_name: e.first_name,
                        last_name: e.last_name,
                        position: e.position,
                        customer_id: this.state.form.id.value,
                        responsibility: "",
                        share: "",
                        created_at: e.created_at,
                        updated_at: e.updated_at,
                    };
                }),
            }));
        });

        if (this.state.updateCRPS) {
            this.handleSaveEmployee();
        }
    };

    updateCRPS = () => {
        const token = StoreService.getStoreProperty("token");
        this.setState({
            showLoader: true,
        });
        ApiService.fetchFromCrps({ PIB: this.state.form.PIB.value }, token).then((res) => {
            if ((res.status = "OK")) {
                if (res.data.errors || res.data.length === 0) {
                    this.setState({
                        showUpdateCrpsErrorModal: true,
                        showLoader: false,
                    });
                } else {
                    this.setState(
                        (prevState: any) => ({
                            crpsData: res.data,
                            updateCRPS: true,
                            fetchedFromCrps: true,
                            form: {
                                ...prevState.form,
                                search: {
                                    value: "",
                                    errors: [],
                                },
                                pibSearch: {
                                    value: "",
                                    errors: [],
                                },
                            },
                        }),
                        () => this.fillCrpsData()
                    );
                }
            }
        });
    };

    createCustomerStaff = () => {
        const token = StoreService.getStoreProperty("token");
        const ceo = {
            crps: this.state.ceo.crps,
            first_name: this.state.ceo.first_name,
            last_name: this.state.ceo.last_name,
            position: this.state.ceo.position,
            bank_account: this.state.ceo.bank_account,
            identity_number: this.state.ceo.identity_number,
        };
        const founders = this.state.founders.map((e) => {
            return {
                crps: e.crps,
                first_name: e.first_name,
                last_name: e.last_name,
                position: e.position,
                bank_account: e.bank_account,
                identity_number: e.identity_number,
            };
        });
        const representatives = this.state.representatives.map((e) => {
            return {
                crps: e.crps,
                first_name: e.first_name,
                last_name: e.last_name,
                position: e.position,
                bank_account: e.bank_account,
                identity_number: e.identity_number,
            };
        });

        const staff = [ceo, ...founders, ...representatives];

        ApiService.createCustomerStaff(
            {
                customer_id: this.state.form.id.value,
                staff: JSON.stringify(staff),
            },
            token
        );
    };

    relatedActions = (id) => {
        StoreService.updateStoreProperty("actionsFilters", {
            customer_id: id,
        });
        this.props.history.push({
            pathname: "/actions/1",
        });
    };

    actionsCalendar = (id) => {
        StoreService.updateStoreProperty("calendarFilters", {
            customer_id: id,
            period: "day",
        });
        this.props.history.push({
            pathname: "/calendar",
        });
    };
    // radim
    createActionForThisClient = () => {
        if (!this.props.newClient) {
            this.props.history.push({
                pathname: `/new-action`,
                client: {
                    id: this.state.form.id.value,
                    name: this.state.form.name.value,
                    employees: {
                        value: this.state.form.users.value,
                    },
                },
            });
        } else {
            this.setState({
                goToNewAction: true,
            });
            this.handleSaveEmployee();
        }
    };

    handlePush = (id, name) => {
        this.props.history.push({
            pathname: `/new-action`,
            client: {
                id,
                name,
                employees: {
                    value: this.state.form.users.value,
                },
            },
        });
    };
    renderRealEstate = () => {
        this.setState({
            showBasicInfo: false,
            showAdditionalInfo: false,
            showActions: false,
            showFiles: false,
            showRealEstate: true,
        });
    };

    submitFilters = (e) => {
        e.preventDefault();
        const token = StoreService.getStoreProperty("token");
        const { filters } = this.state;

        const data = { customer_id: parseInt(this.props.match.params.id) };

        for (let f in filters) {
            if (filters[f].value) {
                if (f === "startDate" || f === "endDate") {
                    data[f] = parseDatetime(filters[f].value);
                } else {
                    data[f] = filters[f].value;
                }
            }
        }

        data["limit"] = 1000;
        data["offset"] = 0;

        ApiService.filterActions(data, token).then((res) => {
            if (res.status === "OK") {
                this.setState({ actions: res.data.actions });
            }
        });
    };

    resetFilters = (e) => {
        e.preventDefault();
        const token = StoreService.getStoreProperty("token");
        this.setState({
            filters: {
                search: {
                    value: "",
                    errors: [],
                },
                startDate: {
                    value: null,
                    errors: [],
                },
                endDate: {
                    value: null,
                    errors: [],
                },
                user_id: {
                    value: 0,
                    errors: [],
                },
            },
        });

        ApiService.filterActions(
            { customer_id: parseInt(this.props.match.params.id), limit: 1000, offset: 0 },
            token
        ).then((res) => {
            if (res.status === "OK") {
                this.setState({ actions: res.data.actions });
            }
        });
    };

    render() {
        const {
            activeTab,
            form: { name, PIB },
        } = this.state;
        const user = StoreService.getStoreProperty("user");

        const { isMobile, screenSize } = this.context;

        const disabledNewAction = name.value === "" || PIB.value === "";

        return (
            <div className="clientsDetails">
                {/* <CustomDropdown
                    name='daca'
                    data={[
                        {label: 'Daca', value: 1}
                    ]}
                    value={{label: 'Pocetna vrednost ili ne', value: 2}}
                    handleChange={this.handleChange}

                /> */}
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showCrpsModal"
                    className={this.state.showCrpsModal ? "visible" : ""}
                    modalWrapperClasses="w-800 padding-20 crpsModal"
                >
                    <div>
                        <h1 className="f-s-25 crps-modal-title">
                            <Translate text="CHOOSE_COMPANY" />
                        </h1>
                        {this.state.showModalLoader ? (
                            <div className="loaderContainer">
                                <Loader width={200} />
                                <span className="loaderContainer mt-100 f-s-15 one-line-text">
                                    <Translate text="COMPANIES_SEARCH" />
                                    ...
                                </span>
                            </div>
                        ) : (
                            <div className="d-flex flex-wrap">
                                {this.state.crpsData &&
                                    this.state.crpsData.map((item, index) => {
                                        return (
                                            <div
                                                key={item.maticnI_BROJ_DRUSTVA}
                                                className="col-xl-6 col-md-6 col-sm-12 mb-20 "
                                                onClick={() => this.fillCrpsData(index)}
                                            >
                                                <div className="crps-company d-flex flex-column justify-content-center align-items-center padding-20 pointer h-200">
                                                    <span className="f-s-16 crps-company-name">
                                                        {item.skracenI_NAZIV_DRUSTVA}
                                                    </span>
                                                    <span className="f-s-14 mt-20">{item.opstinA_SJEDISTE}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                </Modal>
                <div className="w-100perc d-flex flex-column p-10">
                    <div className={`${screenSize <= 768 ? "mobFooterDetails" : ""} clientDetailsHeader`}>
                        <div className="goBackContainer d-flex align-self-start mb-20">
                            <button
                                onClick={this.redirectToClients}
                                className="pointer d-flex align-items-center mr-10 goBack"
                            >
                                <BackIcon width={25} />
                            </button>

                            <span className="headerTitle mr-30 f-s-30  terra-main-3">{this.state.form.name.value}</span>
                        </div>
                        <div className="navigation col-12 border-b-1 padding-0 mb-20 d-flex justify-content-between">
                            <div className="tabMenu d-flex">
                                <CustomButton
                                    //to="/actions"
                                    onClick={() => this.tabChange("basicInfo")}
                                    className={`mr-5 ${
                                        screenSize <= 768 ? "footerNavBtn" : "nav-btn"
                                    } border-radius-br-0 border-radius-bl-0 ${
                                        activeTab === "basicInfo" ? "active" : ""
                                    }`}
                                >
                                    <Translate text={"BASIC_INFO"} />
                                </CustomButton>
                                <CustomButton
                                    onClick={() => this.tabChange("additionalInfo")}
                                    className={`mr-5 ${
                                        screenSize <= 768 ? "footerNavBtn" : "nav-btn"
                                    } border-radius-br-0 border-radius-bl-0 ${
                                        activeTab === "additionalInfo" ? "active" : ""
                                    }`}
                                >
                                    <Translate text={"ADDITIONAL_INFO"} />
                                </CustomButton>
                                {!this.props.newClient && (
                                    <CustomButton
                                        onClick={() => this.tabChange("actions")}
                                        className={`mr-5 ${
                                            screenSize <= 768 ? "footerNavBtn" : "nav-btn"
                                        } border-radius-br-0 border-radius-bl-0 ${
                                            activeTab === "actions" ? "active" : ""
                                        }`}
                                    >
                                        <Translate text={"ACTIONS"} />
                                    </CustomButton>
                                )}

                                <CustomButton
                                    onClick={() => this.tabChange("files")}
                                    className={`mr-5 ${
                                        screenSize <= 768 ? "footerNavBtn" : "nav-btn"
                                    } border-radius-br-0 border-radius-bl-0 ${
                                        activeTab !== "files" ? "" : isMobile ? "active" : ""
                                    }`}
                                >
                                    <Translate text={"FILES"} />
                                </CustomButton>
                                {!this.props.newClient && (
                                    <CustomButton
                                        onClick={() => this.tabChange("realEstates")}
                                        // className={`mr-5 border-radius-br-0 border-radius-bl-0 ${
                                        //     !this.state.showRealEstate ? "btn-back" : ""
                                        // }`}
                                        className={`mr-5 ${
                                            screenSize <= 768 ? "footerNavBtn" : "nav-btn"
                                        } border-radius-br-0 border-radius-bl-0 ${
                                            activeTab !== "realEstates" ? "" : isMobile ? "active" : ""
                                        }`}
                                    >
                                        <Translate text={"REAL_ESTATES"} />
                                    </CustomButton>
                                )}
                            </div>
                            <div className="controlButtons d-flex ">
                                <div>
                                    <CustomButton
                                        className={`border-radius-br-0 border-radius-bl-0 btn-save ${
                                            user.role !== 3 ? "disabledDiv" : ""
                                        }`}
                                        type="button"
                                        onClick={this.handleSaveEmployee}
                                    >
                                        <Translate text={!this.props.newClient ? "UPDATE" : "SAVE"} />
                                    </CustomButton>
                                </div>

                                {!this.props.newClient && (
                                    <div className="ml-5">
                                        <CustomButton
                                            className={`btn-delete border-radius-br-0 border-radius-bl-0 ${
                                                user.role !== 3 ? "disabledDiv" : ""
                                            }`}
                                            type="button"
                                            onClick={() => this.toggleModal("showDeleteModal")}
                                        >
                                            <Translate text="DELETE" />
                                        </CustomButton>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {this.state.showLoader ? (
                        <div className="loaderContainer">
                            <Loader width={200} />
                            <span className="loaderContainer mt-100 f-s-15 one-line-text">
                                <Translate text="CRPS_UPDATING" />
                                ...
                            </span>
                        </div>
                    ) : (
                        <div className="d-flex margin-t-10">
                            {activeTab === "basicInfo" && (
                                <div className="basicInfo w-100perc d-flex">
                                    <div className="basicInfoMain w-80perc d-flex flex-column personal-shadow">
                                        <EditClientAccount
                                            data={this.state.form}
                                            usersArray={this.state.usersArray}
                                            handleInput={this.handleInput}
                                            handleBack={this.redirectToClients}
                                            handleSaveEmployee={this.handleSaveEmployee}
                                            handleDeleteEmployee={this.props.newClient ? false : this.handleDeleteModal}
                                        />

                                        {user.role === 3 && (
                                            <div className="button-group d-flex justify-content-center mb-20">
                                                <div className="mr-10">
                                                    <CustomButton
                                                        className="btn-secondary medium"
                                                        type="button"
                                                        onClick={() => this.relatedActions(this.state.form.id.value)}
                                                    >
                                                        {/* client actions */}
                                                        <Translate text="CLIENT_ACTIONS" />
                                                    </CustomButton>
                                                </div>
                                                <div className="mr-10">
                                                    <CustomButton
                                                        className="btn-secondary medium"
                                                        type="button"
                                                        onClick={() => this.actionsCalendar(this.state.form.id.value)}
                                                    >
                                                        <Translate text="ACTIONS_SCHEDULE" />
                                                    </CustomButton>
                                                </div>
                                                <div className="p-r tooltip">
                                                    <CustomButton
                                                        className="btn-secondary medium"
                                                        onClick={this.createActionForThisClient}
                                                        disabled={disabledNewAction}
                                                    >
                                                        <Translate text="ADD_NEW_ACTION_FOR_THIS_CLIENT" />
                                                    </CustomButton>
                                                    {disabledNewAction && (
                                                        <span className="tooltiptext">
                                                            <Translate text="You need to fill requirement input fields" />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <CustomForm className="mr-30 basicInfoCRPS">
                                        <div className="pa-20 d-flex flex-column align-items-center personal-shadow w-300">
                                            <h2>
                                                <Translate text="DATA_FROM_CRPS" />
                                            </h2>
                                            <div className="my-10 w-100perc">
                                                <CustomInput
                                                    value={this.state.form.search.value}
                                                    handleInput={this.handleInput}
                                                    name="search"
                                                    type="text"
                                                    label="TITLE"
                                                />
                                            </div>
                                            <div className="my-10 w-100perc">
                                                <CustomInput
                                                    value={this.state.form.pibSearch.value}
                                                    handleInput={this.handleInput}
                                                    name="pibSearch"
                                                    type="text"
                                                    label="PIB"
                                                />
                                            </div>
                                            <div className="mt-10">
                                                <CustomButton
                                                    className="btn-save mr-5"
                                                    type="button"
                                                    onClick={this.fetchFromCRPS}
                                                >
                                                    <Translate text="Search" />
                                                </CustomButton>
                                            </div>
                                        </div>
                                    </CustomForm>
                                </div>
                            )}
                            {activeTab === "additionalInfo" && (
                                <ClientAdditionalInfo
                                    data={this.state.form}
                                    handleInput={this.handleInput}
                                    handleBack={this.redirectToClients}
                                    handleSaveEmployee={this.handleSaveEmployee}
                                    handleDeleteEmployee={this.props.newClient ? false : this.handleDeleteModal}
                                    updateCRPS={this.updateCRPS}
                                    staffData={this.state.staffData}
                                    ceo={this.state.ceo}
                                    founders={this.state.founders}
                                    representatives={this.state.representatives}
                                    handleCeo={this.handleCeoInput}
                                    handleFounders={this.handleFoundersInput}
                                    handleRepresentatives={this.handleRepresentativesInput}
                                    addStaff={this.handleAddStaff}
                                    deleteStaff={this.handleDeleteStaff}
                                />
                            )}

                            {activeTab === "actions" && (
                                <div className="h-100-perc transition-2 w-100perc border-radius-12">
                                    <ClientActions
                                        actions={this.state.actions}
                                        users={this.state.usersArray}
                                        tableHeads={this.state.actionsTableHeads}
                                        handleInput={this.handleFilterInput}
                                        handleRedirect={(id) => this.handleActionRedirect(id)}
                                        handleSort={this.sortActions}
                                        filters={this.state.filters}
                                        submitFilters={this.submitFilters}
                                        resetFilters={this.resetFilters}
                                    />
                                </div>
                            )}
                            {activeTab === "files" && (
                                <div className="h-100-perc transition-2 w-100perc border-radius-12">
                                    <ClientFiles
                                        data={this.state.form}
                                        tableHeads={this.state.filesTableHeads}
                                        folderStructure={this.state.folderStructure}
                                        handleRedirect={(id) => this.redirectToFiles(id)}
                                        toggleModal={() => this.toggleModal("changeFolderModal")}
                                    />
                                </div>
                            )}
                            {activeTab === "realEstates" && !this.props.newClient && (
                                <div className="h-100-perc transition-2 w-100perc border-radius-12">
                                    <RealEstates
                                        handleInput={this.handleInput}
                                        pib={this.state.form?.PIB}
                                        props={this.props}
                                        defaultPath={this.state.form.default_path.value}
                                        realEstates={this.state.realEstates}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Modal
                    toggleModal={this.handleFailureModal}
                    modalName="showUpdateCrpsErrorModal"
                    className={this.state.showUpdateCrpsErrorModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="CRPS_UPDATE_FAILURE"
                        modalName="showUpdateCrpsErrorModal"
                        title="FAILURE"
                        toggleModal={this.handleFailureModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showUpdateCrpsSuccessModal"
                    className={this.state.showUpdateCrpsSuccessModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="CRPS_UPDATE_SUCCESS"
                        modalName="showUpdateCrpsSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        text="DELETE_CLIENT_?"
                        modalName="showDeleteModal"
                        title="DELETE_CLIENT"
                        toggleModal={this.toggleModal}
                        deleteItem={this.handleDeleteEmployee}
                        cancelDelete={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.handleSuccessModal}
                    modalName="showDeleteSuccess"
                    className={this.state.showDeleteSuccess ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="CLIENT_DELETE_SUCCESS"
                        modalName="showDeleteSuccess"
                        title="SUCCESS"
                        toggleModal={this.handleSuccessModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.handleFailureModal}
                    modalName="showDeleteFailure"
                    className={this.state.showDeleteFailure ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="CLIENT_DELETE_FAILURE"
                        modalName="showDeleteFailure"
                        title="FAILURE"
                        toggleModal={this.handleFailureModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.handleSuccessModal}
                    modalName="showUpdateSuccessModal"
                    className={this.state.showUpdateSuccessModal ? "visible bg-info-3" : ""}
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="success"
                        text={this.props.newClient ? "CLIENT_ADD_SUCCESS" : "DATA_CHANGE_SUCCESS"}
                        modalName="showUpdateSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.handleSuccessModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.handleFailureModal}
                    modalName="showUpdateErrorModal"
                    className={this.state.showUpdateErrorModal ? "visible bg-info-3" : ""}
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text={this.props.newClient ? "CLIENT_ADD_FAILURE" : "DATA_CHANGE_FAILURE"}
                        modalName="showUpdateErrorModal"
                        title="FAILURE"
                        toggleModal={this.handleFailureModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="changeFolderModal"
                    className={this.state.changeFolderModal ? "visible " : ""}
                    modalWrapperClasses="folderStructureWrapper"
                >
                    <h2 className="text-center border-b-1 py-10 text-color-primary">
                        <Translate text="CHANGE_DEFAULT_FOLDER" />
                    </h2>
                    <FolderStructure
                        data={this.state.folderStructure}
                        selectedItem={`${this.state.contextItemName.value}${this.state.contextItemExt}`}
                        handleDestinationID={this.handleDestinationID}
                    />
                    <div className="button h-60 d-flex justify-content-center align-items-center border-t-1">
                        <CustomButton className="btn btn-save" onClick={this.changeFolder}>
                            <Translate text="CHANGE" />
                        </CustomButton>
                        <CustomButton
                            className="btn btn-cancel ml-30"
                            onClick={() => this.toggleModal("changeFolderModal")}
                        >
                            <Translate text="CANCEL" />
                        </CustomButton>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default ClientsDetails;
