import React, { Component } from "react";
import { CustomButton, CustomDatePicker, CustomDropdown, CustomForm } from "devkit/Form/Form";
import Table from "devkit/Table/Table";
import Translate from "functions/utilFunctions/translate";
import { NavLink } from "react-router-dom";
import ApiService from "services/api.service";
import { StoreService } from "services/store.service";
import { ScreenContext } from "components/ScreenContext/ScreenContext";
import FilterSidebar from "components/FilterSidebar/FilterSidebar";
import { FilterIcon } from "components/Icons/Icons";
import { Loader } from "devkit/Loader/Loader";
import { parseDatetime } from "functions/utilFunctions/parsingFunctions";
import Paginate from "devkit/Pagination/Pagination";

export class TimeTrackTable extends Component<any> {
    state = {
        form: {
            employee_id: {
                value: 0,
                errors: [],
            },
            group_id: {
                value: 0,
                errors: [],
            },
            start_date: {
                value: new Date(),
                errors: [],
            },
            end_date: {
                value: new Date(),
                errors: [],
            },
        },
        employees: [] as any,
        groups: [] as any,
        timeTrackTableHeads: [
            {
                value: "EMPLOYEE",
                sortable: true,
                sortableType: "string",
                sortableName: "full_name_sorter",
            },
            {
                value: "ACTION",
                sortable: true,
                sortableType: "date",
                sortableName: "date",
            },
            {
                value: "DESCRIPTION",
                sortableType: "string",
                sortableName: "type",
            },
            {
                value: "STATUS",
                sortable: true,
                sortableType: "string",
                sortableName: "amount",
            },
            {
                value: "TIME",
                sortableType: "string",
                sortableName: "details",
            },
            {
                value: "START",
                sortableType: "string",
                sortableName: "details",
            },
            {
                value: "END",
                sortableType: "string",
                sortableName: "details",
            },
        ],
        timeTracks: [] as any,
        totalResults: 0,
        currentPage: this.props.match.params.pageNo,
        perPage: 10,
        showFilterSidebar: false,
        showLoader: true,
        showWholeText: false,
    };

    static contextType = ScreenContext;

    componentDidMount() {
        const { currentPage, perPage } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const timeTracksFilters = StoreService.getStoreProperty("timeTracksFilters");
        this.setState(
            (prevState: any) => ({
                form: {
                    ...prevState.form,
                    employee_id: {
                        // value: 0,
                        value: timeTracksFilters?.user_id
                            ? timeTracksFilters.user_id
                            : this.state.form.employee_id.value,
                        errors: [],
                    },
                    group_id: {
                        // value: 0,
                        value: timeTracksFilters?.group_id
                            ? timeTracksFilters.group_id
                            : this.state.form.group_id.value,
                        errors: [],
                    },
                    start_date: {
                        // value: 0,
                        value: timeTracksFilters?.startDate
                            ? timeTracksFilters.startDate
                            : this.state.form.start_date.value,
                        errors: [],
                    },
                    end_date: {
                        // value: 0,
                        value: timeTracksFilters?.endDate ? timeTracksFilters.endDate : this.state.form.end_date.value,
                        errors: [],
                    },
                },
            }),
            () => {
                console.log(this.state.form, "formica");

                const data = {
                    start_date: parseDatetime(this.state.form.start_date.value),
                    end_date: parseDatetime(this.state.form.end_date.value),
                    limit: perPage,
                    offset: offset,
                };
                if (this.state.form.employee_id.value) {
                    data["employee_id"] = this.state.form.employee_id.value;
                }
                if (this.state.form.group_id.value) {
                    data["group_id"] = this.state.form.group_id.value;
                }
                this.getAllTimeTracks(data);
            }
        );
    }
    getAllTimeTracks = (data) => {
        const token = StoreService.getStoreProperty("token");
        ApiService.getAllTimeTracks(data, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    timeTracks: res.data,
                    showLoader: false,
                    employees: res.employees,
                    groups: res.groups,
                    totalResults: res.total,
                });
            } else {
                throw Error("Error");
            }
        });
    };
    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentPage !== this.state.currentPage) {
            console.log("did update");

            const { currentPage, perPage } = this.state;
            const offset = (parseInt(currentPage) - 1) * perPage;
            const data = {
                start_date: this.state.form.start_date.value,
                end_date: this.state.form.end_date.value,
                limit: perPage,
                offset: offset,
            };
            this.getAllTimeTracks(data);
        }
    }

    componentWillUnmount() {
        const { form } = this.state;
        StoreService.updateStoreProperty("timeTracksFilters", {
            group_id: form.group_id.value,
            user_id: form.employee_id.value,
            startDate: new Date(form.start_date.value),
            endDate: new Date(form.end_date.value),
        });
    }

    toggleModal = (name: string) => {
        this.setState({ [name]: !this.state[name] });
    };
    handleInput = (name, data) => {
        this.setState(
            (prevState: any) => ({
                form: {
                    ...prevState.form,
                    [name]: {
                        value: data.value,
                        errors: data.errors,
                    },
                },
            }),
            () => {
                if (name === "end_date" || name === "start_date") {
                    if (new Date(this.state.form.end_date.value) < new Date(this.state.form.start_date.value)) {
                        this.setState((prevState: any) => ({
                            form: {
                                ...prevState.form,
                                end_date: {
                                    value: new Date(this.state.form.start_date.value),
                                },
                            },
                        }));
                    }
                }
            }
        );
    };
    //this function automatically change start and end date when user clicks on arrows
    changeDay = (whereToGo: string) => {
        const { form } = this.state;
        let newDate;
        if (whereToGo === "back") {
            newDate = form.start_date.value.setDate(form.start_date.value.getDate() - 1);
        } else {
            newDate = form.start_date.value.setDate(form.start_date.value.getDate() + 1);
        }
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                start_date: {
                    value: new Date(newDate),
                    errors: [],
                },
                end_date: {
                    value: new Date(newDate),
                    errors: [],
                },
            },
        }));
    };

    toggleSidebar = (e) => {
        e.preventDefault();
        this.setState({ showFilterSidebar: !this.state.showFilterSidebar });
    };

    submitFilters = (e) => {
        e.preventDefault();
        const { form, currentPage, perPage } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const data: any = {
            limit: perPage,
            offset: offset,
        };

        for (let f in form) {
            if (form[f].value) {
                if (f === "start_date" || f === "end_date") {
                    data[f] = parseDatetime(form[f].value);
                } else {
                    data[f] = form[f].value;
                }
            }
        }
        this.getAllTimeTracks(data);
    };

    resetFilters = (e) => {
        e.preventDefault();
        const { currentPage, perPage } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;

        this.setState({
            form: {
                employee_id: {
                    value: 0,
                    errors: [],
                },
                group_id: {
                    value: 0,
                    errors: [],
                },
                start_date: {
                    value: new Date(),
                    errors: [],
                },
                end_date: {
                    value: new Date(),
                    errors: [],
                },
            },
        });

        const data = {
            limit: perPage,
            offset,
            start_date: parseDatetime(new Date()),
            end_date: parseDatetime(new Date()),
        };

        this.getAllTimeTracks(data);
    };
    generatePDF = () => {
        const { form } = this.state;
        const data: any = {};
        if (form.start_date.value) {
            data["start_date"] = form.start_date.value;
        }
        if (form.end_date.value) {
            data["end_date"] = form.end_date.value;
        }
        if (form.employee_id.value !== 0) {
            data["group_id"] = form.group_id.value;
        }
        if (form.employee_id.value !== 0) {
            data["employee_id"] = form.employee_id.value;
        }
        const token = StoreService.getStoreProperty("token");
        ApiService.exportTimeTrackPDF(data, token).then((res) => {
            this.downloadFile(res, "time-track.pdf");
        });
    };
    downloadFile = (blob, fileName) => {
        const link = document.createElement("a");
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    };

    handlePageClick = (pageNumber) => {
        this.props.history.push(`/time-track/${pageNumber}`);
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return {
                currentPage: nextProps.match.params.pageNo,
                showLoader: true,
            };
        }
        return null;
    }

    render() {
        const { isMobile } = this.context;
        const { form, employees, groups, showLoader, showFilterSidebar, totalResults, currentPage, perPage } =
            this.state;

        const employeesForDropdown = employees.map((e) => ({
            value: e.id,
            label: `${e.firstName} ${e.lastName}`,
        }));

        const groupsForDropdown = groups.map((e) => ({
            value: e.id,
            label: e.title,
        }));

        return (
            <div className="timeTrackTable">
                <div className={`${isMobile ? "mobFooterOverview" : ""} d-flex justify-content-md-between mb-20`}>
                    <div className="navigation d-flex">
                        <NavLink
                            to={`/accounts/1`}
                            className={`action-title d-flex align-items-center justify-content-center tab border-radius-br-0 border-radius-bl-0 ${
                                this.props.accounts && "active"
                            }`}
                            style={{
                                textDecoration: "none",
                            }}
                            activeClassName="active"
                        >
                            <Translate text={"EMPLOYEES"} />
                        </NavLink>
                        <NavLink
                            to={`/salaries/1`}
                            style={{
                                textDecoration: "none",
                            }}
                            className={`action-title d-flex align-items-center justify-content-center tab border-radius-br-0 border-radius-bl-0 mx-10 `}
                            activeClassName="active"
                        >
                            <Translate text={"SALARIES"} />
                        </NavLink>
                        <NavLink
                            to={`/time-track/1`}
                            style={{
                                textDecoration: "none",
                            }}
                            className={`action-title d-flex align-items-center justify-content-center tab border-radius-br-0 border-radius-bl-0  ${
                                this.props.timetrack && "active"
                            }`}
                            activeClassName="active"
                        >
                            <Translate text={"Time-track"} />
                        </NavLink>
                    </div>
                    <CustomButton
                        type="button"
                        className="btn-primary ml-20 border-radius-br-0 border-radius-bl-0"
                        onClick={this.generatePDF}
                    >
                        <Translate text="GENERATE_PDF" />
                    </CustomButton>
                </div>
                <div className="filters">
                    <div>
                        <CustomButton
                            onClick={this.toggleSidebar}
                            className="filterBtn btn btn-primary d-flex align-items-center "
                        >
                            <FilterIcon className="icon" width={16}></FilterIcon>
                            <span className="ml-10">Filteri</span>
                        </CustomButton>
                    </div>
                    <FilterSidebar
                        opened={showFilterSidebar}
                        onClose={() => this.setState({ showFilterSidebar: !showFilterSidebar })}
                        submitFilters={this.submitFilters}
                        resetFilters={this.resetFilters}
                    >
                        <CustomForm className="row d-flex flex-column mb-30 mt-10">
                            <div className="mb-20">
                                <CustomDropdown
                                    name="employee_id"
                                    data={employeesForDropdown}
                                    handleChange={this.handleInput}
                                    label="FILTER_BY_EMPLOYEE"
                                    value={
                                        employeesForDropdown.find((el) => el.value === form.employee_id.value) || null
                                    }
                                />
                            </div>
                            <div className="mb-20">
                                <CustomDropdown
                                    name="group_id"
                                    data={groupsForDropdown}
                                    handleChange={this.handleInput}
                                    label="FILTER_BY_GROUP"
                                    value={groupsForDropdown.find((el) => el.value === form.group_id.value) || null}
                                />
                            </div>
                            <div className="d-flex">
                                <div className="col-1 arrow back" onClick={() => this.changeDay("back")}>
                                    <i className="fas fa-arrow-left"></i>
                                </div>
                                <div className="col-10">
                                    <div className="mb-20">
                                        <CustomDatePicker
                                            name="start_date"
                                            handleInput={this.handleInput}
                                            value={form.start_date.value}
                                            errors={form.start_date.errors}
                                            label="Start date"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <CustomDatePicker
                                            name="end_date"
                                            handleInput={this.handleInput}
                                            value={form.end_date.value}
                                            errors={form.end_date.errors}
                                            label="End date"
                                        />
                                    </div>
                                </div>

                                <div className="col-1 arrow" onClick={() => this.changeDay("forward")}>
                                    <i className="fas fa-arrow-right"></i>
                                </div>
                            </div>
                        </CustomForm>
                    </FilterSidebar>
                </div>
                {showLoader ? (
                    <div className="loaderContainer">
                        <Loader width={200} />
                    </div>
                ) : (
                    <div className="overflow-auto mt-30 pb-20">
                        <Table theads={this.state.timeTrackTableHeads} theadsClassname="customThead">
                            {this.state.timeTracks.map((track) => (
                                <tr key={track.id} className="f-s-14">
                                    <td>{track.firstName + " " + track.lastName}</td>
                                    <td>{track.title}</td>
                                    <td className={`w-150 max-w-200 wordWrap`}>
                                        {/* {track.description.length < 50 ? (
                                            track.description
                                        ) : !showWholeText ? (
                                            track.description.substring(0, 49) + "..."
                                        ) : (
                                            <span className="descSpan w-300">{track.description}</span>
                                        )} */}

                                        {/* {track.description.length < 50
                                            ? track.description
                                            : !showWholeText
                                            ? track.description.substring(0, 49) + "..."
                                            : track.description} */}
                                        {/* {track.description} */}
                                        {track.description.length < 50
                                            ? track.description
                                            : track.description.substring(0, 49) + "..."}
                                    </td>
                                    <td>
                                        {track.status === 1 ? (
                                            <Translate text={"IN_PROGRESS"} />
                                        ) : (
                                            <Translate text={"FINISHED"} />
                                        )}
                                    </td>
                                    <td>{track.hours + ":" + track.minutes + "h"}</td>
                                    <td>{track.start_date}</td>
                                    <td>{track.end_date}</td>
                                </tr>
                            ))}
                        </Table>
                        {totalResults > 0 && (
                            <div className="d-flex justify-content-center">
                                <Paginate
                                    totalItemsCount={totalResults}
                                    onChange={this.handlePageClick}
                                    activePage={parseInt(currentPage)}
                                    perPage={perPage}
                                    pageRangeDisplayed={5}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
export default TimeTrackTable;
