import { CustomButton } from "devkit/Form/Form";
import { Loader } from "devkit/Loader/Loader";
import Translate from "functions/utilFunctions/translate";
import React, { Component } from "react";

export class YearlyOverview extends Component<any, any> {
    state = {
        year: new Date().getFullYear(),
    };

    changeYear = (type) => {
        if (type === "minus") {
            this.setState(
                {
                    year: this.state.year - 1,
                },
                () => this.props.handlePickYear(this.state.year)
            );
        } else if (type === "plus") {
            this.setState(
                {
                    year: this.state.year + 1,
                },
                () => this.props.handlePickYear(this.state.year)
            );
        }
    };

    filteredByMonth = (month: string) => {
        const { actions, language } = this.props;
        const filteredActions = actions.filter((action) => {
            let actionArray = action.startDate.split("-");

            return actionArray[1] === month && actionArray[0] === "" + this.state.year;
        });
        // console.log(filteredActions); bitan console log da se isprati bug
        let planned: any = [];
        let inProgress: any = [];
        let completed: any = [];
        let late: any = [];
        filteredActions.forEach((action: any) => {
            if (action.status === 1) {
                planned.push(action);
            }
            if (action.status === 2) {
                inProgress.push(action);
            }
            if (action.status === 3) {
                completed.push(action);
            }
            if (action.status === 2 && new Date(action.endDate) < new Date()) {
                late.push(action);
            }
        });
        return (
            <div className="actionList">
                {late.length > 0 && (
                    <p>
                        <span className="late"></span> {language === "me" ? "U zakasnjenju" : "Late"} : {late.length}
                    </p>
                )}
                {inProgress.length > 0 && (
                    <p>
                        <span className="inProgress"></span>
                        {language === "me" ? "U toku" : "In progress"} : {inProgress.length}
                    </p>
                )}
                {planned.length > 0 && (
                    <p>
                        <span className="planned"></span>
                        {language === "me" ? "Planirane" : "Planned"} : {planned.length}
                    </p>
                )}
                <p>
                    <span className="total"></span>
                    {language === "me" ? "Ukupan broj" : "Total"} : {filteredActions.length}
                </p>
                {completed.length > 0 && (
                    <p>
                        <span className="completed"></span>
                        {language === "me" ? "Zavrsene" : "Finished"} : {completed.length}
                    </p>
                )}
            </div>
        );
    };

    changeToMonthly = (year: any, start: string, end: string) => {
        this.props.handleMonth(new Date(year + start), new Date(year + end));
        this.props.handleMonthlyCalendar();
    };

    render() {
        const { year } = this.state;
        const { isLoading } = this.props;
        // console.log(this.filteredByMonth('04'));

        return (
            <div className="yearCalendar">
                <div
                    className={`calendarForYear ${isLoading ? "p-r" : ""}`}
                    // style={{
                    //     height: `${isLoading ? "calc(100vh - 525px)" : ""}`,
                    // }}
                >
                    {isLoading ? (
                        <div className="loaderContainer d-flex h-100-perc w-100-perc bg-white" style={{ zIndex: 99 }}>
                            <Loader width={200} />
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="top d-flex h-100 ">
                        <CustomButton
                            className="fc-prev-button fc-button fc-button-primary"
                            onClick={() => this.changeYear("minus")}
                        >
                            ＜
                        </CustomButton>
                        <p className="f-s-35 w-100">{year}</p>
                        <CustomButton
                            className="fc-next-button fc-button fc-button-primary"
                            onClick={() => this.changeYear("plus")}
                        >
                            ＞
                        </CustomButton>
                    </div>
                    <div className="year row d-flex">
                        <div className="col-4" onClick={() => this.changeToMonthly(year, "-01-01", "-01-31")}>
                            <div className="month">
                                <p>
                                    <Translate text="January" />
                                </p>
                                <div>{this.filteredByMonth("01")}</div>
                            </div>
                        </div>
                        <div className="col-4 " onClick={() => this.changeToMonthly(year, "-02-01", "-02-28")}>
                            <div className="month">
                                <p>
                                    <Translate text="February" />
                                </p>
                                <div>{this.filteredByMonth("02")}</div>
                            </div>
                        </div>
                        <div className="col-4" onClick={() => this.changeToMonthly(year, "-03-01", "-03-31")}>
                            <div className="month">
                                <p>
                                    <Translate text="March" />
                                </p>
                                <div>{this.filteredByMonth("03")}</div>
                            </div>
                        </div>

                        <div className="col-4" onClick={() => this.changeToMonthly(year, "-04-01", "-04-30")}>
                            <div className="month">
                                <p>
                                    <Translate text="April" />
                                </p>
                                <div>{this.filteredByMonth("04")}</div>
                            </div>
                        </div>
                        <div className="col-4" onClick={() => this.changeToMonthly(year, "-05-01", "-05-31")}>
                            <div className="month">
                                <p>
                                    <Translate text="May" />
                                </p>
                                <div>{this.filteredByMonth("05")}</div>
                            </div>
                        </div>
                        <div className="col-4" onClick={() => this.changeToMonthly(year, "-06-01", "-06-30")}>
                            <div className="month">
                                <p>
                                    <Translate text="June" />
                                </p>
                                <div>{this.filteredByMonth("06")}</div>
                            </div>
                        </div>

                        <div className="col-4" onClick={() => this.changeToMonthly(year, "-07-01", "-07-31")}>
                            <div className="month">
                                <p>
                                    <Translate text="July" />
                                </p>
                                <div>{this.filteredByMonth("07")}</div>
                            </div>
                        </div>
                        <div className="col-4" onClick={() => this.changeToMonthly(year, "-08-01", "-08-31")}>
                            <div className="month">
                                <p>
                                    <Translate text="August" />
                                </p>
                                <div>{this.filteredByMonth("08")}</div>
                            </div>
                        </div>
                        <div className="col-4" onClick={() => this.changeToMonthly(year, "-09-01", "-09-30")}>
                            <div className="month">
                                <p>
                                    <Translate text="September" />
                                </p>
                                <div>{this.filteredByMonth("09")}</div>
                            </div>
                        </div>

                        <div className="col-4" onClick={() => this.changeToMonthly(year, "-10-01", "-10-31")}>
                            <div className="month">
                                <p>
                                    <Translate text="Oktober" />
                                </p>
                                <div>{this.filteredByMonth("10")}</div>
                            </div>
                        </div>
                        <div className="col-4" onClick={() => this.changeToMonthly(year, "-11-01", "-11-30")}>
                            <div className="month">
                                <p>
                                    <Translate text="November" />
                                </p>
                                <div>{this.filteredByMonth("11")}</div>
                            </div>
                        </div>
                        <div className="col-4" onClick={() => this.changeToMonthly(year, "-12-01", "-12-31")}>
                            <div className="month">
                                <p>
                                    <Translate text="December" />
                                </p>
                                <div>{this.filteredByMonth("12")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default YearlyOverview;
