import { CustomForm, CustomInput, CustomDropdown } from "devkit/Form/Form";
import React, { Component } from "react";

class EditClient extends Component<any> {
    render() {
        const { name, address, phone, PDVno, bank_account, PIB, users } = this.props.data;
        const usersArray = this.props.usersArray;
        const usersFiltered: any = users.value && Object.values(users.value).filter((e: any) => e.label !== "");
        return (
            <CustomForm className="w-100perc py-10 d-flex col-12">
                <div className="col-6">
                    <div className="my-20">
                        <CustomInput
                            name="name"
                            label="TITLE"
                            value={name.value}
                            type="text"
                            errors={name.errors}
                            handleInput={this.props.handleInput}
                        />
                    </div>
                    <div className="my-20">
                        <CustomInput
                            name="address"
                            label="ADRESS"
                            value={address.value}
                            type="text"
                            errors={address.errors}
                            handleInput={this.props.handleInput}
                        />
                    </div>
                    <div className="my-20">
                        <CustomInput
                            name="bank_account"
                            label="BANK_ACCOUNT"
                            value={bank_account.value}
                            type="text"
                            errors={bank_account.errors}
                            handleInput={this.props.handleInput}
                        />
                    </div>
                    <div className="my-20">
                        <CustomInput
                            name="phone"
                            label="PHONE"
                            value={phone.value}
                            type="text"
                            errors={phone.errors}
                            handleInput={this.props.handleInput}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="my-20">
                        <CustomInput
                            name="PIB"
                            label="PIB"
                            value={PIB.value}
                            type="text"
                            errors={PIB.errors}
                            handleInput={this.props.handleInput}
                        />
                    </div>
                    <div className="my-20">
                        <CustomInput
                            name="PDVno"
                            label="PDV"
                            value={PDVno.value}
                            type="text"
                            errors={PDVno.errors}
                            handleInput={this.props.handleInput}
                        />
                    </div>

                    <div className="mb-20">
                        <CustomDropdown
                            data={usersArray}
                            name="users"
                            value={usersFiltered}
                            handleChange={this.props.handleInput}
                            multiple
                            className="multiple"
                            label="assignedEmployees"
                            errors={users.errors}
                            required={true}
                        />
                    </div>
                </div>
            </CustomForm>
        );
    }
}

export default EditClient;
