import {
    // CustomDatePicker,
    CustomForm,
    CustomInput,
    CustomDatePicker,
    CustomButton,
} from "devkit/Form/Form";
import React, { Component } from "react";
import Translate from "functions/utilFunctions/translate";

class ClientAdditionalInfo extends Component<any> {
    render() {
        const {
            full_name,
            address,
            activity_code,
            activity_name,
            change_number,
            email,
            PIB,
            city,
            mailing_address,
            mailing_city,
            founding_date,
            update_date,
            capital,
            website,
            status,
            id_number,
        } = this.props.data;
        //const staffData = this.props.staffData;
        const ceo: any = this.props.ceo;
        const founders: any = this.props.founders;
        const representatives: any = this.props.representatives;
        console.log(representatives);

        return (
            <CustomForm className="additionalInfo w-100perc h-100perc py-10 d-flex">
                <div className="additionalInfoMain col-xl-8 col-lg-8 row">
                    <div className="col-lg-6 col-xl-6">
                        <div className="my-20">
                            <CustomInput
                                name="full_name"
                                label="FULL_NAME"
                                value={full_name.value}
                                type="text"
                                errors={full_name.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className=" my-20">
                            <CustomInput
                                name="PIB"
                                label="PIB"
                                value={PIB.value}
                                type="text"
                                errors={PIB.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className=" my-20">
                            <CustomInput
                                name="change_number"
                                label="CHANGE_NUMBER"
                                value={change_number.value}
                                type="number"
                                errors={change_number.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>

                        <div className=" my-20">
                            <CustomInput
                                name="activity_code"
                                label="ACTIVITY_CODE"
                                value={activity_code.value}
                                type="number"
                                errors={activity_code.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className=" my-20">
                            <CustomInput
                                name="activity_name"
                                label="ACTIVITY_NAME"
                                value={activity_name.value}
                                type="text"
                                errors={activity_name.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className=" my-20">
                            <CustomInput
                                name="address"
                                label="ADDRESS"
                                value={address.value}
                                type="text"
                                errors={address.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className=" my-20">
                            <CustomInput
                                name="city"
                                label="CITY"
                                value={city.value}
                                type="text"
                                errors={city.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className=" my-20">
                            <CustomInput
                                name="mailing_address"
                                label="MAILING_ADRESS"
                                value={mailing_address.value}
                                type="text"
                                errors={mailing_address.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 ">
                        <div className="my-20">
                            <CustomInput
                                name="mailing_city"
                                label="MAILING_CITY"
                                value={mailing_city.value}
                                type="text"
                                errors={mailing_city.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>

                        <div className="my-20">
                            <CustomInput
                                name="capital"
                                label="CAPITAL"
                                value={capital.value}
                                type="number"
                                errors={capital.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20">
                            <CustomInput
                                name="website"
                                label="WEBSITE"
                                value={website.value}
                                type="text"
                                errors={website.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20">
                            <CustomInput
                                name="email"
                                label="Email"
                                value={email.value}
                                type="email"
                                errors={email.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>

                        <div className="my-20">
                            <CustomInput
                                name="status"
                                label="STATUS"
                                value={status.value}
                                type="text"
                                errors={status.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>

                        <div className="my-20">
                            <CustomInput
                                name="id_number"
                                label="ID_NUMBER"
                                value={id_number.value}
                                type="text"
                                errors={id_number.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20">
                            <CustomDatePicker
                                name="founding_date"
                                label="FOUNDING_DATE"
                                handleInput={this.props.handleInput}
                                value={founding_date.value}
                                errors={founding_date.errors}
                            />
                        </div>
                        <div className="my-20">
                            <CustomDatePicker
                                name="update_date"
                                label="UPDATE_DATE"
                                handleInput={this.props.handleInput}
                                value={update_date.value}
                                errors={update_date.errors}
                            />
                        </div>
                    </div>
                </div>
                {/* radim */}
                <div className="col-xl-4 col-lg-4 companyStaff">
                    <h2 className="f-s-18">
                        <Translate text="COMPANY_STAFF" />:
                    </h2>
                    <div className="ceoSection d-flex flex-column">
                        <h3 className="mt-15 mb-10">
                            <Translate text="CEO" />:
                        </h3>
                        <div className="row">
                            <div className="col-6 my-10">
                                <CustomInput
                                    name="first_name"
                                    label="NAME"
                                    value={ceo && ceo.first_name}
                                    type="text"
                                    handleInput={this.props.handleCeo}
                                />
                            </div>
                            <div className="col-6 my-10">
                                <CustomInput
                                    name="last_name"
                                    label="LAST_NAME"
                                    value={ceo && ceo.last_name}
                                    type="text"
                                    handleInput={this.props.handleCeo}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 my-10">
                                <CustomInput
                                    name="identity_number"
                                    label="Passport_or_identity_card_number"
                                    value={ceo && ceo.identity_number}
                                    type="number"
                                    handleInput={this.props.handleCeo}
                                />
                            </div>
                            <div className="col-6 my-10">
                                <CustomInput
                                    name="bank_account"
                                    label="Bank_account"
                                    value={ceo && ceo.bank_account}
                                    type="number"
                                    handleInput={this.props.handleCeo}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="foundersSection d-flex flex-column mt-30 mb-15">
                        <div className="d-flex">
                            <h3>
                                <Translate text="FOUNDERS" />:
                            </h3>
                            <div className="align-self-center ml-20">
                                <CustomButton
                                    className="btn-secondary medium"
                                    type="button"
                                    onClick={() => this.props.addStaff("founders")}
                                >
                                    <Translate text="ADD" />
                                </CustomButton>
                            </div>
                        </div>

                        {founders &&
                            founders.map((e, index) => {
                                return (
                                    <div className="row mb-25" key={index}>
                                        <div className="col-5 my-10">
                                            <CustomInput
                                                name="first_name"
                                                label="NAME"
                                                value={e.first_name}
                                                type="text"
                                                index={index}
                                                handleInput={this.props.handleFounders}
                                            />
                                        </div>
                                        <div className="col-5 my-10">
                                            <CustomInput
                                                name="last_name"
                                                label="LAST_NAME"
                                                value={e.last_name}
                                                type="text"
                                                index={index}
                                                handleInput={this.props.handleFounders}
                                            />
                                        </div>
                                        <div className="col-2 trashDiv d-flex align-items-center">
                                            <i
                                                className="fa fa-trash trash-icon border-1 border-radius-6 pointer"
                                                onClick={() => {
                                                    this.props.deleteStaff(index, "founders");
                                                }}
                                            />
                                        </div>
                                        <div className="col-5 my-10">
                                            <CustomInput
                                                name="bank_account"
                                                label="Bank_account"
                                                value={e.bank_account}
                                                type="number"
                                                index={index}
                                                handleInput={this.props.handleFounders}
                                            />
                                        </div>

                                        <div className="col-7 my-10">
                                            <CustomInput
                                                name="identity_number"
                                                label="Passport_or_identity_card_number"
                                                value={e.identity_number}
                                                type="number"
                                                index={index}
                                                handleInput={this.props.handleFounders}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>

                    <div className="representativesSection d-flex flex-column mt-30 mb-15">
                        <div className="d-flex">
                            <h3>
                                <Translate text="REPRESENTATIVES" />:
                            </h3>
                            <div className="align-self-center ml-20">
                                <CustomButton
                                    className="btn-secondary medium"
                                    type="button"
                                    onClick={() => this.props.addStaff("representatives")}
                                >
                                    <Translate text="ADD" />
                                </CustomButton>
                            </div>
                        </div>
                        {representatives &&
                            representatives.map((e, index) => {
                                return (
                                    <div className="row mb-25" key={index}>
                                        <div className="col-5 my-10">
                                            <CustomInput
                                                name="first_name"
                                                label="NAME"
                                                value={e.first_name}
                                                type="text"
                                                index={index}
                                                handleInput={this.props.handleRepresentatives}
                                            />
                                        </div>
                                        <div className="col-5 my-10">
                                            <CustomInput
                                                name="last_name"
                                                label="LAST_NAME"
                                                value={e.last_name}
                                                type="text"
                                                index={index}
                                                handleInput={this.props.handleRepresentatives}
                                            />
                                        </div>
                                        <div className="col-2 trashDiv d-flex align-items-center">
                                            <i
                                                className="fa fa-trash trash-icon p-5 border-1 pointer border-radius-6"
                                                onClick={() => {
                                                    this.props.deleteStaff(index, "representatives");
                                                }}
                                            />
                                        </div>
                                        <div className="col-5 my-10">
                                            <CustomInput
                                                name="bank_account"
                                                label="Bank_account"
                                                value={e.bank_account}
                                                type="number"
                                                index={index}
                                                handleInput={this.props.handleRepresentatives}
                                            />
                                        </div>
                                        <div className="col-7 my-10">
                                            <CustomInput
                                                name="identity_number"
                                                label="Passport_or_identity_card_number"
                                                value={e.identity_number}
                                                type="number"
                                                index={index}
                                                handleInput={this.props.handleRepresentatives}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>

                    <div className="mt-10">
                        <CustomButton className="btn-save mr-5" type="button" onClick={this.props.updateCRPS}>
                            <Translate text="UPDATE_CRPS" />
                        </CustomButton>
                    </div>
                </div>
            </CustomForm>
        );
    }
}

export default ClientAdditionalInfo;
