import { CustomButton, CustomFileInput, CustomForm, CustomInput } from "devkit/Form/Form";
import Translate from "functions/utilFunctions/translate";
import React, { Component, Fragment } from "react";
import PersonalAccountAfter from "../../pages/PersonalAccount/PersonalAccountAfter/PersonalAccountAfter";
import PersonalAccountLeftSide from "./PersonalAccountLeftSide/PersonalAccountLeftSide";
import Modal from "../../devkit/Modal/Modal";
import ApiService from "services/api.service";
import { StoreService } from "../../services/store.service";
import { UploadIcon, BackIcon } from "../../components/Icons/Icons";
import { ModalContent } from "devkit/Modal/ModalContent";
import { Loader } from "../../devkit/Loader/Loader";
import { UtilService } from "services/util.service";
import { parseDateForMySQL } from "functions/utilFunctions/parsingFunctions";
import TimeTrackPersonal from "./TimeTrackPersonal/TimeTrackPersonal";
import { ScreenContext } from "components/ScreenContext/ScreenContext";

class PersonalAccount extends Component<any> {
    state = {
        handleChange: false,
        form: {
            id: {
                value: "",
                errors: [],
            },
            firstName: {
                value: "",
                errors: [],
            },
            lastName: {
                value: "",
                errors: [],
            },
            email: {
                value: "",
                errors: [],
            },
            phone_number: {
                value: "",
                errors: [],
            },
            dateOfBirth: {
                value: null as any,
                errors: [],
            },
            dateOfEmployment: {
                value: null as any,
                errors: [],
            },
            OldPassword: {
                value: "",
                errors: [],
            },
            NewPassword: {
                value: "",
                errors: [],
            },
            RepeatNewPassword: {
                value: "",
                errors: [],
            },
            OldPin: {
                value: "",
                errors: [],
            },
            NewPin: {
                value: "",
                errors: [],
            },
            RepeatNewPin: {
                value: "",
                errors: [],
            },
            role: {
                value: "",
                errors: [],
            },
            profile_image: {
                value: "",
                errors: [],
            },
        },
        photo: {
            value: null,
            errors: [],
        },
        showPasswordModal: false,
        showSuccessModal: false,
        showFailedModal: false,
        showPinModal: false,
        modalSuccessText: "",
        modalFailedText: "",
        changeType: "",
        imageUploadModal: false,
        isOver: false,
        completed: 0,
        uploadComplete: false,
        uploading: false,
        photoUploadModalFailure: false,
        photoUploadModalFailureText: "",
        showSameValue: false,
        showSuccessModalData: false,
        showUpdateFailureModal: false,
        showBasicInfo: true,
        showTimeTrack: false,
        timeTrackModal: false,
        endDateErrorModal: false,
        groups: [] as any,
    };

    static contextType = ScreenContext;

    componentDidMount() {
        const user = StoreService.getStoreProperty("user");
        const token = StoreService.getStoreProperty("token");
        const userFormated = {};
        Object.keys(user).forEach((e) => {
            if (e === "dateOfBirth" || e === "dateOfEmployment") {
                userFormated[e] = {
                    value: new Date(user[e]),
                    errors: [],
                };
            } else {
                userFormated[e] = {
                    value: user[e],
                    errors: [],
                };
            }
        });

        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                ...userFormated,
            },
        }));
        ApiService.getAllGroups(token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    groups: res.data,
                });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.photo.value !== this.state.photo.value && this.state.photo.value) {
            this.setState({ uploading: true });
            this.handleUploadImage();
        }
    }

    handleChange = () => {
        this.setState((prevState: any) => ({
            handleChange: !prevState.handleChange,
        }));
    };

    handleInput = (name, data) => {
        this.setState(
            (prevState: any) => ({
                form: {
                    ...prevState.form,
                    [name]: {
                        value: data.value,
                        errors: data.errors,
                    },
                },
            }),
            () => {
                if (name === "dateOfEmployment" || name === "dateOfBirth") {
                }
            }
        );
    };

    handleBack = () => {
        this.props.history.push("/calendar");
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { phone_number, email, dateOfBirth, dateOfEmployment, firstName, lastName, role, id } = this.state.form;
        const form = {
            email,
            dateOfBirth,
            dateOfEmployment,
            firstName,
            lastName,
            role,
        };

        const errors = {};
        Object.keys(form).forEach((e) => {
            if (!form[e].value && form[e].errors.length === 0) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
        });

        if (new Date(this.state.form.dateOfEmployment.value) < new Date(this.state.form.dateOfBirth.value)) {
            this.setState({
                endDateErrorModal: true,
            });
            return;
        }

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                showInputsRequired: true,
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else {
            const token = StoreService.getStoreProperty("token");
            ApiService.updatePersonalAccount(
                {
                    id: id.value,
                    phone_number: phone_number.value,
                    email: email.value,
                    dateOfBirth: parseDateForMySQL(dateOfBirth.value),
                    dateOfEmployment: parseDateForMySQL(dateOfEmployment.value),
                    firstName: firstName.value,
                    lastName: lastName.value,
                    role: role.value,
                },
                [],
                token
            ).then((response) => {
                if (response.status === "OK") {
                    StoreService.updateStoreProperty("user", response.data[0]);
                    this.setState({
                        handleChange: false,
                        showSuccessModalData: true,
                    });
                } else {
                    this.setState({
                        showUpdateFailureModal: true,
                    });
                }
            });
        }
    };

    handleDataChange = (e) => {
        e.preventDefault();
        const { changeType } = this.state;
        let passwords;
        let resetFields;
        let oldItem;
        let newItem;
        let repeatNewItem;
        if (changeType === "password") {
            oldItem = this.state.form.OldPassword;
            newItem = this.state.form.NewPassword;
            repeatNewItem = this.state.form.RepeatNewPassword;
            passwords = {
                OldPassword: oldItem,
                NewPassword: newItem,
                RepeatNewPassword: repeatNewItem,
            };

            resetFields = {
                NewPassword: {
                    value: "",
                    errors: [],
                },
                OldPassword: {
                    value: "",
                    errors: [],
                },
                RepeatNewPassword: {
                    value: "",
                    errors: [],
                },
            };
        } else {
            oldItem = this.state.form.OldPin;
            newItem = this.state.form.NewPin;
            repeatNewItem = this.state.form.RepeatNewPin;

            passwords = {
                OldPin: oldItem,
                NewPin: newItem,
                RepeatNewPin: repeatNewItem,
            };
            resetFields = {
                OldPin: {
                    value: "",
                    errors: [],
                },
                NewPin: {
                    value: "",
                    errors: [],
                },
                RepeatNewPin: {
                    value: "",
                    errors: [],
                },
            };
        }

        const errors = {};
        Object.keys(passwords).forEach((e) => {
            if (!passwords[e].value) {
                errors[e] = {
                    value: passwords[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            } else if (changeType === "password" && passwords[e].value.length < 6) {
                errors[e] = {
                    value: passwords[e].value,

                    errors: ["PASSWORD_NOT_VALID"],
                };
            } else if (changeType === "password" && newItem.value !== repeatNewItem.value) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        NewPassword: {
                            value: prevState.form.NewPassword.value,
                            errors: ["PSWDontMatch"],
                        },

                        RepeatNewPassword: {
                            value: prevState.form.NewPassword.value,
                            errors: ["PSWDontMatch"],
                        },
                    },
                }));
            } else if (changeType !== "password" && passwords[e].value.length !== 4) {
                errors[e] = {
                    value: passwords[e].value,

                    errors: ["PIN_NOT_VALID"],
                };
            } else if (changeType !== "password" && newItem.value !== repeatNewItem.value) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        NewPin: {
                            value: passwords[e].value,
                            errors: ["PINDontMatch"],
                        },
                        RepeatNewPin: {
                            value: prevState.form.NewPassword.value,
                            errors: ["PINDontMatch"],
                        },
                    },
                }));
            }
        });

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else {
            if (newItem.value !== repeatNewItem.value) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                    },
                }));
            } else {
                let data;
                let url;
                if (changeType === "password") {
                    data = {
                        old_password: oldItem.value,
                        new_password: newItem.value,
                    };
                    url = "users/updatePas";
                } else {
                    data = { old_pin: oldItem.value, new_pin: newItem.value };
                    url = "users/updatePin";
                }
                const token = StoreService.getStoreProperty("token");

                ApiService.updateData(data, token, url).then((response) => {
                    if (response.status === "OK") {
                        this.setState((prevState: any) => ({
                            showPasswordModal: false,
                            showPinModal: false,
                            showSuccessModal: true,
                            form: {
                                ...prevState.form,
                                ...resetFields,
                            },
                        }));
                    } else if (response.info === "Check your old password." && response.status === "NOT OK") {
                        this.setState((prevState: any) => ({
                            showFailedModal: true,
                            form: {
                                ...prevState.form,
                                ...resetFields,
                            },
                        }));
                    } else {
                        this.setState(() => ({
                            showSameValue: true,
                        }));
                    }
                });
            }
        }
    };

    toggleModal = (name) => {
        if (this.state.uploading) {
            return;
        }
        let resetFields;
        let changeType = "";
        if (name === "showPasswordModal") {
            changeType = "password";
        } else if (name === "showPinModal") {
            changeType = "pin";
        }
        resetFields = {
            NewPassword: {
                value: "",
                errors: [],
            },
            OldPassword: {
                value: "",
                errors: [],
            },
            RepeatNewPassword: {
                value: "",
                errors: [],
            },
            OldPin: {
                value: "",
                errors: [],
            },
            NewPin: {
                value: "",
                errors: [],
            },
            RepeatNewPin: {
                value: "",
                errors: [],
            },
        };
        this.setState((prevState: any) => ({
            [name]: !this.state[name],
            modalSuccessText: name,
            modalFailedText: name,
            passwordsDontMatch: name,
            changeType,
            form: {
                ...prevState.form,
                ...resetFields,
            },
        }));
    };

    handleFileChange = (name, e, drop?) => {
        this.setState({
            [name]: UtilService.handleAddFile(name, e, drop),
        });
    };

    handleUploadImage = () => {
        const id = StoreService.getStoreProperty("user").id;
        const token = StoreService.getStoreProperty("token");
        const photo = this.state.photo.value || "";
        if (photo["size"] > 2097152) {
            this.setState({
                uploading: false,
                photoUploadModalFailureText: "Image is too big. Max file size is 2MB",
                photoUploadModalFailure: true,
            });
            return;
        }
        const formData = new FormData();
        formData.append("photo", photo);
        formData.append("id", id);
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        ApiService.uploadUserImage(formData, token, config).then((res) => {
            if (res.status === "OK") {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        profile_image: {
                            value: res.data.profile_image,
                            errors: [],
                        },
                    },
                    completed: 0,
                    uploadComplete: false,
                    imageUploadModal: false,
                    uploading: false,
                    photo: {
                        value: null,
                        errors: [],
                    },
                }));
                let user = StoreService.getStoreProperty("user");
                user = {
                    ...user,
                    profile_image: res.data.profile_image,
                };
                StoreService.updateStoreProperty("user", user);
            } else {
                this.setState({
                    photoUploadModalFailureText: res.info,
                    photoUploadModalFailure: true,
                    uploading: false,
                });
            }
        });
    };

    renderInputsModal = (type) => {
        const condition = type === "password";
        const modalName = condition ? "showPasswordModal" : "showPinModal";
        const translate = condition ? "ChangePassword" : "ChangePin";
        const old_item = condition ? "OldPassword" : "OldPin";
        const new_item = condition ? "NewPassword" : "NewPin";
        const repeat_new_item = condition ? "RepeatNewPassword" : "RepeatNewPin";

        return (
            <Modal
                toggleModal={this.toggleModal}
                modalName={modalName}
                className={this.state[modalName] ? "visible" : ""}
                modalWrapperClasses="w-400 h-400 padding-10"
            >
                <div className="col-10 offset-1 h-100perc padding-0">
                    <div className="h-60 col-12 d-flex row justify-content-between padding-0 margin-l-0">
                        <p className="d-flex align-self-center password-title">
                            <Translate text={translate} />
                        </p>
                        <i
                            className="fas fa-times fa-2x align-self-center pointer"
                            style={{ color: "#43425D" }}
                            onClick={() => this.toggleModal(modalName)}
                        />
                    </div>
                    <div className="col-12 padding-0 h-250">
                        <CustomForm className="d-flex col-12 h-100perc flex-column justify-content-around padding-0">
                            <CustomInput
                                name={old_item}
                                label={old_item}
                                type="password"
                                handleInput={this.handleInput}
                                value={this.state.form[old_item].value}
                                errors={this.state.form[old_item].errors}
                                maxLength={condition ? 20 : 4}
                                required={true}
                            />
                            <CustomInput
                                name={new_item}
                                label={new_item}
                                type="password"
                                handleInput={this.handleInput}
                                value={this.state.form[new_item].value}
                                errors={this.state.form[new_item].errors}
                                maxLength={condition ? 20 : 4}
                                required={true}
                            />
                            <CustomInput
                                name={repeat_new_item}
                                label={repeat_new_item}
                                type="password"
                                handleInput={this.handleInput}
                                value={this.state.form[repeat_new_item].value}
                                errors={this.state.form[repeat_new_item].errors}
                                maxLength={condition ? 20 : 4}
                                required={true}
                            />
                        </CustomForm>
                    </div>
                    <div className="d-flex justify-content-center mt-10">
                        <CustomButton className="btn-save" type="button" onClick={this.handleDataChange}>
                            <Translate text="Update" />
                        </CustomButton>
                    </div>
                </div>
            </Modal>
        );
    };

    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };

    relatedActions = (id) => {
        StoreService.updateStoreProperty("actionsFilters", {
            user_id: id,
        });
        this.props.history.push({
            pathname: "/actions/1",
        });
    };

    actionsCalendar = (id) => {
        StoreService.updateStoreProperty("calendarFilters", {
            user_id: id,
            period: "day",
        });
        this.props.history.push({
            pathname: `/calendar`,
        });
    };

    renderBasicInfo = () => {
        this.setState({
            showBasicInfo: true,
            showTimeTrack: false,
        });
    };

    renderTimeTrack = () => {
        this.setState({
            showBasicInfo: false,
            showTimeTrack: true,
        });
    };

    render() {
        const user = StoreService.getStoreProperty("user");
        const { screenSize } = this.context;

        return (
            <div className="personalAccount">
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessModal"
                    className={this.state.showSuccessModal ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        title="SUCCESSFULLY_CHANGED"
                        text={this.state.modalSuccessText === "showPasswordModal" ? "PSWSuccess" : "PINSuccess"}
                        modalName="showSuccessModal"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showFailedModal"
                    className={this.state.showFailedModal ? "visible error" : "error"}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        title="FAILED"
                        text={this.state.modalFailedText === "showPasswordModal" ? "oldPSWWrong" : "oldPinWrong"}
                        modalName="showFailedModal"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSameValue"
                    className={this.state.showSameValue ? "visible error" : "error"}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        title="FAILED"
                        text={this.state.modalFailedText === "showPasswordModal" ? "sameValuePSW" : "sameValuePIN"}
                        modalName="showFailedModal"
                        toggleModal={() => this.toggleModal("showSameValue")}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessModalData"
                    className={this.state.showSuccessModalData ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        title="SUCCESSFULLY_CHANGED"
                        text={"DATA_CHANGE_SUCCESS"}
                        modalName="showSuccessModalData"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showUpdateFailureModal"
                    className={this.state.showUpdateFailureModal ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        title="FAILURE"
                        text={"DATA_CHANGE_FAILURE"}
                        modalName="showUpdateFailureModal"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="endDateErrorModal"
                    className={this.state.endDateErrorModal ? "visible bg-info-3" : ""}
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="DATE_OF_EMPLOYMENT_CANNOT_BE_LOWER_THAN_DATE_OF_BIRTH"
                        modalName="endDateErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    modalName="imageUploadModal"
                    toggleModal={this.toggleModal}
                    className={this.state.imageUploadModal ? "visible" : ""}
                    modalWrapperClasses="fileUploadModal"
                >
                    {!this.state.uploading && (
                        <div className="pa-20 h-100-perc">
                            <div
                                onDrop={(e) => this.handleFileChange("photo", e, true)}
                                className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                    this.state.isOver ? "dotted" : ""
                                }`}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    this.changeBorderType(true);
                                }}
                                onDragLeave={() => this.changeBorderType(false)}
                            >
                                <div className="text d-flex flex-column justify-content-center align-items-center">
                                    <span className="d-block mb-10">
                                        <UploadIcon width={40} className="uploadIcon" />
                                    </span>
                                    <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                        <Translate text="DRAG_AND_DROP" />
                                    </span>
                                    <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">or</span>
                                </div>
                                <CustomFileInput
                                    name="photo"
                                    handleInput={this.handleFileChange}
                                    value={this.state.photo.value}
                                    label={"CHOSE_PHOTO"}
                                />
                            </div>
                        </div>
                    )}
                    {this.state.uploading && (
                        <div className="progress pa-20 h-100perc text-center">
                            <span className="f-s-20 mb-20">
                                <Translate text="UPLOAD_IN_PROGRESS" />
                            </span>
                            <span className="wrapper d-block">
                                <Loader width={200} />
                                <span className="loader-numbers">{this.state.completed}%</span>
                            </span>
                        </div>
                    )}
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="photoUploadModalFailure"
                    className={this.state.photoUploadModalFailure ? "visible error" : "error"}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        title="FAILURE"
                        text={
                            this.state.photoUploadModalFailureText !== ""
                                ? this.state.photoUploadModalFailureText
                                : "PHOTO_UPLOAD_FAILURE"
                        }
                        modalName="photoUploadModalFailure"
                        toggleModal={this.toggleModal}
                    />
                </Modal>

                {this.renderInputsModal(this.state.changeType)}
                <div className="accountDetails w-100perc d-flex flex-column p-30 w-100-perc row">
                    <div className={`${screenSize <= 650 ? "mobFooterDetails" : ""} d-flex`}>
                        <div className="d-flex align-items-center justify-content-between w-100-perc">
                            <button
                                onClick={this.handleBack}
                                className="pointer d-inline-block mr-30 goBack align-self-start h-40"
                            >
                                <BackIcon width={25} />
                            </button>

                            <div className="d-flex navigation justify-content-between w-100-perc border-b-1">
                                <div className="tabMenu d-flex">
                                    <CustomButton
                                        //to="/actions"
                                        onClick={this.renderBasicInfo}
                                        className={`mr-5 ${
                                            screenSize <= 650 ? "footerNavBtn" : "nav-btn"
                                        } border-radius-br-0 border-radius-bl-0 ${
                                            this.state.showBasicInfo ? "active" : ""
                                        }`}
                                    >
                                        <Translate text={"BASIC_INFO"} />
                                    </CustomButton>
                                    <CustomButton
                                        onClick={this.renderTimeTrack}
                                        className={`mr-5 ${
                                            screenSize <= 650 ? "footerNavBtn" : "nav-btn"
                                        } border-radius-br-0 border-radius-bl-0 ${
                                            this.state.showTimeTrack ? "active" : ""
                                        }`}
                                    >
                                        <Translate text={"Time-track"} />
                                    </CustomButton>
                                </div>
                                <div className="controlButtons d-flex">
                                    {this.state.showTimeTrack ? (
                                        <div className="mr-15">
                                            <CustomButton
                                                onClick={() => this.toggleModal("timeTrackModal")}
                                                className="btn-primary border-radius-br-0 border-radius-bl-0"
                                            >
                                                <Translate text={"Add_item"} />
                                            </CustomButton>
                                        </div>
                                    ) : (
                                        <div>
                                            <CustomButton
                                                className="btn-save border-radius-br-0 border-radius-bl-0"
                                                type="button"
                                                onClick={this.handleSubmit}
                                            >
                                                <Translate text="UPDATE" />
                                            </CustomButton>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accountDetailsBody d-flex align-self-center justify-content-center mt-20 w-100-perc">
                        {this.state.showBasicInfo && (
                            <Fragment>
                                <div className="section1 h-400">
                                    <PersonalAccountLeftSide
                                        toggleModal={this.toggleModal}
                                        firstName={user.firstName}
                                        lastName={user.lastName}
                                        role={user.role}
                                        image={this.state.form.profile_image.value}
                                        redirectToActions={() => this.relatedActions(this.state.form.id.value)}
                                        redirectToCalendar={() => this.actionsCalendar(this.state.form.id.value)}
                                    />
                                </div>
                                <div className="section2 d-flex flex-column personal-shadow align-items-center">
                                    <div className="col-12 d-flex flex-column h-100-perc margin-20 transition-2">
                                        <PersonalAccountAfter
                                            data={this.state.form}
                                            handleInput={this.handleInput}
                                            edit={this.state.handleChange}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        {this.state.showTimeTrack && (
                            <TimeTrackPersonal
                                handleInput={this.handleInput}
                                timeTrackModal={this.state.timeTrackModal}
                                toggleModal={this.toggleModal}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
export default PersonalAccount;
