import React, { Component } from "react";
import ApiService from "services/api.service";
import { StoreService } from "services/store.service";
import { Constants } from "services/constants.service";
import Table from "devkit/Table/Table";
import { Loader } from "devkit/Loader/Loader";
import {
    CustomButton,
    CustomForm,
    CustomInput,
    CustomDropdown,
    CustomTextArea,
    CustomFileInput,
} from "devkit/Form/Form";
import Translate from "functions/utilFunctions/translate";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import FolderStructure from "components/FolderStructure/FolderStructure";
import { parseDate } from "functions/utilFunctions/parsingFunctions";
import { UtilService } from "services/util.service";
import { UploadIcon } from "../../components/Icons/Icons";
import Paginate from "devkit/Pagination/Pagination";

class Groups extends Component<any> {
    state = {
        group: {
            id: {
                value: "",
                errors: [],
            },
            title: {
                value: "",
                errors: [],
            },
            description: {
                value: "",
                errors: [],
            },
            thumbnail: {
                description: "",
                id: "",
                thumbnail: "",
                title: "",
                errors: [],
            },
            employees_id: {
                value: [],
                errors: [],
            },
            members: {
                value: [] as any,
                errors: [""],
            },
            files: [],
            actions: [],
        },
        folderStructure: {
            sub: [
                {
                    sub: [],
                    created_at: null,
                    file_size: null,
                    id: 0,
                    level: 0,
                    parent_id: 0,
                    path: "",
                    title: "",
                    type: "",
                    updated_at: null,
                },
            ],
            created_at: null,
            file_size: null,
            id: 0,
            level: 0,
            parent_id: null,
            path: "",
            title: "",
            type: "",
            updated_at: null,
        },
        tableHeads: [
            {
                value: "TITLE",
                sortable: true,
                sortableType: "string",
                sortableName: "title",
            },
            {
                value: "MEMBERS_NUMBER",
                sortable: true,
                sortableType: "date",
                sortableName: "members_number",
            },
            {
                value: "MEMBERS",
                sortable: false,
                sortableType: "string",
                sortableName: "members",
            },
            {
                value: "ACTIONS",
                sortable: true,
                sortableType: "date",
                sortableName: "actions",
            },
            {
                value: "FILES",
                sortable: true,
                sortableType: "number",
                sortableName: "files",
            },
            { value: "" },
        ],
        membersTableHeads: [
            {
                value: "NAME",
                sortable: true,
                sortableType: "string",
                sortableName: "firstName",
            },
            { value: "" },
        ],
        actionsTableHeads: [
            {
                value: "actionName",
                sortable: true,
                sortableType: "string",
                sortableName: "title",
            },
            {
                value: "START_DATE",
                sortable: true,
                sortableType: "date",
                sortableName: "startDate",
            },
            {
                value: "END_DATE",
                sortable: true,
                sortableType: "date",
                sortableName: "endDate",
            },
            {
                value: "CLIENT",
                sortable: true,
                sortableType: "string",
                sortableName: "customer_name",
            },
            {
                value: "EMPLOYEES",
                sortable: true,
                sortableType: "string",
                sortableName: "firstName",
            },
            {
                value: "PRIORITY",
                sortable: true,
                sortableType: "number",
                sortableName: "priority",
            },
            {
                value: "STATE",
                sortable: true,
                sortableType: "number",
                sortableName: "status",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        groups: [],
        membersArray: [
            {
                value: 0,
                label: "",
            },
        ],
        showLoader: true,
        editGroup: false,
        showAddGroupModal: false,
        showDeleteModal: false,
        showSuccessModal: false,
        showDeleteFailure: false,
        deleteID: "",
        updateGroupSuccess: false,
        updateGroupFailure: false,
        addGroupSuccess: false,
        addGroupFailure: false,
        showMembersModal: false,
        showActionsModal: false,
        showFilesModal: false,
        contextItemExt: "",
        contextItemID: 0,
        contextItemName: {
            value: "",
            errors: [],
        },
        destinationID: 1,
        destinationPath: "",
        destinationLevel: 0,
        photo: {
            value: null,
            errors: [],
        },
        imageUploadModal: false,
        isOver: false,
        completed: 0,
        uploadComplete: false,
        uploading: false,
        photoUploadModalFailure: false,
        photoUploadModalFailureText: "",
        tempPhoto: "",
        actions: [],
        totalResults: 0,
        currentPage: "1",
        perPage: 5,
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        const users = StoreService.getStoreProperty("users");
        const filteredUsers = users.filter((e) => e.role !== 3);
        const membersArray = filteredUsers.map((e) => ({
            value: e.id,
            label: `${e.firstName} ${e.lastName}`,
        }));
        ApiService.getAllGroups(token).then((res) => {
            if (res.status === "OK") {
                const groups = res.data;
                this.setState({
                    groups,
                    membersArray,
                    showLoader: false,
                });
            }
        });
    }

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            group: {
                ...prevState.group,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    getActions = (group) => {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        ApiService.getGroupActions({ limit: perPage, offset: offset, group_id: group.id }, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    actions: res.data.actions,
                    totalResults: res.data.total,
                });
            }
        });
    };

    handlePageClick = (pageNumber) => {
        this.setState({
            currentPage: pageNumber,
        });
    };

    editGroup = (group, modalName) => {
        const members = group.members.map((e) => ({
            value: e.id,
            label: `${e.firstName} ${e.lastName}`,
        }));
        if (modalName === "showActionsModal") {
            this.getActions(group);
        }
        this.setState({
            group: {
                id: {
                    value: group.id,
                    errors: [],
                },
                title: {
                    value: group.title,
                    errors: [],
                },
                description: {
                    value: group.description,
                    errors: [],
                },
                thumbnail: {
                    thumbnail: group.thumbnail,
                    errors: [],
                },
                employees_id: {
                    value: group.members.map((e) => {
                        return e.id;
                    }),
                    errors: [],
                },
                members: {
                    value: members,
                    errors: [""],
                },
                files: group.files[0],
            },
            [modalName]: true,
            editGroup: true,
            folderStructure: group.files[0],
        });
    };

    newGroup = () => {
        this.setState({
            group: {
                id: {
                    value: "",
                    errors: [],
                },
                title: {
                    value: "",
                    errors: [],
                },
                description: {
                    value: "",
                    errors: [],
                },
                thumbnail: {
                    thumbnail: "",
                    errors: [],
                },
                employees_id: {
                    value: [],
                    errors: [],
                },
                members: {
                    value: [] as any,
                    errors: [""],
                },
                files: [],
                actions: [],
            },
            showAddGroupModal: true,
            editGroup: false,
            tempPhoto: "images/user.png",
        });
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    toggleActionsModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    confirmDelete = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };

    handleSubmit = () => {
        const { editGroup } = this.state;
        const { id, title, description, thumbnail, members } = this.state.group;
        const token = StoreService.getStoreProperty("token");
        const form = { title };
        const errors = {};

        const formData = new FormData();
        members.value &&
            members.value.forEach((e) => {
                formData.append("employees_id[]", e.value);
            });
        formData.append("title", title.value);
        formData.append("description", description.value);

        if (editGroup) {
            formData.append("id", id.value);
        }
        if (!editGroup && thumbnail.thumbnail !== "") {
            formData.append("thumbnail", thumbnail.thumbnail);
        }

        Object.keys(form).forEach((e) => {
            if (!form[e].value) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
        });

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                group: {
                    ...prevState.group,
                    ...errors,
                },
            }));
        } else {
            if (!editGroup) {
                ApiService.createGroup(formData, token).then((res) => {
                    if (res.status === "OK") {
                        ApiService.getAllGroups(token).then((res) => {
                            if (res.status === "OK") {
                                const groups = res.data;
                                this.setState({
                                    groups,
                                    showAddGroupModal: false,
                                    addGroupSuccess: true,
                                    showLoader: false,
                                });
                            }
                        });
                    } else {
                        this.setState({
                            showAddGroupModal: false,
                            addGroupFailure: true,
                        });
                    }
                });
            } else {
                ApiService.updateGroup(formData, token).then((res) => {
                    if (res.status === "OK") {
                        ApiService.getAllGroups(token).then((res) => {
                            if (res.status === "OK") {
                                const groups = res.data;
                                this.setState({
                                    groups,
                                    showLoader: false,
                                    showAddGroupModal: false,
                                    updateGroupSuccess: true,
                                });
                            }
                        });
                    } else {
                        this.setState({
                            showAddGroupModal: false,
                            updateGroupFailure: true,
                        });
                    }
                });
            }
        }
    };

    deleteGroup = () => {
        const id = this.state.deleteID;
        const token = StoreService.getStoreProperty("token");

        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            deleteID: "",
        });

        ApiService.deleteGroup(id, token).then((res) => {
            if (res.status === "OK") {
                ApiService.getAllGroups(token).then((res) => {
                    if (res.status === "OK") {
                        const groups = res.data;
                        this.setState({
                            groups,
                            showLoader: false,
                        });
                    }
                });
            }
        });
    };

    editMember = (id) => {
        this.props.history.push(`/accountsDetails/${id}`);
    };

    handleRedirect = (id) => {
        this.props.history.push(`/action/${id}`);
    };

    renderPriority = (priority) => {
        if (parseInt(priority) === 0) {
            return <Translate text="NO_STATE" />;
        }
        if (parseInt(priority) === 1) {
            return <Translate text="IMPORTANT" />;
        }
        if (parseInt(priority) === 2) {
            return <Translate text="VERY_IMPORTANT" />;
        }
        if (parseInt(priority) === 3) {
            return <Translate text="PRIORITY" />;
        }
        if (parseInt(priority) === 4) {
            return <Translate text="URGENT" />;
        }
    };

    renderActionState = (status) => {
        if (parseInt(status) === 0) {
            return <Translate text="NO_STATE" />;
        }
        if (parseInt(status) === 1) {
            return <Translate text="PLANNED_ACTION" />;
        }
        if (parseInt(status) === 2) {
            return <Translate text="IN_PROGRESS_ACTION" />;
        }
        if (parseInt(status) === 3) {
            return <Translate text="COMPLETED_ACTION" />;
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.photo.value !== this.state.photo.value && this.state.photo.value) {
            this.setState({ uploading: true });
            this.state.editGroup ? this.handleUploadImage() : this.showTemporaryPhoto();
        }
        if (prevState.currentPage !== this.state.currentPage) {
            this.getActions(this.state.group);
        }
    }

    handleUploadImage = () => {
        const id = this.state.group.id.value;
        const token = StoreService.getStoreProperty("token");
        const photo = this.state.photo.value || "";
        if (photo["size"] > 2097152) {
            this.setState({
                uploading: false,
                photoUploadModalFailureText: "Image is too big. Max file size is 2MB",
                photoUploadModalFailure: true,
            });
            return;
        }
        const formData = new FormData();
        formData.append("thumbnail", photo);
        formData.append("id", id);
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        ApiService.uploadThumbnail(formData, token, config).then((res) => {
            if (res.status === "OK") {
                this.setState((prevState: any) => ({
                    group: {
                        ...prevState.group,
                        thumbnail: res.data,
                    },
                    completed: 0,
                    uploadComplete: false,
                    imageUploadModal: false,
                    uploading: false,
                    photo: {
                        value: null,
                        errors: [],
                    },
                }));
            } else {
                this.setState({
                    photoUploadModalFailureText: res.info,
                    photoUploadModalFailure: true,
                    uploading: false,
                });
            }
        });
    };

    handleFileChange = (name, e, drop?) => {
        this.setState({
            [name]: UtilService.handleAddFile(name, e, drop),
        });
    };

    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };

    showTemporaryPhoto = () => {
        let img: any = this.state.photo.value;
        if (this.state.photo.value !== null) {
            let blob = new Blob([img], {
                type: "image/png",
            });
            let imageUrl = URL.createObjectURL(blob);
            this.setState({});
            this.setState((prevState: any) => ({
                group: {
                    ...prevState.group,
                    thumbnail: {
                        thumbnail: img,
                    },
                },
                tempPhoto: imageUrl,
                imageUploadModal: false,
                uploading: false,
            }));
        }
    };

    mouseDownHandler = (e) => {
        if (e.button === 1) {
            window.open(`/action/${e.currentTarget.id}`, "_blank");
        }
    };

    render() {
        const { group, groups, showLoader, totalResults, currentPage, perPage } = this.state;

        return (
            <div className="groupsWrapper">
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        text="DELETE_GROUP_?"
                        modalName="showDeleteModal"
                        title="DELETE_GROUP"
                        toggleModal={this.toggleModal}
                        deleteItem={this.deleteGroup}
                        cancelDelete={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessModal"
                    className={this.state.showSuccessModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="DELETE_GROUP_SUCCESS"
                        modalName="showSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteFailure"
                    className={this.state.showDeleteFailure ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="DELETE_GROUP_FAILURE"
                        modalName="showDeleteFailure"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="addGroupSuccess"
                    className={this.state.addGroupSuccess ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="ADD_GROUP_SUCCESS"
                        modalName="addGroupSuccess"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="addGroupFailure"
                    className={this.state.addGroupFailure ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="ADD_GROUP_FAILURE"
                        modalName="addGroupFailure"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="updateGroupSuccess"
                    className={this.state.updateGroupSuccess ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="UPDATE_GROUP_SUCCESS"
                        modalName="updateGroupSuccess"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="updateGroupFailure"
                    className={this.state.updateGroupFailure ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="UPDATE_GROUP_FAILURE"
                        modalName="updateGroupFailure"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showAddGroupModal"
                    className={this.state.showAddGroupModal ? "visible" : ""}
                    modalWrapperClasses="groupDetailsModal w-700 padding-10"
                >
                    <Modal
                        modalName="imageUploadModal"
                        toggleModal={this.toggleModal}
                        className={this.state.imageUploadModal ? "visible" : ""}
                        modalWrapperClasses="fileUploadModal"
                    >
                        {!this.state.uploading && (
                            <div className="pa-20 h-100-perc">
                                <div
                                    onDrop={(e) => this.handleFileChange("photo", e, true)}
                                    className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                        this.state.isOver ? "dotted" : ""
                                    }`}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        this.changeBorderType(true);
                                    }}
                                    onDragLeave={() => this.changeBorderType(false)}
                                >
                                    <div className="text d-flex flex-column justify-content-center align-items-center">
                                        <span className="d-block mb-10">
                                            <UploadIcon width={40} className="uploadIcon" />
                                        </span>
                                        <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                            <Translate text="DRAG_AND_DROP" />
                                        </span>
                                        <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">or</span>
                                    </div>
                                    <CustomFileInput
                                        name="photo"
                                        handleInput={this.handleFileChange}
                                        value={this.state.photo.value}
                                        label={"CHOSE_PHOTO"}
                                    />
                                </div>
                            </div>
                        )}
                        {this.state.uploading && (
                            <div className="progress pa-20 h-100perc text-center">
                                <span className="f-s-20 mb-20">
                                    <Translate text="UPLOAD_IN_PROGRESS" />
                                </span>
                                <span className="wrapper d-block">
                                    <Loader width={200} />
                                    <span className="loader-numbers">{this.state.completed}%</span>
                                </span>
                            </div>
                        )}
                    </Modal>
                    <div className="groupFormWrapper d-flex flex-column align-items-center">
                        <h2>
                            <Translate text={!this.state.editGroup ? "ADD_GROUP" : "EDIT_GROUP"} />
                        </h2>
                        <div className="groupForm d-flex">
                            <div className="image d-flex p-r mr-50">
                                <img
                                    className="image"
                                    src={
                                        !this.state.editGroup
                                            ? this.state.tempPhoto
                                            : group.thumbnail.thumbnail
                                            ? Constants.STORAGE + group.thumbnail.thumbnail
                                            : "images/user.png"
                                    }
                                    alt={"images/user.png"}
                                />
                                <div className="d-flex camera" onClick={() => this.toggleModal("imageUploadModal")}>
                                    <i
                                        className="fas fa-camera fa-2x align-self-center margin-t--1"
                                        style={{
                                            color: "white",
                                        }}
                                    />
                                </div>
                            </div>
                            <CustomForm className="d-flex flex-column w-100perc justify-content-center">
                                <div className="my-30 w-400">
                                    <CustomInput
                                        name="title"
                                        label="TITLE"
                                        type="text"
                                        handleInput={this.handleInput}
                                        value={group.title.value}
                                        errors={group.title.errors}
                                    />
                                </div>
                                <div className="mb-30 w-400">
                                    <CustomTextArea
                                        name="description"
                                        label="description"
                                        handleInput={this.handleInput}
                                        value={group.description.value}
                                        errors={group.description.errors}
                                        className="non-resizable f-s-14"
                                    />
                                </div>
                                <div className="mb-30 w-400">
                                    <CustomDropdown
                                        data={this.state.membersArray}
                                        name="members"
                                        value={group.members.value}
                                        errors={group.employees_id.errors}
                                        handleChange={this.handleInput}
                                        label="EMPLOYEES"
                                        className="multiple"
                                        multiple
                                    />
                                </div>
                            </CustomForm>
                        </div>

                        <div className="d-flex ">
                            <CustomButton type="button" className="btn-primary mr-10" onClick={this.handleSubmit}>
                                <Translate text={!this.state.editGroup ? "ADD_GROUP" : "EDIT_GROUP"} />
                            </CustomButton>

                            <CustomButton
                                type="button"
                                className="btn-primary"
                                onClick={() => this.toggleModal("showAddGroupModal")}
                            >
                                <Translate text="CANCEL" />
                            </CustomButton>
                        </div>
                    </div>
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showMembersModal"
                    className={this.state.showMembersModal ? "visible" : ""}
                    modalWrapperClasses="membersModal padding-20"
                >
                    <div className="d-flex flex-column align-items-center">
                        <h2 className="f-s-20">
                            <Translate text="MEMBERS" />
                        </h2>
                        <div className="mt-30 f-s-14">
                            <Table
                                theads={this.state.membersTableHeads}
                                theadsClassname="customThead one-line-text"
                                sortName="users"
                            >
                                {group.members.value !== null &&
                                    group.members.value.map((e: any, index) => (
                                        <tr
                                            key={index}
                                            //onClick={() => this.handleGoToAcc(e)}
                                            className="pointer"
                                        >
                                            <td className="w-300">{e.label}</td>
                                            <td>
                                                <div className="d-flex padding-5 justify-content-end">
                                                    <i
                                                        className="fas mr-20 fa-edit edit-icon padding-10 border-1 pointer border-radius-6 w-35"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            this.editMember(e.value);
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </Table>
                        </div>

                        <div className="d-flex mt-30">
                            <CustomButton
                                type="button"
                                className="btn-primary"
                                onClick={() => this.toggleModal("showMembersModal")}
                            >
                                <Translate text="CANCEL" />
                            </CustomButton>
                        </div>
                    </div>
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showActionsModal"
                    className={this.state.showActionsModal ? "visible" : ""}
                    modalWrapperClasses="actionsModal padding-20"
                >
                    <div className="d-flex flex-column align-items-center">
                        <h2 className="f-s-20">
                            <Translate text="ACTIONS" />
                        </h2>
                        <div className="margin-top-20 f-s-14 overflow-auto w-100-perc">
                            <Table
                                theads={this.state.actionsTableHeads}
                                theadsClassname="customThead"
                                handleSort={this.props.handleSort}
                                sortName="actions"
                            >
                                {this.state.actions &&
                                    this.state.actions.map((e: any, i) => (
                                        <tr
                                            className="pointer"
                                            id={e.id}
                                            onClick={() => this.handleRedirect(e.id)}
                                            key={i}
                                            onMouseDown={(ev) => this.mouseDownHandler(ev)}
                                        >
                                            <td>{e.title}</td>

                                            <td className="">{parseDate(e.startDate)}</td>
                                            <td className="">{parseDate(e.endDate)}</td>
                                            <td className="">{e.customer_name}</td>
                                            <td className="d-flex flex-column autoHeight">
                                                {e.users &&
                                                    Object.values(e.users).map((e: any) => {
                                                        return <span>{`${e.firstName} ${e.lastName}`}</span>;
                                                    })}
                                            </td>
                                            <td className="">{this.renderPriority(e.priority)}</td>
                                            <td className="">{this.renderActionState(e.status)}</td>
                                        </tr>
                                    ))}
                            </Table>
                            {!!this.state.totalResults && this.state.totalResults > 0 && (
                                <div className="d-flex justify-content-center">
                                    <Paginate
                                        totalItemsCount={totalResults}
                                        onChange={this.handlePageClick}
                                        activePage={parseInt(currentPage)}
                                        perPage={perPage}
                                        pageRangeDisplayed={5}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="d-flex mt-30">
                            <CustomButton
                                type="button"
                                className="btn-primary"
                                onClick={() => this.toggleModal("showActionsModal")}
                            >
                                <Translate text="CANCEL" />
                            </CustomButton>
                        </div>
                    </div>
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showFilesModal"
                    className={this.state.showFilesModal ? "visible" : ""}
                    modalWrapperClasses="folderStructureWrapper"
                >
                    <h2 className="f-s-20 text-center border-b-1 py-10 text-color-primary">
                        <Translate text="FILES" />
                    </h2>

                    <FolderStructure
                        data={this.state.folderStructure}
                        selectedItem={`${this.state.contextItemName.value}${this.state.contextItemExt}`}
                    />

                    {/* <div className="d-flex mt-30">
                        <CustomButton
                            type="button"
                            className="btn-primary"
                            onClick={() => this.toggleModal("showFilesModal")}
                        >
                            <Translate text="CANCEL" />
                        </CustomButton>
                    </div> */}
                </Modal>
                <div className="d-flex justify-content-between">
                    <div className="f-s-20 ">
                        <Translate text="GROUPS" />
                    </div>
                    <div>
                        <CustomButton
                            //to="new-client"
                            onClick={this.newGroup}
                            className="btn-primary"
                        >
                            <Translate text="ADD_GROUP" />
                        </CustomButton>
                    </div>
                </div>
                {showLoader ? (
                    <div className="loaderContainer">
                        <Loader width={200} />
                    </div>
                ) : (
                    <div className="overflow-auto mt-30 f-s-14 pb-20">
                        <Table
                            theads={this.state.tableHeads}
                            theadsClassname="customThead one-line-text"
                            sortName="users"
                            arrayToSort={groups}
                        >
                            {groups &&
                                groups.map((e: any, index) => (
                                    <tr
                                        key={index}
                                        //onClick={() => this.handleGoToAcc(e)}
                                        className="pointer"
                                    >
                                        <td className="w-300">{e.title}</td>
                                        <td className="w-200 one-line-text">{e.members.length}</td>
                                        <td className="w-200">
                                            <CustomButton
                                                type="submit"
                                                onClick={() => this.editGroup(e, "showMembersModal")}
                                                className="btn-secondary"
                                            >
                                                <Translate text="SHOW_MEMBERS" />
                                            </CustomButton>
                                        </td>
                                        <td className="w-200">
                                            <CustomButton
                                                type="submit"
                                                onClick={() => this.editGroup(e, "showActionsModal")}
                                                className="btn-secondary"
                                            >
                                                <Translate text="SHOW_ACTIONS" />
                                            </CustomButton>
                                        </td>
                                        <td className="w-200">
                                            <CustomButton
                                                type="submit"
                                                onClick={() => this.editGroup(e, "showFilesModal")}
                                                className="btn-secondary"
                                            >
                                                <Translate text="SHOW_FILES" />
                                            </CustomButton>
                                        </td>

                                        <td>
                                            <div className="d-flex padding-5 justify-content-end">
                                                <i
                                                    className="fas mr-20 fa-edit edit-icon padding-10 border-1 pointer border-radius-6 w-35"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        this.editGroup(e, "showAddGroupModal");
                                                    }}
                                                />
                                                <i
                                                    className="fas fa-trash trash-icon padding-10 border-1 pointer border-radius-6 w-35"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        this.confirmDelete(e.id);
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </Table>
                    </div>
                )}
            </div>
        );
    }
}

export default Groups;
