import { CustomButton, CustomForm, CustomInput } from "devkit/Form/Form";
import React, { Component } from "react";
import Modal from "devkit/Modal/Modal";
import ApiService from "../../services/api.service";
import Translate from "../../functions/utilFunctions/translate";
import { validateEmail } from "functions/utilFunctions/validations";
import { ModalContent } from "devkit/Modal/ModalContent";
import { ScreenContext } from "components/ScreenContext/ScreenContext";

class Login extends Component<any> {
    state = {
        form: {
            userName: {
                value: "",
                errors: [],
            },
            password: {
                value: "",
                errors: [],
            },
        },
        showSuccessModal: false,
        showErrorModal: false,
    };

    static contextType = ScreenContext;

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { userName, password } = this.state.form;
        const form = {
            userName,
            password,
        };
        const errors = {};
        Object.keys(form).forEach((e) => {
            if (!form[e].value) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
            if (e === "userName" && !validateEmail(form[e].value)) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["EMAIL_INVALID"],
                };
            }
        });
        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else {
            ApiService.login(form.userName.value, form.password.value).then((response) => {
                if (response.status === "OK") {
                    this.props.handleLogin(true, response.token, response.user, response.data);
                } else {
                    this.setState({
                        showErrorModal: true,
                    });
                }
            });
        }
    };

    redirectToReset = () => {
        this.props.history.push("/reset-password");
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleErrorModal = () => {
        this.setState((prevState: any) => ({
            showErrorModal: !prevState.showErrorModal,
        }));
        this.props.history.push("/login");
    };

    render() {
        const { userName, password } = this.state.form;

        return (
            <div className="login d-flex">
                <div>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showErrorModal"
                        className={this.state.showErrorModal ? "visible error" : "error"}
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            title="FAILED"
                            text="WRONG_CREDENTIALS"
                            modalName="showErrorModal"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                </div>
                <div id="left" className="leftSide w-50-perc h-vh-100" />
                <div id="rightSide" className="d-flex w-50-perc h-vh-100 flex-column justify-content-center">
                    <div className="align-self-center">
                        {/* <img
                            src="../images/logo.png"
                            alt="logo"
                            className="logo"
                        /> */}
                        <span className="hydraTitle">Kadar Plus</span>
                    </div>
                    <span className="f-s-16 align-self-center pt-20 welcomeMessage">
                        <Translate text={"Welcome"} />
                    </span>
                    <CustomForm handleSubmit={this.handleSubmit} className="d-flex flex-column">
                        <div className="padding-v-30 w-40-perc align-self-center">
                            <CustomInput
                                name="userName"
                                type="email"
                                handleInput={this.handleInput}
                                value={userName.value}
                                errors={userName.errors}
                                label="Email"
                                required
                            />
                        </div>
                        <div className="padding-b-20 w-40-perc align-self-center">
                            <CustomInput
                                name="password"
                                type="password"
                                handleInput={this.handleInput}
                                value={password.value}
                                errors={password.errors}
                                label="Password"
                                required
                            />
                        </div>
                        <div className="d-flex align-self-center pt-20">
                            <CustomButton type="submit" onClick={this.handleSubmit} className="btn-login btn-primary">
                                <Translate text="LOGIN" />
                            </CustomButton>
                        </div>
                    </CustomForm>
                    <div onClick={this.redirectToReset} className="flex align-self-center pointer margin-t-20 f-s-14">
                        <Translate text="Reset password" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
