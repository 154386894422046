import React, { Component } from "react";
import { parseDatetime } from "functions/utilFunctions/parsingFunctions";
import { StoreService } from "../../services/store.service";
import { Constants } from "../../services/constants.service";

const IMAGES_URL = Constants.STORAGE;

interface IProps {
    conversation: any;
    clickHandle: any;
    mentors?: any;
    students?: any;
    allUsers?: any;
    currentConversation: any;
}

interface IState {
    currentUser: any;
    chatProfiles: any;
}

export default class ChatConversationPreview extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        const currentUser = StoreService.getStoreProperty("user");

        this.state = {
            currentUser: currentUser,
            chatProfiles: this.getChatProfiles(
                props.conversation.users,
                currentUser,
                props
            ),
        };
    }

    getChatProfiles(userIds, currentUser, props) {
        let chatProfiles: any = [];

        if (userIds && currentUser && props) {
            Object.values(userIds).forEach((uid: any) => {
                if (uid !== currentUser.uid) {
                    const allProfiles =
                        StoreService.getStoreProperty("chatProfiles");
                    const thisChatProfile = allProfiles[uid];

                    if (thisChatProfile) {
                        let user = Object.values(props.allUsers).filter(
                            (student: any) => {
                                return student.id === thisChatProfile.userId;
                            }
                        )[0];

                        if (user) {
                            chatProfiles.push(user);
                        }
                    }
                }
            });
        }

        return chatProfiles && chatProfiles.length
            ? chatProfiles
            : [currentUser];
    }

    displayDateForConversation = () => {
        let dateTime = parseDatetime(
            this.props.conversation.timestamp.seconds * 1000
        ).split(" ");
        const time = dateTime[1].split(":");
        let hourMinute = time[0] + ":" + time[1];
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        if (
            new Date(
                this.props.conversation.timestamp.seconds * 1000
            ).getDate() -
                today.getDate() ===
            0
        ) {
            return hourMinute;
        } else if (
            new Date(
                this.props.conversation.timestamp.seconds * 1000
            ).getDate() -
                today.getDate() ===
            -1
        ) {
            return "yesterday";
        } else {
            const date = dateTime[0].split(".");
            return date[0] + "/" + date[1] + "/" + date[2];
        }
    };

    render() {
        return (
            <div
                className={`${
                    !this.props.currentConversation.messages.length
                        ? "oneConversation "
                        : " d-flex flex-column align-items-center justify-content-center h-100"
                }`}
                onClick={() => {
                    this.props.clickHandle();
                }}
            >
                {!!this.state.chatProfiles.length && (
                    <div className="chat-profiles-wrapper">
                        {this.state.chatProfiles.length === 1 ? (
                            Object.values(this.state.chatProfiles).map(
                                (profile: any, index) => {
                                    return (
                                        <div
                                            className="chat-profile"
                                            key={index}
                                        >
                                            <div
                                                className="margin-5"
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    backgroundImage:
                                                        "url(" +
                                                        IMAGES_URL +
                                                        profile.profile_image +
                                                        ")",
                                                    backgroundSize: "cover",
                                                    backgroundPosition:
                                                        "50% 50%",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                            <div className="nameDate">
                                                <span className="text-center titles">
                                                    {profile.firstName +
                                                        " " +
                                                        profile.lastName}
                                                </span>
                                                <p className="text-center titles mr-5 f-s-12">
                                                    {this.displayDateForConversation()}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                }
                            )
                        ) : (
                            <>
                                <div className="chat-profile mr-10">
                                    <div className="groupImages mx-10">
                                        {Object.values(
                                            this.state.chatProfiles
                                        ).map((profile: any, index: number) => {
                                            return (
                                                <div
                                                    className=""
                                                    style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        backgroundImage:
                                                            "url(" +
                                                            IMAGES_URL +
                                                            profile.profile_image +
                                                            ")",
                                                        backgroundSize: "cover",
                                                        backgroundPosition:
                                                            "50% 50%",
                                                        borderRadius: "50%",
                                                    }}
                                                    key={index}
                                                />
                                            );
                                        })}
                                    </div>

                                    <div className="nameDate">
                                        <span className="text-center titles">
                                            Group
                                        </span>
                                        <p className="text-center titles mr-5 f-s-12">
                                            {this.displayDateForConversation()}
                                        </p>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
