import { handleDebouncing, handleThrottle } from "../functions/utilFunctions/debounceAndThrottle";

export const UtilService = {
    loopThroughItems: (items, callback) => {
        for (const itemKey in items) {
            callback(items[itemKey], itemKey);
        }
    },
    clone: (obj) => {
        if (obj === null || typeof obj !== "object") {
            return obj;
        }
        if (obj instanceof Date) {
            return new Date(obj.getTime());
        }
        if (Array.isArray(obj)) {
            const clonedArr = [];
            obj.forEach(function (element) {
                // @ts-ignore
                clonedArr.push(UtilService.clone(element));
            });
            return clonedArr;
        }
        const clonedObj = new obj.constructor();

        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                clonedObj[prop] = UtilService.clone(obj[prop]);
            }
        }

        return clonedObj;
    },
    handleDebouncing: (callbackKey, callback, period) => {
        return handleDebouncing(callbackKey, callback, period);
    },
    handleThrottle: (callbackKey, callback, period) => {
        return handleThrottle(callbackKey, callback, period);
    },
    truncateString: (string, limit) => {
        if (!string || !limit) return "";
        string = String(string);

        return string.length > limit ? string.substr(0, limit - 1) + "..." : string;
    },
    sortArray: (sortItems, sortableName, direction, type) => {
        if (type === "string") {
            if (direction) {
                return sortItems.sort((a, b) => UtilService.sortStringArray(a, b, sortableName));
            } else {
                return sortItems.sort((a, b) => UtilService.sortStringArray(a, b, sortableName)).reverse();
            }
        }
        if (type === "date") {
            if (direction) {
                return sortItems.sort((a, b) => UtilService.sortDateArray(a, b, sortableName));
            } else {
                return sortItems.sort((a, b) => UtilService.sortDateArray(a, b, sortableName)).reverse();
            }
        }
        if (type === "number") {
            if (direction) {
                return sortItems.sort((a, b) => UtilService.sortNumberArray(a, b, sortableName));
            } else {
                return sortItems.sort((a, b) => UtilService.sortNumberArray(a, b, sortableName)).reverse();
            }
        }
    },
    sortStringArray: (curr, prev, name) => {
        const a = (curr[name] || "").toLowerCase();
        const b = (prev[name] || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
    },
    sortDateArray: (curr, prev, name) => {
        // @ts-ignore
        return new Date(prev[name]) - new Date(curr[name]);
    },
    sortNumberArray: (curr, prev, name) => {
        const a = parseInt(curr[name]);
        const b = parseInt(prev[name]);
        return a > b ? -1 : b > a ? 1 : 0;
    },
    handleAddFile: (name, e, drop?) => {
        // console.log('cao', name, e, drop);
        if (drop) {
            e.preventDefault();
            if (e.dataTransfer.files.length > 1) {
                alert("only one file allowed");
            } else {
                return { value: e.dataTransfer.files[0], errors: [] };
            }
        } else {
            return { value: e.value[0], errors: [] };
        }
    },
    handleAddFiles: (name, e, drop?) => {
        // console.log('cao', e.dataTransfer.files, drop);
        if (drop) {
            e.preventDefault();
            const files: any = Object.values(e.dataTransfer.files);
            let valueFiles = [] as any;
            for (let index = 0; index < files.length; index++) {
                let file = files[index];
                valueFiles.push(file);
            }
            return { value: valueFiles, errors: [] };
        } else {
            const files: any = Object.values(e.value);
            let valueFiles = [] as any;
            for (let index = 0; index < files.length; index++) {
                let file = files[index];
                valueFiles.push(file);
            }
            return { value: valueFiles, errors: [] };
        }
    },
    currentWeek: () => {
        const now = new Date();
        const startDay = 1; //0=sunday, 1=monday etc.
        const d = now.getDay(); //get the current day
        const weekStart = new Date(now.valueOf() - (d <= 0 ? 7 - startDay : d - startDay) * 86400000); //rewind to start day
        const weekEnd = new Date(weekStart.valueOf() + 6 * 86400000); //add 6 days to get last day
        return {
            weekStart,
            weekEnd,
        };
    },
};
