import { CustomButton, CustomForm, CustomInput } from "devkit/Form/Form";
import React, { Component } from "react";
import ApiService from "services/api.service";
import Table from "devkit/Table/Table";
import { StoreService } from "services/store.service";
import { parseDate } from "functions/utilFunctions/parsingFunctions";
import Translate from "functions/utilFunctions/translate";
import Modal from "devkit/Modal/Modal";
import CustomDropdown from "../../devkit/Form/CustomDropdown/CustomDropdown";
import { UtilService } from "../../services/util.service";
import { ModalContent } from "devkit/Modal/ModalContent";
import Paginate from "devkit/Pagination/Pagination";
import { Loader } from "devkit/Loader/Loader";
import { NavLink } from "react-router-dom";
import { ScreenContext } from "components/ScreenContext/ScreenContext";
import FilterSidebar from "components/FilterSidebar/FilterSidebar";
import { FilterIcon, ResetIcon } from "components/Icons/Icons";

class Accounts extends Component<any> {
    state = {
        form: {
            search: {
                value: "",
                errors: [],
            },
            customer_id: {
                value: null,
                errors: [],
            },
        },
        tableHeads: [
            {
                value: "NAME",
                sortable: true,
                sortableType: "string",
                sortableName: "first_name",
            },
            {
                value: "LAST_NAME",
                sortable: true,
                sortableType: "string",
                sortableName: "last_name",
            },
            {
                value: "DATE_OF_BIRTH",
                sortable: true,
                sortableType: "date",
                sortableName: "date_birth",
            },
            {
                value: "CLIENTS",
                sortable: false,
                sortableType: "string",
                sortableName: "name",
            },
            {
                value: "DATE_OF_EMPLOYMENT",
                sortable: true,
                sortableType: "date",
                sortableName: "date_employment",
            },
            {
                value: "ROLE",
                sortable: true,
                sortableType: "number",
                sortableName: "role",
            },
            { value: "" },
        ],
        salaries: [],
        users: [],
        customers: [] as any,
        showDeleteModal: false,
        showSuccessfulDelete: false,
        deleteID: "",
        totalResults: 0,
        currentPage: this.props.match.params.pageNo,
        perPage: 20,
        sorterDirection: "",
        sorterName: "",
        showLoader: true,
        showFilterSidebar: false,
    };

    static contextType = ScreenContext;

    handleNewAccount = () => {
        this.props.history.push("/new-account");
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        const { form } = this.state;
        // const { currentPage, perPage, form } = this.state;
        // const offset = (parseInt(currentPage) - 1) * perPage;

        const accountFilters = StoreService.getStoreProperty("accountFilters");
        this.setState(
            (prevState: any) => ({
                form: {
                    ...prevState.form,
                    search: {
                        value: accountFilters?.search ? accountFilters.search : form.search.value,
                        errors: [],
                    },
                    customer_id: {
                        value: accountFilters?.customer_id ? accountFilters.customer_id : form.customer_id.value,
                        errors: [],
                    },
                },
            })
            // () => {
            //     const data = {
            //         limit: perPage,
            //         offset: offset,
            //         customer_id: form.customer_id.value,
            //         search: form.search.value,
            //     };
            //     console.log(data, "data");

            //     ApiService.getAllUsers(data, token).then((res) => {
            //         if (res.status === "OK") {
            //             this.setState({
            //                 users: res.data.users,
            //                 totalResults: res.data.total,
            //             });
            //         }
            //     });
            // }
        );

        const { isMobile } = this.context;
        if (isMobile) {
            this.submitFilters();
        }
        ApiService.filterCustomers({ limit: 1000, offset: 0 }, token).then((res) => {
            if (res.success) {
                const customers = res.data.customers.map((e) => ({
                    value: e.id,
                    label: e.name,
                }));

                // customers.unshift(null);

                this.setState({
                    customers,
                    showLoader: false,
                });
            }
        });
    }

    componentWillUnmount() {
        const { form } = this.state;
        StoreService.updateStoreProperty("accountFilters", {
            search: form.search.value,
            customer_id: form.customer_id.value,
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return {
                currentPage: nextProps.match.params.pageNo,
                showLoader: true,
            };
        }
        return null;
    }

    handlePageClick = (pageNumber) => {
        this.props.history.push(`/accounts/${pageNumber}`);
    };

    componentDidUpdate(prevProps, prevState) {
        const { isMobile } = this.context;
        const {
            currentPage,
            perPage,
            sorterDirection,
            sorterName,
            form: { search, customer_id },
        } = this.state;
        if ((!isMobile && prevState.form !== this.state.form) || prevState.currentPage !== this.state.currentPage) {
            console.log("did update");

            const offset = (parseInt(currentPage) - 1) * perPage;
            const data: any = { limit: perPage, offset };
            if (sorterName || sorterDirection) {
                data[sorterName] = sorterDirection;
            }

            if (search.value) {
                data["search"] = this.state.form.search.value;
            }
            if (customer_id?.value) {
                data["customer_id"] = this.state.form.customer_id.value;
            }

            const token = StoreService.getStoreProperty("token");
            UtilService.handleDebouncing(
                "accountsDebouncing",
                () =>
                    ApiService.getAllUsers(data, token).then((res) => {
                        if (res.status === "OK") {
                            this.setState({
                                users: res.data.users,
                                totalResults: res.data.total,
                                showLoader: false,
                            });
                        }
                    }),
                300
            );
        }
    }
    handleGoToAcc = (user) => {
        this.props.history.push(`/accountsDetails/${user.id}`);
    };
    deleteHandle = () => {
        const { currentPage, perPage, deleteID, sorterDirection, sorterName } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const data: any = { limit: perPage, offset };
        if (sorterName || sorterDirection) {
            data[sorterName] = sorterDirection;
        }
        const token = StoreService.getStoreProperty("token");
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            deleteID: "",
        });
        ApiService.deleteAccount(
            {
                id: deleteID,
            },
            token
        ).then((response) => {
            if (response.status === "OK") {
                ApiService.getAllUsers(data, token).then((res) => {
                    if (res.status === "OK") {
                        this.setState({
                            users: res.data.users,
                            showSuccessfulDelete: true,
                        });
                    }
                });
            }
        });
    };
    confirmDelete = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };
    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    handleSort = (sortableName) => {
        const { currentPage, perPage, sorterDirection } = this.state;
        const sorter_direction = sorterDirection ? (sorterDirection === "desc" ? "asc" : "desc") : "asc";
        const token = StoreService.getStoreProperty("token");
        const offset = (parseInt(currentPage) - 1) * perPage;
        let data = { limit: perPage, offset };
        data[sortableName] = sorter_direction;

        ApiService.getAllUsers(data, token).then((response) => {
            if (response.status === "OK") {
                const users = response.data.users;
                this.setState({
                    users,
                    totalResults: response.data.total,
                    sorterDirection: sorter_direction,
                    sorterName: sortableName,
                    showLoader: false,
                });
            }
        });
    };

    renderRole = (role) => {
        if (parseInt(role) === 1) {
            return <Translate text="INTERN" />;
        }
        if (parseInt(role) === 2) {
            return <Translate text="EMPLOYEE" />;
        }
        if (parseInt(role) === 3) {
            return <Translate text="ADMIN" />;
        }
    };

    toggleSidebar = (e) => {
        e.preventDefault();
        this.setState({ showFilterSidebar: !this.state.showFilterSidebar });
    };

    resetFilters = (e) => {
        e.preventDefault();
        const token = StoreService.getStoreProperty("token");
        const { perPage } = this.state;
        this.setState({
            form: {
                search: {
                    value: "",
                    errors: [],
                },
                customer_id: {
                    value: null,
                    errors: [],
                },
            },
        });

        ApiService.getAllUsers({ limit: perPage, offset: 0 }, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    users: res.data.users,
                    totalResults: res.data.total,
                    showLoader: false,
                });
            }
        });
    };

    submitFilters = (e?) => {
        if (e) {
            e.preventDefault();
        }
        const token = StoreService.getStoreProperty("token");
        const {
            form: { search, customer_id },
            perPage,
            sorterDirection,
            sorterName,
        } = this.state;

        const data = { limit: perPage, offset: 0 };

        if (search.value) {
            data["search"] = search.value;
        }
        if (customer_id?.value) {
            data["customer_id"] = customer_id.value;
        }
        if (sorterDirection) {
            data[sorterName] = sorterDirection;
        }

        ApiService.getAllUsers(data, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    users: res.data.users,
                    totalResults: res.data.total,
                    showLoader: false,
                });
            }
        });
    };

    renderFilters = () => {
        const { isMobile } = this.context;
        const {
            customers,
            form: { search, customer_id },
        } = this.state;

        if (isMobile) {
            return (
                <>
                    <div>
                        <CustomButton
                            onClick={this.toggleSidebar}
                            className="filterBtn btn btn-primary d-flex align-items-center"
                        >
                            <FilterIcon className="icon" width={16}></FilterIcon>
                            <span className="ml-10">Filteri</span>
                        </CustomButton>
                    </div>
                    <FilterSidebar
                        opened={this.state.showFilterSidebar}
                        onClose={() => this.setState({ showFilterSidebar: !this.state.showFilterSidebar })}
                        submitFilters={this.submitFilters}
                        resetFilters={this.resetFilters}
                    >
                        <CustomForm>
                            <div className="mb-20">
                                <CustomInput
                                    value={search.value}
                                    handleInput={this.handleInput}
                                    name="search"
                                    type="text"
                                    label="Search"
                                />
                            </div>
                            <div className={"mb-20"}>
                                <CustomDropdown
                                    data={customers}
                                    name="customer_id"
                                    handleChange={this.handleInput}
                                    placeholder={<Translate text="CHOOSE_CUSTOMER" />}
                                    value={customers.find((c: any) => c.value === customer_id.value) || null}
                                    label="CLIENTS"
                                />
                            </div>
                        </CustomForm>
                    </FilterSidebar>
                </>
            );
        } else {
            return (
                <CustomForm className={"row mt-10"}>
                    <div className="col-md-3 mt-10">
                        <CustomInput
                            value={search.value}
                            handleInput={this.handleInput}
                            name="search"
                            type="text"
                            label="Search"
                        />
                    </div>
                    <div className={"col-md-3 mt-10"}>
                        <CustomDropdown
                            data={customers}
                            name="customer_id"
                            handleChange={this.handleInput}
                            label="CLIENTS"
                            value={customers.find((c: any) => c.value === customer_id.value) || null}
                            placeholder={<Translate text="CHOOSE_CUSTOMER" />}
                        />
                    </div>
                    <div className="w-100-perc d-flex align-items-center justify-content-end col-6">
                        <CustomButton className="btn-primary d-flex align-items-center" onClick={this.resetFilters}>
                            <ResetIcon width={15} className="fill-white mr-10"></ResetIcon>
                            <Translate text="RESET_FILTERS"></Translate>
                        </CustomButton>
                    </div>
                </CustomForm>
            );
        }
    };

    render() {
        const { totalResults, currentPage, perPage, showLoader } = this.state;
        const { screenSize } = this.context;
        return (
            <div className="accounts-wrapper">
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        title="DELETE_EMPLOYEE"
                        text={"areYouSureDeleteEmployee"}
                        modalName="showDeleteModal"
                        toggleModal={this.toggleModal}
                        deleteItem={this.deleteHandle}
                        cancelDelete={() => this.toggleModal("showDeleteModal")}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessfulDelete"
                    className={this.state.showSuccessfulDelete ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        title="DELETE_EMPLOYEE"
                        text={"EMPLOYEE_DELETE_SUCCESS"}
                        modalName="showSuccessfulDelete"
                        toggleModal={this.toggleModal}
                    />
                </Modal>{" "}
                <div className="d-flex flex-column">
                    <div
                        className={`${
                            screenSize <= 576 ? "mobFooterOverview" : ""
                        } d-flex justify-content-between align-items-end mb-20`}
                    >
                        <div className="navigation d-flex">
                            <NavLink
                                to={`/accounts/1`}
                                className={`action-title d-flex align-items-center justify-content-center tab border-radius-br-0 border-radius-bl-0 ${
                                    this.props.accounts && "active"
                                }`}
                                style={{
                                    textDecoration: "none",
                                }}
                                activeClassName="active"
                            >
                                <Translate text={"EMPLOYEES"} />
                            </NavLink>
                            <NavLink
                                to={`/salaries/1`}
                                style={{
                                    textDecoration: "none",
                                }}
                                className={`action-title d-flex align-items-center justify-content-center tab border-radius-br-0 border-radius-bl-0 mx-10 ${
                                    !this.props.accounts && "active"
                                }`}
                                activeClassName="active"
                            >
                                <Translate text={"SALARIES"} />
                            </NavLink>
                            <NavLink
                                to={`/time-track/1`}
                                style={{
                                    textDecoration: "none",
                                }}
                                className={`action-title d-flex align-items-center justify-content-center tab border-radius-br-0 border-radius-bl-0  ${
                                    this.props.timetrack && "active"
                                }`}
                                activeClassName="active"
                            >
                                <Translate text={"Time-track"} />
                            </NavLink>
                        </div>

                        <div className="controlButtons">
                            <div>
                                <CustomButton className="btn-primary" type="submit" onClick={this.handleNewAccount}>
                                    <Translate text={"addEmployee"} />
                                </CustomButton>
                            </div>
                        </div>
                    </div>

                    <div className="filters"> {this.renderFilters()}</div>
                </div>
                {showLoader ? (
                    <div className="loaderContainer">
                        <Loader width={200} />
                    </div>
                ) : (
                    <>
                        <div className="overflow-auto mt-30 f-s-14 pb-20">
                            <Table
                                theads={this.state.tableHeads}
                                theadsClassname="customThead"
                                handleSort={this.handleSort}
                                sortName="users"
                                arrayToSort={this.state.users}
                            >
                                {this.state.users.map((e: any) => (
                                    <tr key={e.id} onClick={() => this.handleGoToAcc(e)} className="pointer">
                                        <td className="w-250">{e.firstName}</td>
                                        <td className="w-300">{e.lastName}</td>
                                        <td className="w-300">{parseDate(e.dateOfBirth)}</td>
                                        <td className=" w-200">
                                            {e.customers &&
                                                Object.values(e.customers).map((e: any) => {
                                                    return (
                                                        <span key={e.id} className="mr-5">
                                                            {e.name}
                                                        </span>
                                                    );
                                                })}
                                        </td>
                                        <td className="w-250">{parseDate(e.dateOfEmployment)}</td>
                                        <td className="w-150"> {this.renderRole(e.role)}</td>

                                        <td>
                                            <div className="d-flex padding-5 justify-content-end">
                                                <i
                                                    className="fas mr-20 fa-edit edit-icon padding-10 border-1 pointer border-radius-6 w-35"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        this.handleGoToAcc(e);
                                                    }}
                                                />
                                                <i
                                                    className="fas fa-trash trash-icon padding-10 border-1 pointer border-radius-6 w-35"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        this.confirmDelete(e.id);
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </Table>
                        </div>
                        {!!this.state.totalResults && this.state.totalResults > 0 && (
                            <div className="d-flex justify-content-center">
                                <Paginate
                                    totalItemsCount={totalResults}
                                    onChange={this.handlePageClick}
                                    activePage={parseInt(currentPage)}
                                    perPage={perPage}
                                    pageRangeDisplayed={5}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default Accounts;
