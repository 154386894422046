import React, { Component } from "react";
import { CustomButton, CustomInput, CustomForm } from "devkit/Form/Form";
import Translate from "../../functions/utilFunctions/translate";
import ContextMenu from "../../devkit/ContextMenu/ContextMenu";
import ApiService from "services/api.service";
import { StoreService } from "services/store.service";
import { EditIcon } from "components/Icons/Icons";
import { ScreenContext } from "components/ScreenContext/ScreenContext";

class Labels extends Component<any> {
    state = {
        showLabelsModal: false,
        allLabels: [
            {
                id: 0,
                title: "",
                color: "",
            },
        ] as any,
        label: {
            value: "",
            color: "",
            id: "",
        },
        ctxTop: 0,
        ctxLeft: 0,
        ctxVisible: false,
        ctxHeight: 80,
        renameItemModal: false,
        contextItemID: this.props.itemID,
        contextItemName: {
            value: "",
            errors: [],
        },
        contextItemExt: "",
        contextItemType: "",
        labels: this.props.labels,
        showCreateModal: false,
        labelName: {
            value: "",
            errors: [],
        },
        color: "",
        colors: [
            {
                id: 1,
                name: "color1",
                value: "#61bd4f",
                className: "w-50 h-30 border-radius-4 labelColor mr-5",
            },
            {
                id: 2,
                name: "color2",
                value: "#f2d600",
                className: "w-50 h-30 border-radius-4 labelColor mr-5",
            },
            {
                id: 3,
                name: "color3",
                value: "#ff9f1a",
                className: "w-50 h-30 border-radius-4 labelColor mr-5",
            },
            {
                id: 4,
                name: "color4",
                value: "#eb5a46",
                className: "w-50 h-30 border-radius-4 labelColor mr-5",
            },
            {
                id: 5,
                name: "color5",
                value: "#0079bf",
                className: "w-50 h-30 border-radius-4 labelColor mr-5",
            },
            {
                id: 6,
                name: "color6",
                value: "#00c2e0",
                className: "w-50 h-30 border-radius-4 labelColor mr-5",
            },
            {
                id: 7,
                name: "color7",
                value: "#51e898",
                className: "w-50 h-30 border-radius-4 labelColor mr-5",
            },
            {
                id: 8,
                name: "color8",
                value: "#344563",
                className: "w-50 h-30 border-radius-4 labelColor",
            },
        ],
        activeColor: null,
        editLabel: false,
        labelID: 0,
    };

    static contextType = ScreenContext;

    componentDidMount() {
        document.addEventListener("contextmenu", this.removeContextMenu);
        document.addEventListener("click", () => {
            this.hideContextMenu();
        });
    }
    componentWillUnmount() {
        document.removeEventListener("contextmenu", this.removeContextMenu);
        document.removeEventListener("click", this.hideContextMenu);
    }

    handleInput = (name, data) => {
        this.setState({
            [name]: {
                value: data.value,
            },
        });
    };

    createNewLabel = (e) => {
        let ctxTop = this.state.ctxTop;
        if (e.pageY + 190 > window.innerHeight) {
            ctxTop = ctxTop - 190;
        }
        this.setState({
            showCreateModal: true,
            editLabel: false,
            ctxTop,
            labelName: {
                value: "",
                errors: [],
            },
        });
    };

    editLabel = (ev, id) => {
        ev.stopPropagation();
        let label = this.state.allLabels.find((e) => e.id === id);
        this.setState({
            showCreateModal: true,
            editLabel: true,
            ctxHeight: 500,
            labelName: {
                value: label.title,
                errors: [],
            },
            color: label.color,
            labelID: label.id,
        });
    };

    addNewLabel = () => {
        const token = StoreService.getStoreProperty("token");
        let file_id = this.state.contextItemID;

        if (this.state.editLabel) {
            ApiService.updateLabel(
                { id: this.state.labelID, title: this.state.labelName.value, color: this.state.color },
                token
            ).then((res) => {
                if (res.status === "OK") {
                    ApiService.getAllLabels({ file_id }, token).then((response) => {
                        if (response.status === "OK") {
                            this.setState({
                                allLabels: response.data,
                            });
                        }
                    });
                }
            });
        } else {
            ApiService.storeLabel({ title: this.state.labelName.value, color: this.state.color }, token).then((res) => {
                if (res.status === "OK") {
                    this.setState((prevState: any) => ({
                        allLabels: [...prevState.allLabels, res.data],
                    }));
                }
            });
        }
    };

    deleteLabel = (ev, id) => {
        ev.stopPropagation();
        const token = StoreService.getStoreProperty("token");
        let file_id = this.state.contextItemID;
        ApiService.deleteLabel({ id }, token).then((res) => {
            if (res.status === "OK") {
                ApiService.getAllLabels({ file_id }, token).then((response) => {
                    if (response.status === "OK") {
                        this.setState({
                            allLabels: response.data,
                        });
                    }
                });
            }
        });
    };

    handleContextMenu = (e, id) => {
        let ctxTop = e.pageY;
        let ctxLeft = e.pageX;
        let ctxHeight = 360;

        if (e.pageX + 280 > window.innerWidth) {
            ctxLeft = ctxLeft - 280 + 10;
        }
        if (e.pageY + 280 > window.innerHeight) {
            ctxTop = ctxTop - 280;
        }
        if (e.nativeEvent.clientY + 280 < window.innerHeight) {
            ctxTop = e.nativeEvent.clientY;
        }
        if (e.pageY > window.innerHeight) {
            ctxTop = 400;
        }

        const token = StoreService.getStoreProperty("token");
        ApiService.getAllLabels({ file_id: id }, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    allLabels: res.data,
                    ctxTop,
                    ctxLeft,
                    ctxVisible: true,
                    contextItemID: id,
                    ctxHeight,
                });
            }
        });
    };

    hideContextMenu = () => {
        this.setState({ ctxVisible: false, contextItemID: 0, showCreateModal: false, color: "" });
    };

    hideCreateMenu = () => {
        this.setState({ showCreateModal: false });
    };

    removeContextMenu = (e) => {
        e.preventDefault();
    };

    addLabelToFile = (id) => {
        const token = StoreService.getStoreProperty("token");
        let file_id = this.state.contextItemID;
        let label_id = id;
        ApiService.addLabelToFile({ file_id, label_id }, token).then((res) => {
            if (res.status === "OK") {
                ApiService.getAllLabels({ file_id }, token).then((response) => {
                    if (response.status === "OK") {
                        this.setState((prevState: any) => ({
                            labels: [...prevState.labels, res.data],
                            allLabels: response.data,
                        }));
                    }
                });
            }
        });
    };

    removeLabelFromFile = (event, id) => {
        event.stopPropagation();
        let file_id = event.target.closest("div").id;
        const token = StoreService.getStoreProperty("token");
        let label_id = id;

        ApiService.removeLabelFromFile({ file_id, label_id }, token).then((res) => {
            if (res.status === "OK") {
                ApiService.getAllLabels({ file_id }, token).then((response) => {
                    if (response.status === "OK") {
                        this.setState({
                            labels: res.data,
                            allLabels: response.data,
                        });
                    }
                });
            }
        });
    };

    selectColor = (color, id) => {
        this.setState({
            color,
            activeColor: id,
        });
    };

    render() {
        let { ctxTop, ctxLeft, ctxVisible, labels, allLabels, activeColor, editLabel } = this.state;

        return (
            <div className="d-flex align-items-center labelsWrapper">
                <div className="d-flex align-items-center p-r flex-row-reverse">
                    <div className="d-flex aling-items-center" id={this.props.itemID}>
                        {labels &&
                            labels.map((e) => (
                                <span
                                    key={e.id}
                                    className="py-2 px-5 border-radius-12 mr-5 d-flex justify-content-between align-items-center"
                                    style={{ backgroundColor: e.color, color: "white" }}
                                >
                                    {e.title}
                                    <span
                                        className="removeLabelIcon ml-5 pointer border-radius-6 align-self-center"
                                        onClick={(event) => this.removeLabelFromFile(event, e.id)}
                                    >
                                        x
                                    </span>
                                </span>
                            ))}
                    </div>
                    <div className="d-flex">
                        <span>
                            <CustomButton
                                className="btn-back small smallerButton mx-10"
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleContextMenu(e, this.props.itemID);
                                }}
                            >
                                <Translate text="add" />
                            </CustomButton>
                        </span>

                        <ContextMenu
                            top={ctxTop}
                            left={ctxLeft}
                            visible={ctxVisible}
                            // height={!!this.state.contextMenuLoader ? 150 : '' }
                            className={"labelsContextMenu"}
                        >
                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-between  align-items-center p-10">
                                    <h2 className="ml-10">
                                        <Translate text="LABELS" />
                                    </h2>
                                    <span className="d-flex px-15 f-s-14  closeIcon" onClick={this.hideContextMenu}>
                                        x
                                    </span>
                                </div>

                                <div className="d-flex flex-column mt-10 ">
                                    <div className="d-flex flex-column allLabelsWrapper">
                                        {allLabels &&
                                            allLabels.map((e) => (
                                                <span
                                                    key={e.id}
                                                    className="d-flex justify-content-between align-items-center py-5 px-10 border-radius-12 mx-5 mb-5 allLabelsColor"
                                                    style={{ backgroundColor: e.color, color: "white" }}
                                                    onClick={() => this.addLabelToFile(e.id)}
                                                >
                                                    {e.title}
                                                    <div className="d-flex align-items-center">
                                                        <span onClick={(ev) => this.editLabel(ev, e.id)}>
                                                            <EditIcon width={20} className="mr-5 mt-5 editIcon" />
                                                        </span>
                                                        <i
                                                            className="fa fa-minus trash-icon p-5  pointer border-radius-6 align-self-center"
                                                            onClick={(ev) => this.deleteLabel(ev, e.id)}
                                                        />
                                                    </div>
                                                </span>
                                            ))}
                                    </div>
                                    <div className="mx-auto mt-20 mb-10">
                                        <CustomButton className="btn-create" onClick={(e) => this.createNewLabel(e)}>
                                            <Translate text="CREATE_NEW_LABEL" />
                                        </CustomButton>
                                    </div>
                                    {this.state.showCreateModal && (
                                        <div className="d-flex flex-column">
                                            <span
                                                className="d-flex align-self-end mr-20 mt-10 mb-10 f-s-14 px-5 closeIcon"
                                                onClick={this.hideCreateMenu}
                                            >
                                                x
                                            </span>
                                            <CustomForm className="d-flex flex-column mx-5">
                                                <CustomInput
                                                    handleInput={this.handleInput}
                                                    name="labelName"
                                                    value={this.state.labelName.value}
                                                    type="text"
                                                    errors={this.state.labelName.errors}
                                                    label="LABEL_NAME"
                                                />
                                                <div className="my-10">
                                                    <span>
                                                        <Translate text="SELECT_COLOR" />
                                                    </span>
                                                    <div className="d-flex mx-10">
                                                        {this.state.colors.map((item) => (
                                                            <div
                                                                key={item.id}
                                                                className={`${item.className} ${
                                                                    item.id === activeColor ? " active" : ""
                                                                }`}
                                                                style={{ backgroundColor: item.value }}
                                                                onClick={() => this.selectColor(item.value, item.id)}
                                                            ></div>
                                                        ))}
                                                    </div>
                                                    <div className="mt-10">
                                                        <CustomButton
                                                            className="btn-create small mx-10"
                                                            type="button"
                                                            onClick={this.addNewLabel}
                                                        >
                                                            <Translate text={editLabel ? "EDIT_LABEL" : "ADD_LABEL"} />
                                                        </CustomButton>
                                                    </div>
                                                </div>
                                            </CustomForm>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ContextMenu>
                    </div>
                </div>
            </div>
        );
    }
}

export default Labels;
