import { CustomButton, CustomForm, CustomInput } from "devkit/Form/Form";
import React, { Component } from "react";
import ApiService from "services/api.service";
import Table from "devkit/Table/Table";
import { StoreService } from "services/store.service";
import Translate from "functions/utilFunctions/translate";
import Modal from "devkit/Modal/Modal";
import { UtilService } from "services/util.service";
import { ModalContent } from "devkit/Modal/ModalContent";
import { parseDate } from "functions/utilFunctions/parsingFunctions";
import Paginate from "devkit/Pagination/Pagination";
import { Loader } from "devkit/Loader/Loader";

class Clients extends Component<any> {
    state = {
        form: {
            search: {
                value: "",
                errors: [],
            },
        },
        tableHeads: [
            {
                value: "TITLE",
                sortable: true,
                sortableType: "string",
                sortableName: "name",
            },
            {
                value: "ADRESS",
                sortable: true,
                sortableType: "string",
                sortableName: "address",
            },
            {
                value: "BANK_ACCOUNT",
                sortable: true,
                sortableType: "string",
                sortableName: "bank_account",
            },
            {
                value: "CREATED_AT",
                sortable: true,
                sortableType: "date",
                sortableName: "created_at",
            },
            {
                value: "PHONE",
            },
            {
                value: "PDV",
            },
            {
                value: "PIB",
            },
            { value: "" },
        ],
        customers: [
            {
                name: "Djordje",
                address: "...",
                email: "test@test.com",
                bank_account: "",
                phone: "067123456",
                PDVno: "1312",
                PIB: "1312",
                id: "1",
                founding_date: null,
            },
        ],
        showDeleteModal: false,
        yes: false,
        showSuccessModal: false,
        showDeleteFailure: false,
        deleteID: "",
        totalResults: 0,
        currentPage: this.props.match.params.pageNo,
        perPage: 10,
        sorterDirection: "",
        sorterName: "",
        showLoader: true,
    };

    handleClientsDetails = () => {
        this.props.history.push("/new-client");
    };

    componentDidMount() {
        // const customers = StoreService.getStoreProperty("customers");
        // this.setState({ customers });
        // const token = StoreService.getStoreProperty("token");
        // const { currentPage, perPage } = this.state;
        // const offset = (parseInt(currentPage) - 1) * perPage;
        // const data = { limit: perPage, offset: offset };
        const clientsFilters = StoreService.getStoreProperty("clientsFilters");
        this.setState(
            {
                form: {
                    search: {
                        value: clientsFilters?.search ? clientsFilters.search : this.state.form.search.value,
                        errors: [],
                    },
                },
            }
            // },
            // () => {
            //     if (this.state.form.search.value) {
            //         data["search"] = this.state.form.search.value;
            //     }
            //     ApiService.filterCustomers(data, token).then((res) => {
            //         if (res.success === 1) {
            //             this.setState({
            //                 customers: res.data.customers,
            //                 totalResults: res.data.total,
            //                 showLoader: false,
            //             });
            //         }
            //     });
            // }
        );
    }

    componentWillUnmount() {
        const { form } = this.state;
        StoreService.updateStoreProperty("clientsFilters", {
            search: form.search.value,
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return {
                currentPage: nextProps.match.params.pageNo,
                showLoader: true,
            };
        }
        return null;
    }

    handlePageClick = (pageNumber) => {
        this.props.history.push(`/clients/${pageNumber}`);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.form !== this.state.form || prevState.currentPage !== this.state.currentPage) {
            const { currentPage, perPage, sorterDirection, sorterName } = this.state;
            const offset = (parseInt(currentPage) - 1) * perPage;
            const data: any = { limit: perPage, offset };
            if (sorterName || sorterDirection) {
                data[sorterName] = sorterDirection;
            }
            if (this.state.form.search.value) {
                data["search"] = this.state.form.search.value;
            }
            const token = StoreService.getStoreProperty("token");
            UtilService.handleDebouncing(
                "clientsDebouncing",
                () =>
                    ApiService.filterCustomers(data, token).then((res) => {
                        if (res.success === 1) {
                            this.setState({
                                customers: res.data.customers,
                                totalResults: res.data.total,
                                showLoader: false,
                            });
                        }
                    }),
                300
            );
        }
    }

    handleGoToAcc = (client) => {
        this.props.history.push(`/clientDetails/${client.id}`, this.state.currentPage);
    };

    deleteHandle = () => {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage, deleteID, sorterDirection, sorterName } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const data: any = { limit: perPage, offset };
        if (sorterName || sorterDirection) {
            data[sorterName] = sorterDirection;
        }
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            deleteID: "",
        });
        ApiService.deleteClient(
            {
                id: deleteID,
            },
            token
        ).then((response) => {
            if (response.status === "OK") {
                ApiService.filterCustomers(data, token).then((res) => {
                    if (res.success === 1) {
                        this.setState({
                            customers: res.data.customers,
                            totalResults: res.data.total,
                        });
                    }
                });
            } else {
                this.setState({
                    showDeleteFailure: true,
                });
            }
        });
    };

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };

    handleSuccessModal = (name) => {
        this.setState((prevState: any) => ({
            [name]: !prevState[name],
        }));
    };

    handleNoModal = () => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            deleteID: "",
        });
    };

    handleSort = (sortableName) => {
        const { currentPage, perPage, sorterDirection } = this.state;
        const sorter_direction = sorterDirection ? (sorterDirection === "desc" ? "asc" : "desc") : "asc";
        const token = StoreService.getStoreProperty("token");
        const offset = (parseInt(currentPage) - 1) * perPage;
        let data = { limit: perPage, offset };
        data[sortableName] = sorter_direction;

        ApiService.filterCustomers(data, token).then((res) => {
            if (res.success === 1) {
                this.setState({
                    customers: res.data.customers,
                    totalResults: res.data.total,
                    sorterDirection: sorter_direction,
                    sorterName: sortableName,
                    showLoader: false,
                });
            }
        });
    };

    render() {
        const { totalResults, currentPage, perPage, showLoader } = this.state;
        const user = StoreService.getStoreProperty("user");

        return (
            <div className="accounts-wrapper">
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        text="DELETE_CLIENT_?"
                        modalName="showDeleteModal"
                        title="DeleteClient"
                        toggleModal={this.toggleModal}
                        deleteItem={this.deleteHandle}
                        cancelDelete={this.handleNoModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessModal"
                    className={this.state.showSuccessModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="CLIENT_DELETE_SUCCESS"
                        modalName="showSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteFailure"
                    className={this.state.showDeleteFailure ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="CLIENT_DELETE_FAILURE"
                        modalName="showDeleteFailure"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="f-s-20 ">
                        <Translate text={"CLIENTS"} />
                    </div>
                    <div>
                        <CustomButton
                            //to="new-client"
                            onClick={this.handleClientsDetails}
                            className="btn-primary"
                        >
                            <Translate text={"addClient"} />
                        </CustomButton>
                    </div>
                </div>
                <CustomForm className={"row mt-30"}>
                    <div className="col-lg-3 col-md-4 col-sm-6 mt-10">
                        <CustomInput
                            value={this.state.form.search.value}
                            handleInput={this.handleInput}
                            name="search"
                            type="text"
                            label="Search"
                        />
                    </div>
                </CustomForm>

                {showLoader ? (
                    <div className="loaderContainer">
                        <Loader width={200} />
                    </div>
                ) : (
                    <>
                        <div className="clientsTableWrapper overflow-auto pb-20 mt-30 f-s-14">
                            <Table
                                theads={this.state.tableHeads}
                                theadsClassname="customThead"
                                className=" clientsTable"
                                handleSort={this.handleSort}
                                sortName="customers"
                                arrayToSort={this.state.customers}
                            >
                                {this.state.customers.map((e, index) => (
                                    <tr key={index} onClick={() => this.handleGoToAcc(e)} className="pointer">
                                        <td>{e.name}</td>
                                        <td>{e.address}</td>
                                        <td>{e.bank_account}</td>
                                        <td className="min-w-150">{parseDate(e.founding_date)}</td>
                                        <td className="min-w-150">{e.phone ? e.phone : "-/-"}</td>
                                        <td>{e.PDVno}</td>
                                        <td>{e.PIB}</td>

                                        <td>
                                            <div className="d-flex padding-5 justify-content-center">
                                                <i
                                                    className="mr-20 fas fa-edit edit-icon padding-10 border-1 pointer border-radius-6 w-35"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        this.handleGoToAcc(e);
                                                    }}
                                                />
                                                <i
                                                    className={`fas fa-trash trash-icon padding-10 border-1 pointer border-radius-6 w-35 ${
                                                        user.role !== 3 ? "disabledDiv" : ""
                                                    }`}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        this.handleDeleteModal(e.id);
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </Table>
                        </div>
                        {!!this.state.totalResults && this.state.totalResults > 0 && (
                            <div className="d-flex justify-content-center">
                                <Paginate
                                    totalItemsCount={totalResults}
                                    onChange={this.handlePageClick}
                                    activePage={parseInt(currentPage)}
                                    perPage={perPage}
                                    pageRangeDisplayed={5}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default Clients;
