import React, { Component } from "react";
import { parseDate, parseDateWithoutSeconds } from "functions/utilFunctions/parsingFunctions";

// import ApiService from "services/api.service";
import FullCalendar from "@fullcalendar/react";
import { Loader } from "devkit/Loader/Loader";
import { StoreService } from "services/store.service";
import Translate from "functions/utilFunctions/translate";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

class DailyOverview extends Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                search: {
                    value: "",
                    errors: [],
                },
            },
            actions: [],
            language: false,
            status: 0,
            isLoading: false,
            daily: false,
            lang: localStorage.getItem("lang"),
        };
    }

    renderStatus = (status) => {
        if (parseInt(status) === 1) {
            return <Translate text="IMPORTANT" />;
        }
        if (parseInt(status) === 2) {
            return <Translate text="VERY_IMPORTANT" />;
        }
        if (parseInt(status) === 3) {
            return <Translate text="PRIORITY" />;
        }
        if (parseInt(status) === 4) {
            return <Translate text="URGENT" />;
        }
    };

    handleSelect = (selectedInfo) => {
        this.props.handleDatePicked(selectedInfo.start);
    };

    static getDerivedStateFromProps(props, state) {
        if (props.state === state.daily) {
            return {
                daily: props.state,
            };
        }
        if (props.language !== state.language) {
            return {
                language: props.language,
            };
        }
        if (props.status === 5) {
            return { status: props.status };
        } else {
            return { status: 0 };
        }
    }

    mouseDownHandler = (e) => {
        if (e.button === 1) {
            window.open(`/action/${e.currentTarget.id}`, "_blank");
        }
    };

    render() {
        const actions = StoreService.getStoreProperty("actions");
        const ac = Object.values(actions).filter((item: any) => {
            const currentDate = parseDate(new Date());
            if (parseDate(item.startDate) === currentDate) {
                return item.users.length === 0;
            } else {
                return false;
            }
        });

        const { isLoading } = this.props;
        if (isLoading) {
            return (
                <div className="loaderContainer">
                    <Loader width={200} />
                </div>
            );
        } else {
            return (
                <div className="dailyCalendar">
                    <div id="calendar" className="padding-v-20 h-100 ">
                        <FullCalendar
                            initialView="dayGridDay"
                            headerToolbar={{
                                left: "prev title next",
                                center: "",
                                right: "",
                            }}
                            titleFormat={{
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                weekday: "long",
                            }}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            locale={this.props.language === "en" ? "en-US" : "sr-latn-SR"}
                            datesSet={this.handleSelect}
                            navLinks={false}
                            eventBackgroundColor={"#ff0000"}
                            selectable={true}
                            showNonCurrentDates={true}
                            initialDate={this.props.startDate}
                            displayEventTime={false}
                            viewClassNames="fc-view-harness-daily fc-view-harness-active-daily d-none"
                        />
                    </div>
                    {this.state.status === 5 ? (
                        <div className="d-flex flex-column border-6 br-r-8 padding-20 h-500 overflow-auto col-md-12">
                            <div className="d-flex flex-column">
                                {ac.map((item: any, i) => {
                                    return (
                                        <div
                                            id={item.id}
                                            key={i}
                                            className="d-flex border-6 margin-v-10 pointer min-h-150 padding-10 "
                                            onClick={() => this.props.handleRedirect(item)}
                                            onMouseDown={(ev) => this.mouseDownHandler(ev)}
                                        >
                                            <div className="d-flex  w-30-perc min-h-100 padding-10 border-r-6 justify-content-lg-between flex-column ">
                                                <span className="d-flex align-items-center f-s-14  w-100perc h-30">
                                                    {item.title}
                                                </span>
                                                <div className="d-flex justify-content-lg-between">
                                                    <span
                                                        className={`${
                                                            item.priority === 4
                                                                ? "danger f-s-14"
                                                                : item.priority === 1
                                                                ? "important-1 f-s-14"
                                                                : item.priority === 2
                                                                ? "important-2 f-s-14"
                                                                : "important-3 f-s-14"
                                                        }`}
                                                    >
                                                        {/* //TODO conditional className */}
                                                        {this.renderStatus(item.priority)}
                                                    </span>
                                                    <div>
                                                        <span className="f-s-12">
                                                            {parseDateWithoutSeconds(item.startDate)} {"- "}{" "}
                                                        </span>
                                                        <span className="f-s-12">
                                                            {parseDateWithoutSeconds(item.endDate)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex w-70-perc min-h-100 padding-10 justify-content-lg-between flex-column">
                                                <span
                                                    className="f-s-12
                                                    "
                                                >
                                                    {item.remark}
                                                </span>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex flex-column">
                                                        <div>
                                                            <span className="f-s-12 bold">
                                                                <Translate text="CLIENT" />
                                                                {": "}
                                                            </span>
                                                            <span className="f-s-12 padding-l-10">
                                                                {item.customer_name}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="f-s-12 bold">
                                                                <Translate text="EMPLOYEE" />
                                                                {": "}
                                                            </span>
                                                            <span className="f-s-12 padding-l-10">
                                                                {item.users.length &&
                                                                    item.users.map((e: any, index) => {
                                                                        return (
                                                                            <span key={index}>
                                                                                {`${e.firstName} ${e.lastName}`}
                                                                            </span>
                                                                        );
                                                                    })}{" "}
                                                                {/* {relatedUser.lastName} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-center align-items-end">
                                                        <span className="info-5 f-s-16">{item.unfinished}</span>
                                                        <span className="f-s-16">{"/"}</span>
                                                        <span className="f-s-16 important-1">{item.finished}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex flex-column border-6 br-r-8 padding-20 h-500 overflow-auto col-md-12 dailyActionsList">
                            <div className="d-flex flex-column ">
                                {this.props.actions?.map((item, i) => {
                                    return (
                                        <div
                                            key={i}
                                            id={item.id}
                                            className={`d-flex border-6 br-r-8 margin-v-10 pointer min-h-150 padding-10 dailyAction ${
                                                new Date(item.endDate) < this.props.startDate ? "late" : ""
                                            }`}
                                            onClick={() => this.props.handleRedirect(item)}
                                            onMouseDown={(ev) => this.mouseDownHandler(ev)}
                                        >
                                            <div className="d-flex  w-30-perc min-h-100 padding-10 border-r-6 justify-content-lg-between flex-column ">
                                                <div className="top d-flex justify-content-between align-items-center">
                                                    <span className="d-flex align-items-center f-s-14 h-30">
                                                        {item.title}
                                                    </span>
                                                    {new Date(item.endDate) < this.props.startDate && (
                                                        <span className="danger f-s-14 f-w-600">
                                                            <Translate text="LATE" />
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="bottom d-flex justify-content-lg-between">
                                                    <span
                                                        className={`${
                                                            item.priority === 4
                                                                ? "danger f-s-14"
                                                                : item.priority === 1
                                                                ? "important-1 f-s-14"
                                                                : item.priority === 2
                                                                ? "important-2 f-s-14"
                                                                : "important-3 f-s-14"
                                                        }`}
                                                    >
                                                        {/* //TODO conditional className */}
                                                        {this.renderStatus(item.priority)}
                                                    </span>
                                                    <div>
                                                        <span className="f-s-12">
                                                            {parseDateWithoutSeconds(item.startDate)} {"- "}{" "}
                                                        </span>

                                                        <span className="f-s-12">
                                                            {parseDateWithoutSeconds(item.endDate)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex w-70-perc min-h-100 padding-10 justify-content-lg-between flex-column">
                                                <div className="top d-flex justify-content-between">
                                                    <span className="f-s-12">{item.remark}</span>
                                                </div>
                                                <div className="bottom d-flex justify-content-between">
                                                    <div className="d-flex flex-column">
                                                        <div>
                                                            <span className="f-s-12 bold">
                                                                <Translate text="CLIENT" />
                                                                {": "}
                                                            </span>
                                                            <span className="f-s-12 padding-l-10">
                                                                {item.customer_name}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="f-s-12 bold">
                                                                <Translate text="EMPLOYEE" />
                                                                {": "}
                                                            </span>
                                                            <span className="f-s-12 padding-l-10">
                                                                {item.users.length &&
                                                                    item.users.map((e: any, index) => {
                                                                        return (
                                                                            <span key={index}>
                                                                                {`${e.firstName} ${e.lastName}`}
                                                                            </span>
                                                                        );
                                                                    })}{" "}
                                                                {/* {relatedUser.lastName} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-center align-items-end">
                                                        <span className="info-5 f-s-16">{item.unfinished}</span>
                                                        <span className="f-s-16">/</span>
                                                        <span className="f-s-16 important-1">{item.finished}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            );
        }
    }
}

export default DailyOverview;
