import React from "react";
import { Component } from "react";

export const ScreenContext = React.createContext({ isMobile: window.innerWidth <= 960 });

export class ScreenContextProvider extends Component {
    state = {
        isMobile: window.innerWidth <= 960,
        screenSize: window.innerWidth,
    };

    componentDidMount() {
        window.addEventListener("resize", this.setScreenSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setScreenSize);
    }

    setScreenSize = () => {
        this.setState({ isMobile: window.innerWidth <= 960, screenSize: window.innerWidth });
    };

    render() {
        return <ScreenContext.Provider value={{ ...this.state }}>{this.props.children}</ScreenContext.Provider>;
    }
}
