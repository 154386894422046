import Sidebar from "components/Sidebar/Sidebar";
import { parseDateForMySQL } from "functions/utilFunctions/parsingFunctions";
import React, { useState } from "react";

const HamburgerMenu = () => {
    const [isActive, setIsActive] = useState(false);

    const toggleSidebar = () => {
        setIsActive(!isActive);
    };

    return (
        <div className={`${isActive ? "open" : ""} hamburger-menu justify-content-center align-items-center d-flex`}>
            <div
                onClick={toggleSidebar}
                className="pointer w-100-perc h-100-perc d-flex justify-content-center align-items-center hamburger-container"
            >
                <div className="d-flex flex-column d-flex">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>

            <div onClick={toggleSidebar} className={`${!isActive ? "hide" : ""} sidebar-container`}></div>
            <Sidebar day={parseDateForMySQL(new Date())} open={isActive} toggleSidebar={toggleSidebar}></Sidebar>
        </div>
    );
};

export default HamburgerMenu;
