import { CustomButton, CustomForm, CustomInput, CustomFileInput } from "devkit/Form/Form";
import React, { Component } from "react";
import ApiService from "services/api.service";
import CreateNewAccount from "pages/Accounts/AccountDetails/CreateNewAccount/CreateNewAccount";
import Modal from "../../../devkit/Modal/Modal";
import PersonalAccountLeftSide from "pages/PersonalAccount/PersonalAccountLeftSide/PersonalAccountLeftSide";
import { StoreService } from "services/store.service";
import Translate from "functions/utilFunctions/translate";
import { validateEmail } from "functions/utilFunctions/validations";
import { ModalContent } from "devkit/Modal/ModalContent";
import { UploadIcon, BackIcon } from "components/Icons/Icons";
import { Loader } from "devkit/Loader/Loader";
import { parseDateForMySQL } from "functions/utilFunctions/parsingFunctions";
import Salaries from "pages/Accounts/AccountDetails/Salaries";
import { ScreenContext } from "components/ScreenContext/ScreenContext";

class AccountDetails extends Component<any> {
    state = {
        form: {
            id: {
                value: "",
                errors: [],
            },
            firstName: {
                value: "",
                errors: [],
            },
            lastName: {
                value: "",
                errors: [],
            },
            email: {
                value: "",
                errors: [],
            },
            phone_number: {
                value: "",
                errors: [],
            },
            dateOfBirth: {
                value: null as any,
                errors: [],
            },
            dateOfEmployment: {
                value: null as any,
                errors: [],
            },

            NewPassword: {
                value: "",
                errors: [],
            },
            RepeatNewPassword: {
                value: "",
                errors: [],
            },

            NewPin: {
                value: "",
                errors: [],
            },
            RepeatNewPin: {
                value: "",
                errors: [],
            },
            role: {
                value: 0,
                errors: [],
            },
            mentor: {
                value: 0,
                errors: [],
            },
            customer: {
                value: null,
                errors: [],
            },
            customer_id: { value: "", errors: [] },

            customers: {
                value: [{ value: 0, label: "" }],
                errors: [""],
            },
            profile_image: {
                value: null,
                errors: [],
            },
        },
        photo: {
            value: null,
            errors: [],
        },
        roles: [
            { value: 1, label: <Translate text="INTERN" /> },
            { value: 2, label: <Translate text="EMPLOYEE" /> },
            { value: 3, label: <Translate text="ADMIN" /> },
        ],
        user: {},
        users: [],
        customersArray: [],
        showPasswordModal: false,
        showPinModal: false,
        showSuccessModal: false,
        showSuccessModalData: false,
        showSuccessfulDelete: false,
        showFailedModal: false,
        photoUploadModalFailure: false,
        photoUploadModalFailureText: "",
        handleChange: false,
        id: "",
        newAccountId: "",
        successNewEmployee: false,
        newAccount: !this.props.match.params.id,
        changeType: "",
        modalSuccessText: "",
        modalFailedText: "",
        showDeleteModal: false,
        employeeAdded: false,
        emailAlreadyExists: false,
        imageUploadModal: false,
        isOver: false,
        completed: 0,
        uploadComplete: false,
        uploading: false,
        showBasicInfo: true,
        showAdditionalInfo: false,
    };

    static contextType = ScreenContext;

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        if (!this.state.newAccount) {
            const users = StoreService.getStoreProperty("users");
            const user: any = Object.values(users).find((u: any) => u.id.toString() === this.props.match.params.id);
            let userCustomers =
                user.customers &&
                Object.values(user.customers).map((e: any) => ({
                    value: e.id,
                    label: e.name,
                }));
            const userFormated = {};
            ApiService.getExpenses({ id: this.props.match.params.id }, token).then((response) => {
                if (response.status === "OK") {
                    alert("Uspesno");
                }
            });
            if (user) {
                Object.keys(user).forEach((e) => {
                    if (e === "dateOfBirth" || e === "dateOfEmployment") {
                        userFormated[e] = {
                            value: new Date(user[e]),
                            errors: [],
                        };
                    } else if (e === "customers") {
                        userFormated[e] = {
                            value: userCustomers,
                            errors: [],
                        };
                    } else {
                        userFormated[e] = {
                            value: user[e],
                            errors: [],
                        };
                    }
                });
                const usersArray = StoreService.getStoreProperty("users");
                const customers = StoreService.getStoreProperty("customers");
                const users = usersArray.map((e) => ({
                    value: e.id,
                    label: `${e.firstName} ${e.lastName}`,
                }));
                const customersArray = customers.map((e) => ({
                    value: e.id,
                    label: e.name,
                }));
                this.setState((prevState: any) => ({
                    user,
                    form: {
                        ...prevState.form,
                        ...userFormated,
                    },
                    users,
                    customersArray,
                }));
            } else {
                this.props.history.push("/accounts");
            }
        } else {
            const usersArray = StoreService.getStoreProperty("users");
            const users = usersArray.map((e) => ({
                value: e.id,
                label: `${e.firstName} ${e.lastName}`,
            }));
            const customers = StoreService.getStoreProperty("customers");
            const customersArray = customers.map((e) => ({
                value: e.id,
                label: e.name,
            }));
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                },
                users,
                customersArray,
            }));
            //TODO handle new account logic
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id && this.props.match.params.id) {
            this.setState({ newAccount: false });
        }
        if (prevState.photo.value !== this.state.photo.value && this.state.photo.value) {
            this.setState({ uploading: true });
            this.handleUploadImage();
        }
        if (prevState.form.role !== this.state.form.role && this.state.form.role.value !== 1) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    mentor: {
                        value: null,
                        errors: [],
                    },
                },
            }));
        }
    }

    handleDropdown = (name, value) => {
        if (name === "roleDropdown") {
            const role = value.value;
            this.setState((prevState: any) => ({
                [name]: value,
                form: {
                    ...prevState.form,
                    role: {
                        value: role,
                        errors: [""],
                    },
                },
            }));
        }
        if (name === "userDropdown") {
            const user = value.value;
            this.setState((prevState: any) => ({
                [name]: value,
                form: {
                    ...prevState.form,

                    mentor: {
                        value: user,
                        errors: [""],
                    },
                },
            }));
        }
        if (name === "customers") {
            const customers = value.value;
            this.setState((prevState: any) => ({
                [name]: value,

                form: {
                    ...prevState.form,

                    customers: {
                        value: customers,
                        errors: [""],
                    },
                },
            }));
        }
    };
    handleChange = () => {
        this.setState((prevState: any) => ({
            handleChange: !prevState.handleChange,
        }));
    };
    handleInput = (name, data) => {
        this.setState(
            (prevState: any) => ({
                form: {
                    ...prevState.form,
                    [name]: {
                        value: data.value,
                        errors: data.errors,
                    },
                },
            }),
            () => {
                if (name === "dateOfEmployment" || name === "dateOfBirth") {
                    if (
                        new Date(this.state.form.dateOfEmployment.value) < new Date(this.state.form.dateOfBirth.value)
                    ) {
                        if (
                            this.state.form.dateOfEmployment.value === "" ||
                            this.state.form.dateOfEmployment.value === null
                        ) {
                            return;
                        } else {
                            this.setState((prevState: any) => ({
                                form: {
                                    ...prevState.form,
                                    dateOfEmployment: {
                                        value: new Date(this.state.form.dateOfBirth.value),
                                    },
                                },
                            }));
                        }
                    }
                }
            }
        );
    };
    handleSuccessModalData = (name) => {
        this.setState((prevState: any) => ({
            [name]: !prevState[name],
        }));
        this.handleBack();
    };
    handleBack = () => {
        // const id = this.props.history.location.pathname.split('/');
        //this.props.history.push(`/accounts/1`);
        this.props.history.goBack();
    };

    redirectToNewAccount = () => {
        if (this.state.newAccountId) {
            this.setState((prevState: any) => ({
                employeeAdded: !prevState.employeeAdded,
                form: {
                    ...prevState.form,
                    id: {
                        value: this.state.newAccountId,
                        errors: [],
                    },
                },
            }));
            this.props.history.push(`/accountsDetails/${this.state.newAccountId}`);
        } else {
            // console.log("wrong");
        }
    };

    deleteHandle = () => {
        const deleteID = this.state.form.id.value;
        const token = StoreService.getStoreProperty("token");
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            deleteID: "",
        });
        ApiService.deleteAccount(
            {
                id: deleteID,
            },
            token
        ).then((response) => {
            if (response.status === "OK") {
                const users = StoreService.getStoreProperty("users");
                const newUsers = Object.values(users).filter((item: any) => {
                    return parseInt(item.id) !== parseInt(deleteID);
                });
                this.setState({ users: newUsers, showSuccessfulDelete: true });
                StoreService.updateStoreProperty("users", newUsers);
            }
        });
    };

    validatePIN = (pin) => {
        return /^(\d{4})$/.test(pin);
    };
    validatePassword = (password) => {
        return password.length > 5;
    };
    handleDataChange = (e) => {
        e.preventDefault();
        const { changeType } = this.state;
        let passwords;
        let resetFields;
        let newItem;

        let repeatNewItem;
        if (changeType === "password") {
            newItem = this.state.form.NewPassword;
            repeatNewItem = this.state.form.RepeatNewPassword;
            passwords = {
                NewPassword: newItem,
                RepeatNewPassword: repeatNewItem,
            };

            resetFields = {
                NewPassword: {
                    value: "",
                    errors: [],
                },

                RepeatNewPassword: {
                    value: "",
                    errors: [],
                },
            };
        } else {
            newItem = this.state.form.NewPin;
            repeatNewItem = this.state.form.RepeatNewPin;
            passwords = {
                NewPin: newItem,
                RepeatNewPin: repeatNewItem,
            };
            resetFields = {
                NewPin: {
                    value: "",
                    errors: [],
                },
                RepeatNewPin: {
                    value: "",
                    errors: [],
                },
            };
        }

        const errors = {};
        Object.keys(passwords).forEach((e) => {
            if (!passwords[e].value) {
                errors[e] = {
                    value: passwords[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            } else if (changeType === "password" && passwords[e].value.length < 6) {
                errors[e] = {
                    value: passwords[e].value,

                    errors: ["PASSWORD_NOT_VALID"],
                };
            } else if (changeType === "password" && newItem.value !== repeatNewItem.value) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        NewPassword: {
                            value: prevState.form.NewPassword.value,
                            errors: ["PSWDontMatch"],
                        },

                        RepeatNewPassword: {
                            value: prevState.form.NewPassword.value,
                            errors: ["PSWDontMatch"],
                        },
                    },
                }));
            } else if (changeType !== "password" && passwords[e].value.length !== 4) {
                errors[e] = {
                    value: passwords[e].value,

                    errors: ["PIN_NOT_VALID"],
                };
            } else if (changeType !== "password" && newItem.value !== repeatNewItem.value) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        NewPin: {
                            value: passwords[e].value,
                            errors: ["PINDontMatch"],
                        },
                        RepeatNewPin: {
                            value: prevState.form.NewPassword.value,
                            errors: ["PINDontMatch"],
                        },
                    },
                }));
            }
        });

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else {
            if (newItem.value !== repeatNewItem.value) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                    },
                }));
            } else {
                let data;
                let url;
                if (changeType === "password") {
                    data = {
                        new_password: newItem.value,
                        id: this.state.form.id.value,
                    };
                    url = "users/updatePas";
                } else {
                    data = {
                        new_pin: newItem.value,
                        id: this.state.form.id.value,
                    };

                    url = "users/updatePin";
                }
                const token = StoreService.getStoreProperty("token");

                ApiService.updateData(data, token, url).then((response) => {
                    if (response.status === "OK") {
                        this.setState((prevState: any) => ({
                            showPasswordModal: false,
                            showPinModal: false,
                            showSuccessModal: true,
                            form: {
                                ...prevState.form,
                                ...resetFields,
                            },
                        }));
                    } else {
                        this.setState((prevState: any) => ({
                            showFailedModal: true,
                            form: {
                                ...prevState.form,
                                ...resetFields,
                            },
                        }));
                    }
                });
            }
        }
    };
    handleSaveEmployee = (e) => {
        e.preventDefault();
        const {
            phone_number,
            email,
            dateOfBirth,
            dateOfEmployment,
            firstName,
            lastName,
            role,
            mentor,
            id,
            customers,
            NewPin,
            NewPassword,
            customer_id,
        } = this.state.form;
        const form = {
            email,
            dateOfBirth,
            dateOfEmployment,
            firstName,
            lastName,
            role,
            mentor,
            customers,
        };
        if (form.role.value !== 1) {
            delete form.mentor;
            delete form.customers;
        }

        const errors = {};
        Object.keys(form).forEach((e) => {
            if (!form[e].value) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
            // else if (
            //     e === "customers" &&
            //     (!customers.value ||
            //         customers.value.length === 0 ||
            //         customers.value[0].value === 0)
            // ) {
            //     customers.errors = ["INPUT_REQUIRED"];

            //     errors[e] = {
            //         value: form[e].value,
            //         errors: ["INPUT_REQUIRED"],
            //     };
            // }
            // else if (e === "role" && role.value !== 1) {
            //     mentor.errors = [];
            // }
            else if (e === "email" && !validateEmail(form[e].value)) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["EMAIL_INVALID"],
                };
            }
        });
        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else {
            const token = StoreService.getStoreProperty("token");

            if (this.state.newAccount) {
                if (
                    !this.validatePIN(this.state.form.NewPin.value) ||
                    !this.validatePassword(this.state.form.NewPassword.value)
                ) {
                    if (!this.validatePassword(this.state.form.NewPassword.value)) {
                        this.setState((prevState: any) => ({
                            form: {
                                ...prevState.form,
                                NewPassword: {
                                    value: prevState.form.NewPassword.value,
                                    errors: ["PASSWORD_NOT_VALID"],
                                },
                            },
                        }));
                    }
                    if (!this.validatePIN(this.state.form.NewPin.value)) {
                        this.setState((prevState: any) => ({
                            form: {
                                ...prevState.form,
                                NewPin: {
                                    value: prevState.form.NewPin.value,
                                    errors: ["PIN_NOT_VALID"],
                                },
                            },
                        }));
                    }
                    return;
                }
                ApiService.createNewAccount(
                    {
                        phone_number: phone_number.value,
                        email: email.value,
                        dateOfBirth: parseDateForMySQL(dateOfBirth.value),
                        dateOfEmployment: parseDateForMySQL(dateOfEmployment.value),
                        firstName: firstName.value,
                        lastName: lastName.value,
                        role: role.value,
                        mentor: mentor.value,
                        password: NewPassword.value,
                        pin: NewPin.value,
                    },

                    customers.value[0].value !== 0
                        ? Object.values(customers.value).map((e) => ({
                              customer_id: e.value,
                          }))
                        : null,

                    token
                ).then((response) => {
                    if (response.status === "OK") {
                        this.setState({
                            newAccountId: response.data.user[0].id,
                            employeeAdded: true,
                        });
                        const users = StoreService.getStoreProperty("users");
                        users.push(response.data.user[0]);
                        StoreService.updateStoreProperty("users", users);
                        this.setState((prevState: any) => ({
                            form: {
                                ...prevState.form,
                                NewPin: {
                                    value: "",
                                    errors: [],
                                },
                                NewPassword: {
                                    value: "",
                                    errors: [],
                                },
                            },
                        }));
                    } else {
                        this.setState({
                            emailAlreadyExists: true,
                        });
                    }
                });
            } else {
                ApiService.updatePersonalAccount(
                    {
                        id: id.value,
                        phone_number: phone_number.value,
                        email: email.value,
                        dateOfBirth: parseDateForMySQL(dateOfBirth.value),
                        dateOfEmployment: parseDateForMySQL(dateOfEmployment.value),
                        firstName: firstName.value,
                        lastName: lastName.value,
                        role: role.value,
                        mentor: mentor.value,
                        customer_id: customer_id.value,
                    },
                    customers.value && customers.value[0] && customers.value[0].value !== 0
                        ? Object.values(customers.value).map((e) => ({
                              customer_id: e.value,
                          }))
                        : null,
                    token
                ).then((response) => {
                    if (response.status === "OK") {
                        this.setState({
                            showSuccessModalData: true,
                            showFailedModal: false,
                        });
                        const users = StoreService.getStoreProperty("users");
                        const newUsers = users.map((e) => {
                            if (e.id === this.state.form.id.value) {
                                return {
                                    ...e,
                                    phone_number: phone_number.value,
                                    email: email.value,
                                    dateOfBirth: dateOfBirth.value,
                                    dateOfEmployment: dateOfEmployment.value,
                                    firstName: firstName.value,
                                    lastName: lastName.value,
                                    role: role.value,
                                    mentor: mentor.value,
                                    //customer_id: customer_id.value,
                                    customers: response.data[0].customers,
                                };
                            } else {
                                return e;
                            }
                        });
                        StoreService.updateStoreProperty("users", newUsers);

                        this.setState({
                            showSuccessModalData: true,
                        });
                    } else {
                        this.setState({
                            emailAlreadyExists: true,
                        });
                    }
                });
            }
        }
    };
    renderInputsModal = (type) => {
        const condition = type === "password";
        const modalName = condition ? "showPasswordModal" : "showPinModal";
        const translate = condition ? "ChangePassword" : "ChangePin";
        const new_item = condition ? "NewPassword" : "NewPin";
        const repeat_new_item = condition ? "RepeatNewPassword" : "RepeatNewPin";

        return (
            <Modal
                toggleModal={this.toggleModal}
                modalName={modalName}
                className={this.state[modalName] ? "visible" : ""}
                modalWrapperClasses="w-400 h-300 padding-10"
            >
                <div className="col-10 offset-1 h-100perc justify-content-between padding-0">
                    <div className="h-60 col-12 d-flex justify-content-between padding-0 margin-l-0">
                        <p className="d-flex align-self-center password-title">
                            <Translate text={translate} />
                        </p>
                        <i
                            className="fas fa-times fa-2x align-self-center pointer"
                            style={{ color: "#43425D" }}
                            onClick={() => this.toggleModal(modalName)}
                        />
                    </div>
                    <div className="col-12 padding-0 h-150 mb-10">
                        <CustomForm className="d-flex col-12 h-100perc flex-column justify-content-around padding-0">
                            <CustomInput
                                name={new_item}
                                label={new_item}
                                type="password"
                                maxLength={condition ? 20 : 4}
                                handleInput={this.handleInput}
                                value={this.state.form[new_item].value}
                                errors={this.state.form[new_item].errors}
                                required={true}
                            />
                            <CustomInput
                                name={repeat_new_item}
                                label={repeat_new_item}
                                type="password"
                                maxLength={condition ? 20 : 4}
                                handleInput={this.handleInput}
                                value={this.state.form[repeat_new_item].value}
                                errors={this.state.form[repeat_new_item].errors}
                                required={true}
                            />
                        </CustomForm>
                    </div>
                    <div className="d-flex justify-content-center">
                        <CustomButton className="btn-save" type="button" onClick={this.handleDataChange}>
                            <Translate text="UPDATE" />
                        </CustomButton>
                    </div>
                </div>
            </Modal>
        );
    };
    toggleModal = (name) => {
        if (this.state.uploading) {
            return;
        }
        let resetFields;
        let changeType = "";
        if (name === "showPasswordModal") {
            changeType = "password";
        } else if (name === "showPinModal") {
            changeType = "pin";
        }
        resetFields = {
            NewPassword: {
                value: "",
                errors: [],
            },
            OldPassword: {
                value: "",
                errors: [],
            },
            RepeatNewPassword: {
                value: "",
                errors: [],
            },
            OldPin: {
                value: "",
                errors: [],
            },
            NewPin: {
                value: "",
                errors: [],
            },
            RepeatNewPin: {
                value: "",
                errors: [],
            },
        };

        this.setState((prevState: any) => ({
            [name]: !this.state[name],
            modalSuccessText: name,
            modalFailedText: name,
            passwordsDontMatch: name,
            changeType,
            form: {
                ...prevState.form,
                ...resetFields,
            },
        }));
    };

    handleUploadImage = () => {
        const id = this.state.form.id.value;
        const token = StoreService.getStoreProperty("token");
        const photo = this.state.photo.value || "";
        if (photo["size"] > 2097152) {
            this.setState({
                uploading: false,
                photoUploadModalFailureText: "Image is too big. Max file size is 2MB",
                photoUploadModalFailure: true,
            });
            return;
        }
        const formData = new FormData();
        formData.append("photo", photo);
        formData.append("id", id);
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        ApiService.uploadUserImage(formData, token, config).then((res) => {
            if (res.status === "OK") {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        profile_image: {
                            value: res.data.profile_image,
                            errors: [],
                        },
                    },
                    completed: 0,
                    uploadComplete: false,
                    imageUploadModal: false,
                    uploading: false,
                    photo: {
                        value: null,
                        errors: [],
                    },
                }));
                const users = StoreService.getStoreProperty("users");
                const newUsers = users.map((e) => {
                    if (e.id === this.state.form.id.value) {
                        return {
                            ...e,
                            profile_image: res.data.profile_image,
                        };
                    } else {
                        return e;
                    }
                });
                StoreService.updateStoreProperty("users", newUsers);
            } else {
                this.setState({
                    photoUploadModalFailureText: res.info,
                    photoUploadModalFailure: true,
                    uploading: false,
                });
            }
        });
    };

    handleFileChange = (name, e, drop?) => {
        if (drop) {
            e.preventDefault();
            if (e.dataTransfer.files.length > 1) {
                alert("only one file allowed");
            } else {
                this.setState({
                    [name]: { value: e.dataTransfer.files[0], errors: [] },
                });
            }
        } else {
            this.setState({
                [name]: {
                    value: e.value[0],
                    errors: [],
                },
            });
        }
    };

    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };

    relatedActions = (id) => {
        this.props.history.push(`/actions/${id}`);
    };

    actionsCalendar = (id) => {
        this.props.history.push(`/calendar/${id}`);
    };

    renderBasicInfo = () => {
        this.setState({
            showBasicInfo: true,
            showAdditionalInfo: false,
            loading: true,
        });
    };

    renderAdditionalInfo = () => {
        this.setState({
            showAdditionalInfo: true,
            showBasicInfo: false,
        });
    };

    render() {
        const { screenSize } = this.context;
        const { newAccount, changeType } = this.state;
        return (
            <div className="d-flex justify-content-center">
                <Modal
                    modalName="imageUploadModal"
                    toggleModal={this.toggleModal}
                    className={this.state.imageUploadModal ? "visible" : ""}
                    modalWrapperClasses="fileUploadModal"
                >
                    {!this.state.uploading && (
                        <div className="pa-20 h-100-perc">
                            <div
                                onDrop={(e) => this.handleFileChange("photo", e, true)}
                                className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                    this.state.isOver ? "dotted" : ""
                                }`}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    this.changeBorderType(true);
                                }}
                                onDragLeave={() => this.changeBorderType(false)}
                            >
                                <div className="text d-flex flex-column justify-content-center align-items-center">
                                    <span className="d-block mb-10">
                                        <UploadIcon width={40} className="uploadIcon" />
                                    </span>
                                    <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                        <Translate text="DRAG_AND_DROP" />
                                    </span>
                                    <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">or</span>
                                </div>
                                <CustomFileInput
                                    name="photo"
                                    handleInput={this.handleFileChange}
                                    value={this.state.photo.value}
                                    label={"CHOSE_PHOTO"}
                                />
                            </div>
                        </div>
                    )}
                    {this.state.uploading && (
                        <div className="progress pa-20 h-100perc text-center">
                            <span className="f-s-20 mb-20">
                                <Translate text="UPLOAD_IN_PROGRESS" />
                            </span>
                            <span className="wrapper d-block">
                                <Loader width={200} />
                                <span className="loader-numbers">{this.state.completed}%</span>
                            </span>
                        </div>
                    )}
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessModal"
                    className={this.state.showSuccessModal ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        title="SUCCESSFULLY_CHANGED"
                        text={this.state.modalSuccessText === "showPasswordModal" ? "PSWSuccess" : "PINSuccess"}
                        modalName="showSuccessModal"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="photoUploadModalFailure"
                    className={this.state.photoUploadModalFailure ? "visible error" : "error"}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        title="FAILURE"
                        text={
                            this.state.photoUploadModalFailureText !== ""
                                ? this.state.photoUploadModalFailureText
                                : "PHOTO_UPLOAD_FAILURE"
                        }
                        // text={"PHOTO_UPLOAD_FAILURE"}
                        modalName="photoUploadModalFailure"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showFailedModal"
                    className={this.state.showFailedModal ? "visible error" : "error"}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        title="FAILED"
                        text={this.state.modalFailedText === "showFailedModal" ? "oldPSWWrong" : "oldPinWrong"}
                        modalName="showFailedModal"
                        toggleModal={this.toggleModal}
                    />
                </Modal>

                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        title="DELETE_EMPLOYEE"
                        text={"areYouSureDeleteEmployee"}
                        modalName="showDeleteModal"
                        toggleModal={this.toggleModal}
                        deleteItem={this.deleteHandle}
                        cancelDelete={() => this.toggleModal("showDeleteModal")}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessfulDelete"
                    className={this.state.showSuccessfulDelete ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        title="DELETE_EMPLOYEE"
                        text="EMPLOYEE_DELETE_SUCCESS"
                        modalName="showSuccessfulDelete"
                        toggleModal={this.handleBack}
                    />
                </Modal>
                <Modal
                    toggleModal={this.handleSuccessModalData}
                    modalName="showSuccessModalData"
                    className={this.state.showSuccessModalData ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        title="SUCCESSFULLY_CHANGED"
                        text={"DATA_CHANGE_SUCCESS"}
                        modalName="showSuccessModalData"
                        toggleModal={this.handleSuccessModalData}
                    />
                </Modal>

                <Modal
                    toggleModal={this.toggleModal}
                    modalName="employeeAdded"
                    className={this.state.employeeAdded ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        title="SUCCESSFULLY_CHANGED"
                        text={"EMPLOYEE_SUCCESSFULLY_ADDED"}
                        modalName="employeeAdded"
                        toggleModal={this.redirectToNewAccount}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="emailAlreadyExists"
                    className={this.state.emailAlreadyExists ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        title="FAILED"
                        text={"EMAIL_EXISTS"}
                        modalName="emailAlreadyExists"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                {this.renderInputsModal(changeType)}
                <div className="accountDetails w-100perc d-flex flex-column p-30 w-100-perc row">
                    <div className={`${screenSize <= 768 ? "mobFooterDetails" : ""} d-flex`}>
                        <div className="d-flex align-items-center justify-content-between w-100-perc">
                            <button
                                onClick={this.handleBack}
                                className="pointer d-inline-block mr-30 goBack align-self-start h-40"
                            >
                                <BackIcon width={25} />
                            </button>
                            {!newAccount && (
                                <div className="d-flex navigation justify-content-between w-100-perc border-b-1">
                                    <div className="tabMenu d-flex">
                                        <CustomButton
                                            //to="/actions"
                                            onClick={this.renderBasicInfo}
                                            className={`mr-5 ${
                                                screenSize <= 768 ? "footerNavBtn" : "nav-btn"
                                            } border-radius-br-0 border-radius-bl-0 ${
                                                this.state.showBasicInfo ? "active" : ""
                                            }`}
                                        >
                                            <Translate text={"BASIC_INFO"} />
                                        </CustomButton>
                                        <CustomButton
                                            onClick={this.renderAdditionalInfo}
                                            className={`mr-5 ${
                                                screenSize <= 768 ? "footerNavBtn" : "nav-btn"
                                            } border-radius-br-0 border-radius-bl-0 ${
                                                this.state.showAdditionalInfo ? "active" : ""
                                            }`}
                                        >
                                            <Translate text="SALARIES" />
                                        </CustomButton>
                                    </div>
                                    <div className="controlButtons d-flex">
                                        <div>
                                            <CustomButton
                                                className="btn-save mr-10 border-radius-br-0 border-radius-bl-0"
                                                type="button"
                                                onClick={this.props.handleSaveEmployee}
                                            >
                                                <Translate text={newAccount ? "SAVE" : "UPDATE"} />
                                            </CustomButton>
                                        </div>
                                        <div>
                                            <CustomButton
                                                className={`${
                                                    this.state.newAccount ? "btn-back" : "btn-delete"
                                                } border-radius-br-0 border-radius-bl-0`}
                                                type="button"
                                                onClick={newAccount ? this.props.handleBack : this.props.handleDelete}
                                            >
                                                <Translate text={newAccount ? "BACK" : "DELETE"} />
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="accountDetailsBody d-flex align-self-center justify-content-center mt-20 w-100-perc">
                        {this.state.showBasicInfo && (
                            <>
                                {!this.state.newAccount && (
                                    <div className="section1 h-400">
                                        <PersonalAccountLeftSide
                                            toggleModal={this.toggleModal}
                                            firstName={this.state.form.firstName.value}
                                            lastName={this.state.form.lastName.value}
                                            role={this.state.form.role.value}
                                            image={this.state.form.profile_image.value}
                                            redirectToActions={() => this.relatedActions(this.state.form.id.value)}
                                            redirectToCalendar={() => this.actionsCalendar(this.state.form.id.value)}
                                        />
                                    </div>
                                )}
                                <div className="section2 d-flex flex-column personal-shadow align-items-center">
                                    <div className="col-12 d-flex flex-column h-100-perc margin-20 transition-2">
                                        <CreateNewAccount
                                            data={this.state.form}
                                            roles={this.state.roles}
                                            users={this.state.users}
                                            customersArray={this.state.customersArray}
                                            handleInput={this.handleInput}
                                            handleSaveEmployee={this.handleSaveEmployee}
                                            handleDelete={
                                                this.state.newAccount
                                                    ? false
                                                    : () => this.toggleModal("showDeleteModal")
                                            }
                                            handleDropdown={this.handleDropdown}
                                            newAccount={this.state.newAccount}
                                            handleBack={this.handleBack}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {this.state.showAdditionalInfo && (
                            <div className="mt-20 w-100-perc">
                                <Salaries employeeID={this.props.match.params.id} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountDetails;
