import React from "react";
import Translate from "./translate";

export const ActionUtilFunctions = {
    renderPriority: (priority) => {
        if (parseInt(priority) === 0) {
            return <Translate text="NO_STATE" />;
        }
        if (parseInt(priority) === 1) {
            return <Translate text="IMPORTANT" />;
        }
        if (parseInt(priority) === 2) {
            return <Translate text="VERY_IMPORTANT" />;
        }
        if (parseInt(priority) === 3) {
            return <Translate text="PRIORITY" />;
        }
        if (parseInt(priority) === 4) {
            return <Translate text="URGENT" />;
        }
    },
    renderActionState: (status) => {
        if (parseInt(status) === 0) {
            return <Translate text="NO_STATE" />;
        }
        if (parseInt(status) === 1) {
            return <Translate text="PLANNED_ACTION" />;
        }
        if (parseInt(status) === 2) {
            return <Translate text="IN_PROGRESS_ACTION" />;
        }
        if (parseInt(status) === 3) {
            return <Translate text="COMPLETED_ACTION" />;
        }
    },
};
