import React from "react";
import { CustomButton, CustomDatePicker, CustomDropdown, CustomForm, CustomTextArea } from "devkit/Form/Form";
import Modal from "devkit/Modal/Modal";
import { StoreService } from "services/store.service";
import Translate from "functions/utilFunctions/translate";
import ApiService from "services/api.service";
import Table from "devkit/Table/Table";
import { ModalContent } from "devkit/Modal/ModalContent";
import { FilterIcon } from "components/Icons/FileManagerIcons";
import FilterSidebar from "components/FilterSidebar/FilterSidebar";
import { parseDatetime } from "functions/utilFunctions/parsingFunctions";
import { UtilService } from "services/util.service";

class TimeTrackPersonal extends React.Component<any, any> {
    state = {
        filters: {
            start_date: {
                value: new Date(),
                errors: [],
            },
            end_date: {
                value: new Date(),
                errors: [],
            },
        },
        form: {
            actionName: {
                value: 0,
                errors: [],
            },
            actionDesc: {
                value: "",
                errors: [],
            },
            actionStatus: {
                value: 0,
                errors: [],
            },
            spentTimeHours: {
                value: 0,
                errors: [],
            },
            spentTimeMinutes: {
                value: 0,
                errors: [],
            },
            startDateAction: {
                value: new Date(),
                errors: [],
            },
            endDateAction: {
                value: new Date(),
                errors: [],
            },
        },
        timeTrackTableHeads: [
            {
                value: "EMPLOYEE",
                sortable: true,
                sortableType: "string",
                sortableName: "full_name_sorter",
            },
            {
                value: "ACTION",
                sortable: true,
                sortableType: "date",
                sortableName: "date",
            },
            {
                value: "DESCRIPTION",
                sortableType: "string",
                sortableName: "type",
            },
            {
                value: "STATUS",
                sortable: true,
                sortableType: "string",
                sortableName: "amount",
            },
            {
                value: "TIME",
                sortableType: "string",
                sortableName: "details",
            },
            {
                value: "START",
                sortableType: "string",
                sortableName: "details",
            },
            {
                value: "END",
                sortableType: "string",
                sortableName: "details",
            },
            { value: "" },
        ],
        timeTracks: [] as any,
        actionsData: [] as any,
        actionArray: [] as any,
        statusesData: [
            { value: 1, label: <Translate text="IN_PROGRESS" /> },
            { value: 2, label: <Translate text="FINISHED" /> },
        ],
        disableInputs: false,
        timeTrackID: 0,
        // timeTrackModal: this.props.timeTrackModal,
        showDeleteModal: false,
        showSuccessfulDelete: false,
        updateTimeTrackModal: false,
        endDateErrorModal: false,
        showFilterSidebar: false,
        showSuccessfulStoreItem: false,
    };

    async componentDidMount() {
        const component = this;
        // const employeeId = StoreService.getStoreProperty("user").id;

        await this.getAllTimeTrack();

        const actionsForLoggedEmployee = this.state.actionArray.filter((action) => {
            const date = new Date(action.startDate);
            return (
                // return actions for logged user which startDate is -30 or +30 days from today
                new Date().getTime() - date.getTime() <= 30 * 24 * 60 * 60 * 1000 ||
                date.getTime() - new Date().getTime() >= 30 * 24 * 60 * 60 * 1000
            );
            // }
        });

        const actionsData = actionsForLoggedEmployee.map((e) => ({
            value: e.id,
            label: e.title,
        }));
        // actionsData.unshift({ value: 0, label: <Translate text="CHOOSE_ACTION" /> });
        component.setState({
            actionsData,
        });
    }

    getAllTimeTrack = () => {
        const token = StoreService.getStoreProperty("token");
        const employeeId = StoreService.getStoreProperty("user").id;

        const timeTrackPersonalFilters = StoreService.getStoreProperty("timeTrackPersonalFilters");
        this.setState(
            (prevState: any) => ({
                filters: {
                    ...prevState.filters,
                    start_date: {
                        // value: 0,
                        value: timeTrackPersonalFilters?.startDate
                            ? timeTrackPersonalFilters.startDate
                            : UtilService.currentWeek().weekStart,
                        errors: [],
                    },
                    end_date: {
                        // value: 0,
                        value: timeTrackPersonalFilters?.endDate
                            ? timeTrackPersonalFilters.endDate
                            : UtilService.currentWeek().weekEnd,
                        errors: [],
                    },
                },
            }),
            async () => {
                const data = {
                    start_date: parseDatetime(this.state.filters.start_date.value),
                    end_date: parseDatetime(this.state.filters.end_date.value),
                    employee_id: employeeId,
                    limit: 20,
                    offset: 0,
                };

                await ApiService.getAllTimeTracks(data, token).then((res) => {
                    if (res.status === "OK") {
                        this.setState({
                            timeTracks: res.data,
                            actionArray: res.user_actions,
                        });
                    }
                });
            }
        );

        // const data = {
        //     start_date: UtilService.currentWeek().weekStart,
        //     end_date: UtilService.currentWeek().weekEnd,
        //     limit: 20,
        //     offset: 0,
        //     employee_id: employeeId,
        // };
        // this.setState({
        //     filters: {
        //         start_date: {
        //             value: UtilService.currentWeek().weekStart,
        //             errors: [],
        //         },
        //         end_date: {
        //             value: UtilService.currentWeek().weekEnd,
        //             errors: [],
        //         },
        //     },
        // });
    };
    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.form.actionName !== this.state.form.actionName &&
            typeof this.state.form.actionName.value === "number"
        ) {
            this.fillInputs();
        }
        if (prevProps.timeTrackModal !== this.props.timeTrackModal && !this.props.timeTrackModal) {
            this.clearInputs();
        }
    }
    componentWillUnmount() {
        const { filters } = this.state;
        StoreService.updateStoreProperty("timeTrackPersonalFilters", {
            startDate: new Date(filters.start_date.value),
            endDate: new Date(filters.end_date.value),
        });
    }
    fillInputs = () => {
        const component = this;
        const { actionArray } = this.state;
        const choosenAction = actionArray.find((action) => action.id === this.state.form.actionName.value);
        let actionStatus;
        if (choosenAction) {
            if (choosenAction.status <= 2) {
                actionStatus = choosenAction.status;
            } else {
                actionStatus = 2;
            }
            component.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    actionDesc: {
                        value: choosenAction?.remark ? choosenAction.remark : "",
                        errors: [],
                    },
                    actionStatus: {
                        value: actionStatus,
                        errors: [],
                    },
                    spentTimeHours: {
                        value: 0,
                        errors: [],
                    },
                    spentTimeMinutes: {
                        value: 0,
                        errors: [],
                    },
                    startDateAction: {
                        value: new Date(choosenAction.startDate),
                        errors: [],
                    },
                    endDateAction: {
                        value: new Date(choosenAction.endDate),
                        errors: [],
                    },
                },
                disableInputs: true,
            }));
        }
    };

    clearInputs = () => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                actionName: {
                    // value: actionNameValue ? actionNameValue : 0,
                    value: 0,
                    errors: [],
                },
                actionDesc: {
                    value: "",
                    errors: [],
                },
                actionStatus: {
                    value: 0,
                    errors: [],
                },
                spentTimeHours: {
                    value: 0,
                    errors: [],
                },
                spentTimeMinutes: {
                    value: 0,
                    errors: [],
                },
                startDateAction: {
                    value: new Date(),
                    errors: [],
                },
                endDateAction: {
                    value: new Date(),
                    errors: [],
                },
            },
            disableInputs: false,
            timeTrackID: 0,
            updateTimeTrackModal: false,
        }));
    };

    handleInput = (name, data) => {
        if (name === "start_date" || name === "end_date") {
            this.setState((prevState: any) => ({
                filters: {
                    ...prevState.filters,
                    [name]: {
                        value: data.value,
                        errors: data.errors,
                    },
                },
            }));
        } else {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    [name]: {
                        value: data.value,
                        errors: data.errors,
                    },
                },
            }));
        }
    };

    hours = () => {
        let hours = [] as any;
        for (let index = 0; index < 25; index++) {
            hours.push(index);
        }
        let finalHours = hours.map((el, index) => {
            return {
                value: index,
                label: el,
            };
        });
        // finalHours.unshift({ label: "Select", value: 0 });

        return finalHours;
    };
    saveTimeTrack = () => {
        const { form } = this.state;
        let actionTitle;

        if (typeof form.actionName.value === "number") {
            actionTitle = this.state.actionsData.find((action) => action.value === form.actionName.value);
        }

        if (new Date(this.state.form.endDateAction.value) < new Date(this.state.form.startDateAction.value)) {
            this.setState({
                endDateErrorModal: true,
            });
            return;
        }

        const errors = {};
        Object.keys(form).forEach((e) => {
            console.log(e);

            if (!form[e].value) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
            if (e === "actionDesc") {
                if (form["actionDesc"].value.length < 4) {
                    errors["actionDesc"] = {
                        value: form[e].value,
                        errors: ["MIN_LENGTH_4_CHAR"],
                    };
                }
            }
        });

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else {
            const token = StoreService.getStoreProperty("token");
            const data = {
                title: typeof form.actionName.value === "string" ? form.actionName.value : actionTitle?.label,
                description: form.actionDesc.value,
                status: form.actionStatus.value,
                start_date: parseDatetime(form.startDateAction.value),
                end_date: parseDatetime(form.endDateAction.value),
                hours: typeof form.spentTimeHours.value === "object" ? 0 : form.spentTimeHours.value,
                minutes: form.spentTimeMinutes.value > 1 ? form.spentTimeMinutes.value : 0,
                action_id: typeof form.actionName.value === "number" ? form.actionName.value : undefined,
            };
            ApiService.createTimeTrack(data, token).then((res) => {
                if (res.status === "OK") {
                    this.setState({
                        showSuccessfulStoreItem: true,
                    });
                    this.props.toggleModal("timeTrackModal");
                    this.toggleModal("showSuccessModalData");
                    this.clearInputs();
                    this.getAllTimeTrack();
                }
            });
        }
    };

    fillModalForUpdate = async (timeTrackID) => {
        const token = StoreService.getStoreProperty("token");
        this.setState({
            timeTrackID: timeTrackID,
            updateTimeTrackModal: true,
        });
        const data = {
            id: timeTrackID,
        };
        await ApiService.getTimeTrackById(data, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    form: {
                        actionName: {
                            value: res.data.title,
                            // value: res.data.action_id !== null ? res.data.action_id : res.data.title,
                            errors: [],
                        },
                        actionDesc: {
                            value: res.data.description,
                            errors: [],
                        },
                        actionStatus: {
                            value: res.data.status,
                            errors: [],
                        },
                        spentTimeHours: {
                            value: res.data.hours,
                            errors: [],
                        },
                        spentTimeMinutes: {
                            value: res.data.minutes > 1 ? res.data.minutes : 1,
                            errors: [],
                        },
                        startDateAction: {
                            value: new Date(res.data.start_date),
                            errors: [],
                        },
                        endDateAction: {
                            value: new Date(res.data.end_date),
                            errors: [],
                        },
                    },
                    // updateTimeTrackModal: true,
                });
                this.props.toggleModal("timeTrackModal");
            } else {
                alert("Something went wrong");
            }
        });
    };

    updateTimeTrack = () => {
        const { form } = this.state;

        const errors = {};
        Object.keys(form).forEach((e) => {
            if (!form[e].value) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
        });

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else {
            const token = StoreService.getStoreProperty("token");
            const data = {
                id: this.state.timeTrackID,
                title: form.actionName.value ? form.actionName.value : "",
                description: form.actionDesc.value,
                status: form.actionStatus.value,
                start_date: form.startDateAction.value,
                end_date: form.endDateAction.value,
                hours: form.spentTimeHours.value,
                minutes: form.spentTimeMinutes.value > 1 ? form.spentTimeMinutes.value : 0,
                // action_id?: ,
            };
            ApiService.updateTimeTrack(data, token).then((res) => {
                if (res.status === "OK") {
                    this.props.toggleModal("timeTrackModal");
                    this.toggleModal("showSuccessModalData");
                    this.clearInputs();
                    this.getAllTimeTrack();
                }
            });
        }
    };

    confirmDelete = (id) => {
        this.setState({
            showDeleteModal: true,
            timeTrackID: id,
        });
    };

    deleteTimeTrack = () => {
        const { timeTrackID } = this.state;
        const token = StoreService.getStoreProperty("token");
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            timeTrackID: 0,
        });
        const data = {
            id: timeTrackID,
        };

        ApiService.deleteTimeTrack(data, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    showSuccessfulDelete: true,
                });
                this.getAllTimeTrack();
            }
        });
    };
    toggleModal = (name) => {
        this.setState({
            [name]: !this.state[name],
        });
    };

    changeDay = (whereToGo: string) => {
        const { filters } = this.state;
        if (whereToGo === "back") {
            let dateBack = filters.start_date.value.setDate(filters.start_date.value.getDate() - 1);
            this.setState((prevState: any) => ({
                filters: {
                    ...prevState.filters,
                    start_date: {
                        value: new Date(dateBack),
                        errors: [],
                    },
                    end_date: {
                        value: new Date(dateBack),
                        errors: [],
                    },
                },
            }));
        } else {
            let dateForward = filters.start_date.value.setDate(filters.start_date.value.getDate() + 1);

            this.setState((prevState: any) => ({
                filters: {
                    ...prevState.filters,
                    start_date: {
                        value: new Date(dateForward),
                        errors: [],
                    },
                    end_date: {
                        value: new Date(dateForward),
                        errors: [],
                    },
                },
            }));
        }
    };

    toggleSidebar = (e) => {
        e.preventDefault();
        this.setState({ showFilterSidebar: !this.state.showFilterSidebar });
    };

    submitFilters = (e) => {
        e.preventDefault();
        const token = StoreService.getStoreProperty("token");
        const employeeId = StoreService.getStoreProperty("user").id;
        const { filters } = this.state;
        const offset = (1 - 1) * 20;
        const data: any = {
            limit: 20,
            offset: offset,
            employee_id: employeeId,
        };

        for (let f in filters) {
            if (filters[f].value) {
                if (f === "start_date" || f === "end_date") {
                    data[f] = parseDatetime(filters[f].value);
                } else {
                    data[f] = filters[f].value;
                }
            }
        }

        ApiService.getAllTimeTracks(data, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    timeTracks: res.data,
                });
            } else {
                throw Error("Error");
            }
        });
    };

    resetFilters = (e) => {
        e.preventDefault();
        const token = StoreService.getStoreProperty("token");
        const employeeId = StoreService.getStoreProperty("user").id;
        const offset = (1 - 1) * 20;

        this.setState({
            filters: {
                start_date: {
                    value: new Date(UtilService.currentWeek().weekStart),
                    errors: [],
                },
                end_date: {
                    value: new Date(UtilService.currentWeek().weekEnd),
                    errors: [],
                },
            },
        });
        const data = {
            limit: 20,
            offset,
            start_date: UtilService.currentWeek().weekStart,
            end_date: UtilService.currentWeek().weekEnd,
            employee_id: employeeId,
        };

        ApiService.getAllTimeTracks(data, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    timeTracks: res.data,
                });
            } else {
                throw Error("Error");
            }
        });
    };
    render() {
        const {
            filters,
            form,
            disableInputs,
            actionsData,
            timeTrackTableHeads,
            timeTracks,
            statusesData,
            showFilterSidebar,
        } = this.state;
        const actionsDataFormatted = actionsData ? actionsData.find((el) => el.value === form.actionName.value) : "";

        return (
            <div className="timeTrackEmployee w-100-perc overflow-auto pb-20">
                <div className="filters mb-30">
                    <div>
                        <CustomButton
                            onClick={this.toggleSidebar}
                            className="filterBtn btn btn-primary d-flex align-items-center "
                        >
                            <FilterIcon className="icon" width={16} />
                            <span className="ml-10">Filteri</span>
                        </CustomButton>
                    </div>
                    <FilterSidebar
                        opened={showFilterSidebar}
                        onClose={() => this.setState({ showFilterSidebar: !showFilterSidebar })}
                        submitFilters={this.submitFilters}
                        resetFilters={this.resetFilters}
                    >
                        <CustomForm className="row d-flex flex-column mb-30 mt-10">
                            <div className="d-flex">
                                <div className="col-1 arrow back" onClick={() => this.changeDay("back")}>
                                    <i className="fas fa-arrow-left"></i>
                                </div>
                                <div className="col-10">
                                    <div className="mb-20">
                                        <CustomDatePicker
                                            name="start_date"
                                            handleInput={this.handleInput}
                                            value={filters.start_date.value}
                                            errors={filters.start_date.errors}
                                            label="Start date"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <CustomDatePicker
                                            name="end_date"
                                            handleInput={this.handleInput}
                                            value={filters.end_date.value}
                                            errors={filters.end_date.errors}
                                            label="End date"
                                        />
                                    </div>
                                </div>

                                <div className="col-1 arrow" onClick={() => this.changeDay("forward")}>
                                    <i className="fas fa-arrow-right"></i>
                                </div>
                            </div>
                        </CustomForm>
                    </FilterSidebar>
                </div>
                <Table theads={timeTrackTableHeads} theadsClassname="customThead">
                    {timeTracks.map((track) => (
                        <tr key={track.id} className="f-s-14">
                            <td>{track.firstName + " " + track.lastName}</td>
                            <td>{track.title}</td>
                            <td className="w-100 max-w-200 wordWrap">
                                {track.description.length < 50
                                    ? track.description
                                    : track.description.substring(0, 49) + "..."}
                            </td>
                            <td>
                                {track.status === 1 ? (
                                    <Translate text={"IN_PROGRESS"} />
                                ) : (
                                    <Translate text={"FINISHED"} />
                                )}
                            </td>
                            <td>
                                {(track.hours < 10 ? "0" + track.hours : track.hours) +
                                    ":" +
                                    (track.minutes === 0 ? "00" : track.minutes) +
                                    "h"}
                            </td>
                            <td>{track.start_date}</td>
                            <td>{track.end_date}</td>
                            <td>
                                <div className="d-flex padding-5 justify-content-end">
                                    <i
                                        className="fas mr-20 fa-edit edit-icon padding-10 border-1 pointer border-radius-6 w-35"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            // this.handleGoToAcc(e);
                                            this.fillModalForUpdate(track.id);
                                        }}
                                    />
                                    <i
                                        className="fas fa-trash trash-icon padding-10 border-1 pointer border-radius-6 w-35"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            this.confirmDelete(track.id);
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </Table>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        title="DELETE_TIME_TRACK"
                        text={"areYouSureDeleteTimeTrack"}
                        modalName="showDeleteModal"
                        toggleModal={this.toggleModal}
                        deleteItem={this.deleteTimeTrack}
                        cancelDelete={() => this.toggleModal("showDeleteModal")}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessfulDelete"
                    className={this.state.showSuccessfulDelete ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        title="DELETE_TIME_TRACK"
                        text={"TIME_TRACK_DELETE_SUCCESS"}
                        modalName="showSuccessfulDelete"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessfulStoreItem"
                    className={this.state.showSuccessfulStoreItem ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        title="SUCCESS"
                        text={"SUCCESSFULLY_ADDED_TIME_TRACK"}
                        modalName="showSuccessfulStoreItem"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                {/* this timeTrackModal opens and closes in the parent component Personal account because button for him is on that screen */}
                <Modal
                    modalName="timeTrackModal"
                    toggleModal={this.props.toggleModal}
                    className={this.props.timeTrackModal ? "visible" : ""}
                    modalWrapperClasses="timeTrackModal w-500 p-20"
                >
                    <div>
                        <CustomForm>
                            <CustomDropdown
                                handleChange={this.handleInput}
                                name="actionName"
                                label="ACTION_NAME"
                                data={actionsData}
                                value={
                                    actionsDataFormatted !== undefined
                                        ? actionsDataFormatted
                                        : { label: form.actionName.value, value: form.actionName.value }
                                }
                                errors={form.actionName.errors}
                                creatable
                                required
                            />
                            <CustomTextArea
                                name="actionDesc"
                                value={form.actionDesc.value}
                                errors={form.actionDesc.errors}
                                label="ACTION_DESCRIPTION"
                                handleInput={this.handleInput}
                                className="my-30 f-s-14"
                                required
                            />
                            <CustomDropdown
                                handleChange={this.handleInput}
                                name="actionStatus"
                                data={statusesData}
                                value={statusesData.find((el) => el.value === form.actionStatus.value)}
                                errors={form.actionStatus.errors}
                                label="STATUS"
                                required
                                disabled={disableInputs}
                            />
                            <div className="d-flex my-30 dropdownGroup">
                                <div className="w-50-perc">
                                    <CustomDropdown
                                        handleChange={this.handleInput}
                                        name="spentTimeHours"
                                        data={this.hours()}
                                        value={this.hours().find((el) => el.value === form.spentTimeHours.value)}
                                        errors={form.spentTimeHours.errors}
                                        label="SPENT_TIME_HOURS"
                                        required
                                    />
                                </div>
                                <div className="w-50-perc ml-15">
                                    <CustomDropdown
                                        handleChange={this.handleInput}
                                        name="spentTimeMinutes"
                                        data={[
                                            { label: "00", value: 1 },
                                            { label: "15", value: 15 },
                                            { label: "30", value: 30 },
                                            { label: "45", value: 45 },
                                        ]}
                                        label="SPENT_TIME_MINUTES"
                                        required
                                        value={[
                                            { label: "Select", value: 0 },
                                            { label: "00", value: 1 },
                                            { label: "15", value: 15 },
                                            { label: "30", value: 30 },
                                            { label: "45", value: 45 },
                                        ].find((el) => el.value === form.spentTimeMinutes.value)}
                                        errors={form.spentTimeMinutes.errors}
                                    />
                                </div>
                            </div>
                            <div className="d-flex dropdownGroup">
                                <div className="w-50-perc">
                                    <CustomDatePicker
                                        name="startDateAction"
                                        handleInput={this.handleInput}
                                        value={form.startDateAction.value}
                                        errors={form.startDateAction.errors}
                                        label="ACTION_START_DATE"
                                        disabled={disableInputs}
                                        required
                                    />
                                </div>
                                <div className="w-50-perc ml-15">
                                    <CustomDatePicker
                                        name="endDateAction"
                                        handleInput={this.handleInput}
                                        value={form.endDateAction.value}
                                        errors={form.endDateAction.errors}
                                        label="ACTION_END_DATE"
                                        disabled={disableInputs}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-30">
                                <CustomButton
                                    className="btn-save mr-10"
                                    type="button"
                                    onClick={
                                        this.state.updateTimeTrackModal ? this.updateTimeTrack : this.saveTimeTrack
                                    }
                                >
                                    <Translate text={this.state.updateTimeTrackModal ? "UPDATE" : "SAVE"} />
                                </CustomButton>
                                <CustomButton
                                    className={"btn-back  w-150"}
                                    type="button"
                                    onClick={() => {
                                        this.clearInputs();
                                        this.props.toggleModal("timeTrackModal");
                                    }}
                                >
                                    <Translate text={"BACK"} />
                                </CustomButton>
                            </div>
                        </CustomForm>
                    </div>
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="endDateErrorModal"
                    className={this.state.endDateErrorModal ? "visible bg-info-3" : ""}
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="End date cannot be lower than start date!"
                        modalName="endDateErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
            </div>
        );
    }
}

export default TimeTrackPersonal;
