interface FileSystemEntry {
    FileSystem: FileSystem;
    isFile: boolean;
    isDirectory: boolean;
    createReader: () => FileSystemDirectoryReader;
    file: any;
    fullPath: string;
}

interface FileSystemDirectoryReader {
    readEntries: (successCallback) => Array<FileSystemEntry>;
}

export async function getFilesAsync(dataTransfer: DataTransfer) {
    const files: File[] = [];
    for (let i = 0; i < dataTransfer.items.length; i++) {
        const item = dataTransfer.items[i];
        if (item.kind === "file") {
            if (typeof item.webkitGetAsEntry === "function") {
                const entry = item.webkitGetAsEntry();
                const entryContent = await readEntryContentAsync(entry);
                files.push(...entryContent);
                continue;
            }
            const file = item.getAsFile();
            if (file) {
                files.push(file);
            }
        }
    }
    return files;
}

function readEntryContentAsync(entry: FileSystemEntry) {
    return new Promise<File[]>((resolve) => {
        let reading = 0;
        const contents: File[] = [];
        readEntry(entry, "");
        function readEntry(entry: FileSystemEntry, path: string) {
            if (isFile(entry)) {
                reading++;
                entry.file((file) => {
                    reading--;
                    file.path = path;
                    contents.push(file);
                    if (reading === 0) {
                        resolve(contents);
                    }
                });
            } else if (isDirectory(entry)) {
                readReaderContent(entry.createReader());
            }
        }
        function readReaderContent(reader: FileSystemDirectoryReader) {
            reading++;
            reader.readEntries(function (entries) {
                reading--;
                for (const entry of entries) {
                    readEntry(
                        entry,
                        entry.fullPath.replace(entry.name, "").slice(0, -1)
                    );
                }
                if (reading === 0) {
                    resolve(contents);
                }
            });
        }
    });
}

function isDirectory(entry: FileSystemEntry): boolean {
    return entry.isDirectory;
}

function isFile(entry: FileSystemEntry): boolean {
    return entry.isFile;
}
