import { CustomDatePicker, CustomForm, CustomInput, CustomDropdown, CustomButton } from "devkit/Form/Form";
import Translate from "functions/utilFunctions/translate";
import React, { Component } from "react";

class EditAccount extends Component<any> {
    render() {
        const {
            firstName,
            lastName,
            email,
            phone_number,
            dateOfBirth,
            dateOfEmployment,
            role,
            mentor,
            NewPassword,
            NewPin,
            customers,
        } = this.props.data;
        const { newAccount, roles, users, customersArray } = this.props;
        const filteredCustomers = customers.value && customers.value.filter((e: any) => e.label !== "");
        return (
            <div className="col-12 h-100perc">
                <CustomForm className="d-flex col-12 flex-column padding-0 margin-t-10">
                    <div className="mb-30">
                        <CustomInput
                            name="firstName"
                            label={"NAME"}
                            value={firstName.value}
                            type="text"
                            required={true}
                            errors={firstName.errors}
                            handleInput={this.props.handleInput}
                        />
                    </div>
                    <div className="mb-30">
                        <CustomInput
                            name="lastName"
                            label="LAST_NAME"
                            value={lastName.value}
                            type="text"
                            required={true}
                            errors={lastName.errors}
                            handleInput={this.props.handleInput}
                        />
                    </div>
                    <div className="mb-30">
                        <CustomDropdown
                            data={customersArray}
                            name="customers"
                            value={filteredCustomers}
                            handleChange={this.props.handleDropdown}
                            multiple
                            className="multiple"
                            label="CLIENT"
                            errors={customers.errors}
                            //required={true}
                        />
                    </div>
                    <div className="mb-30">
                        <CustomDropdown
                            data={roles}
                            name="roleDropdown"
                            placeholder={role.label}
                            value={roles.find((e) => e.value === role.value)}
                            handleChange={this.props.handleDropdown}
                            label="ROLE"
                            errors={role.errors}
                        />
                    </div>
                    {role.value === 1 ? (
                        <div className="mb-30">
                            <CustomDropdown
                                data={users}
                                name="userDropdown"
                                // placeholder={mentor.label}
                                value={users.find((e) => e.value === mentor.value)}
                                handleChange={this.props.handleDropdown}
                                label="MENTOR"
                                errors={mentor.errors}
                                // required={role !== 1 ? false : true}
                            />
                        </div>
                    ) : (
                        <div></div>
                    )}
                    <div className="mb-30">
                        <CustomInput
                            name="email"
                            label="Email"
                            value={email.value}
                            type="email"
                            required={true}
                            errors={email.errors}
                            handleInput={this.props.handleInput}
                        />
                    </div>
                    <div className="mb-30">
                        <CustomInput
                            name="phone_number"
                            label="PHONE"
                            value={phone_number.value}
                            type="number"
                            errors={phone_number.errors}
                            handleInput={this.props.handleInput}
                        />
                    </div>
                    <div className="mb-30">
                        <CustomDatePicker
                            name="dateOfBirth"
                            handleInput={this.props.handleInput}
                            value={dateOfBirth.value}
                            errors={dateOfBirth.errors}
                            label="DATE_OF_BIRTH"
                        />
                    </div>
                    <div className="mb-30">
                        <CustomDatePicker
                            name="dateOfEmployment"
                            handleInput={this.props.handleInput}
                            value={dateOfEmployment.value}
                            errors={dateOfEmployment.errors}
                            label="DATE_OF_EMPLOYMENT"
                        />
                    </div>
                    {newAccount && (
                        <div className="d-flex flex-column mb-30">
                            <div className="mb-30">
                                <CustomInput
                                    name="NewPin"
                                    label="NewPin"
                                    type="password"
                                    handleInput={this.props.handleInput}
                                    value={NewPin.value}
                                    errors={NewPin.errors}
                                    required={true}
                                    maxLength={4}
                                />
                            </div>
                            <div>
                                <CustomInput
                                    name="NewPassword"
                                    label="NewPassword"
                                    type="password"
                                    handleInput={this.props.handleInput}
                                    value={NewPassword.value}
                                    errors={NewPassword.errors}
                                    required={true}
                                />
                            </div>
                        </div>
                    )}
                    <div className="d-flex justify-content-between align-items-center">
                        <CustomButton className="btn-save mr-10" type="button" onClick={this.props.handleSaveEmployee}>
                            <Translate text={this.props.handleDelete ? "UPDATE" : "SAVE"} />
                        </CustomButton>
                        <CustomButton
                            className={this.props.handleDelete ? "btn-delete" : "btn-back w-150"}
                            type="button"
                            onClick={this.props.handleDelete ? this.props.handleDelete : this.props.handleBack}
                        >
                            <Translate text={this.props.handleDelete ? "DELETE" : "BACK"} />
                        </CustomButton>
                    </div>
                </CustomForm>
            </div>
        );
    }
}

export default EditAccount;
