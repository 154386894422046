const constDebounceThrottle = {};

export const handleDebouncing = (callbackKey, callback, period) => {
    return new Promise(() => {
        function interval(callbackObject) {
            clearInterval(callbackObject.interval);
            callbackObject.callback();
        }

        if (constDebounceThrottle[callbackKey]) {
            clearInterval(constDebounceThrottle[callbackKey].interval);
        }

        constDebounceThrottle[callbackKey] = {
            duration: period,
            interval: setInterval(() => {
                interval(constDebounceThrottle[callbackKey]);
            }, period),
            callback: callback,
        };
    });
};

const throttleCallback = {};

export const handleThrottle = (callbackKey, callback, period) => {
    return new Promise(() => {
        function timeout(callbackKey) {
            delete throttleCallback[callbackKey];
        }
        if (throttleCallback[callbackKey]) {
            return;
        }

        throttleCallback[callbackKey] = {
            duration: period,
            interval: setTimeout(() => {
                callback();
                timeout(callbackKey);
            }, period),
            callback: callback,
        };
    });
};
