import { CustomButton, CustomForm, CustomInput } from "devkit/Form/Form";
import { Loader } from "devkit/Loader/Loader";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import Translate from "functions/utilFunctions/translate";
import React, { Component } from "react";
import ApiService from "services/api.service";
import { StoreService } from "services/store.service";
import AccordionRealEstates from "components/Accordion/AccordionRealEstates";

export class RealEstates extends Component<any, any> {
    state = {
        form: {
            pibSearch: {
                value: this.props.pib.value,
                errors: [],
            },
        },
        realEstates: this.props.realEstates,
        showLoader: false,
        noRealEstatesModal: false,
        previewFileModal: false,
    };

    getData = (e) => {
        e.preventDefault();
        const data = {
            jmbg: this.state.form.pibSearch.value,
            customer_id: this.props.props?.match?.params?.id,
        };
        const token = StoreService.getStoreProperty("token");
        this.setState({
            showLoader: true,
        });
        ApiService.getRealEstate(data, token).then((res) => {
            if (res.status === "OK") {
                const data = res.data;
                if (data.length) {
                    this.setState({
                        realEstates: data,
                        showLoader: false,
                    });
                } else {
                    this.setState({
                        showLoader: false,
                        noRealEstatesModal: true,
                    });
                }
            } else {
                alert("Error");
            }
        });
    };
    refreshData = (e) => {
        e.preventDefault();
        // const broj = "03292053";
        const data = {
            jmbg: this.state.form.pibSearch.value,
            customer_id: this.props.props?.match?.params?.id,
        };

        const token = StoreService.getStoreProperty("token");
        this.setState({
            showLoader: true,
        });
        ApiService.refreshRealEstate(data, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    realEstates: res.data,
                    showLoader: false,
                });
            }
        });
    };
    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    downloadFile = (id, name) => {
        const token = StoreService.getStoreProperty("token");
        this.setState({ activeDownload: [{ name }], showDownloadComponent: true }, () => {
            const config = {
                onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({
                        completedList: [{ completed: percentCompleted }],
                    });
                },
            };
            ApiService.downloadFile({ id }, config, token).then((res) => {
                if (!res) return;

                const blobURL = window.webkitURL.createObjectURL(res);
                const link = document.createElement("a");
                link.href = blobURL;
                link.setAttribute("download", name); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        });
    };
    previewFile = () => {
        this.setState({
            previewFileModal: true,
        });
    };
    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };
    render() {
        // const storage = Constants.STORAGE.slice(0, -1);
        const { realEstates } = this.state;

        return (
            <div className="realEstates">
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="noRealEstatesModal"
                    className={this.state.noRealEstatesModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="NO_DATA"
                        modalName="noRealEstatesModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <CustomForm>
                    <div className="w-300">
                        <CustomInput
                            value={this.state.form.pibSearch.value}
                            handleInput={this.handleInput}
                            name="pibSearch"
                            type="text"
                            label="JMBG/PIB"
                        />
                    </div>
                    <CustomButton className="btn-primary mt-20 mr-20" onClick={this.getData}>
                        <Translate text={"GET_DATA"} />
                    </CustomButton>
                    <CustomButton className="btn-primary mt-20" onClick={this.refreshData}>
                        <Translate text={"REFRESH_DATA"} />
                    </CustomButton>
                </CustomForm>
                {this.state.showLoader ? (
                    <div className="loaderContainer">
                        <Loader width={200} />
                        <span className="loaderContainer mt-100 f-s-15 one-line-text">
                            <Translate text="REAL_ESTATES_SEARCH" />
                            ...
                        </span>
                    </div>
                ) : (
                    <div className="d-flex flex-column mt-50">
                        {realEstates.map((realEstate) => {
                            return (
                                <AccordionRealEstates
                                    key={realEstate.uid}
                                    realEstate={realEstate}
                                    downloadFile={(id, name) => this.downloadFile(id, name)}
                                    previewFile={this.previewFile}
                                    toggleModal={this.toggleModal}
                                    previewFileModal={this.state.previewFileModal}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

export default RealEstates;
