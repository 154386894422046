import Translate from "functions/utilFunctions/translate";
import React, { useState } from "react";
import { Constants } from "services/constants.service";
import { StoreService } from "services/store.service";
import { CustomTextArea } from "devkit/Form/Form";
import ApiService from "services/api.service";
import { SendMessageIcon } from "../../../components/Icons/Icons";
import CommentComponent from "./CommentComponent";
import { Avatar } from "components/Icons/Icons";
// import { parseDatetime } from "functions/utilFunctions/parsingFunctions";

const Comments = ({ actionID, allComments }) => {
    // const [editCommentState, setEditCommentState] = useState({ value: editComment.value, errors: editComment.errors });

    const [newComment, setNewComment] = useState({ value: "", errors: [] });
    const user = StoreService.getStoreProperty("user");
    const token = StoreService.getStoreProperty("token");
    const pictureUrl = user.profile_image && user.profile_image.split("/");

    const handleInput = (name, data) => {
        setNewComment({ value: data.value, errors: data.errors });
    };
    const [allCommentsState, setAllCommentsState] = useState(allComments);

    const sendComment = (e) => {
        e.preventDefault();
        const data = {
            text: newComment.value,
            action_id: parseInt(actionID),
            user_id: user.id,
        };

        ApiService.createActionComment(data, token).then((res) => {
            if (res.status === "OK") {
                const noviKom = {
                    id: res.data.id,
                    text: newComment.value,
                    created_at: res.data.created_at,
                    user: {
                        id: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        profile_image: user.profile_image,
                    },
                };
                allCommentsState.unshift(noviKom);
                setAllCommentsState(allCommentsState);

                // setAllCommentsState((prevState) => {
                //     return [...prevState, noviKom];
                // });
                setNewComment({ value: "", errors: [] });
            } else {
                alert("Error");
            }
        });
    };

    const deleteComment = (commentID) => {
        const data = {
            id: commentID,
        };
        ApiService.deleteActionComment(data, token).then((res) => {
            if (res.status === "OK") {
                setAllCommentsState(allCommentsState.filter((element) => element.id !== commentID));
            } else {
                // alert("Error");
            }
        });
    };
    return (
        <div className="commentsSection my-30">
            <div className="d-flex justify-content-between">
                <span className="smallerFont f-s-16 bold theme-main-1">
                    <Translate text={"COMMENTS"} />
                </span>
            </div>

            <div className="newComment d-flex align-items-center my-20 justify-content-between">
                <div className="d-flex justify-content-center align-items-center">
                    {pictureUrl && pictureUrl[pictureUrl.length - 1] !== "null" ? (
                        <img
                            src={`${Constants.STORAGE}${user.profile_image}`}
                            alt="user"
                            className="w-50 h-50 border-radius-50-perc"
                        />
                    ) : (
                        <Avatar className="w-40 h-40 border-radius-50-perc" />
                    )}
                </div>
                <div className="w-70-perc">
                    <CustomTextArea
                        name="comment"
                        placeholder="New comment"
                        handleInput={handleInput}
                        value={newComment.value}
                        errors={newComment.errors}
                        className="non-resizable"
                        autoGrowTextarea
                    />
                </div>
                <div>
                    <div className="icons d-flex align-items-center justify-content-center">
                        <div
                            className="send w-40 h-40 d-flex justify-content-center align-items-center pointer"
                            onClick={sendComment}
                        >
                            <SendMessageIcon width={15} />
                        </div>
                    </div>
                </div>
            </div>
            {allCommentsState.map((commentItem) => {
                return (
                    <CommentComponent
                        comment={commentItem}
                        token={token}
                        user={user}
                        deleteComment={deleteComment}
                        key={commentItem.id}
                    />
                );
            })}
        </div>
    );
};

export default Comments;
