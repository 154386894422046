import { CustomButton, CustomDropdown, CustomForm, CustomInput } from "devkit/Form/Form";
import React, { Component } from "react";
import {
    dayDifference,
    newDateFromString,
    parseDate,
    parseDateForMySQL,
} from "functions/utilFunctions/parsingFunctions";
import ApiService from "services/api.service";
import { Constants } from "services/constants.service";
import DailyOverview from "./DailyOverview/DailyOverview";
import MonthlyOverview from "./MonthlyOverview/MonthlyOverview";
import { StoreService } from "services/store.service";
import Translate from "functions/utilFunctions/translate";
import { UtilService } from "services/util.service";
import YearlyOverview from "./YearlyOverview/YearlyOverview";
import AgileOverview from "./AgileOverview.tsx/AgileOverview";
import FilterSidebar from "components/FilterSidebar/FilterSidebar";
import { FilterIcon, PlusIcon } from "components/Icons/Icons";

// const calendarFilters = StoreService.getStoreProperty("calendarFilters");

class Calendar extends Component<any> {
    state = {
        form: {
            search: {
                value: "",
                // value: calendarFilters?.search ? calendarFilters?.search : "",
                errors: [],
            },
            user_id: {
                value: 0,
                // value: calendarFilters?.user_id ? calendarFilters?.user_id : "",
                errors: [],
            },
            customer_id: {
                value: 0,
                // value: calendarFilters?.customer_id ? calendarFilters?.customer_id : "",
                errors: [],
            },
            priority: {
                value: 0,
                // value: calendarFilters?.priority ? calendarFilters?.priority : "",
                errors: [],
            },
            status: {
                value: 0,
                // value: calendarFilters?.status ? calendarFilters?.status : "",
                errors: [],
            },
        },
        action: {
            value: 0,
            errors: [],
        },
        priorities: [
            { value: 1, label: <Translate text="IMPORTANT" /> },
            { value: 2, label: <Translate text="VERY_IMPORTANT" /> },
            { value: 3, label: <Translate text="PRIORITY" /> },
            { value: 4, label: <Translate text="URGENT" /> },
        ] as any,
        statuses: [
            { value: 1, label: <Translate text="PLANNED_ACTION" /> },
            { value: 2, label: <Translate text="IN_PROGRESS" /> },
            { value: 4, label: <Translate text="LATE" /> },
            { value: 3, label: <Translate text="FINISHED" /> },
            { value: 5, label: <Translate text="UNASSIGNED_TASKS" /> },
        ] as any,
        users: [] as any,
        actions: null,
        columns: [],
        customers: [] as any,
        currentUser: {
            currentUser: [],
            role: 0,
        },
        showYearlyOverview: false,
        showMonthlyOverview: false,
        showDailyOverview: true,
        showAgile: false,

        // pickedDate: new Date(this.props.match.params.date),
        pickedDate: new Date(),
        isLoading: true,
        startDate: null,
        endDate: null,
        language: true,
        lang: localStorage.getItem("lang"),
        year: new Date().getFullYear(),
        yearToMonth: false,
        filtersLoaded: false,
        showFilterSidebar: false,
    };

    cachedFilters = () => {
        const calendarFilters = StoreService.getStoreProperty("calendarFilters");

        this.setState(
            (prevState: any) => ({
                ...prevState.form,
                form: {
                    search: {
                        // value: "",
                        value: calendarFilters?.search ? calendarFilters?.search : this.state.form.search.value,
                        errors: [],
                    },
                    user_id: {
                        // value: 0,
                        value: calendarFilters?.user_id ? calendarFilters?.user_id : this.state.form.user_id.value,
                        errors: [],
                    },
                    customer_id: {
                        // value: 0,
                        value: calendarFilters?.customer_id
                            ? calendarFilters?.customer_id
                            : this.state.form.customer_id.value,
                        errors: [],
                    },
                    priority: {
                        // value: 0,
                        value: calendarFilters?.priority ? calendarFilters?.priority : this.state.form.priority.value,
                        errors: [],
                    },
                    status: {
                        // value: 0,
                        value: calendarFilters?.status ? calendarFilters?.status : this.state.form.status.value,
                        errors: [],
                    },
                },
            }),
            () => {
                if (calendarFilters?.period === "day") {
                    this.handleGetDailyOverviewActions();
                } else if (calendarFilters?.period === "month") {
                    this.handleGetMonthlyOverviewActions();
                } else {
                    this.handleGetYearlyOverviewActions();
                }
            }
        );
    };

    async componentDidMount() {
        const usersArray = StoreService.getStoreProperty("users");
        const customersArray = StoreService.getStoreProperty("customers");
        const currentUser = StoreService.getStoreProperty("user");
        const recurringActions = StoreService.getStoreProperty("recurringActions");
        const token = StoreService.getStoreProperty("token");
        // const filters = StoreService.getStoreProperty("filters")[0];

        StoreService.updateStoreProperty("recurringActionMap", this.handleRecurringActions(recurringActions), true);

        const users = usersArray.map((e) => ({
            value: e.id,
            label: `${e.firstName} ${e.lastName}`,
        }));
        const customers = customersArray.map((e) => ({
            value: e.id,
            label: e.name,
        }));
        let pickedDate = this.state.pickedDate;

        if (this.props.match.params.date && this.props.match.params.date !== "pin") {
            pickedDate = new Date(this.props.match.params.date);
        }

        this.cachedFilters();

        this.setState({
            users,
            customers,
            currentUser,
            pickedDate,
        });

        currentUser.role === 2 &&
            ApiService.getAllGroups(token).then((res) => {
                if (res.status === "OK") {
                    let usersForDropdownArray: any = [];
                    res.data &&
                        res.data.forEach((e: any) => {
                            usersForDropdownArray.push(...e.members);
                        });
                    let uniqueUsers = usersForDropdownArray.reduce((acc: any, c: any) => {
                        if (acc.findIndex((el: any) => el.id === c.id) === -1) {
                            acc.push(c);
                            return acc;
                        }
                        return acc;
                    }, []);
                    let dropdownUsers = uniqueUsers.map((e) => {
                        return { value: e.id, label: `${e.firstName} ${e.lastName}` };
                    });
                    dropdownUsers.unshift({
                        value: 0,
                        label: <Translate text="CHOOSE_EMPLOYEE" />,
                    });
                    this.setState({
                        users: dropdownUsers,
                    });
                }
            });

        if (this.props.location?.state?.agile) {
            const data = {};
            data["date"] = parseDateForMySQL(this.state.pickedDate);
            const token = StoreService.getStoreProperty("token");
            await ApiService.dailyOverviewActions(data, token).then((res) => {
                if (res.success) {
                    this.setState({
                        columns: res.columns,
                    });
                }
            });
            this.toggleAgile("agile");
        }
    }

    componentDidUpdate(_, prevState) {
        // const { form } = this.state;

        if (prevState.pickedDate.getTime() !== this.state.pickedDate.getTime()) {
            this.setState({ isLoading: true, actions: [] });
            this.handleGetDailyOverviewActions();
        }

        if (prevState.showDailyOverview !== this.state.showDailyOverview && this.state.showDailyOverview) {
            this.setState({ isLoading: true, actions: [] });
            this.handleGetDailyOverviewActions();
        }

        if (
            (this.state.startDate && prevState.startDate !== this.state.startDate) ||
            (prevState.form !== this.state.form && this.state.showMonthlyOverview)
        ) {
            if (!!this.state.startDate) {
                this.setState({ isLoading: true, actions: [] });
                this.handleGetMonthlyOverviewActions();
            }
        }

        if (prevState.year !== this.state.year) {
            this.handleGetYearlyOverviewActions();
        }
        if (this.state.showAgile && prevState.showDailyOverview !== this.state.showDailyOverview) {
        }
        if (this.state.showAgile && prevState.showMonthlyOverview !== this.state.showMonthlyOverview) {
            if (this.state.startDate === null) {
                this.pickedDate();
            }
            this.handleGetMonthlyOverviewActions();
        }
        if (this.state.showAgile && prevState.showYearlyOverview !== this.state.showYearlyOverview) {
            this.handleGetYearlyOverviewActions();
        }
    }
    componentWillUnmount() {
        let period;
        if (this.state.showDailyOverview) {
            period = "day";
        } else if (this.state.showMonthlyOverview) {
            period = "month";
        } else {
            period = "year";
        }

        const { form } = this.state;
        StoreService.updateStoreProperty("calendarFilters", {
            search: form.search.value,
            priority: form.priority.value,
            status: form.status.value,
            customer_id: form.customer_id.value,
            user_id: form.user_id.value,
            period,
        });
    }
    pickedDate = () => {
        const { pickedDate } = this.state;
        let month: any = pickedDate.getMonth();
        const year = pickedDate.getFullYear();

        this.setState({
            startDate: new Date(year, month, 1),
            endDate: new Date(year, month, 30),
        });
    };

    handleRecurringActions(actions, late?, startDate?, endDate?) {
        let recurringActions: object[] = [];
        if (actions && actions[0]) {
            actions
                .sort((a: any, b: any) => {
                    return a.id - b.id;
                })
                .forEach((action: any) => {
                    const initialActionStart = new Date(action.startDate);
                    const initialActionEnd = new Date(action.endDate);
                    const actionDuration = dayDifference(initialActionStart, initialActionEnd);
                    let dayCounter = new Date();
                    let dayEndCounter = new Date();
                    let oneYearLater = new Date();
                    let actionCounter = {};
                    oneYearLater.setFullYear(Number(oneYearLater.getFullYear()) + 1);

                    if (!late) {
                        switch (Number(action.frequency)) {
                            case Constants.ACTION_FREQUENCY_WEEKLY:
                                recurringActions.push(action);
                                dayCounter = new Date(action.startDate);
                                dayEndCounter = new Date(action.startDate);
                                do {
                                    dayCounter.setDate(Number(dayCounter.getDate()) + 7);
                                    dayEndCounter.setFullYear(dayCounter.getFullYear());
                                    dayEndCounter.setMonth(dayCounter.getMonth());
                                    dayEndCounter.setDate(dayCounter.getDate() + actionDuration);
                                    actionCounter = {
                                        ...action,
                                        status: Constants.ACTION_STATUS_PLANNED,
                                        endDate: dayEndCounter.toISOString(),
                                        startDate: dayCounter.toISOString(),
                                        rootID: action.id,
                                        id: null,
                                    };
                                    recurringActions.push(actionCounter);
                                } while (dayCounter <= oneYearLater);
                                break;
                            case Constants.ACTION_FREQUENCY_MONTHLY:
                                recurringActions.push(action);
                                dayCounter = new Date(action.startDate);
                                do {
                                    dayCounter.setMonth(Number(dayCounter.getMonth()) + 1);
                                    dayEndCounter.setFullYear(dayCounter.getFullYear());
                                    dayEndCounter.setDate(1);
                                    dayEndCounter.setMonth(dayCounter.getMonth());
                                    dayEndCounter.setDate(dayCounter.getDate() + actionDuration);

                                    actionCounter = {
                                        ...action,
                                        status: Constants.ACTION_STATUS_PLANNED,
                                        endDate: dayEndCounter.toISOString(),
                                        startDate: dayCounter.toISOString(),
                                        rootID: action.id,
                                        id: null,
                                    };

                                    recurringActions.push(actionCounter);
                                } while (dayCounter <= oneYearLater);

                                break;
                            case Constants.ACTION_FREQUENCY_QUARTERLY:
                                recurringActions.push(action);

                                dayCounter = new Date(action.startDate);

                                do {
                                    dayCounter.setMonth(Number(dayCounter.getMonth()) + 3);
                                    dayEndCounter.setFullYear(dayCounter.getFullYear());
                                    dayEndCounter.setMonth(dayCounter.getMonth());
                                    dayEndCounter.setDate(dayCounter.getDate() + actionDuration);

                                    actionCounter = {
                                        ...action,
                                        status: Constants.ACTION_STATUS_PLANNED,
                                        endDate: dayEndCounter.toISOString(),
                                        startDate: dayCounter.toISOString(),
                                        rootID: action.id,
                                        id: null,
                                    };

                                    recurringActions.push(actionCounter);
                                } while (dayCounter <= oneYearLater);

                                break;
                            case Constants.ACTION_FREQUENCY_YEARLY:
                                recurringActions.push(action);

                                dayCounter = new Date(action.startDate);

                                do {
                                    dayCounter.setFullYear(Number(dayCounter.getFullYear()) + 1);
                                    dayEndCounter.setFullYear(dayCounter.getFullYear());
                                    dayEndCounter.setDate(dayCounter.getDate() + actionDuration);

                                    actionCounter = {
                                        ...action,
                                        status: Constants.ACTION_STATUS_PLANNED,
                                        endDate: dayEndCounter.toISOString(),
                                        startDate: dayCounter.toISOString(),
                                        rootID: action.id,
                                        id: null,
                                    };

                                    recurringActions.push(actionCounter);
                                } while (dayCounter <= oneYearLater);

                                break;
                        }
                    } else {
                        dayCounter = new Date(startDate);
                        do {
                            dayCounter.setDate(Number(dayCounter.getDate()) + 1);
                            actionCounter = {
                                ...action,
                                status: Constants.ACTION_STATUS_DELAY,
                                startDate: dayCounter.toISOString(),
                            };
                            if (dayCounter > initialActionEnd) {
                                recurringActions.push(actionCounter);
                            }
                        } while (dayCounter <= endDate);
                    }
                });
        }

        return recurringActions.filter((e: any) => !e.id);
    }

    getStatusOfRecurringActions = (start, end) => {
        const today = new Date();

        if (start > today) {
            return Constants.ACTION_STATUS_PLANNED;
        } else if (end > today && start < today) {
            return Constants.ACTION_STATUS_IN_PROGRESS;
        } else if (end < today) {
            return Constants.ACTION_STATUS_DELAY;
        }
    };

    handleGetMonthlyOverviewActions = () => {
        const { form } = this.state;
        const token = StoreService.getStoreProperty("token");
        const start_date = parseDate(this.state.startDate);
        const end_date = parseDate(this.state.endDate);
        const data = { start_date, end_date };
        const employeeID = this.props.match.params.employeeId;

        if (employeeID) {
            data["user_id"] = employeeID;
        }

        if (form.user_id.value !== 0 && typeof form.user_id.value !== "object") {
            data["user_id"] = form.user_id.value;
        }

        if (form.search.value) {
            data["search"] = form.search.value;
        }
        if (form.customer_id.value !== 0 && typeof form.customer_id.value !== "object") {
            data["customer_id"] = form.customer_id.value;
        }
        if (form.priority.value !== 0 && typeof form.priority.value !== "object") {
            data["priority"] = form.priority.value;
        }
        ApiService.monthlyActions(data, token).then((res) => {
            if (res.success) {
                const actionsForMonth = res.data;
                const recurringActionMap = StoreService.getStoreProperty("recurringActionMap");
                const clonnedActions = this.cloneActions(actionsForMonth, start_date, end_date, "month");
                //? treba li ova funkcija?
                // const clonnedRecurringActions = this.cloneActions(
                //     recurringActionMap,
                //     start_date,
                //     end_date,
                //     "month"
                // );

                const lateActions = StoreService.getStoreProperty("lateActions");
                const lateActionsMap = this.handleRecurringActions(
                    lateActions,
                    true,
                    this.state.startDate,
                    this.state.endDate
                );

                this.setState({
                    actions: [...lateActionsMap, ...clonnedActions, ...recurringActionMap],
                    columns: res.columns,
                    isLoading: false,
                });
            }
        });
    };

    handleGetDailyOverviewActions = () => {
        const token = StoreService.getStoreProperty("token");
        const calendarFilters = StoreService.getStoreProperty("calendarFilters");

        if (calendarFilters && calendarFilters.period === "month" && this.state.showDailyOverview) {
            this.setState({
                showDailyOverview: false,
                showMonthlyOverview: true,
            });
            // this.handleGetMonthlyOverviewActions();
        } else if (calendarFilters && calendarFilters.period === "year" && this.state.showDailyOverview) {
            this.setState({
                showDailyOverview: false,
                showYearlyOverview: true,
            });
        } else {
            const { form } = this.state;

            const data = {} as any;

            Object.keys(form).forEach((filter) => {
                if (typeof form[filter].value === "object") {
                    return;
                } else if (form[filter].value) {
                    if (filter === "status") {
                        if (form[filter].value !== 4 && form[filter].value !== 5) {
                            data[filter] = form[filter].value;
                        } else {
                            return;
                        }
                    } else {
                        data[filter] = form[filter].value;
                    }
                }
            });
            // radim
            // for (const property in calendarFilters) {
            //     data[property] = calendarFilters[property];
            // }

            data["date"] = parseDateForMySQL(this.state.pickedDate);

            // if (status.value !== 4 && status.value !== 5) {
            UtilService.handleDebouncing(
                "calendarActionsDebouncing",
                () =>
                    ApiService.dailyOverviewActions(data, token).then((res) => {
                        if (res.success) {
                            const singleActions = res.data;

                            let actions = StoreService.getStoreProperty("actions");

                            const recurringActionsMap = StoreService.getStoreProperty("recurringActionMap");

                            let actionsToShow = recurringActionsMap.filter((e: any) => {
                                return (
                                    new Date(e.startDate).setHours(0, 0, 0, 0) ===
                                    this.state.pickedDate.setHours(0, 0, 0, 0)
                                );
                            });

                            let clonnedActions = this.cloneActions(
                                actions,
                                this.state.pickedDate,
                                this.state.pickedDate,
                                "day"
                            );

                            let newActions =
                                clonnedActions &&
                                clonnedActions.filter((action: any) => {
                                    return (
                                        this.state.pickedDate >= new Date(action.startDate) &&
                                        this.state.pickedDate <= new Date(action.endDate)
                                    );
                                });

                            const newArr = newActions.reduce((acc: any, c: any) => {
                                if (acc.findIndex((el: any) => el.id === c.id) === -1) {
                                    acc.push(c);
                                    return acc;
                                }
                                return acc;
                            }, []);

                            actionsToShow = [...actionsToShow, ...newArr];

                            actionsToShow = actionsToShow.filter((action) => {
                                return Object.keys(data).every((filter: any) => {
                                    return action[filter] === data[filter];
                                });
                            });

                            let allActions = [...singleActions, ...actionsToShow];

                            if (form.status.value === 4) {
                                allActions = allActions.filter((action) => {
                                    return new Date(action.endDate) < new Date();
                                });
                            } else if (form.status.value === 5) {
                                allActions = allActions.filter((action) => {
                                    return action.users.length === 0;
                                });
                            }

                            this.setState({
                                actions: allActions,
                                isLoading: false,
                                columns: res.columns,
                            });
                        }
                    }),
                300
            );
            // }
        }
    };

    handleGetYearlyOverviewActions = () => {
        const token = StoreService.getStoreProperty("token");
        const start_date = `01.01.${this.state.year}`;
        const end_date = `31.12.${this.state.year}`;
        const data = { start_date, end_date };
        ApiService.monthlyActions(data, token).then((res) => {
            if (res.success) {
                const actionsForYear = res.data;
                const recurringActionMap = StoreService.getStoreProperty("recurringActionMap");
                const clonnedActions = this.cloneActions(actionsForYear, start_date, end_date, "month");

                const lateActions = StoreService.getStoreProperty("lateActions");
                const lateActionsMap = this.handleRecurringActions(
                    lateActions,
                    true,
                    this.state.startDate,
                    this.state.endDate
                );
                this.setState({
                    actions: [...lateActionsMap, ...clonnedActions, ...recurringActionMap],
                    columns: res.columns,
                    isLoading: false,
                });
            }
        });
    };

    cloneActions = (actions, startDate, endDate, range) => {
        // startDate =
        //     typeof startDate !== "object"
        //         ? newDateFromString(startDate)
        //         : startDate;
        endDate = typeof endDate !== "object" ? newDateFromString(endDate) : endDate;

        let today = new Date();
        let dayCounter = new Date();
        let actionCounter = {};
        let clonnedActions: object[] = [];
        today.setHours(23);
        if (actions && actions.length) {
            actions.forEach((action: any) => {
                const actionStartDate = new Date(action.startDate);
                let setActionDate = new Date(action.endDate).setHours(23, 59, 59);

                let actionEndDate = new Date(setActionDate);

                const initialActionStatus = Number(action.status);

                if (actionEndDate > actionStartDate) {
                    action.status = Constants.ACTION_STATUS_IN_PROGRESS;
                }
                if (today < actionStartDate) {
                    action.status = Constants.ACTION_STATUS_PLANNED;
                }

                if (range === "month") {
                    // If action `startDate` is between given parameters - `startDate` and `endDate`.
                    //* ovo sam zakomentarisao jer postoje akcije koje su pocele u prethodnom mjesecu koje nijesu prolazile
                    //* provjeru, a server svakako vrace za taj range (mogu li klonirane u tom slucaju biti problem?)
                    // if (
                    //     actionStartDate >= startDate &&
                    //     actionStartDate <= endDate
                    // )
                    // If action has `status` completed, clone it for every day until its endDate.
                    // For every day between its `startDate` and `endDate`, action is considered as `in progress`.
                    // For the last day (endDate) action is considered as `completed`.
                    if (initialActionStatus === Constants.ACTION_STATUS_COMPLETED) {
                        clonnedActions.push(action);

                        dayCounter = new Date(action.startDate);

                        dayCounter.setDate(Number(dayCounter.getDate()) + 1);

                        if (dayCounter <= actionEndDate) {
                            do {
                                actionCounter = {
                                    ...action,
                                    startDate: dayCounter.toISOString(),
                                    rootID: action.id,
                                    status:
                                        dayCounter.getDate() === actionEndDate.getDate() &&
                                        dayCounter.getMonth() === actionEndDate.getMonth()
                                            ? Constants.ACTION_STATUS_COMPLETED
                                            : Constants.ACTION_STATUS_IN_PROGRESS,
                                };

                                clonnedActions.push(actionCounter);
                                dayCounter.setDate(Number(dayCounter.getDate()) + 1);
                            } while (dayCounter <= actionEndDate && dayCounter <= endDate);
                        }
                    } else {
                        // If action is `not completed`, clone it for every day until today.
                        // For everyday that exceeds action's `endDate`, action is considered as `in delay`.
                        clonnedActions.push(action);
                        dayCounter = new Date(action.startDate);
                        dayCounter.setDate(Number(dayCounter.getDate()) + 1);

                        if (dayCounter <= today) {
                            do {
                                actionCounter = {
                                    ...action,
                                    startDate: dayCounter.toISOString(),
                                    rootID: action.id,
                                    status:
                                        dayCounter <= actionEndDate
                                            ? Constants.ACTION_STATUS_IN_PROGRESS
                                            : Constants.ACTION_STATUS_DELAY,
                                };

                                clonnedActions.push(actionCounter);

                                dayCounter.setDate(Number(dayCounter.getDate()) + 1);
                            } while (dayCounter <= today);
                        }
                    }
                } else if (range === "day") {
                    if (initialActionStatus === Constants.ACTION_STATUS_COMPLETED) {
                        clonnedActions.push(action);

                        dayCounter = new Date(action.startDate);

                        dayCounter.setDate(Number(dayCounter.getDate()) + 1);

                        if (dayCounter <= actionEndDate) {
                            do {
                                actionCounter = {
                                    ...action,
                                    startDate: dayCounter.toISOString(),
                                    rootID: action.id,
                                    status:
                                        dayCounter.getDate() === actionEndDate.getDate() &&
                                        dayCounter.getMonth() === actionEndDate.getMonth()
                                            ? Constants.ACTION_STATUS_COMPLETED
                                            : Constants.ACTION_STATUS_IN_PROGRESS,
                                };

                                clonnedActions.push(actionCounter);
                                dayCounter.setDate(Number(dayCounter.getDate()) + 1);
                            } while (dayCounter <= actionEndDate && dayCounter <= endDate);
                        }
                    } else {
                        // If action is `not completed`, clone it for every day until today.
                        // For everyday that exceeds action's `endDate`, action is considered as `in delay`.
                        clonnedActions.push(action);
                        dayCounter = new Date(action.startDate);
                        dayCounter.setDate(Number(dayCounter.getDate()) + 1);

                        if (dayCounter <= today) {
                            do {
                                actionCounter = {
                                    ...action,
                                    startDate: dayCounter.toISOString(),
                                    rootID: action.id,
                                    status:
                                        dayCounter <= actionEndDate
                                            ? Constants.ACTION_STATUS_IN_PROGRESS
                                            : Constants.ACTION_STATUS_DELAY,
                                };

                                clonnedActions.push(actionCounter);

                                dayCounter.setDate(Number(dayCounter.getDate()) + 1);
                            } while (dayCounter <= today);
                        }
                    }
                }
            });
        }

        return clonnedActions;
    };

    static getDerivedStateFromProps(props, state) {
        if (props.language !== state.language) {
            return {
                language: props.language,
            };
        }
        return null;
    }

    handleDailyCalendar = () => {
        const { form } = this.state;
        const calendarFilters = {
            search: form.search.value,
            priority: form.priority.value,
            status: form.status.value,
            customer_id: form.customer_id.value,
            user_id: form.user_id.value,
            period: "day",
        };
        StoreService.updateStoreProperty("calendarFilters", calendarFilters);
        if (!this.state.showDailyOverview) {
            this.setState(() => ({
                showDailyOverview: true,
                showMonthlyOverview: false,
                showYearlyOverview: false,
                actions: [],
                isLoading: true,
            }));
        }
    };

    handleMonthlyCalendar = () => {
        if (!this.state.showMonthlyOverview) {
            this.setState(() => ({
                showMonthlyOverview: true,
                showDailyOverview: false,
                showYearlyOverview: false,
                actions: [],
                isLoading: true,
            }));
        }
    };

    handleYearlyCalendar = () => {
        if (!this.state.showYearlyOverview) {
            this.setState(() => ({
                showYearlyOverview: true,
                showMonthlyOverview: false,
                showDailyOverview: false,
                actions: [],
                isLoading: true,
                //year: new Date().getFullYear(),
            }));
            this.handleGetYearlyOverviewActions();
        }
    };

    toggleAgile = (btn) => {
        this.setState(() => ({
            showAgile: btn === "agile" ? true : false,
        }));
    };

    handleRedirect = (item) => {
        if (item.rootID) {
            this.props.history.push(`/new-action/${item.rootID}/${item.startDate}`);
        } else if (!item) {
            this.props.history.push("/new-action");
        } else {
            this.props.history.push(`/action/${item.id}`);
        }
    };

    pickDate = (date) => {
        this.setState({
            pickedDate: date,
            showDailyOverview: true,
            showMonthlyOverview: false,
        });
        // this.props.changeDay(date);
        this.props.history.push(`/calendar/${parseDateForMySQL(date)}`);
    };
    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    handleMonthChanged = (startDate, endDate) => {
        this.setState({ startDate, endDate });
    };

    handleLanguage = () => {
        if (this.props.language) {
            this.setState((prevState: any) => ({
                language: !prevState.language,
            }));
        }
    };

    pickYear = (date) => {
        this.setState({
            year: date,
        });
    };

    toggleSidebar = (e) => {
        e.preventDefault();
        this.setState({ showFilterSidebar: !this.state.showFilterSidebar });
    };

    submitFilters = (e) => {
        e.preventDefault();
        const { showDailyOverview, showMonthlyOverview } = this.state;

        if (showDailyOverview) {
            this.handleGetDailyOverviewActions();
        } else if (showMonthlyOverview) {
            this.handleGetMonthlyOverviewActions();
        } else {
            this.handleGetYearlyOverviewActions();
        }
    };

    resetFilters = (e) => {
        e.preventDefault();
        const { showDailyOverview, showMonthlyOverview } = this.state;
        StoreService.updateStoreProperty("calendarFilters", {
            search: "",
            priority: "",
            status: "",
            customer_id: "",
            user_id: "",
            period: "day",
        });
        this.setState(
            {
                form: {
                    search: {
                        value: "",
                        errors: [],
                    },
                    user_id: {
                        value: null,
                        errors: [],
                    },
                    customer_id: {
                        value: null,
                        errors: [],
                    },
                    priority: {
                        value: null,
                        errors: [],
                    },
                    status: {
                        value: null,
                        errors: [],
                    },
                },
            },
            () => {
                if (showDailyOverview) {
                    this.handleGetDailyOverviewActions();
                } else if (showMonthlyOverview) {
                    this.handleGetMonthlyOverviewActions();
                } else {
                    this.handleGetYearlyOverviewActions();
                }
            }
        );
    };

    redirectToNewAction = () => {
        this.props.history.push("/new-action");
    };

    render() {
        const {
            showAgile,
            showDailyOverview,
            showMonthlyOverview,
            showYearlyOverview,
            priorities,
            statuses,
            customers,
            users,
            form: { search, priority, status, customer_id, user_id },
        } = this.state;

        return (
            <div className="calendarWrapper">
                <FilterSidebar
                    opened={this.state.showFilterSidebar}
                    onClose={() => this.setState({ showFilterSidebar: !this.state.showFilterSidebar })}
                    submitFilters={this.submitFilters}
                    resetFilters={this.resetFilters}
                >
                    <CustomForm className="d-flex flex-column">
                        <div className="mb-20">
                            <CustomInput
                                value={search.value}
                                handleInput={this.handleInput}
                                name="search"
                                type="text"
                                label="Search"
                            />
                        </div>
                        <div className="mb-20">
                            <CustomDropdown
                                data={priorities}
                                name="priority"
                                label="FILTER_BY_PRIORITY"
                                handleChange={this.handleInput}
                                value={priorities.find((e: any) => e.value === priority.value) || null}
                                placeholder={<Translate text="CHOOSE_PRIORITY" />}
                            />
                        </div>
                        <div className="mb-20">
                            <CustomDropdown
                                data={statuses}
                                name="status"
                                label="FILTER_BY_STATUS"
                                handleChange={this.handleInput}
                                value={statuses.find((e: any) => e.value === status.value) || null}
                                placeholder={<Translate text="CHOOSE_STATUS" />}
                            />
                        </div>
                        <div className="mb-20">
                            <CustomDropdown
                                data={customers}
                                label="FILTER_BY_CUSTOMER"
                                handleChange={this.handleInput}
                                name="customer_id"
                                value={customers.find((c: any) => c.value === customer_id.value) || null}
                                placeholder={<Translate text="CHOOSE_CUSTOMER" />}
                            />
                        </div>
                        <div className="mb-20 mb-10 min-w-20">
                            <CustomDropdown
                                data={users}
                                label="FILTER_BY_EMPLOYEE"
                                handleChange={this.handleInput}
                                name="user_id"
                                value={users.find((e: any) => e.value === user_id.value) || null}
                                placeholder={<Translate text="CHOOSE_EMPLOYEE" />}
                            />
                        </div>
                    </CustomForm>
                </FilterSidebar>
                <div className="controlButtons d-flex justify-content-between align-items-start">
                    <div className="d-flex justify-content-between w-100-perc leftSide mb-15">
                        <CustomButton
                            onClick={this.toggleSidebar}
                            className="filterBtn btn-primary d-flex align-items-center"
                        >
                            <FilterIcon className="icon" width={16}></FilterIcon>
                            <span className="ml-10">Filteri</span>
                        </CustomButton>

                        <div className="agile-switch mx-20">
                            <button
                                onClick={() => this.toggleAgile("agile")}
                                type="button"
                                className={`agile-switch-agile ${showAgile ? "clicked" : ""}`}
                            >
                                <Translate text="AGILE" />
                            </button>
                            <button
                                onClick={() => this.toggleAgile("calendar")}
                                type="button"
                                className={`agile-switch-calendar ${!showAgile ? "clicked" : ""}`}
                            >
                                <Translate text="CALENDAR" />
                            </button>
                        </div>
                    </div>

                    <div className="d-flex align-items-center mb-15">
                        <CustomButton
                            className={`${showDailyOverview ? "" : "btn-day-clicked"} btn-primary mr-10`}
                            type="button"
                            onClick={this.handleDailyCalendar}
                        >
                            <Translate text="DAY" />
                        </CustomButton>

                        <CustomButton
                            className={`${showMonthlyOverview ? "" : "btn-day-clicked"} btn-primary mr-10`}
                            // className="btn-day"
                            type="button"
                            onClick={this.handleMonthlyCalendar}
                        >
                            <Translate text="MONTH" />
                        </CustomButton>
                        <CustomButton
                            className={`${showYearlyOverview ? "" : "btn-day-clicked"} btn-primary`}
                            // className="btn-day"
                            type="button"
                            onClick={this.handleYearlyCalendar}
                        >
                            <Translate text="YEAR" />
                        </CustomButton>
                    </div>

                    {!showAgile && (
                        <div className="d-flex mb-10 addActionButton ml-10">
                            <CustomButton
                                onClick={this.redirectToNewAction}
                                className="d-flex addColBtn align-items-center btn-primary"
                            >
                                <PlusIcon width={14} className="icon mr-10"></PlusIcon>

                                <div>
                                    <Translate text="ADD_ACTION" />
                                </div>
                            </CustomButton>
                        </div>
                    )}
                </div>
                <div className="calendarBody p-10">
                    {showDailyOverview && !showAgile && (
                        <DailyOverview
                            handleRedirect={(item) => this.handleRedirect(item)}
                            startDate={this.state.pickedDate}
                            actions={this.state.actions}
                            status={this.state.form.status.value}
                            language={this.props.language}
                            user={this.state.form.user_id.value}
                            state={this.state.showMonthlyOverview}
                            isLoading={this.state.isLoading}
                            handleDatePicked={(date) => this.pickDate(date)}
                        />
                    )}
                    {(showMonthlyOverview || this.state.yearToMonth) && !showAgile && (
                        <MonthlyOverview
                            cloneActions={this.cloneActions}
                            handleDatePicked={(date) => this.pickDate(date)}
                            handleMonth={(startDate, endDate) => this.handleMonthChanged(startDate, endDate)}
                            startDate={this.state.pickedDate}
                            endDate={this.state.endDate}
                            actions={this.state.actions}
                            state={this.state.showDailyOverview}
                            language={this.props.language}
                            isLoading={this.state.isLoading}
                            form={this.state.form}
                            user={this.state.form.user_id.value}
                            customer={this.state.form.customer_id.value}
                            status={this.state.form.status.value}
                            priority={this.state.form.priority.value}
                            function={this.handleGetMonthlyOverviewActions}
                            employee={this.props.match.params.employeeId}
                        />
                    )}
                    {showYearlyOverview && !showAgile && (
                        <YearlyOverview
                            cloneActions={this.cloneActions}
                            handlePickYear={(date) => this.pickYear(date)}
                            language={this.props.language}
                            isLoading={this.state.isLoading}
                            actions={this.state.actions}
                            handleMonth={(startDate, endDate) => this.handleMonthChanged(startDate, endDate)}
                            // yearToMonth={this.yearToMonth}
                            // showYearlyOverview={this.state.showYearlyOverview}
                            // showMonthlyOverview={this.state.showMonthlyOverview}
                            handleMonthlyCalendar={this.handleMonthlyCalendar}
                        />
                    )}
                    {showAgile && (
                        <div className="padding-10">
                            <AgileOverview
                                columns={this.state.columns}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                props={this.props}
                            ></AgileOverview>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Calendar;
