import { CustomForm, PinCode } from "devkit/Form/Form";
import React, { Component } from "react";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import { BackIcon } from "../../components/Icons/Icons";
import ApiService from "../../services/api.service";
import Translate from "../../functions/utilFunctions/translate";
import { StoreService } from "../../services/store.service";

interface IPin {
    handlePinChange: (value: boolean) => void;
    token: string;
    logout: () => void;
}

class Pin extends Component<IPin> {
    state = {
        pin: ["", "", "", ""],
        errors: [],
        showErrorModal: false,
        logout: false,
    };
    handleInput = (pin, errors) => {
        this.setState({ pin, errors });
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleErrorModal = () => {
        this.setState((prevState: any) => ({
            showErrorModal: !prevState.showErrorModal,
        }));
    };

    handlePinValidation = () => {
        const token = StoreService.getStoreProperty("token");
        ApiService.pin(this.state.pin.join(""), token).then((response) => {
            if (response.status === "OK") {
                this.props.handlePinChange(true);
            } else {
                this.setState({
                    pin: ["", "", "", ""],
                    showErrorModal: true,
                });
            }
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.pin !== this.state.pin) {
            const newArray = this.state.pin.filter((num) => num !== "");
            if (newArray.length === 4) {
                this.handlePinValidation();
            }
        }
    }

    render() {
        const { pin } = this.state;
        return (
            <div className="pinWrapper w-100perc d-flex ">
                <div>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showErrorModal"
                        className={this.state.showErrorModal ? "visible error" : "error"}
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            title="FAILED"
                            text="WRONG_PIN"
                            modalName="showErrorModal"
                            toggleModal={this.handleErrorModal}
                        />
                    </Modal>
                </div>
                <div id="pin-left-column" className="pin-left-photo w-50-perc h-vh-100" />
                <div
                    id="pin-right-column"
                    className="right-side w-50perc d-flex flex-column justify-content-center align-items-center"
                >
                    <div className="align-center m-t-60">
                        {/* <img
                            src="../images/pin/406@2x.png"
                            alt="logo"
                            className="hydra-logo"
                        /> */}
                        <span className="hydraTitle">Kadar Plus</span>
                    </div>
                    <div className="d-flex flex-column space-between">
                        <span className=" margin-h-10 margin-b-50 align-self-center f-s-16">
                            <Translate text={"INSERT_PIN"} />
                        </span>
                        <div className="p-v-20 d-flex flex-column align-items-center">
                            <CustomForm className="">
                                <PinCode value={pin} handleInput={this.handleInput} />
                            </CustomForm>
                            {/* <div
                                onClick={this.props.logout}
                                className="flex align-self-center pointer margin-t-20 f-s-14"
                            >
                                <Translate text="GOTO_LOGIN" />
                            </div> */}
                            <button
                                onClick={this.props.logout}
                                className="f-s-14 margin-t-20 pointer d-flex align-items-center goBack"
                            >
                                <span className="mr-5">
                                    <BackIcon width={20} />
                                </span>
                                <span className="pb-2">
                                    <Translate text="GOTO_LOGIN" />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pin;
