import {
    ArrowDownIcon,
    BackIcon,
    SearchIcon,
    UploadIcon,
    ShowPasswordIcon,
    ActivitiesIcon,
} from "../../components/Icons/Icons";
import {
    CloudFolderIcon,
    FileDeleteIcon,
    FileEditIcon,
    FileManagerIcons,
    FileMoveIcon,
    FileUploadIcon,
    FileZipIcon,
    FolderUploadIcon,
    ItemDownloadIcon,
    NewFolderIcon,
    PermissionIcon,
    FileShareIcon,
    FileSelectIcon,
    CloneIcon,
    FilterIcon,
} from "../../components/Icons/FileManagerIcons";
import {
    CustomButton,
    CustomCheckbox,
    CustomFileInput,
    CustomForm,
    CustomInput,
    CustomDropdown,
} from "../../devkit/Form/Form";
import React, { Component } from "react";
import { formatBytes, parseDateWithoutSeconds } from "../../functions/utilFunctions/parsingFunctions";
import ApiService from "../../services/api.service";
import ContextMenu from "../../devkit/ContextMenu/ContextMenu";
import FileUploadProgressList from "../../components/FileUploadProgressList/FileUploadProgressList";
import FolderStructure from "../../components/FolderStructure/FolderStructure";
import { Link } from "react-router-dom";
import { Loader } from "../../devkit/Loader/Loader";
import Modal from "../../devkit/Modal/Modal";
import { ModalContent } from "../../devkit/Modal/ModalContent";
import { StoreService } from "../../services/store.service";
import Table from "../../devkit/Table/Table";
import Translate from "../../functions/utilFunctions/translate";
import { UtilService } from "../../services/util.service";
import { getFilesAsync } from "../../functions/utilFunctions/folderDragAndDropUpload";
import { Devkit } from "oykos-development-devkit";
import Labels from "./Labels";
import { ScreenContext } from "components/ScreenContext/ScreenContext";
import FilterSidebar from "components/FilterSidebar/FilterSidebar";
import LogsSidebar from "components/LogsSidebar/LogsSidebar";
import { DISABLE_FIREBASE } from "../../services/constants.service";

//TODO on every fileSystem change, fetch new tree

class FileManager extends Component<any> {
    state = {
        tableHeads: [
            {
                value: "NAME",
                sortable: true,
                sortableType: "string",
                sortableName: "name",
            },
            {
                value: "PREVIEW",
                sortable: true,
                sortableType: "string",
                sortableName: "preview",
            },
            {
                value: "LABELS",
                sortable: true,
                sortableType: "string",
                sortableName: "labels",
            },
            {
                value: "SIZE",
                sortable: true,
                sortableType: "string",
                sortableName: "size",
            },
            {
                value: "TYPE",
                sortable: true,
                sortableType: "string",
                sortableName: "type",
            },
            {
                value: "DATE_CREATED",
                sortable: true,
                sortableType: "date",
                sortableName: "dateAdded",
            },
        ],
        items: [
            {
                created_at: null,
                file_size: 0,
                id: 0,
                level: 0,
                parent_id: 0,
                path: "",
                title: "",
                type: "",
                updated_at: null,
                file_permissions: [],
                labels: [],
                isSelected: false,
                new_users_read: 0,
                new_users_write: 0,
            },
        ],
        // item: [
        //     {
        //         canWrite: 0,
        //         created_at: null,
        //         file_size: 0,
        //         new_users_read: 0,
        //         new_users_write: 0,
        //         id: 0,
        //         level: 0,
        //         parent_id: 0,
        //         path: "",
        //         title: "",
        //         type: "",
        //         updated_at: null,
        //         file_permissions: [],
        //     },
        // ],
        folderID: this.props.match.params.id || 1,
        isLoading: true,
        newFolderName: {
            value: "",
            errors: [],
        },
        createFolderModal: false,
        current: {
            id: 0,
            path: "",
            title: "",
            level: 0,
            parent_id: 0,
        },
        fileUploadModal: false,
        fileUploadReplaceModal: false,
        isOver: false,
        completed: 0,
        completedList: [{ completed: 0 }],
        uploadComplete: false,
        uploading: false,
        file: {
            value: [] as any,
            errors: [],
        },
        replaceFile: {
            value: null,
            errors: [],
        },
        fileUpload: true,
        folder: [],
        ctxTop: 0,
        ctxLeft: 0,
        ctxVisible: false,
        ctxHeight: 80,
        renameItemModal: false,
        contextItemID: 0 as any,
        contextItemName: {
            value: "",
            errors: [],
        },
        contextItemExt: "",
        contextItemType: "",
        deleteItemModal: false,
        cloneItemModal: false,
        deleteItemConfirmationModal: false,
        showDeleteErrorModal: false,
        showCloneErrorModal: false,
        folderStructure: {
            sub: [
                {
                    sub: [],
                    created_at: null,
                    file_size: null,
                    id: 0,
                    level: 0,
                    parent_id: 0,
                    path: "",
                    title: "",
                    type: "",
                    updated_at: null,
                },
            ],
            created_at: null,
            file_size: null,
            id: 0,
            level: 0,
            parent_id: null,
            path: "",
            title: "",
            type: "",
            updated_at: null,
            new_users_read: 0,
            new_users_write: 0,
        },
        actionRelations: [{ action_id: 0, path: "", title: "" }],
        defaultPathActionRelations: [{ action_id: 0, default_path: "", title: "", file_id: 0 }],
        defaultPathCustomerRelations: [{ PIB: 0, customer_id: 0, default_path: "", file_id: 0, name: "" }],
        moveItemModal: false,
        destinationID: 1,
        folderCreated: false,
        zipFileModal: false,
        downloadAllZipModal: false,
        activeDownload: [],
        showUploadComponent: true,
        minimize: false,
        showDownloadComponent: true,
        minimizeDownload: false,
        parents: [{ id: 0, title: "root" }],
        folderSearch: "",
        pickedLabel: "",
        showFolderSearch: false,
        changePermissionsModal: false,
        changePermissionsForMultipleFilesModal: false,
        permissionLoader: false,
        visibleToNewUsers: 0,
        showDeniedAccessModal: false,
        selectItem: false,
        selectedItemsIds: [],
        toggleSelect: false,
        default_path: "",
        employeePermission: false,
        contextMenuLoader: false,
        users: [] as any,
        allRead: false,
        allWrite: false,
        currentRead: false,
        currentWrite: false,
        newUsersReadMultiple: false,
        newUsersWriteMultiple: false,
        item_ids: [],
        showEmployeesTab: true,
        showGroupsTab: false,
        groups: [] as any,
        showMembersModal: false,
        groupID: "",
        groupUsers: [] as any,
        touchTimer: 0,
        labels: [
            {
                name: "test",
            },
        ] as any,
        allLabels: [] as any,
        showIFrameModalContent: false,
        fileName: "",
        IFrameURL: "",
        showFilterSidebar: false,
        timer: undefined,
        showLogsSidebar: false,
        logs: [],
    };

    fileManagerRef = React.createRef<HTMLDivElement>();
    folderSearchInputRef = React.createRef<HTMLInputElement>();
    tableWrapper = React.createRef<HTMLDivElement>();

    static contextType = ScreenContext;

    removeContextMenu = (e) => {
        e.preventDefault();
    };

    handleSetDestination = (destination) => {
        this.setState({ destination });
    };

    hideContextMenu = () => {
        this.setState({ ctxVisible: false, contextItemID: 0 });
    };

    componentDidMount() {
        this.getItems();
        this.getItem();
        document.addEventListener("contextmenu", this.removeContextMenu);
        document.addEventListener("click", () => {
            if (!this.state.folderSearch) {
                this.setState({ showFolderSearch: false });
            }
            this.hideContextMenu();
        });
        this.handleGetStructure();
        const token = StoreService.getStoreProperty("token");
        ApiService.getAllUsers({ limit: 1000, offset: 0 }, token).then((res) => {
            if (res.status === "OK") {
                const users = res.data.users.filter((user) => {
                    return user.role !== 3;
                });
                users.unshift({
                    firstName: <Translate text="FUTURE_USERS" />,
                });
                this.setState({
                    users,
                });
            }
        });
        ApiService.getAllLabels({}, token).then((res) => {
            const allLabels = res.data.map((e) => ({
                value: e.id,
                label: e.title,
            }));
            allLabels.unshift({
                value: 0,
                label: <Translate text="CHOOSE_LABEL" />,
            });
            this.setState({
                allLabels,
            });
        });
    }

    handleGetStructure = () => {
        const token = StoreService.getStoreProperty("token");
        ApiService.getStructure(token).then((res) => {
            if ((res.status = "OK")) {
                this.setState({ folderStructure: res.data[0] });
            }
        });
    };

    componentWillUnmount() {
        document.removeEventListener("contextmenu", this.removeContextMenu);
        document.removeEventListener("click", this.hideContextMenu);
    }

    handleIcon = (type) => {
        return FileManagerIcons.getIcon(type, { width: 25 });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.folderID !== this.state.folderID) {
            this.getItems();
            this.getItem();
        }
        if (prevState.contextItemID !== this.state.contextItemID) {
            this.getItem();
        }
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({ folderID: this.props.match.params.id });
        }
        if (prevState.file.value !== this.state.file.value && this.state.file.value) {
            this.setState({ uploading: true });
            this.handleFileUpload();
        }
        if (prevState.replaceFile.value !== this.state.replaceFile.value && this.state.replaceFile.value) {
            this.handleFileUpload(true, this.state.contextItemID);
        }
        if (
            this.context.screenSize >= 768 &&
            (prevState.folderSearch !== this.state.folderSearch || prevState.pickedLabel !== this.state.pickedLabel)
        ) {
            const { id, path } = this.state.current;
            //const search = this.state.folderSearch;
            let data = { id, path };
            if (prevState.folderSearch !== this.state.folderSearch) {
                data["search"] = this.state.folderSearch;
            }
            if (prevState.pickedLabel !== this.state.pickedLabel && typeof this.state.pickedLabel !== "object") {
                data["label"] = this.state.pickedLabel;
            }
            const token = StoreService.getStoreProperty("token");
            if (!!this.state.folderSearch || this.state.pickedLabel) {
                UtilService.handleDebouncing(
                    "searchFolderDebouncing",
                    () =>
                        ApiService.searchFolder(data, token).then((res) => {
                            if (res.status === "OK") {
                                this.setState({ items: res.data });
                            }
                        }),
                    300
                );
            } else {
                this.getItems();
            }
        }
    }

    folderClick = (ev, id) => {
        if (!!this.state.toggleSelect) {
            // const closestParent = ev.target.closest(".isCheckbox");
            ev.target.closest(".isCheckbox");
        } else {
            this.props.history.push(`/file-manager/${id}`);
            this.setState({
                items: [],
                isLoading: true,
                folderID: id,
                folderSearch: "",
                showFolderSearch: false,
            });
        }
    };

    getItems = (callback?) => {
        const token = StoreService.getStoreProperty("token");

        ApiService.getFolderByID(this.state.folderID || 1, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    items: res.data.children,
                    isLoading: false,
                    current: {
                        id: res.data.parent.id,
                        path: res.data.parent.path,
                        title: res.data.parent.title,
                        level: res.data.parent.level,
                        parent_id: res.data.parent.parent_id,
                    },
                    parents: res.data.parents,
                });
                if (callback) {
                    callback();
                }
            } else {
                this.setState({
                    isLoading: false,
                    showDeniedAccessModal: true,
                });
                this.props.history.push("/file-manager");
            }
        });
    };
    getItem = async () => {
        // const { contextItemID } = this.state;
        const { contextItemID, contextItemType } = this.state;
        const token = StoreService.getStoreProperty("token");
        const role = StoreService.getStoreProperty("user").role;
        const userID = StoreService.getStoreProperty("user").id;

        if (role !== 3) {
            this.setState({
                employeePermission: false,
                contextMenuLoader: true,
            });
        }

        // if (contextItemID) {
        // if (typeof contextItemID === "number") {
        if (contextItemID) {
            await ApiService.getFolderByID(contextItemID, token).then((res) => {
                if (res.status === "OK") {
                    if (role !== 3) {
                        if (typeof contextItemID === "number") {
                            const searchThisEmployee = res.data.parent.file_permissions.find(
                                (element) => element.user_id === userID
                            );
                            if (searchThisEmployee?.write === 1) {
                                let height = 240;
                                if (contextItemType !== "folder") height = 280;
                                this.setState({
                                    employeePermission: true,
                                    contextMenuLoader: false,
                                    ctxHeight: height,
                                });
                            } else {
                                this.setState({
                                    contextMenuLoader: false,
                                    ctxHeight: 80,
                                });
                            }
                        } else {
                            const searchThisEmployee = res.data.parent.map((element) => {
                                return element.file_permissions.find((element) => element.user_id === userID);
                            });
                            const writePermission = searchThisEmployee.find((item) => {
                                return item.write === 0;
                            });

                            if (typeof writePermission === "object") {
                                //move & delete for employee
                                this.setState({
                                    ctxHeight: 40,
                                    contextMenuLoader: false,
                                });
                            } else {
                                //samo download
                                this.setState({
                                    employeePermission: true,
                                    ctxHeight: 120,
                                    contextMenuLoader: false,
                                });
                            }
                        }
                    } else {
                        // let height =
                        //     this.state.selectedItemsIds.length > 1 ? 160 : contextItemType !== "folder" ? 360 : 400;
                        // this.setState({
                        //     ctxHeight: height,
                        // });
                    }

                    this.setState({
                        item: res.data.parent,
                    });
                } else {
                }
            });
        } else {
            this.setState({
                contextMenuLoader: false,
            });
        }
    };

    bulkPermission = (type, checked) => {
        this.setState({
            [type]: !this.state[type],
            permissionLoader: true,
        });

        const token = StoreService.getStoreProperty("token");
        const { users, contextItemID } = this.state;
        const filteredUsers = UtilService.clone(users);
        filteredUsers.shift();
        let users_with_permissions = {} as any;
        if (type === "allRead") {
            users_with_permissions = filteredUsers.map((user) => {
                const write = this.checkIfPermissionExists(user.id).write;
                return {
                    user_id: user.id,
                    read: checked ? 1 : 0,
                    write: write ? 1 : 0,
                };
            });
        } else {
            users_with_permissions = filteredUsers.map((user) => {
                const read = this.checkIfPermissionExists(user.id).read;
                return {
                    user_id: user.id,
                    read: read ? 1 : 0,
                    write: checked ? 1 : 0,
                };
            });
        }
        const data = {
            files_id: typeof contextItemID === "number" ? [contextItemID] : contextItemID,
            users_with_permissions,
        };
        ApiService.bulkPermissions(data, token).then((response) => {
            if (response.status === "OK") {
                const token = StoreService.getStoreProperty("token");
                ApiService.getFolderByID(this.state.folderID || 1, token).then((res) => {
                    if (res.status === "OK") {
                        this.setState({
                            items: res.data.children,
                            permissionLoader: false,
                            current: {
                                id: res.data.parent.id,
                                path: res.data.parent.path,
                                title: res.data.parent.title,
                                level: res.data.parent.level,
                                parent_id: res.data.parent.parent_id,
                            },
                            parents: res.data.parents,
                        });
                    } else {
                        this.props.history.push("/file-manager");
                    }
                });
            }
        });
    };
    toggleModal = (name) => {
        const { changePermissionsModal, users, contextItemID } = this.state;
        const token = StoreService.getStoreProperty("token");

        this.setState((prevState: any) => ({
            [name]: !prevState[name],
            ctxVisible: false,
            newFolderName: {
                value: "",
                errors: [],
            },
            destinationID: 1,
        }));
        if (name === "changePermissionsModal") {
            this.tableWrapper.current?.scrollTo(0, 0);
            const filteredUsers = UtilService.clone(users);
            filteredUsers.shift();
            const data = {
                files_id: typeof contextItemID === "number" ? [contextItemID] : contextItemID,
            };
            ApiService.manageGroupFilePermissions(data, token).then((res) => {
                if (res.status === "OK") {
                    this.setState({
                        // groups: res.data,
                        groups: res.data[0].groupPermissions,
                    });
                }
            });
            if (!changePermissionsModal) {
                const allRead = filteredUsers.forEach((user) => {
                    return this.checkIfPermissionExists(user).read;
                });
                const allWrite = filteredUsers.forEach((user) => {
                    return this.checkIfPermissionExists(user).write;
                });

                this.setState({ allRead, allWrite });
            }
        }
        if (name === "changePermissionsForMultipleFilesModal") {
            this.tableWrapper.current?.scrollTo(0, 0);
            const filteredUsers = UtilService.clone(users);
            filteredUsers.shift();

            // if (selectedItemsIds.length > 1) {
            //     this.setState({ checked: false });
            // }
        }
    };

    handleInput = (name, data) => {
        this.setState({
            [name]: {
                value: data.value,
                errors: data.errors,
            },
        });
    };

    handleFileChange = (name, e, drop?) => {
        if (name === "file") {
            this.setState({
                [name]: UtilService.handleAddFiles(name, e, drop),
            });
        } else {
            this.setState({
                [name]: UtilService.handleAddFile(name, e, drop),
            });
        }
    };

    handleFolderChange = (name, e) => {
        let files: any = [];
        let completedList: any = [];
        Array.from(e.value).forEach((el: any) => {
            el["path"] = el.webkitRelativePath.replace(el.name, "").slice(0, -1);
            files.push(el);
            completedList.push({ completed: 0 });
        });
        if (files.length > 0) {
            this.setState(
                {
                    [name]: files,
                    completedList,
                    fileUploadModal: false,
                    folderCreated: false,
                },
                () => {
                    this.state.folder.forEach((e, i) => {
                        this.handleFolderUpload(e, i);
                    });
                }
            );
        }
    };

    handleFolderDrop = async (name, e) => {
        const files: any = await getFilesAsync(e.dataTransfer);
        let N = files.length;
        let b = 0;
        let completedList: any = [];
        while (b < N) {
            completedList.push({ completed: 0 });
            b++;
        }
        if (files.length > 0) {
            this.setState(
                {
                    [name]: files,
                    completedList,
                    fileUploadModal: false,
                    folderCreated: false,
                    showUploadComponent: true,
                },
                () => {
                    this.state.folder.forEach((e, i) => {
                        this.handleFolderUpload(e, i);
                    });
                }
            );
        }
    };

    createFolder = () => {
        const { newFolderName, visibleToNewUsers } = this.state;
        const new_users_read = visibleToNewUsers;
        const new_users_write = visibleToNewUsers;
        if (!newFolderName.value) {
            this.setState({
                newFolderName: {
                    value: "",
                    errors: ["INPUT_REQUIRED"],
                },
            });
            return;
        }
        const { id, level, path } = this.state.current;
        const data = {
            title: newFolderName.value,
            parent_id: id,
            path,
            level: level + 1,
            new_users_read,
            new_users_write,
        };
        const token = StoreService.getStoreProperty("token");
        ApiService.createFolder(data, token).then((res) => {
            if (res.status === "OK") {
                const notifications = res.data.notifications;

                if (notifications && Object.values(notifications).length) {
                    Object.values(notifications).forEach((notification: any) => {
                        if (!DISABLE_FIREBASE) {
                            Devkit.FirebaseService.sendNotification(
                                notification.action,
                                notification.item,
                                notification.link,
                                notification.read,
                                null,
                                notification.users
                            );
                        }
                    });
                }

                res.data = res.data.file;

                this.handleGetStructure();
                this.setState((prevState: any) => ({
                    items: [res.data, ...prevState.items],
                    createFolderModal: false,
                    visibleToNewUsers: 0,
                }));
            } else {
                //TODO handle error - if folder exists offer user to enter a new name or cancel (this probably should not be implemented if function is called from folder upload)
                this.setState({
                    createFolderModal: false,
                });
                alert("You don't have permission to edit this parent folder!");
            }
        });
    };

    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };

    handleFolderUpload = (currFile, index) => {
        const { level, id } = this.state.current;
        const token = StoreService.getStoreProperty("token");
        const file = currFile;

        const formData = new FormData();
        formData.append("files[]", file);
        formData.append("type", "file");
        formData.append("parent_id", id.toString());
        formData.append("path", file.path);
        formData.append("level", (level + 1).toString());

        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState((prevState: any) => ({
                    completedList: [
                        ...prevState.completedList.slice(0, index),
                        {
                            completed,
                        },
                        ...prevState.completedList.slice(index + 1),
                    ],
                    showUploadComponent: true,
                }));
            },
        };
        ApiService.uploadFile(formData, token, config).then((res) => {
            if (res.status === "OK") {
                const notifications = res.data.notifications;

                if (notifications && Object.values(notifications).length) {
                    Object.values(notifications).forEach((notification: any) => {
                        if (!DISABLE_FIREBASE) {
                            Devkit.FirebaseService.sendNotification(
                                notification.action,
                                notification.item,
                                notification.link,
                                notification.read,
                                null,
                                notification.users
                            );
                        }
                    });
                }

                res.data = res.data.file;

                if (!this.state.folderCreated) {
                    this.setState({ folderCreated: true }, () => {
                        this.getItems();
                        //this.getItem();
                        this.handleGetStructure();
                    });
                }
            }
            //TODO handle bad response from server
        });
    };
    handleFileUpload = (replace?, fileID?) => {
        const { id, level } = this.state.current;
        const token = StoreService.getStoreProperty("token");
        let formData: any = new FormData();

        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        if (replace === true) {
            const replaceFile = this.state.replaceFile.value || "";
            formData.append("file", replaceFile);
            formData.append("file_id", fileID);
            ApiService.replaceFile(formData, token, config).then((res) => {
                if (res.status === "OK") {
                    const notifications = res.data.notifications;

                    if (notifications && Object.values(notifications).length) {
                        Object.values(notifications).forEach((notification: any) => {
                            if (!DISABLE_FIREBASE) {
                                Devkit.FirebaseService.sendNotification(
                                    notification.action,
                                    notification.item,
                                    notification.link,
                                    notification.read,
                                    null,
                                    notification.users
                                );
                            }
                        });
                    }

                    res.data = res.data.file;

                    this.setState({
                        // items: [
                        //     ...prevState.items.slice(0, foldersNo),
                        //     {
                        //         ...res.data,
                        //     },
                        //     ...prevState.items.slice(foldersNo),
                        // ],
                        completed: 0,
                        uploadComplete: false,
                        fileUploadReplaceModal: false,
                        uploading: false,
                        replaceFile: {
                            value: null,
                            errors: [],
                        },
                    });
                    this.handleGetStructure();
                    window.location.reload();
                }
                //TODO handle bad response from server
            });
        } else {
            let file = this.state.file.value;
            file.forEach((e) => {
                formData.append("files[]", e);
            });

            formData.append("type", "file");
            formData.append("parent_id", id.toString());
            formData.append("path", "/");
            formData.append("level", (level + 1).toString());
            ApiService.uploadFile(formData, token, config).then((res) => {
                if (res.status === "OK") {
                    const notifications = res.data.notifications;

                    if (notifications && Object.values(notifications).length) {
                        Object.values(notifications).forEach((notification: any) => {
                            if (!DISABLE_FIREBASE) {
                                Devkit.FirebaseService.sendNotification(
                                    notification.action,
                                    notification.item,
                                    notification.link,
                                    notification.read,
                                    null,
                                    notification.users
                                );
                            }
                        });
                    }
                    this.getItems();
                    this.handleGetStructure();

                    this.setState({
                        completed: 0,
                        uploadComplete: false,
                        fileUploadModal: false,
                        uploading: false,
                        file: {
                            value: null,
                            errors: [],
                        },
                    });
                } else {
                    alert("Error");
                }
                //TODO handle bad response from server
            });
        }
    };

    handleContextMenu = (e, id, type) => {
        const role = StoreService.getStoreProperty("user").role;
        const name = this.state.items.find((e) => e.id === id)?.title;
        const re = /(?:\.([^.]+))?$/;
        const extArr = re.exec(name || "");

        let ext;
        let ctxLeft;
        let ctxTop;
        let ctxHeight;

        if (role === 3) {
            ctxHeight = this.state.selectedItemsIds.length > 1 ? 160 : type === "folder" ? 360 : 400;
        }

        const bottomHalf = e.clientY > window.innerHeight / 2;

        if (bottomHalf) {
            ctxTop = e.clientY - ctxHeight;
        } else {
            ctxTop = e.clientY;
        }

        if (window.innerHeight / 2 < ctxHeight) {
            if (bottomHalf) {
                ctxTop += ctxHeight - window.innerHeight / 2;
            } else {
                ctxTop -= ctxHeight - window.innerHeight / 2;
            }
        }

        if (extArr) {
            ext = extArr[0];
        }

        if (this.context.screenSize >= 480) {
            ctxLeft = e.pageX;

            if (e.pageX + 280 > window.innerWidth) {
                ctxLeft = ctxLeft - 248;
            }

            this.setState({
                ctxTop,
                ctxLeft,
                ctxVisible: true,
                contextItemID: id,
                contextItemName: {
                    value: name?.replace(re, ""),
                    errors: [],
                },
                contextItemExt: ext || "",
                contextItemType: type,
                ctxHeight,
            });
        } else {
            ctxLeft = this.context.screenSize / 2 - 248 / 2;

            this.setState({
                ctxTop,
                ctxLeft,
                ctxVisible: true,
                contextItemID: id,
                contextItemName: {
                    value: name?.replace(re, ""),
                    errors: [],
                },
                contextItemExt: ext || "",
                contextItemType: type,
                ctxHeight,
            });
        }
    };

    handleGoBack = () => {
        const { parent_id } = this.state.current;
        this.props.history.push(`/file-manager${parent_id !== 1 ? "/" + parent_id : ""}`);
        this.setState({
            isLoading: true,
        });
    };

    handleRenameItem = () => {
        const { contextItemID, contextItemName, contextItemExt, contextItemType } = this.state;
        const data = {
            title: `${contextItemName.value}${contextItemExt}`,
            id: contextItemID,
            type: contextItemType !== "folder" ? "file" : "folder",
        };
        if (contextItemName.value === "") {
            this.setState({
                contextItemName: {
                    value: "",
                    errors: ["INPUT_REQUIRED"],
                },
            });
            return;
        }
        const token = StoreService.getStoreProperty("token");
        ApiService.renameItem(data, token).then((res) => {
            if (res.status === "OK") {
                /* @TODO for now we don't have a notification when renaming.
                   Leaving this code in case we want to implement it someday.
                const notifications = res.data.notifications;

                if (notifications && Object.values(notifications).length) {
                    Object.values(notifications).forEach((notification: any) => {
                        Devkit.FirebaseService.sendNotification(
                            notification.action,
                            notification.item,
                            notification.link,
                            notification.read,
                            null,
                            notification.users
                        );
                    });
                }

                res.data = res.data.file;
                */
                const items = this.state.items.map((e) => {
                    if (e.id === res.data.id) {
                        return { ...e, title: res.data.title };
                    }
                    return e;
                });
                this.setState({ items, renameItemModal: false });
                this.handleGetStructure();
                this.getItems();
            }
        });
    };
    handleCloneItem = () => {
        const { contextItemID } = this.state;
        const token = StoreService.getStoreProperty("token");
        const data = {
            file_id: contextItemID,
        };
        ApiService.cloneFile(data, token).then((res) => {
            if (res.status === "OK") {
                const notifications = res.data.notifications;

                if (notifications && Object.values(notifications).length) {
                    Object.values(notifications).forEach((notification: any) => {
                        if (!DISABLE_FIREBASE) {
                            Devkit.FirebaseService.sendNotification(
                                notification.action,
                                notification.item,
                                notification.link,
                                notification.read,
                                null,
                                notification.users
                            );
                        }
                    });
                }

                this.setState({
                    cloneItemModal: false,
                });
                this.getItems();
            } else {
                this.setState({
                    cloneItemModal: false,
                    showCloneErrorModal: true,
                });
            }
        });
    };
    handleDeleteItem = () => {
        const { contextItemID, selectedItemsIds } = this.state;
        const token = StoreService.getStoreProperty("token");

        selectedItemsIds.length > 0
            ? selectedItemsIds.map((id): any => this.deleteItemFunction(id, token))
            : this.deleteItemFunction(contextItemID, token);
    };
    deleteItemFunction = (contextItemID, token) => {
        ApiService.deleteItem(contextItemID, token).then((res) => {
            if (res.status === "OK") {
                const notifications = res.data.notifications;

                if (notifications && Object.values(notifications).length) {
                    Object.values(notifications).forEach((notification: any) => {
                        if (!DISABLE_FIREBASE) {
                            Devkit.FirebaseService.sendNotification(
                                notification.action,
                                notification.item,
                                notification.link,
                                notification.read,
                                null,
                                notification.users
                            );
                        }
                    });
                }

                const items = this.state.items.filter((e) => e.id !== contextItemID);

                this.setState({
                    items,
                    deleteItemModal: false,
                    toggleSelect: false,
                });
                this.handleGetStructure();
            } else {
                const actionRelations: any = res.data.action_relations && res.data.action_relations;
                const defaultPathActionRelations: any =
                    res.data.default_path_action_relations && res.data.default_path_action_relations;
                const defaultPathCustomerRelations: any =
                    res.data.default_path_customer_relations && res.data.default_path_customer_relations;
                this.setState({
                    deleteItemModal: false,
                    actionRelations,
                    defaultPathActionRelations,
                    defaultPathCustomerRelations,
                });
                this.toggleModal("deleteItemConfirmationModal");
                //TODO handle no permission
                //TODO handle bad delete request
            }
        });
    };
    handleConfirmationDeleteItem = () => {
        const { contextItemID } = this.state;
        const confirmation = 1;
        const token = StoreService.getStoreProperty("token");
        ApiService.deleteItem(contextItemID, token, confirmation).then((res) => {
            if (res.status === "OK") {
                const notifications = res.data.notifications;

                if (notifications && Object.values(notifications).length) {
                    Object.values(notifications).forEach((notification: any) => {
                        if (!DISABLE_FIREBASE) {
                            Devkit.FirebaseService.sendNotification(
                                notification.action,
                                notification.item,
                                notification.link,
                                notification.read,
                                null,
                                notification.users
                            );
                        }
                    });
                }

                const items = this.state.items.filter((e) => e.id !== contextItemID);
                this.setState({
                    deleteItemModal: false,
                    items,
                    deleteItemConfirmationModal: false,
                });
                this.handleGetStructure();
            } else {
                this.setState({
                    deleteItemModal: false,
                    deleteItemConfirmationModal: false,
                    showDeleteErrorModal: true,
                });
                alert("Cannot be deleted");
            }
        });
    };
    handleCheckbox = (checked) => {
        this.setState((prev: any) => ({
            ...prev,
            visibleToNewUsers: checked ? 1 : 0,
        }));
    };

    handleDestinationID = (destinationID) => {
        this.setState({ destinationID });
    };

    handleMoveItem = () => {
        const { contextItemID, destinationID, selectedItemsIds } = this.state;
        const data = { item_id: contextItemID, destination_id: destinationID };
        const token = StoreService.getStoreProperty("token");
        selectedItemsIds.length > 0
            ? selectedItemsIds.map((id): any =>
                  this.moveItemFunction({ item_id: id, destination_id: destinationID }, token)
              )
            : this.moveItemFunction(data, token);
    };
    moveItemFunction = (data, token) => {
        //const { contextItemID } = this.state;
        ApiService.moveItem(data, token).then((res) => {
            if (res.status === "OK") {
                const notifications = res.data.notifications;

                if (notifications && Object.values(notifications).length) {
                    Object.values(notifications).forEach((notification: any) => {
                        if (!DISABLE_FIREBASE) {
                            Devkit.FirebaseService.sendNotification(
                                notification.action,
                                notification.item,
                                notification.link,
                                notification.read,
                                null,
                                notification.users
                            );
                        }
                    });
                }
                const items = this.state.items.filter((e) => e.id !== data.item_id);
                this.handleGetStructure();
                this.getItem();
                this.setState({
                    items,
                    destinationID: 1,
                    moveItemModal: false,
                    toggleSelect: false,
                });
            } else {
                alert("Couldn't move file/folder!");
            }
        });
        this.setState({
            selectedItemsIds: [],
        });
    };
    handleDoubleClick = (id, type, name) => {
        if (type !== "folder") {
            const token = StoreService.getStoreProperty("token");
            this.setState({ activeDownload: [{ name }], showDownloadComponent: true }, () => {
                const config = {
                    onDownloadProgress: (progressEvent) => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        this.setState({
                            completedList: [{ completed: percentCompleted }],
                        });
                    },
                };
                ApiService.downloadFile({ id }, config, token).then((res) => {
                    if (!res) return;

                    const blobURL = window.webkitURL.createObjectURL(res);
                    const link = document.createElement("a");
                    link.href = blobURL;
                    link.setAttribute("download", name); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            });
        }
    };

    setFileUpload = (fileUpload) => {
        this.setState({ fileUpload });
    };

    uploadMinimize = () => {
        this.setState({ minimize: !this.state.minimize });
    };

    handleCloseUpload = () => {
        this.setState({ showUploadComponent: false });
    };
    downloadMinimize = () => {
        this.setState({ minimizeDownload: !this.state.minimizeDownload });
    };

    handleCloseDownload = () => {
        this.setState({ showDownloadComponent: false });
    };

    zipItem = (callback?: any) => {
        this.setState({ ctxVisible: false, zipFileModal: true }, () => {
            const token = StoreService.getStoreProperty("token");
            ApiService.zipItem(this.state.contextItemID, token).then((res) => {
                if (res.status === "OK") {
                    //TODO this block of code is identical to one found in handleUploadFile function, abstract it to separate function
                    const foldersNo = this.state.items.filter((e) => e.type === "folder").length;
                    this.setState((prevState: any) => ({
                        items: [
                            ...prevState.items.slice(0, foldersNo),
                            {
                                ...res.data,
                            },
                            ...prevState.items.slice(foldersNo),
                        ],
                        zipFileModal: false,
                    }));
                    this.handleGetStructure();
                    if (typeof callback === "function") {
                        callback(res.data.id, res.data.title);
                    }
                } else {
                    //TODO handle errors - usually it will be that folder is empty, but further testing is needed
                }
            });
        });
    };

    downloadItem = () => {
        const { contextItemType, contextItemID, contextItemName, contextItemExt } = this.state;

        if (contextItemType !== "folder") {
            this.handleDoubleClick(contextItemID, contextItemType, `${contextItemName.value}${contextItemExt}`);
        } else {
            this.zipItem((id, name) => this.handleDoubleClick(id, "zip", name));
        }
    };
    handleFolderSearchInput = (name, data) => {
        this.setState({ [name]: data.value });
    };

    handleDropdownInput = (name, data) => {
        this.setState({ [name]: data.value });
    };

    toggleSearch = () => {
        this.setState({ showFolderSearch: true }, () => {
            this.folderSearchInputRef.current?.focus();
        });
    };
    // checkFilePermission = () => {
    //     const { items } = this.state;

    //     let newUserRead = items.find((item) => item.new_users_read === 0);
    //     let newUserWrite = items.find((item) => item.new_users_write === 0);

    //     // return {
    //     //     new_users_read: items && !!items[0].new_users_read,
    //     //     new_users_write: items && !!items[0].new_users_write,
    //     // };
    //     return {
    //         new_users_read: typeof newUserRead === "object" ? false : true,
    //         new_users_write: typeof newUserWrite === "object" ? false : true,
    //     };
    // };

    checkFilePermission = () => {
        const { items, contextItemID } = this.state;
        let selectedItem;
        let newUserRead;
        let newUserWrite;
        if (contextItemID !== 0) {
            if (typeof contextItemID === "number") {
                selectedItem = items.find((itemFiltered) => itemFiltered.id === contextItemID);
            } else {
                selectedItem = contextItemID.map((element) =>
                    items.find((itemFiltered) => itemFiltered.id === element)
                );
            }
            // console.log(selectedItem);
            // stari kod
            newUserRead = items.find((item) => item.new_users_read === 0);
            newUserWrite = items.find((item) => item.new_users_write === 0);
            if (typeof contextItemID === "number") {
                newUserRead = selectedItem && selectedItem.new_users_read === 1 ? true : false;
                newUserWrite = selectedItem && selectedItem.new_users_write === 1 ? true : false;
            } else {
                newUserRead = selectedItem.find((element) => element.new_users_read === 0);
                newUserWrite = selectedItem.find((element) => element.new_users_write === 0);
            }
            //kod od pre 3 verzije
            // return {
            //     new_users_read: items && !!items[0].new_users_read,
            //     new_users_write: items && !!items[0].new_users_write,
            // };
            if (typeof contextItemID === "number") {
                return {
                    new_users_read: newUserRead,
                    new_users_write: newUserWrite,
                };
            } else {
                return {
                    //stari a ujedno i novi kod
                    new_users_read: typeof newUserRead === "object" ? false : true,
                    new_users_write: typeof newUserWrite === "object" ? false : true,
                };
            }
        } else {
            return {
                //stari a ujedno i novi kod
                new_users_read: false,
                new_users_write: false,
            };
        }
    };

    checkIfPermissionExists = (userID) => {
        let read = false;
        let write = false;
        if (this.state.changePermissionsModal) {
            const { contextItemID, items } = this.state;
            let filePermissions;
            if (typeof contextItemID === "number") {
                filePermissions = items.find((e) => e.id === contextItemID)?.file_permissions;
            } else {
                contextItemID.map((item) => {
                    return (filePermissions = items.find((e) => e.id === item)?.file_permissions);
                });
            }

            // const filePermissions = items.find((e) => e.id === contextItemID)?.file_permissions;

            const user = StoreService.getStoreProperty("users").find((e) => e.id === userID)?.id;
            if (filePermissions) {
                const filePermission: any = filePermissions.find((e: any) => e.user_id === user);

                if (filePermission) {
                    read = !!filePermission.read;
                    write = !!filePermission.write;
                    return {
                        read: read,
                        write: write,
                        // read: !!filePermission.read,
                        // write: !!filePermission.write,
                    };
                }
                //  else {
                //     return {
                //         read: false,
                //         write: false,
                //     };
                // }
                // return {
                //     read: false,
                //     write: false,
                // };
            }
            // return {
            //     read: false,
            //     write: false,
            // };
        }
        return {
            read: read,
            write: write,
        };
    };
    changeUserPermission = (user_id, read, write) => {
        const { contextItemID } = this.state;

        const data = {
            items_id: typeof contextItemID === "number" ? [contextItemID] : contextItemID,
            user_id,
            read: read ? 1 : 0,
            write: write ? 1 : 0,
        };
        const token = StoreService.getStoreProperty("token");
        this.setState({ permissionLoader: true });

        ApiService.managePermissions(data, token).then((res) => {
            if (res.status === "OK") {
                if (typeof contextItemID === "number") {
                    const callback = () => {
                        this.state.items.map((element, index) => {
                            if (contextItemID === element.id) {
                                this.setState((prevState: any) => ({
                                    items: [
                                        ...prevState.items.slice(0, index),
                                        {
                                            ...element,
                                            isSelected: true,
                                        },
                                        ...prevState.items.slice(index + 1),
                                    ],
                                    permissionLoader: false,
                                }));
                            }
                            return "";
                        });
                    };
                    this.getItems(callback);
                } else {
                    const callback = () => {
                        this.state.items.map((element, index) => {
                            if (contextItemID.find((id) => id === element.id)) {
                                this.setState((prevState: any) => ({
                                    items: [
                                        ...prevState.items.slice(0, index),
                                        {
                                            ...element,
                                            isSelected: true,
                                        },
                                        ...prevState.items.slice(index + 1),
                                    ],
                                    permissionLoader: false,
                                }));
                            }
                            return "";
                        });
                    };
                    this.getItems(callback);
                }
            } else {
                //TODO handle failed response
                alert("Couldn't change permissions!");
            }
        });
    };

    changeNewUsersFilePermission = (new_users_read, new_users_write) => {
        const { contextItemID } = this.state;
        let read = 0;
        let write = 0;

        if (new_users_read) read = 1;
        if (new_users_write) write = 1;
        const data = {
            files_id: typeof contextItemID === "number" ? [contextItemID] : contextItemID,
            new_users_read: read,
            new_users_write: write,
        };
        const token = StoreService.getStoreProperty("token");
        this.setState({ permissionLoader: true });
        ApiService.changeNewUsersFilePermission(data, token).then((res) => {
            if (res.status === "OK") {
                if (typeof contextItemID === "number") {
                    const callback = () => {
                        this.state.items.map((element, index) => {
                            if (contextItemID === element.id) {
                                this.setState((prevState: any) => ({
                                    items: [
                                        ...prevState.items.slice(0, index),
                                        {
                                            ...element,
                                            isSelected: true,
                                        },
                                        ...prevState.items.slice(index + 1),
                                    ],
                                    permissionLoader: false,
                                }));
                            }
                            return null;
                        });
                    };
                    this.getItems(callback);
                } else {
                    const callback = () => {
                        this.state.items.map((element, index) => {
                            if (contextItemID.find((id) => id === element.id)) {
                                this.setState((prevState: any) => ({
                                    items: [
                                        ...prevState.items.slice(0, index),
                                        {
                                            ...element,
                                            isSelected: true,
                                        },
                                        ...prevState.items.slice(index + 1),
                                    ],
                                    permissionLoader: false,
                                }));
                            }
                            return null;
                        });
                    };
                    this.getItems(callback);
                }
                // res.data.map((element, index) =>
                //     this.setState((prevState: any) => ({
                //         items: [
                //             ...prevState.items.slice(0, index),
                //             {
                //                 ...element,
                //                 isSelected: true,
                //             },
                //             ...prevState.items.slice(index + 1),
                //         ],
                //         permissionLoader: false,
                //     }))
                // );
                // this.setState(
                //     {
                //         item: res.data,
                //         permissionLoader: false,
                //     }
                // ); --- earlier code
            } else {
                //TODO handle failed response
            }
        });
    };

    downloadZip = () => {
        this.setState({
            downloadAllZipModal: true,
        });
        const token = StoreService.getStoreProperty("token");
        ApiService.backupDataToZip(token).then((res) => {
            // const url = window.URL.createObjectURL(new Blob([res]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'file.zip'); //or any other extension
            // document.body.appendChild(link);
            // link.click();
            this.downloadFile(res, "files.zip");
        });
    };
    downloadFile = (blob, fileName) => {
        const link = document.createElement("a");
        let binaryData = [] as any;
        binaryData.push(blob);
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(new Blob(binaryData, { type: "application/zip" }));
        // link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        this.setState({
            downloadAllZipModal: false,
        });
        setTimeout(() => URL.revokeObjectURL(link.href), 11000);
    };
    handleCancel = () => {
        this.setState({ visibleToNewUsers: 0 });
        this.toggleModal("createFolderModal");
    };
    shareFileFolder = () => {
        const el = document.createElement("input");
        let { contextItemType, contextItemID, folderID } = this.state;
        let id = contextItemType === "folder" ? contextItemID : folderID;
        let serverUrl = window.location.hostname;
        if (serverUrl === "localhost") {
            serverUrl = "http://localhost:3000";
        }

        let URL = `${serverUrl}/file-manager/${id}?share=true`;

        el.value = URL;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        this.hideContextMenu();
    };

    selectItem = (id, checked, index) => {
        let ids: any = this.state.selectedItemsIds;
        let newIds: any = [...ids, id];
        if (ids.includes(id)) {
            newIds = newIds.filter((day) => day !== id);
        }

        this.setState((prevState: any) => ({
            items: [
                ...prevState.items.slice(0, index),
                {
                    ...prevState.items[index],
                    isSelected: checked,
                },
                ...prevState.items.slice(index + 1),
            ],
            selectedItemsIds: newIds,
        }));
    };

    toggleSelect = (select) => {
        let isSelect = select;
        if (isSelect) {
            this.setState({
                toggleSelect: isSelect,
            });
        } else {
            let items = this.state.items;
            items.forEach((item) => {
                item.isSelected = false;
            });
            this.setState({
                items,
                toggleSelect: isSelect,
                selectedItemsIds: [],
            });
        }
    };

    selectFromMenu = () => {
        let { contextItemID, items } = this.state;
        let index = items.findIndex((item) => item.id === contextItemID);
        let checked = !items[index].isSelected;

        this.toggleSelect(true);
        this.selectItem(contextItemID, checked, index);
        this.hideContextMenu();
    };

    renderNoTitle = (t) => {
        if (t) {
            return t;
        } else return <Translate text="NO_TITLE" />;
    };

    renderEmployeesTab = () => {
        this.setState({
            showEmployeesTab: true,
            showGroupsTab: false,
        });
    };

    renderGroupsTab = () => {
        this.setState({
            showEmployeesTab: false,
            showGroupsTab: true,
        });
    };

    changeGroupPermissions = (group, id, read, write) => {
        const { contextItemID } = this.state;
        const data = {
            files_id: typeof contextItemID === "number" ? [contextItemID] : contextItemID,
            group_id: id,
            read: read ? 1 : 0,
            write: write ? 1 : 0,
        };

        const token = StoreService.getStoreProperty("token");
        this.setState({ permissionLoader: true });

        ApiService.manageGroupFilePermissions(data, token).then((res) => {
            if (res.status === "OK") {
                this.getItems();
                this.setState({
                    groups: res.data[0].groupPermissions,
                    permissionLoader: false,
                });
            } else {
                alert("Error");
            }
        });
    };

    showMembers = (id) => {
        let group = this.state.groups.find((e) => e.id === id);
        this.setState({
            showMembersModal: true,
            groupUsers: group.employees,
        });
    };

    downloadMultipleItems = () => {
        const token = StoreService.getStoreProperty("token");
        this.setState({ activeDownload: [{ name: "multiple" }], showDownloadComponent: true }, () => {
            const config = {
                onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({
                        completedList: [{ completed: percentCompleted }],
                    });
                },
            };
            ApiService.downloadMultipleFiles(this.state.contextItemID, config, token).then((res) => {
                if (!res) return;

                const blobURL = window.webkitURL.createObjectURL(res);
                const link = document.createElement("a");
                link.href = blobURL;
                link.setAttribute("download", ".zip"); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        });
    };
    handleGoToRelation = (name, id) => {
        if (name === "customer") {
            window.open(`/clientDetails/${id}`, "_blank");
        } else {
            window.open(`/action/${id}`, "_blank");
        }
    };
    previewFile = (e) => {
        const name = e.title;
        const id = e.id;

        const token = StoreService.getStoreProperty("token");
        this.setState({ activeDownload: [{ name }], fileName: name }, () => {
            const config = {
                onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({
                        completedList: [{ completed: percentCompleted }],
                    });
                },
            };
            ApiService.downloadFile({ id }, config, token).then((res) => {
                if (!res) return;
                const blobURL = window.webkitURL.createObjectURL(res);
                const link = document.createElement("a");
                link.href = blobURL;
                this.setState({ IFrameURL: link });
            });
            this.handleCloseDownload();
        });
        this.toggleModal("showIFrameModalContent");
    };

    submitFilters = (e) => {
        e.preventDefault();
        const { folderSearch, pickedLabel, current } = this.state;
        const { id, path } = current;
        let data = { id, path };
        if (folderSearch) {
            data["search"] = folderSearch;
        }
        if (pickedLabel) {
            data["label"] = pickedLabel;
        }
        const token = StoreService.getStoreProperty("token");
        if (!!folderSearch || pickedLabel) {
            ApiService.searchFolder(data, token).then((res) => {
                if (res.status === "OK") {
                    this.setState({ items: res.data });
                }
            });
        } else {
            this.getItems();
        }
    };

    resetFilters = (e) => {
        e.preventDefault();
        this.setState({ folderSearch: "", pickedLabel: null }, () => {
            this.getItems();
        });
    };

    toggleSidebar = (e) => {
        e.preventDefault();
        this.setState({ showFilterSidebar: !this.state.showFilterSidebar });
    };

    showLogs = (e) => {
        e.preventDefault();

        const token = StoreService.getStoreProperty("token");
        let item_id = this.state.contextItemID;
        ApiService.getSpecificLogs({ item_id, table: 2 }, token).then((res) => {
            if (res.status === "OK") {
                this.setState({ logs: res.data, showLogsSidebar: !this.state.showLogsSidebar });
                this.hideContextMenu();
            }
        });
    };

    renderFilters = () => {
        const { screenSize } = this.context;

        if (screenSize <= 768) {
            return (
                <FilterSidebar
                    opened={this.state.showFilterSidebar}
                    onClose={() => this.setState({ showFilterSidebar: !this.state.showFilterSidebar })}
                    sidebarClasses="clientsFilterSidebar"
                    wrapperClasses="clientsFilterWrapper"
                    submitFilters={this.submitFilters}
                    resetFilters={this.resetFilters}
                >
                    <CustomForm className=" d-flex flex-column px-10 pointer" handleSubmit={(e) => e.preventDefault()}>
                        <div className="mb-20" onClick={(e) => e.stopPropagation()}>
                            <CustomInput
                                handleInput={this.handleFolderSearchInput}
                                label="SEARCH"
                                type="text"
                                value={this.state.folderSearch}
                                name="folderSearch"
                            />
                        </div>
                        <div>
                            <CustomDropdown
                                data={this.state.allLabels}
                                name="pickedLabel"
                                handleChange={this.handleDropdownInput}
                                value={
                                    this.state.allLabels.find((e: any) => e.value === this.state.pickedLabel) || null
                                }
                                label="LABELS"
                                placeholder={<Translate text="CHOOSE_LABEL" />}
                            />
                        </div>
                    </CustomForm>
                </FilterSidebar>
            );
        } else {
            return (
                <CustomForm className="search d-flex px-10 pointer" handleSubmit={(e) => e.preventDefault()}>
                    <div className="d-flex align-items-center mr-10" onClick={(e) => e.stopPropagation()}>
                        <SearchIcon className="theme2Icon" width={18} onClick={this.toggleSearch} />
                        <CustomInput
                            handleInput={this.handleFolderSearchInput}
                            className={`folderSearch ${this.state.showFolderSearch ? "active" : ""}`}
                            type="text"
                            value={this.state.folderSearch}
                            preview
                            name="folderSearch"
                            edit={true}
                            ref={this.folderSearchInputRef}
                        />
                    </div>
                    <div className="w-200 ">
                        <CustomDropdown
                            data={this.state.allLabels}
                            name="pickedLabel"
                            handleChange={this.handleDropdownInput}
                            value={this.state.allLabels.find((e: any) => e.value === this.state.pickedLabel) || null}
                            label="LABELS"
                            placeholder={<Translate text="CHOOSE_LABEL" />}
                        />
                    </div>
                </CustomForm>
            );
        }
    };

    renderLogs = () => {
        return (
            <LogsSidebar
                opened={this.state.showLogsSidebar}
                onClose={() => this.setState({ showLogsSidebar: !this.state.showLogsSidebar })}
                sidebarClasses="clientsFilterSidebar"
                wrapperClasses="clientsFilterWrapper"
                items={this.state.logs}
                deleteLog={(id) => this.deleteLog(id)}
            ></LogsSidebar>
        );
    };

    deleteLog = (id) => {
        const token = StoreService.getStoreProperty("token");
        let item_id = this.state.contextItemID;
        ApiService.deleteLog({ id }, token).then(() => {
            ApiService.getSpecificLogs({ item_id, table: 2 }, token).then((res) => {
                if (res.status === "OK") {
                    this.setState({ logs: res.data });
                }
            });
        });
    };

    onTouchStart = (e, id, type) => {
        // if (this.state.ctxVisible) {
        //     this.setState({ ctxVisible: false });
        // }

        let timer;

        timer = setTimeout(() => {
            this.handleContextMenu(e, id, type);
        }, 500);

        this.setState({ timer });
    };

    onTouchEnd = () => {
        if (this.state.timer) {
            clearTimeout(this.state.timer);
            this.setState({ timer: undefined });
        }
    };

    render() {
        const {
            tableHeads,
            items,
            isLoading,
            ctxTop,
            ctxLeft,
            ctxVisible,
            ctxHeight,
            parents,
            toggleSelect,
            actionRelations,
            defaultPathActionRelations,
            defaultPathCustomerRelations,
            visibleToNewUsers,
            selectedItemsIds,
            groups,
            IFrameURL,
            showIFrameModalContent,
        } = this.state;

        const multipleSelection = selectedItemsIds.length > 1; //! promijeniti uslov na 0 ukoliko logicki bude bolje u zavisnosti od toga kakvo se ponasanje zeli ukoliko je selektovan jedan a klikne se desni klik na drugi itd.

        const role = StoreService.getStoreProperty("user").role;
        const users = StoreService.getStoreProperty("users").filter((e) => e.role !== 3);
        users.unshift({ firstName: <Translate text="FUTURE_USERS" /> });

        if (isLoading) {
            return (
                <div className="loaderContainer">
                    <Loader width={200} />
                </div>
            );
        } else {
            return (
                <div id="fileManager" ref={this.fileManagerRef}>
                    <FileUploadProgressList
                        completedList={this.state.completedList}
                        folder={this.state.folder}
                        visible={this.state.showUploadComponent && this.state.folder.length > 0}
                        hidden={this.state.minimize}
                        handleArrowClick={this.uploadMinimize}
                        handleClose={this.handleCloseUpload}
                    />
                    <FileUploadProgressList
                        completedList={this.state.completedList}
                        folder={this.state.activeDownload}
                        visible={this.state.showDownloadComponent && this.state.activeDownload.length > 0}
                        hidden={this.state.minimizeDownload}
                        handleArrowClick={this.downloadMinimize}
                        handleClose={this.handleCloseDownload}
                        download
                    />
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showIFrameModalContent"
                        className={showIFrameModalContent ? "visible" : ""}
                        modalWrapperClasses="w-70-perc h-80-perc overflow-hidden d-flex flex-column pt-10"
                    >
                        <h3 className="align-self-center">{this.state.fileName}</h3>
                        <div className="justify-content-center align-items-center w-100-perc h-100-perc">
                            <iframe
                                src={IFrameURL}
                                width="100%"
                                height="100%"
                                className="p-10 border-none"
                                title="IFrame"
                            ></iframe>
                        </div>
                    </Modal>
                    <ContextMenu top={ctxTop} left={ctxLeft} visible={ctxVisible} height={ctxHeight}>
                        {!!this.state.contextMenuLoader ? (
                            <div className={`loaderContainer d-flex h-80 `}>
                                <Loader width={50} />
                            </div>
                        ) : (
                            <>
                                {(!!this.state.employeePermission || role === 3) &&
                                !multipleSelection &&
                                this.state.contextItemType !== "folder" ? (
                                    <li
                                        className="f-s-14"
                                        onClick={() => {
                                            this.setFileUpload(true);
                                            this.toggleModal("fileUploadReplaceModal");
                                        }}
                                    >
                                        <span className="mr-10">
                                            <i className="fas fa-retweet"></i>
                                        </span>
                                        <Translate text="Replace_file" />
                                    </li>
                                ) : (
                                    <> </>
                                )}
                                {(!!this.state.employeePermission || role === 3) && !multipleSelection ? (
                                    <li className="f-s-14" onClick={() => this.toggleModal("renameItemModal")}>
                                        <span>
                                            <FileEditIcon className="contextIcon mr-10" width={15} />
                                        </span>
                                        <Translate text="RENAME" />
                                    </li>
                                ) : (
                                    <> </>
                                )}

                                {!!this.state.employeePermission || role === 3 ? (
                                    <li className="f-s-14" onClick={() => this.toggleModal("moveItemModal")}>
                                        <span>
                                            <FileMoveIcon className="contextIcon mr-10" width={15} />
                                        </span>
                                        <Translate text="MOVE" />
                                    </li>
                                ) : (
                                    <> </>
                                )}
                                {!multipleSelection && (
                                    <li className="f-s-14" onClick={this.shareFileFolder}>
                                        <span>
                                            <FileShareIcon className="contextIcon mr-10" width={15} />
                                        </span>
                                        <Translate text="SHARE" />
                                    </li>
                                )}
                                {this.state.contextItemType === "folder" && !multipleSelection ? (
                                    <li className="f-s-14" onClick={this.zipItem}>
                                        <span>
                                            <FileZipIcon className="contextIcon mr-10" width={15} />
                                        </span>
                                        <Translate text="ZIP_ITEM" />
                                    </li>
                                ) : (
                                    <></>
                                )}
                                {/* TODO ovde ubaciti download za multiple files */}
                                <li
                                    className="f-s-14"
                                    onClick={!multipleSelection ? this.downloadItem : this.downloadMultipleItems}
                                >
                                    <span>
                                        <ItemDownloadIcon className="contextIcon mr-10" width={15} />
                                    </span>
                                    <Translate
                                        text={`${
                                            this.state.contextItemType === "folder"
                                                ? "DOWNLOAD_FOLDER"
                                                : "DOWNLOAD_FILE"
                                        }`}
                                    />
                                </li>
                                {role === 3 ? (
                                    <>
                                        <li
                                            className="f-s-14"
                                            onClick={() => this.toggleModal("changePermissionsModal")}
                                        >
                                            <span>
                                                <PermissionIcon className="contextIcon mr-10" width={15} />
                                            </span>
                                            <Translate text="CHANGE_PERMISSIONS" />
                                        </li>
                                        {!multipleSelection && (
                                            <li className="f-s-14" onClick={this.selectFromMenu}>
                                                <span>
                                                    <FileSelectIcon className="contextIcon mr-10" width={15} />
                                                </span>
                                                <Translate text="SELECT" />
                                            </li>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}

                                {(role === 3 || !!this.state.employeePermission) &&
                                this.state.contextItemType !== "folder" &&
                                !multipleSelection ? (
                                    <li className="f-s-14" onClick={() => this.toggleModal("cloneItemModal")}>
                                        <span>
                                            <CloneIcon className="contextIcon mr-10" width={15} />
                                        </span>
                                        <Translate text="CLONE" />
                                    </li>
                                ) : (
                                    <></>
                                )}
                                {!!this.state.employeePermission || role === 3 ? (
                                    <li
                                        className="f-s-14 contextDelete"
                                        onClick={() => this.toggleModal("deleteItemModal")}
                                    >
                                        <span>
                                            <FileDeleteIcon className="contextIcon mr-10" width={15} />
                                        </span>
                                        <Translate text="DELETE" />
                                    </li>
                                ) : (
                                    <></>
                                )}
                                <li className="f-s-14" onClick={this.showLogs}>
                                    <span>
                                        <ActivitiesIcon className="contextIcon mr-10" width={15} />
                                    </span>
                                    <Translate text="SHOW_LOGS" />
                                </li>
                            </>
                        )}
                    </ContextMenu>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="createFolderModal"
                        className={this.state.createFolderModal ? "visible" : ""}
                    >
                        <div className="h-200 w-400 pa-20 d-flex justify-content-center align-items-center">
                            <CustomForm handleSubmit={(e) => e.preventDefault()}>
                                <CustomInput
                                    handleInput={this.handleInput}
                                    name="newFolderName"
                                    value={this.state.newFolderName.value}
                                    type="text"
                                    errors={this.state.newFolderName.errors}
                                    label="FOLDER_NAME"
                                    required
                                />
                                {role === 3 ? (
                                    <div className="d-flex my-20">
                                        <CustomCheckbox
                                            name={"visibleToNewUsers"}
                                            checked={!!visibleToNewUsers}
                                            handleChange={this.handleCheckbox}
                                        />
                                        <span className="align-self-center ml-10 f-s-12">
                                            <Translate text="VISIBLE_TO_NEW_USERS" />
                                        </span>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div className={`buttons d-flex justify-content-between ${role !== 3 && "mt-30"}`}>
                                    <CustomButton className="mr-20 btn-create" onClick={this.createFolder}>
                                        <Translate text="CREATE" />
                                    </CustomButton>
                                    <CustomButton onClick={this.handleCancel}>
                                        <Translate text="CANCEL" />
                                    </CustomButton>
                                </div>
                            </CustomForm>
                        </div>
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="renameItemModal"
                        className={this.state.renameItemModal ? "visible" : ""}
                    >
                        <div className="h-200 w-400 pa-20 d-flex justify-content-center align-items-center">
                            <CustomForm handleSubmit={(e) => e.preventDefault()}>
                                <CustomInput
                                    handleInput={this.handleInput}
                                    name="contextItemName"
                                    value={this.state.contextItemName.value}
                                    type="text"
                                    errors={this.state.contextItemName.errors}
                                    label="NEW_NAME"
                                    required
                                />
                                <div className="buttons mt-30 d-flex justify-content-center">
                                    <CustomButton className="mr-20 btn-create" onClick={this.handleRenameItem}>
                                        <Translate text="RENAME" />
                                    </CustomButton>
                                    <CustomButton onClick={() => this.toggleModal("renameItemModal")}>
                                        <Translate text="CANCEL" />
                                    </CustomButton>
                                </div>
                            </CustomForm>
                        </div>
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="changePermissionsModal"
                        className={`${this.state.changePermissionsModal ? "visible" : ""} permissionsModal`}
                    >
                        <div className="h-400 w-500 p-r">
                            <div className="title border-b-1 d-flex justify-content-center align-items-center h-50">
                                <h2 className="text-color-primary">
                                    <Translate text="CHANGE_PERMISSIONS" />
                                </h2>
                            </div>
                            <div className="d-flex my-10 ml-10">
                                <CustomButton
                                    onClick={this.renderEmployeesTab}
                                    className={`mr-5 border-radius-br-0 border-radius-bl-0 ${
                                        !this.state.showEmployeesTab ? "btn-back" : ""
                                    }`}
                                >
                                    <Translate text="EMPLOYEES" />
                                </CustomButton>
                                <CustomButton
                                    onClick={this.renderGroupsTab}
                                    className={`mr-5 border-radius-br-0 border-radius-bl-0 ${
                                        !this.state.showGroupsTab ? "btn-back" : ""
                                    }`}
                                >
                                    <Translate text="GROUPS" />
                                </CustomButton>
                            </div>
                            {this.state.showEmployeesTab && (
                                <div className="userList  h-350" ref={this.tableWrapper}>
                                    <Table theads={[{ value: "EMPLOYEES" }, { value: "READ" }, { value: "WRITE" }]}>
                                        <tr className="bg-boja-3">
                                            <td className="f-s-16 bold border-b-1 ">
                                                <Translate text="ALL" />
                                            </td>
                                            <td className="border-b-1">
                                                <CustomCheckbox
                                                    name={"allRead"}
                                                    checked={this.state.allRead}
                                                    handleChange={() =>
                                                        this.bulkPermission("allRead", !this.state.allRead)
                                                    }
                                                />
                                            </td>
                                            <td className="border-b-1">
                                                <CustomCheckbox
                                                    name={"allWrite"}
                                                    checked={this.state.allWrite}
                                                    handleChange={() =>
                                                        this.bulkPermission("allWrite", !this.state.allWrite)
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        {users.map((e, index) => {
                                            const permission: any =
                                                index !== 0
                                                    ? this.checkIfPermissionExists(e.id)
                                                    : this.checkFilePermission();

                                            return (
                                                <tr key={e.id} className={`${index === 0 && "bg-boja-3"}`}>
                                                    <td className="f-s-13 text-color-primary">
                                                        {e.firstName} {e.lastName}
                                                    </td>
                                                    <td>
                                                        <CustomCheckbox
                                                            name={"permissionRead"}
                                                            // checked={true}
                                                            checked={
                                                                index !== 0
                                                                    ? permission.read
                                                                    : permission.new_users_read
                                                            }
                                                            handleChange={() =>
                                                                index !== 0
                                                                    ? this.changeUserPermission(
                                                                          e.id,
                                                                          !permission.read,
                                                                          permission.write
                                                                      )
                                                                    : this.changeNewUsersFilePermission(
                                                                          !permission.new_users_read,
                                                                          permission.new_users_write
                                                                      )
                                                            }
                                                            index={index}
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomCheckbox
                                                            name={"permissionWrite"}
                                                            checked={
                                                                index !== 0
                                                                    ? permission.write
                                                                    : permission.new_users_write
                                                            }
                                                            handleChange={() =>
                                                                index !== 0
                                                                    ? this.changeUserPermission(
                                                                          e.id,
                                                                          permission.read,
                                                                          !permission.write
                                                                      )
                                                                    : this.changeNewUsersFilePermission(
                                                                          permission.new_users_read,
                                                                          !permission.new_users_write
                                                                      )
                                                            }
                                                            index={index}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </Table>
                                </div>
                            )}
                            {/* radim */}
                            {this.state.showGroupsTab && (
                                <div className="userList h-350" ref={this.tableWrapper}>
                                    <Table
                                        theads={[
                                            { value: "GROUPS" },
                                            { value: "EMPLOYEES" },
                                            { value: "READ" },
                                            { value: "WRITE" },
                                        ]}
                                    >
                                        <Modal
                                            toggleModal={this.toggleModal}
                                            modalName="showMembersModal"
                                            className={`${
                                                this.state.showMembersModal ? "visible" : ""
                                            } permissionsModal`}
                                            modalWrapperClasses="w-400 p-10"
                                        >
                                            <div className="userList h-350" ref={this.tableWrapper}>
                                                <Table
                                                    theads={[
                                                        { value: "EMPLOYEES" },
                                                        // { value: "READ" },
                                                        // { value: "WRITE" },
                                                    ]}
                                                >
                                                    {this.state.groupUsers &&
                                                        // this.state.groupUsers.map((e, index) => {
                                                        this.state.groupUsers.map((e) => {
                                                            // const permission: any = this.checkIfPermissionExists(e.id);
                                                            return (
                                                                <tr key={e.id}>
                                                                    <td className="f-s-13 text-color-primary">
                                                                        {e.firstName}
                                                                        {e.lastName}
                                                                    </td>
                                                                    {/* <td>
                                                                        <CustomCheckbox
                                                                            name={"permissionRead"}
                                                                            checked={permission.read}
                                                                            // handleChange={() =>
                                                                            //     this.changeUserPermission(
                                                                            //         e.id,
                                                                            //         !permission.read,
                                                                            //         permission.write
                                                                            //     )
                                                                            // }
                                                                            handleChange={() => {}}
                                                                            index={index}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <CustomCheckbox
                                                                            name={"permissionWrite"}
                                                                            checked={permission.write}
                                                                            // handleChange={() =>
                                                                            //     this.changeUserPermission(
                                                                            //         e.id,
                                                                            //         permission.read,
                                                                            //         !permission.write
                                                                            //     )
                                                                            // }
                                                                            handleChange={() => {}}
                                                                            index={index}
                                                                        />
                                                                    </td> */}
                                                                </tr>
                                                            );
                                                        })}
                                                </Table>
                                            </div>
                                        </Modal>
                                        {groups.map((e, index) => {
                                            return (
                                                <tr key={e.id}>
                                                    <td className="f-s-13 text-color-primary">{e.title}</td>
                                                    <td className="w-100">
                                                        <CustomButton
                                                            type="submit"
                                                            onClick={() => this.showMembers(e.id)}
                                                            className="my-5 btn-back small"
                                                        >
                                                            <Translate text="SHOW_MEMBERS" />
                                                        </CustomButton>
                                                    </td>
                                                    <td>
                                                        <CustomCheckbox
                                                            name={"permissionRead"}
                                                            checked={e.read}
                                                            handleChange={() =>
                                                                this.changeGroupPermissions(e, e.id, !e.read, e.write)
                                                            }
                                                            index={index}
                                                        />
                                                    </td>
                                                    <td>
                                                        <CustomCheckbox
                                                            name={"permissionWrite"}
                                                            checked={e.write}
                                                            handleChange={() =>
                                                                this.changeGroupPermissions(e, e.id, e.read, !e.write)
                                                            }
                                                            index={index}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </Table>
                                </div>
                            )}
                            {this.state.permissionLoader && (
                                <div className="processing d-flex justify-content-center align-items-center">
                                    <Loader width={100} />
                                </div>
                            )}
                        </div>
                    </Modal>

                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="deleteItemModal"
                        className={this.state.deleteItemModal ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="warning"
                            text="DELETE_ITEM"
                            modalName="deleteItemModal"
                            title="DELETE_MODAL_ITEM"
                            toggleModal={this.toggleModal}
                            deleteItem={this.handleDeleteItem}
                            cancelDelete={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="deleteItemConfirmationModal"
                        className={this.state.deleteItemConfirmationModal ? "visible " : ""}
                        modalWrapperClasses="padding-10"
                    >
                        <ModalContent
                            type="custom"
                            text="DELETE_ITEM_CONFIRMATION"
                            modalName="deleteItemConfirmationModal"
                            title={"DELETE_MODAL_ITEM"}
                            toggleModal={this.toggleModal}
                            deleteItem={this.handleConfirmationDeleteItem}
                            cancelDelete={this.toggleModal}
                            contentClass="w-700" //?check w/@dino if it is better to always have 3 columns for these 3 arrays even though some of them are empty or leave it like this
                        >
                            <div className="p-r">
                                <div className="f-s-10 d-flex justify-content-center align-items-center p-5 mb-10">
                                    <h2 className="text-color-primary">
                                        <Translate text={"DELETE_ITEM_CONFIRMATION"} />
                                    </h2>
                                </div>
                                <div className="d-flex">
                                    {actionRelations && actionRelations[0] ? (
                                        <div className="actionRelationsTableWrapper">
                                            <Table
                                                theads={[
                                                    {
                                                        value: "ACTION_RELATIONS",
                                                    },
                                                ]}
                                                withoutShowingNoData
                                                theadsClassname="h-50"
                                                className="relationsTable"
                                            >
                                                {actionRelations &&
                                                    Object.values(actionRelations).map((e) => {
                                                        return (
                                                            <tr key={e.action_id}>
                                                                <td className="f-s-13 text-color-primary">
                                                                    {this.renderNoTitle(e.title)}
                                                                </td>
                                                                <td className="pt-6-i">
                                                                    <span
                                                                        onClick={() =>
                                                                            this.handleGoToRelation(
                                                                                "action",
                                                                                e.action_id
                                                                            )
                                                                        }
                                                                    >
                                                                        <ShowPasswordIcon
                                                                            width={20}
                                                                            className="showPassword pt-6"
                                                                        />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </Table>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {defaultPathActionRelations && defaultPathActionRelations[0] ? (
                                        <div className="actionRelationsTableWrapper">
                                            <Table
                                                theads={[
                                                    {
                                                        value: "DEFAULT_ACTION_RELATIONS",
                                                    },
                                                ]}
                                                withoutShowingNoData
                                                theadsClassname="h-50"
                                                className="relationsTable"
                                            >
                                                {defaultPathActionRelations &&
                                                    defaultPathActionRelations.map((e) => {
                                                        return (
                                                            <tr key={e.action_id}>
                                                                <td className="f-s-13 text-color-primary">
                                                                    {this.renderNoTitle(e.title)}
                                                                </td>
                                                                <td className="pt-6-i">
                                                                    <span
                                                                        onClick={() =>
                                                                            this.handleGoToRelation(
                                                                                "action_default",
                                                                                e.action_id
                                                                            )
                                                                        }
                                                                    >
                                                                        <ShowPasswordIcon
                                                                            width={20}
                                                                            className="showPassword pt-6"
                                                                        />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </Table>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {defaultPathCustomerRelations && defaultPathCustomerRelations[0] ? (
                                        <div className="actionRelationsTableWrapper">
                                            <Table
                                                theads={[
                                                    {
                                                        value: "DEFAULT_CUSTOMER_RELATIONS",
                                                    },
                                                ]}
                                                withoutShowingNoData
                                                theadsClassname="h-50"
                                                className="relationsTable"
                                            >
                                                {defaultPathCustomerRelations &&
                                                    defaultPathCustomerRelations.map((e) => {
                                                        return (
                                                            <tr key={e.customer_id}>
                                                                <td className="f-s-13 text-color-primary">
                                                                    {this.renderNoTitle(e.name)}
                                                                </td>
                                                                <td className="pt-6-i">
                                                                    <span
                                                                        onClick={() =>
                                                                            this.handleGoToRelation(
                                                                                "customer",
                                                                                e.customer_id
                                                                            )
                                                                        }
                                                                    >
                                                                        <ShowPasswordIcon
                                                                            width={20}
                                                                            className="showPassword pt-6"
                                                                        />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </Table>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </ModalContent>
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="cloneItemModal"
                        className={this.state.cloneItemModal ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="warning"
                            text="CLONE_ITEM"
                            modalName="cloneItemModal"
                            title="CLONE_MODAL_ITEM"
                            toggleModal={this.toggleModal}
                            buttonText="CLONE"
                            deleteItem={this.handleCloneItem}
                            cancelDelete={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showDeleteErrorModal"
                        className={this.state.showDeleteErrorModal ? "visible bg-info-3" : ""}
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text={"DELETE_FILE_FAILED"}
                            modalName="showDeleteErrorModal"
                            title="FAILURE"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showCloneErrorModal"
                        className={this.state.showCloneErrorModal ? "visible bg-info-3" : ""}
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text={"CLONE_FILE_FAILED"}
                            modalName="showCloneErrorModal"
                            title="FAILURE"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="moveItemModal"
                        className={this.state.moveItemModal ? "visible " : ""}
                        modalWrapperClasses="folderStructureWrapper"
                    >
                        <h2 className="text-center border-b-1 py-10 text-color-primary">
                            <Translate text="MOVE" />
                        </h2>
                        <FolderStructure
                            data={this.state.folderStructure}
                            selectedItem={`${this.state.contextItemName.value}${this.state.contextItemExt}`} //ispraviti
                            handleDestinationID={this.handleDestinationID}
                        />
                        <div className="button h-60 d-flex justify-content-center align-items-center border-t-1">
                            <CustomButton className="btn btn-save" onClick={this.handleMoveItem}>
                                <Translate text="MOVE" />
                            </CustomButton>
                            <CustomButton
                                className="btn btn-cancel ml-30"
                                onClick={() => this.toggleModal("moveItemModal")}
                            >
                                <Translate text="CANCEL" />
                            </CustomButton>
                        </div>
                    </Modal>
                    <Modal
                        modalName="fileUploadReplaceModal"
                        toggleModal={this.toggleModal}
                        className={this.state.fileUploadReplaceModal ? "visible" : ""}
                        modalWrapperClasses="fileUploadModal fileUploadReplaceModal"
                    >
                        {!this.state.uploading && (
                            <div className="pa-20 h-100-perc">
                                <div
                                    onDrop={(e) => this.handleFileChange("replaceFile", e, true)}
                                    className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                        this.state.isOver ? "dotted" : ""
                                    }`}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        this.changeBorderType(true);
                                    }}
                                    onDragLeave={() => this.changeBorderType(false)}
                                >
                                    <div className="text d-flex flex-column justify-content-center align-items-center">
                                        <span className="d-block mb-10">
                                            <UploadIcon width={40} className="uploadIcon" />
                                        </span>
                                        <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                            <Translate text="DRAG_AND_DROP" />
                                        </span>
                                        <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">or</span>
                                    </div>
                                    <CustomFileInput
                                        name="replaceFile"
                                        handleInput={this.handleFileChange}
                                        value={this.state.replaceFile.value}
                                        label={"CHOOSE_FILE"}
                                    />
                                </div>
                            </div>
                        )}
                        {this.state.uploading && (
                            <div className="progress pa-20 h-100perc text-center">
                                <span className="f-s-20 mb-20">
                                    <Translate text="UPLOAD_IN_PROGRESS" />
                                </span>
                                <span className="wrapper d-block">
                                    <Loader width={200} />
                                    <span className="loader-numbers">{this.state.completed}%</span>
                                </span>
                            </div>
                        )}
                    </Modal>
                    <Modal
                        modalName="fileUploadModal"
                        toggleModal={this.toggleModal}
                        className={this.state.fileUploadModal ? "visible" : ""}
                        modalWrapperClasses="fileUploadModal"
                    >
                        {!this.state.uploading && (
                            <div className="pa-20 h-100-perc">
                                <div
                                    onDrop={(e) => {
                                        if (this.state.fileUpload) {
                                            this.handleFileChange("file", e, true);
                                        } else {
                                            this.handleFolderDrop("folder", e);
                                        }
                                    }}
                                    className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                        this.state.isOver ? "dotted" : ""
                                    }`}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        this.changeBorderType(true);
                                    }}
                                    onDragLeave={() => this.changeBorderType(false)}
                                >
                                    <div className="text d-flex flex-column justify-content-center align-items-center">
                                        <span className="d-block mb-10">
                                            <UploadIcon width={40} className="uploadIcon" />
                                        </span>
                                        <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                            {this.state.fileUpload ? (
                                                <Translate text="DRAG_AND_DROP" />
                                            ) : (
                                                <Translate text="DRAG_AND_DROP_FOLDER" />
                                            )}
                                        </span>
                                        <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">or</span>
                                    </div>
                                    {this.state.fileUpload ? (
                                        <CustomFileInput
                                            name="file"
                                            handleInput={this.handleFileChange}
                                            value={this.state.file.value}
                                            label={"CHOOSE_FILE"}
                                            multiple
                                        />
                                    ) : (
                                        <CustomFileInput
                                            name="folder"
                                            handleInput={this.handleFolderChange}
                                            value={this.state.file.value}
                                            label={"CHOOSE_FOLDER"}
                                            folder
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {this.state.uploading && (
                            <div className="progress pa-20 h-100perc text-center">
                                <span className="f-s-20 mb-20">
                                    <Translate text="UPLOAD_IN_PROGRESS" />
                                </span>
                                <span className="wrapper d-block">
                                    <Loader width={200} />
                                    <span className="loader-numbers">{this.state.completed}%</span>
                                </span>
                            </div>
                        )}
                    </Modal>
                    <Modal
                        modalName=""
                        toggleModal={this.toggleModal}
                        className={this.state.zipFileModal ? "visible" : ""}
                        modalWrapperClasses="zipFileModal w-500"
                    >
                        <div className="pa-20 h-100perc text-center">
                            <span className="f-s-20 mb-20">
                                <Translate text="ZIP_IN_PROGRESS" />
                            </span>
                            <span className="wrapper d-block">
                                <Loader width={200} />
                            </span>
                        </div>
                    </Modal>
                    <Modal
                        modalName=""
                        toggleModal={this.toggleModal}
                        className={this.state.downloadAllZipModal ? "visible" : ""}
                        modalWrapperClasses="downloadAllZipModal w-500"
                    >
                        <div className="pa-20 h-100perc text-center">
                            <span className="f-s-20 mb-20">
                                <Translate text="DOWNLOAD_IN_PROGRESS" />
                            </span>
                            <span className="wrapper d-block">
                                <Loader width={200} />
                            </span>
                        </div>
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showDeniedAccessModal"
                        className={this.state.showDeniedAccessModal ? "visible bg-info-3" : ""}
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text={"DENIED_ACCESS"}
                            modalName="showDeniedAccessModal"
                            title="FAILURE"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <div className="actions d-flex justify-content-between pb-20 flex-column">
                        <div className="leftSide d-flex align-items-center mb-10 justify-content-between">
                            <div className="d-flex">
                                {this.state.current.parent_id && (
                                    <button
                                        onClick={this.handleGoBack}
                                        className="pointer d-flex align-items-center goBack mr-20"
                                    >
                                        <BackIcon width={20} />
                                    </button>
                                )}
                                <div className="breadCrumbs d-flex align-items-center">
                                    {parents.length > 1 &&
                                        parents.map((e, index) => {
                                            return e.id === 1 ? (
                                                <>
                                                    <Link to={"/file-manager"} key={e.id} className="lh">
                                                        <CloudFolderIcon width={24} className="lightFill rootIcon" />
                                                    </Link>
                                                    <ArrowDownIcon width={12} className="rotate--90 lightFill mx-5" />
                                                </>
                                            ) : (
                                                <>
                                                    {parents[index + 1] ? (
                                                        <>
                                                            <Link
                                                                className="f-s-14 text-color-font-l"
                                                                key={e.id}
                                                                to={`${e.id}`}
                                                            >
                                                                {e.title}
                                                            </Link>
                                                            <ArrowDownIcon
                                                                width={12}
                                                                className="rotate--90 lightFill mx-5"
                                                            />
                                                        </>
                                                    ) : (
                                                        <span className="f-s-14 text-color-primary" key={e.id}>
                                                            {e.title}
                                                        </span>
                                                    )}
                                                </>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="rightSide d-flex justify-content-end">
                            <div className="controlButtons d-flex">
                                <div className="filters">{this.renderFilters()}</div>
                                <div className="logs">{this.renderLogs()}</div>
                                <div
                                    className="action d-flex align-items-center pointer mr-20"
                                    onClick={() => this.toggleSelect(!toggleSelect)}
                                >
                                    <FileSelectIcon width={18} />
                                    {this.context.screenSize >= 340 && (
                                        <span className="f-s-14 ml-5">
                                            <Translate text={toggleSelect ? "CLEAR" : "SELECT"} />
                                        </span>
                                    )}
                                </div>
                                <div
                                    className="action d-flex align-items-center pointer mr-20"
                                    onClick={() => this.toggleModal("createFolderModal")}
                                >
                                    <NewFolderIcon width={18} />
                                    {this.context.screenSize >= 340 && (
                                        <span className="f-s-14 ml-5">
                                            <Translate text="NEW_FOLDER" />
                                        </span>
                                    )}
                                </div>
                                <div
                                    className="action d-flex align-items-center pointer mr-20"
                                    onClick={() => {
                                        this.setFileUpload(true);
                                        this.toggleModal("fileUploadModal");
                                    }}
                                >
                                    <FileUploadIcon width={18} />
                                    {this.context.screenSize >= 340 && (
                                        <span className="f-s-14 ml-5">
                                            <Translate text="FILE_UPLOAD" />
                                        </span>
                                    )}
                                </div>
                                <div
                                    className="action d-flex align-items-center pointer mr-20"
                                    onClick={() => {
                                        this.setFileUpload(false);
                                        this.toggleModal("fileUploadModal");
                                    }}
                                >
                                    <FolderUploadIcon width={18} />
                                    {this.context.screenSize >= 340 && (
                                        <span className="f-s-14 ml-5">
                                            <Translate text="UPLOAD_FOLDER" />
                                        </span>
                                    )}
                                </div>
                                <div className="action d-flex align-items-center pointer mr-20">
                                    <FileZipIcon width={18} />
                                    {this.context.screenSize >= 340 && (
                                        <span className="f-s-14 ml-5">
                                            <Translate text="BACKUP" />
                                        </span>
                                    )}
                                </div>
                                {this.context.screenSize <= 768 && (
                                    <div
                                        className="action d-flex align-items-center pointer mr-20"
                                        onClick={this.toggleSidebar}
                                    >
                                        <FilterIcon width={18} />
                                        {this.context.screenSize >= 340 && (
                                            <span className="f-s-14 ml-5">
                                                <Translate text="FILTERS" />
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="pb-20 overflow-auto">
                        <Table theads={tableHeads}>
                            {items.map((e: any, index) => (
                                <tr
                                    key={index}
                                    className={`pointer`}
                                    onClick={(ev) => e.type === "folder" && this.folderClick(ev, e.id)}
                                    id={e.id}
                                    onContextMenu={(ev) => {
                                        this.handleContextMenu(ev, multipleSelection ? selectedItemsIds : e.id, e.type);
                                    }}
                                    onDoubleClick={() => this.handleDoubleClick(e.id, e.type, e.title)}
                                    // onTouchStart={(ev) =>
                                    //     this.onTouchStart(ev, multipleSelection ? selectedItemsIds : e.id, e.type)
                                    // }
                                    // onTouchEnd={this.onTouchEnd}
                                >
                                    <td className="isCheckbox f-s-12 d-flex align-items-center w-400">
                                        {toggleSelect && (
                                            <div className="isCheckbox checkBox mr-10">
                                                <CustomCheckbox
                                                    name="selectItem"
                                                    checked={items[index].isSelected}
                                                    handleChange={() => {
                                                        this.selectItem(e.id, !items[index].isSelected, index);
                                                    }}
                                                    index={index}
                                                />
                                            </div>
                                        )}
                                        <div
                                            className="d-flex align-items-center mr-10"
                                            onMouseDown={() => {
                                                setTimeout(() => {
                                                    this.toggleSelect(true);
                                                    this.selectItem(e.id, !items[index].isSelected, index);
                                                }, 1000);
                                            }}
                                        >
                                            {this.handleIcon(e.type)}
                                        </div>
                                        <div>{e.title}</div>
                                    </td>

                                    {
                                        <td>
                                            {e.type !== "docx" && e.type !== "folder" && e.type !== "zip" && (
                                                <span className="mx-15" onClick={() => this.previewFile(e)}>
                                                    <ShowPasswordIcon width={20} className="showPassword pt-5" />
                                                </span>
                                            )}
                                        </td>
                                    }

                                    <td className="f-s-12 w-500">
                                        <Labels labels={e.labels && e.labels} itemID={e.id} />
                                    </td>
                                    <td className="f-s-12" style={{ width: "100px" }}>
                                        {formatBytes(e.file_size)}
                                    </td>
                                    <td className="f-s-12" style={{ width: "120px" }}>
                                        {e.type}
                                    </td>
                                    <td className="f-s-12" style={{ width: "175px" }}>
                                        {parseDateWithoutSeconds(e.updated_at)}
                                    </td>
                                </tr>
                            ))}
                        </Table>
                    </div>
                </div>
            );
        }
    }
}

export default FileManager;
