import React from "react";
import Pagination from "react-js-pagination";

interface IPaginate {
    totalItemsCount: number;
    onChange: (pageNumber) => void;
    activePage: number;
    perPage: number;
    itemsCountPerPage?: number;
    pageRangeDisplayed?: number;
}

const Paginate = ({
    totalItemsCount,
    onChange,
    activePage,
    perPage,
    pageRangeDisplayed,
}: IPaginate) => {
    
    return (
        <div className="d-flex w-100-perc pagination justify-content-center pt-30">
            <Pagination
                totalItemsCount={totalItemsCount}
                onChange={onChange}
                activePage={activePage}
                itemsCountPerPage={perPage}
                pageRangeDisplayed={pageRangeDisplayed}
                prevPageText="-"
                nextPageText="+"
                activeClass="activePage"
            />
        </div>
    );
};

export default Paginate;
