import React, { ReactChildren } from "react";

interface IContextMenu {
    top: number;
    left: number;
    height?: number | string;
    visible: boolean;
    children: ReactChildren | string | JSX.Element | Array<JSX.Element>;
    className?: string;
}

const ContextMenu = ({ top, left, height, visible, children, className }: IContextMenu) => {
    return (
        <ul
            id="contextMenu"
            style={{
                top: `${top}px`,
                left: `${left}px`,
                height: `${height}px`,
                display: visible ? "block" : "none",
            }}
            onClick={(e) => e.stopPropagation()}
            className={className}
        >
            {children}
        </ul>
    );
};

export default ContextMenu;
