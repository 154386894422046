import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Translate from "functions/utilFunctions/translate";
import Table from "devkit/Table/Table";
import ApiService from "services/api.service";
import { StoreService } from "services/store.service";
import { parseDatetime } from "functions/utilFunctions/parsingFunctions";
import { Loader } from "devkit/Loader/Loader";
import Paginate from "devkit/Pagination/Pagination";
import { Devkit } from "oykos-development-devkit";
import { ActivityLogService } from "./ActivityLogService";
import { ScreenContext } from "components/ScreenContext/ScreenContext";
import { CustomForm, CustomInput, CustomDropdown } from "devkit/Form/Form";
import { UtilService } from "services/util.service";

class ActivityLog extends Component<any> {
    state = {
        ...ActivityLogService.state,
        currentPage: this.props.match.params.pageNo,
        form: {
            search: {
                value: "",
                errors: [],
            },
            employee_id: {
                value: null,
                errors: [],
            },
        },
        employees: [],
    };

    static contextType = ScreenContext;

    callApi = () => {
        const token = StoreService.getStoreProperty("token");
        const {
            currentPage,
            perPage,
            form: { search, employee_id },
        } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const filesData: any = { limit: perPage, offset, table: 2 };
        const actionsData: any = { limit: perPage, offset, table: 1 };
        const activityLogFilters = StoreService.getStoreProperty("activityLogFilters");
        // this.setState((prevState: any) => ({
        //     form: {
        //         ...prevState.form,
        //         search: {
        //             // value: 0,
        //             value: activityLogFilters?.search ? activityLogFilters.search : this.state.form.search.value,
        //             errors: [],
        //         },
        //         employee_id: {
        //             // value: 0,
        //             value: activityLogFilters?.employee_id
        //                 ? activityLogFilters.employee_id
        //                 : this.state.form.employee_id.value,
        //             errors: [],
        //         },
        //     },
        // }));
        if (search.value || activityLogFilters?.search) {
            filesData["search"] = activityLogFilters.search ? activityLogFilters.search : this.state.form.search.value;
            actionsData["search"] = activityLogFilters.search
                ? activityLogFilters.search
                : this.state.form.search.value;
        }
        if (employee_id?.value || activityLogFilters?.employee_id) {
            filesData["user_id"] = activityLogFilters?.employee_id
                ? activityLogFilters?.employee_id
                : this.state.form.employee_id.value;
            actionsData["user_id"] = activityLogFilters?.employee_id
                ? activityLogFilters?.employee_id
                : this.state.form.employee_id.value;
        }

        UtilService.handleDebouncing(
            "filesLogsDebouncing",
            () =>
                ApiService.getActivityLogs(filesData, token).then((response) => {
                    if (response.status === "OK") {
                        const logs = response.data.logs;
                        this.setState({
                            filesLog: logs,
                            isLoading: false,
                            totalResults: response.data.total,
                        });
                    }
                }),
            300
        );

        UtilService.handleDebouncing(
            "actionsLogsDebouncing",
            () =>
                ApiService.getActivityLogs(actionsData, token).then((response) => {
                    if (response.status === "OK") {
                        const logs = response.data.logs;
                        this.setState({
                            actionsLog: logs,
                            isLoading: false,
                            totalResults: response.data.total,
                        });
                    }
                }),
            300
        );
    };

    getUpdatedLogs = () => {
        //const user = StoreService.getStoreProperty("user");

        this.callApi();

        if (Devkit && Devkit.FirebaseService && Devkit.FirebaseService.getNotificationsForUser) {
            /*Devkit.FirebaseService.getNotificationsForUser(
                user.id,
                user.role === 2 ? 1 : null,
                (doc) => {
                    if (doc) {
                        this.callApi();
                    }
                });*/
            /*Devkit.FirebaseService.sendNotification(
                'TEST_NOTIFICATION',
                '/klijenti/123',
                'https://google.com',
                false,
                null,
                [6, 2]
            ).then((res) => {
                console.log(res);
            });*/
        }
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        const activityLogFilters = StoreService.getStoreProperty("activityLogFilters");
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                search: {
                    // value: 0,
                    value: activityLogFilters?.search ? activityLogFilters.search : this.state.form.search.value,
                    errors: [],
                },
                employee_id: {
                    // value: 0,
                    value: activityLogFilters?.employee_id
                        ? activityLogFilters.employee_id
                        : this.state.form.employee_id.value,
                    errors: [],
                },
            },
        }));
        this.getUpdatedLogs();
        ApiService.getAllUsers({ limit: 1000, offset: 0 }, token).then((res) => {
            if (res.status === "OK") {
                const employees = res.data.users.map((e) => ({
                    value: e.id,
                    label: `${e.firstName} ${e.lastName}`,
                }));
                this.setState({
                    employees,
                });
            }
        });
    }

    componentWillUnmount() {
        const { form } = this.state;
        StoreService.updateStoreProperty("activityLogFilters", {
            search: form.search.value,
            employee_id: form.employee_id.value,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.form !== this.state.form || prevState.currentPage !== this.state.currentPage) {
            StoreService.updateStoreProperty("activityLogFilters", {
                search: "",
                employee_id: "",
            });
            this.callApi();
        }
    }

    handleRedirect = (link) => {
        if (link !== "") {
            this.props.history.push(`/${link}`);
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return {
                currentPage: nextProps.match.params.pageNo,
                showLoader: true,
            };
        }
        return null;
    }

    handlePageClick = (pageNumber) => {
        const path = this.props.match.path.split("/");
        if (path[1] === "activities") {
            this.props.history.push(`/activities/${pageNumber}`);
        } else {
            this.props.history.push(`/file-activities/${pageNumber}`);
        }
    };

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    deleteLog = (id) => {
        const token = StoreService.getStoreProperty("token");
        ApiService.deleteLog({ id }, token).then((res) => {
            if (res.status === "OK") {
                this.callApi();
            }
        });
    };

    render() {
        const tableHeads = this.state.tableHeads;
        const isLoading = this.state.isLoading;
        const {
            totalResults,
            currentPage,
            perPage,
            form: { search, employee_id },
            employees,
        } = this.state;
        const { screenSize } = this.context;
        if (isLoading) {
            return (
                <div className="loaderContainer">
                    <Loader width={200} />
                </div>
            );
        } else {
            return (
                <div className="activityLog">
                    <div
                        className={`${
                            screenSize <= 576 ? "mobFooterOverview" : ""
                        } tabMenu d-flex border-b-1 padding-0`}
                    >
                        <div className="navigation d-flex padding-0">
                            <NavLink
                                to={`/activities/${this.state.currentPage}`}
                                className={`${
                                    screenSize <= 576 ? "footerNavBtn" : ""
                                } action-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0`}
                                style={{
                                    textDecoration: "none",
                                }}
                                activeClassName="active"
                            >
                                <Translate text={"ACTIONS"} />
                            </NavLink>
                            <NavLink
                                to={`/file-activities/${this.state.currentPage}`}
                                style={{
                                    textDecoration: "none",
                                }}
                                className={`${
                                    screenSize <= 576 ? "footerNavBtn" : ""
                                } action-title d-flex align-items-center justify-content-center tab border-radius-br-0 border-radius-bl-0 
                                
                            }`}
                                activeClassName="active"
                            >
                                <Translate text={"FILES"} />
                            </NavLink>
                        </div>
                    </div>
                    <CustomForm className={"row mt-10"}>
                        <div className="col-md-3 mt-10">
                            <CustomInput
                                value={search.value}
                                handleInput={this.handleInput}
                                name="search"
                                type="text"
                                label="Search"
                            />
                        </div>
                        <div className={"col-md-3 mt-10"}>
                            <CustomDropdown
                                data={employees}
                                name="employee_id"
                                handleChange={this.handleInput}
                                label="EMPLOYEES"
                                value={employees.find((c: any) => c.value === employee_id.value) || null}
                                placeholder={<Translate text="CHOOSE_EMPLOYEE" />}
                            />
                        </div>
                    </CustomForm>
                    <div className="table-container margin-t-60 overflow-auto">
                        <Table theads={tableHeads} theadsClassname="customThead">
                            {this.props.files
                                ? this.state.filesLog.map((e: any, i) => (
                                      <tr
                                          className="pointer f-s-14"
                                          onClick={() => this.handleRedirect(e.link)}
                                          key={i}
                                      >
                                          <td>{e.item}</td>
                                          <td className="">{e.user}</td>
                                          <td className="">{parseDatetime(e.updated_at)}</td>
                                          <td className="">
                                              <Translate text={e.action} />
                                          </td>
                                          <td
                                              onClick={(event) => {
                                                  event.stopPropagation();
                                                  this.deleteLog(e.id);
                                              }}
                                          >
                                              <i className="fas fa-trash trash-icon padding-10 border-1 pointer border-radius-6 w-35" />
                                          </td>
                                      </tr>
                                  ))
                                : this.state.actionsLog.map((e: any, i) => (
                                      <tr
                                          className="pointer f-s-14"
                                          onClick={() => this.handleRedirect(e.link)}
                                          key={i}
                                      >
                                          <td>{e.item}</td>
                                          <td className="">{e.user}</td>
                                          <td className="">{parseDatetime(e.updated_at)}</td>
                                          <td className="">
                                              <Translate text={e.action} />
                                          </td>
                                          <td
                                              onClick={(event) => {
                                                  event.stopPropagation();
                                                  this.deleteLog(e.id);
                                              }}
                                          >
                                              <i className="fas fa-trash trash-icon padding-10 border-1 pointer border-radius-6 w-35" />
                                          </td>
                                      </tr>
                                  ))}
                        </Table>
                    </div>
                    {!!this.state.totalResults && this.state.totalResults > 0 && (
                        <div className="pagination-container d-flex justify-content-center">
                            <Paginate
                                totalItemsCount={totalResults}
                                onChange={this.handlePageClick}
                                activePage={parseInt(currentPage)}
                                perPage={perPage}
                                pageRangeDisplayed={5}
                            />
                        </div>
                    )}
                </div>
            );
        }
    }
}

export default ActivityLog;
