/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, NavLink } from "react-router-dom";
import React, { Component } from "react";
import Translate from "../../functions/utilFunctions/translate";
import {
    ActivitiesIcon,
    CalendarIcon,
    ChatIcon,
    ClientsIcon,
    EmployeesIcon,
    FilesIcon,
    ProfileIcon,
    TasksIcon,
    GroupsIcon,
} from "../Icons/Icons";
import { StoreService } from "services/store.service";
import { UtilService } from "../../services/util.service";
import { ScreenContext } from "components/ScreenContext/ScreenContext";

interface Props {
    day: string;
    open?: boolean;
    toggleSidebar?: () => void;
}

interface State {
    day: string;
}

class Sidebar extends Component<Props, State> {
    state = {
        day: this.props.day,
    };

    static contextType = ScreenContext;

    navRef = React.createRef<HTMLDivElement>();
    handleSidebarHover = () => {
        const el = this.navRef.current;
        let timeout: any = null;
        if (el) {
            el.onmouseenter = () => {
                timeout = setTimeout(() => {
                    el.classList.add("open");
                }, 1000);
            };

            el.onmouseleave = () => {
                el.classList.remove("open");
                clearTimeout(timeout);
            };
        }
    };

    // static getDerivedStateFromProps(props, state) {
    //     if (props.day !== state.day) {
    //         return {
    //             day: props.day,
    //         };
    //     }
    //     return null;
    // }

    componentDidMount() {
        console.log(this.context);
        if (!this.context.isMobile) {
            this.handleSidebarHover();
        }

        UtilService.handleDebouncing("uniqueKey", () => {}, 300);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open && window.innerWidth > 960) {
            this.handleSidebarHover();
        }
        if (prevProps.open !== this.props.open && window.innerWidth <= 960) {
        }
    }

    render() {
        const user = StoreService.getStoreProperty("user");
        return (
            <nav ref={this.navRef} className={`${this.props.open ? "open" : ""} sidebar-nav`}>
                <div className="logoContainer p-r d-flex justify-content-center align-items-center py-10">
                    <Link to="/" className="d-flex align-items-center">
                        <img src="/images/Terra_development_logo_V-white.png" alt="terra-logo" />
                    </Link>
                </div>
                <div className="navContainer d-flex flex-column justify-content-between">
                    <ul>
                        <li
                            onClick={() => {
                                this.props.toggleSidebar && this.props.toggleSidebar();
                            }}
                        >
                            <NavLink
                                exact
                                to={`/calendar/${this.state.day}`}
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center pl-20"
                                activeClassName="active"
                            >
                                <span className="icon">
                                    <CalendarIcon width={24} className="fill" />
                                </span>
                                <span className="label">
                                    <Translate text="DASHBOARD" />
                                </span>
                            </NavLink>
                        </li>
                        <li
                            onClick={() => {
                                this.props.toggleSidebar && this.props.toggleSidebar();
                            }}
                        >
                            <NavLink
                                to="/file-manager"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center pl-20"
                                activeClassName="active"
                            >
                                <span className="icon">
                                    <FilesIcon className="fill" width={24} />
                                </span>
                                <span className="label">
                                    <Translate text="FILES" />
                                </span>
                            </NavLink>
                        </li>
                        <li
                            onClick={() => {
                                this.props.toggleSidebar && this.props.toggleSidebar();
                            }}
                        >
                            <NavLink
                                to="/clients/1"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center pl-20"
                                activeClassName="active"
                                isActive={(match, location) => {
                                    if (
                                        match ||
                                        location.pathname.includes("/clientDetails/") ||
                                        location.pathname.includes("/new-client")
                                    ) {
                                        return true;
                                    }
                                    return false;
                                }}
                            >
                                <span className="icon">
                                    <ClientsIcon className="fill" width={24} />
                                </span>
                                <span className="label">
                                    <Translate text="CLIENTS" />
                                </span>
                            </NavLink>
                        </li>
                        <li
                            onClick={() => {
                                this.props.toggleSidebar && this.props.toggleSidebar();
                            }}
                        >
                            <NavLink
                                to="/actions/1"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center pl-20"
                                activeClassName="active"
                                isActive={(match, location) => {
                                    if (
                                        match?.isExact ||
                                        location.pathname.includes("/templates/") ||
                                        location.pathname.includes("/action/") ||
                                        location.pathname.includes("/template/") ||
                                        location.pathname.includes("/new-action") ||
                                        location.pathname.includes("/new-template")
                                    ) {
                                        return true;
                                    }
                                    return false;
                                }}
                            >
                                <span className="icon">
                                    <TasksIcon className="fill" width={24} />
                                </span>
                                <span className="label">
                                    <Translate text="actions" />
                                </span>
                            </NavLink>
                        </li>
                        {user.role === 3 && (
                            <li
                                onClick={() => {
                                    this.props.toggleSidebar && this.props.toggleSidebar();
                                }}
                            >
                                <NavLink
                                    to="/accounts/1"
                                    className="text-color-white f-s-16 h-100-perc d-flex align-items-center pl-20"
                                    activeClassName="active"
                                    isActive={(match, location) => {
                                        if (
                                            match?.isExact ||
                                            location.pathname.includes("/accountsDetails/") ||
                                            location.pathname.includes("/salaries/") ||
                                            location.pathname.includes("/time-track/") ||
                                            location.pathname.includes("/new-account")
                                        ) {
                                            return true;
                                        }
                                        return false;
                                    }}
                                >
                                    <span className="icon">
                                        <EmployeesIcon className="fill" width={24} />
                                    </span>
                                    <span className="label">
                                        <Translate text="EMPLOYEES" />
                                    </span>
                                </NavLink>
                            </li>
                        )}
                        {user.role === 3 && (
                            <li
                                onClick={() => {
                                    this.props.toggleSidebar && this.props.toggleSidebar();
                                }}
                            >
                                <NavLink
                                    to="/groups"
                                    className="text-color-white f-s-16 h-100-perc d-flex align-items-center pl-20"
                                    activeClassName="active"
                                >
                                    <span className="icon">
                                        <GroupsIcon className="fill" width={24} />
                                    </span>
                                    <span className="label">
                                        <Translate text="GROUPS" />
                                    </span>
                                </NavLink>
                            </li>
                        )}
                        {user.role === 3 && (
                            <li
                                onClick={() => {
                                    this.props.toggleSidebar && this.props.toggleSidebar();
                                }}
                            >
                                <NavLink
                                    to="/activities/1"
                                    className="text-color-white f-s-16 h-100-perc d-flex align-items-center pl-20"
                                    activeClassName="active"
                                    isActive={(match, location) => {
                                        if (match?.isExact || location.pathname.includes("/file-activities")) {
                                            return true;
                                        }
                                        return false;
                                    }}
                                >
                                    <span className="icon">
                                        <ActivitiesIcon className="fill" width={24} />
                                    </span>
                                    <span className="label">
                                        <Translate text="ACTIVITY_LOG" />
                                    </span>
                                </NavLink>
                            </li>
                        )}

                        <li
                            onClick={() => {
                                this.props.toggleSidebar && this.props.toggleSidebar();
                            }}
                        >
                            <NavLink
                                to="/chat"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center pl-20"
                                activeClassName="active"
                                isActive={(match, location) => {
                                    if (location.pathname === "/chat") {
                                        return true;
                                    }

                                    return false;
                                }}
                            >
                                <span className="icon">
                                    <ChatIcon className="fill" width={24} />
                                </span>
                                <span className="label">
                                    <Translate text="chat" />
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                    <ul>
                        <li
                            onClick={() => {
                                this.props.toggleSidebar && this.props.toggleSidebar();
                            }}
                        >
                            <NavLink
                                to="/personal-account"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center pl-20"
                                activeClassName="active"
                            >
                                <span className="icon">
                                    <ProfileIcon className="fill" width={24} />
                                </span>
                                <span className="label">
                                    <Translate text="PROFILE" />
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Sidebar;
