import "./i18n/i18n";

import * as serviceWorker from "./serviceWorker";

import App from "./pages/App";
import React from "react";
import ReactDOM from "react-dom";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import i18next from "i18next";
import sr from "javascript-time-ago/locale/sr";
import srLatn from "javascript-time-ago/locale/sr-Latn";
import { ScreenContextProvider } from "components/ScreenContext/ScreenContext";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(srLatn);

TimeAgo.addLocale(sr);

let lang = localStorage.getItem("lang");

if (!lang) {
    localStorage.setItem("lang", "en");
    lang = "en";
}

i18next.changeLanguage(lang);

ReactDOM.render(
    <React.StrictMode>
        <ScreenContextProvider>
            <App />
        </ScreenContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
