import axios from "axios";
import { Constants } from "services/constants.service";
import { StoreService } from "services/store.service";

export const ActivityLogService = {
    state: {
        tableHeads: [
            {
                value: "NAME",
                sortable: false,
            },
            {
                value: "CHANGED_BY",
                sortable: false,
            },
            {
                value: "CHANGED_AT",
                sortable: false,
            },
            {
                value: "TYPE_OF_CHANGE",
                sortable: false,
            },
            { value: "" },
        ],
        filesLog: [
            {
                user: "",
                updated_at: "",
                item: "",
                action: "",
                log_details: [],
                link: "",
            },
        ],
        actionsLog: [
            {
                user: "",
                updated_at: "",
                item: "",
                action: "",
                log_details: [],
                link: "",
            },
        ],
        isLoading: true,
        totalResults: 0,
        totalResultsFile: 0,
        perPage: 20,
    },
    functions: {
        getLog: async (currentPage) => {
            const token = StoreService.getStoreProperty("token");
            const offset = (parseInt(currentPage) - 1) * 20;

            const activityLog = await axios({
                url: `${Constants.SERVER}/logs/getLogs`,
                method: "POST",
                data: { table: 2, limit: 20, offset: offset },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.data)
                .catch((e) => e);

            return activityLog.data;
        },
        getFileLog: async (currentPage) => {
            const token = StoreService.getStoreProperty("token");
            const offset = (parseInt(currentPage) - 1) * 20;

            const fileLog = await axios({
                url: `${Constants.SERVER}/logs/getLogs`,
                method: "POST",
                data: { table: 1, limit: 20, offset: offset },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.data)
                .catch((e) => e);

            return fileLog.data;
        },
    },
};
