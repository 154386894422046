import React, { useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Translate from "../../../functions/utilFunctions/translate";

interface IHandleInputProps {
    value: string | number;
    errors: Array<string>;
}

export interface IData {
    value: string | number;
    label: string | JSX.Element;
}

interface ICustomDropdown {
    data: Array<IData>;
    name: string;
    handleChange: (
        name: string,
        data: IHandleInputProps,
        index?: number,
        parentIndex?: number,
        multiple?: boolean
    ) => void;
    placeholder?: string | JSX.Element;
    index?: number;
    parentIndex?: number;
    value?: IData | Array<IData> | null;
    className?: string;
    required?: boolean;
    errors?: Array<string>;
    disabled?: boolean;
    multiple?: boolean;
    label?: string;
    labelClass?: string;
    filter?: boolean;
    creatable?: boolean;
    isOpen?: boolean;
}

const _cdd: React.FC<ICustomDropdown> = ({
    data,
    placeholder,
    name,
    handleChange,
    value,
    index,
    parentIndex,
    className,
    errors,
    disabled,
    label,
    labelClass,
    multiple,
    required,
    filter,
    creatable,
    isOpen,
}) => {
    const handleValueChange = (n, v, i, parentIndex, m) => {
        handleChange(n, { value: v, errors: [] }, i, parentIndex, m);
    };

    const [showLabel, setShowLabel] = useState(false);

    const handleLabelChange = (showLabel) => {
        setShowLabel(showLabel);
    };

    return (
        <div
            className={`customDropdown p-r ${
                (!Array.isArray(value) && value?.value === 0) || (Array.isArray(value) && value.length > 0)
                    ? "noValue"
                    : ""
            } ${disabled ? "disabled" : ""} ${multiple ? "multiple" : ""} ${filter ? "filter" : ""}
            `}
        >
            {creatable ? (
                <CreatableSelect
                    className={`basic-single ${className ? className : ""} ${disabled ? "disabled" : ""}`}
                    classNamePrefix="select"
                    isSearchable={true}
                    name="color"
                    options={data}
                    noDataRenderer={"Nema rezultata"}
                    onChange={(e: any) => {
                        const value = !multiple && e && e.value ? e.value : e;

                        handleValueChange(name, value, index, parentIndex, multiple);
                        handleLabelChange(false);
                    }}
                    // onInputChange={(newValue: any) => {
                    //     console.log(typeof newValue);
                    // }}
                    placeholder={placeholder}
                    menuPlacement="auto"
                    value={value}
                    isDisabled={disabled}
                    onFocus={() => handleLabelChange(true)}
                    onBlur={() => handleLabelChange(false)}
                    isMulti={multiple}
                    menuIsOpen={isOpen}
                />
            ) : (
                <Select
                    className={`basic-single ${className ? className : ""} ${disabled ? "disabled" : ""}`}
                    classNamePrefix="select"
                    isSearchable={true}
                    name="color"
                    options={data}
                    noDataRenderer={"Nema rezultata"}
                    onChange={(e: any) => {
                        const value = !multiple && e && e.value ? e.value : e;

                        handleValueChange(name, value, index, parentIndex, multiple);
                        handleLabelChange(false);
                    }}
                    placeholder={placeholder}
                    menuPlacement="auto"
                    value={value}
                    isDisabled={disabled}
                    onFocus={() => handleLabelChange(true)}
                    onBlur={() => handleLabelChange(false)}
                    isMulti={multiple}
                    menuIsOpen={isOpen}
                    required={required}
                />
            )}
            {label && (
                <label
                    className={`${labelClass ? labelClass : "f-s-16"} ${
                        (!Array.isArray(value) && value?.value !== 0) ||
                        (Array.isArray(value) && value.length > 0) ||
                        showLabel
                            ? "top"
                            : ""
                    } ${disabled ? "disabled" : ""}`}
                >
                    <Translate text={label} />
                </label>
            )}
            {errors?.map((e, i) => (
                <span className="error f-s-12 is-warning danger p-10" key={i}>
                    <Translate text={e} />
                </span>
            ))}
        </div>
    );
};

export default _cdd;
