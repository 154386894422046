import React, { Component } from "react";
import { Translate } from "../../functions/utilFunctions/translate";
import { CloseIcon } from "../../components/Icons/Icons";
import { parseDatetime } from "functions/utilFunctions/parsingFunctions";

interface IProps {
    opened: boolean;
    onClose: () => void;
    deleteLog: (id) => void;
    sidebarClasses?: string;
    wrapperClasses?: string;
    titleMargin?: string;
    items?: Array<any>;
}

export default class LogsSidebar extends Component<IProps> {
    render() {
        const { opened, onClose, deleteLog, sidebarClasses, wrapperClasses, titleMargin, items } = this.props;

        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
                className={`${wrapperClasses ? wrapperClasses : ""} ${opened ? "open" : ""} logs-sidebar-wrapper `}
            >
                <div
                    className={`${sidebarClasses ? sidebarClasses : ""} ${
                        opened ? "open" : ""
                    } logs-sidebar d-flex flex-column form-box-shadow `}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={`d-flex justify-content-between ${titleMargin ? titleMargin : ""} `}>
                        <h1>
                            <Translate text="LOGS"></Translate>
                        </h1>
                        <div
                            onClick={onClose}
                            className="d-flex align-items-center justify-content-center pointer close-icon"
                        >
                            <CloseIcon width={18} className=""></CloseIcon>
                        </div>
                    </div>
                    <div className="main h-100-perc d-flex flex-column">
                        {/* <div className="logsBody mt-20">{this.props.children}</div> */}
                        {items &&
                            items[0] &&
                            items.map((e) => (
                                <div className="log w-100perc d-flex justify-content-between border-radius-8 my-10 ">
                                    <div className="d-flex flex-column ">
                                        <span className="d-flex justify-content-start f-s-13">
                                            <Translate text="EMPLOYEE" />:<span className="ml-5 bold">{e.user}</span>
                                        </span>
                                        <span className="d-flex justify-content-start aling-items-center ">
                                            <span className="f-s-13">
                                                <Translate text={e.action} />
                                            </span>
                                            :<span className="ml-5 bold mt-2">{e.item}</span>
                                        </span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="d-flex justify-content-start">
                                            <span className="">{parseDatetime(e.updated_at)}</span>
                                        </span>
                                        <i
                                            onClick={() => deleteLog(e.id)}
                                            className="fas fa-trash trash-icon padding-5 border-1 pointer border-radius-6 w-23 d-flex align-self-end mt-5"
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        );
    }
}
