import React from "react";
import { isEmpty } from "../CustomInput/inputValidations/InputValidations";
import Translate from "../../../functions/utilFunctions/translate";
import { useFormContext } from "../CustomForm/CustomForm";
// import { FileUploadIcon } from "components/Icons/FileManagerIcons";
import { SendArrow } from "components/Icons/Icons";

interface IHandleInputProps {
    value: string | number;
    errors: Array<string>;
}

interface ICustomInput {
    name: string;
    value: string;
    handleInput: (name: string, data: IHandleInputProps) => void;
    required?: boolean;
    errors?: Array<string>;
    className?: string;
    classNameInputC?: string;
    label?: string;
    labelClass?: string;
    placeholder?: string;
    disabled?: boolean;
    icon?: boolean;
    handleSend?: (e) => void;
    handleFile?: any;
    autoGrowTextarea?: boolean;
}

const _cta: React.FC<ICustomInput> = ({
    value,
    label,
    labelClass,
    placeholder,
    handleInput,
    name,
    className,
    classNameInputC,
    required,
    errors,
    icon,
    disabled,
    handleSend,
    autoGrowTextarea,
}: // handleFile,
ICustomInput) => {
    const isForm = useFormContext();
    if (!isForm) {
        throw Error("You can't use CustomTextArea outside of CustomForm component!");
    }

    const handleCounter = (val) => {
        const element = document.getElementById(`${name}`);
        if (element) {
            element.innerHTML = val;
        }
    };

    const validateInput = () => {
        handleInput(name, {
            value: value.replace(/\s{2,}/g, " ").trim(),
            errors: [],
        });
        if (required && isEmpty(value)) {
            handleInput(name, { value, errors: ["INPUT_REQUIRED"] });
        }
    };

    const generateIcon = () => {
        if (icon) {
            switch (name) {
                case "currentMessage":
                    return (
                        <div className="d-flex align-items-center">
                            <span onClick={handleSend}>
                                <SendArrow width={20} className="mr-20 iconTextArea pointer" />
                            </span>
                            {/* <span onClick={() => handleFile(name)}>
                                <PaperclipIcon
                                    width={20}
                                    className="iconTextArea pointer"
                                />
                            </span> */}
                        </div>
                    );

                default:
                    break;
            }
        }
    };

    const autoGrow = (e) => {
        const textarea = e.target;
        textarea.style.height = "20px";
        textarea.style.maxHeight = "90px";
        textarea.style.height = textarea.scrollHeight + "px";
    };

    return (
        <div className={`textAreaComponent inputComponent ${classNameInputC && classNameInputC}`}>
            <div className={`inputWrapper textArea  ${value ? "hasText" : ""} ${className ? className : ""}`}>
                {" "}
                {label && (
                    <label className={`${labelClass ? labelClass : ""}`}>
                        <Translate text={label} />
                    </label>
                )}
                <textarea
                    placeholder={placeholder || ""}
                    value={value}
                    onChange={(e) => {
                        handleCounter(e.target.value.length);
                        handleInput(name, {
                            value: e.target.value,
                            errors: [],
                        });
                    }}
                    onKeyPress={autoGrowTextarea ? autoGrow : () => {}}
                    onKeyUp={autoGrowTextarea ? autoGrow : () => {}}
                    onFocus={autoGrowTextarea ? autoGrow : () => {}}
                    onBlur={validateInput}
                    className="p-10 d-flex flex-column non-resizable w-100perc f-s-13"
                    disabled={disabled}
                />
                {
                    // console.log(icon)
                    generateIcon()
                }
                {/* <span className="count f-s-10 px-5">
                    <Translate text={"CHARACTER_COUNT"} />:{" "}
                    <b id={`${name}`}>{0}</b>
                </span> */}
                {errors?.map((e, i) => (
                    <span className="error f-s-13 is-warning danger mt-5" key={i}>
                        <Translate text={e} />
                    </span>
                ))}
            </div>
        </div>
    );
};

export default _cta;
