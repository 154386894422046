import Table from "devkit/Table/Table";
import Translate from "functions/utilFunctions/translate";
import React, { Component } from "react";
import { parseDate, parseDateForMySQL } from "functions/utilFunctions/parsingFunctions";
import { StoreService } from "services/store.service";
import ApiService from "services/api.service";
import {
    CustomButton,
    CustomDatePicker,
    CustomDropdown,
    CustomForm,
    CustomInput,
    CustomTextArea,
} from "devkit/Form/Form";
import { NavLink } from "react-router-dom";
import { UtilService } from "services/util.service";
import Paginate from "devkit/Pagination/Pagination";
import Modal from "devkit/Modal/Modal";
import { ScreenContext } from "components/ScreenContext/ScreenContext";
import { Loader } from "devkit/Loader/Loader";
import { ModalContent } from "devkit/Modal/ModalContent";

export class SalariesForAllEmployees extends Component<any, any> {
    state = {
        search: {
            value: "",
            errors: [],
        },

        salariesTableHeads: [
            {
                value: "FULL_NAME",
                sortable: true,
                sortableType: "string",
                sortableName: "full_name_sorter",
            },
            {
                value: "ENTRY_DATE",
                sortable: true,
                sortableType: "date",
                sortableName: "date",
            },
            {
                value: "TYPE",
                sortableType: "string",
                sortableName: "type",
            },
            {
                value: "AMOUNT",
                sortable: true,
                sortableType: "string",
                sortableName: "amount",
            },
            {
                value: "DETAILS",
                sortableType: "string",
                sortableName: "details",
            },
            // { value: "" },
        ],
        addSalary: {
            id: {
                value: "",
                errors: [],
            },
            type: {
                value: 1,
                errors: [],
            },
            amount: {
                value: 0,
                errors: [],
            },
            employee: {
                value: 0,
                errors: [],
            },
            entry_date: {
                value: new Date(),
                errors: [],
            },
            description: {
                value: "",
                errors: [],
            },
        },
        salary_types: [
            { value: 1, label: <Translate text="MONTHLY_SALARY" /> },
            { value: 2, label: <Translate text="PART_TIME_SALARY" /> },
        ] as any,
        showAddSalaryModal: false,
        addSalarySuccess: false,
        addSalaryFailure: false,
        showDeleteModal: false,
        showSuccessModal: false,
        showDeleteFailure: false,
        updateSalarySuccess: false,
        updateSalaryFailure: false,
        users: [] as any,
        salaries: [] as any,
        totalResults: 0,
        currentPage: this.props.match.params.pageNo,
        perPage: 20,
        sorterDirection: "",
        sorterName: "",
        showLoader: true,
        editSalary: false,
        employeeID: this.props.employeeID,
        deleteID: "",
    };

    static contextType = ScreenContext;

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const data = { limit: perPage, offset: offset };
        const salariesFilters = StoreService.getStoreProperty("salariesFilters");
        this.setState(
            {
                search: {
                    value: salariesFilters?.search ? salariesFilters.search : this.state.search.value,
                    errors: [],
                },
            }
            // () => {
            //     if (this.state.search.value) {
            //         data["search"] = this.state.search.value;
            //     }
            //     ApiService.getExpenses(data, token).then((res) => {
            //         if (res.success) {
            //             const salaries = res.data;
            //             const totalResults = res.total;
            //             this.setState(() => ({
            //                 salaries,
            //                 totalResults,
            //                 showLoader: false,
            //             }));
            //         }
            //     });
            // }
        );
        const { isMobile } = this.context;
        if (isMobile) {
            this.getSalaries(data);
        }

        ApiService.getAllUsers({ limit: 1000, offset: 0 }, token).then((res) => {
            if (res.status === "OK") {
                const users = res.data.users.map((el) => ({
                    value: el.id,
                    label: el.firstName + " " + el.lastName,
                }));
                users.unshift({
                    label: <Translate text="CHOOSE_EMPLOYEE" />,
                    value: 0,
                });
                this.setState({
                    users,
                });
            }
        });
    }

    componentWillUnmount() {
        const { search } = this.state;
        StoreService.updateStoreProperty("salariesFilters", {
            search: search.value,
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return {
                currentPage: nextProps.match.params.pageNo,
                showLoader: false,
            };
        }
        return null;
    }

    handlePageClick = (pageNumber) => {
        this.props.history.push(`/salaries/${pageNumber}`);
    };

    componentDidUpdate(prevProps, prevState) {
        const { isMobile } = this.context;
        const { currentPage, perPage } = this.state;

        if (!isMobile && prevState.search !== this.state.search) {
            let data = {};
            if (!isMobile && (prevState.search !== this.state.search || this.state.search.value !== "")) {
                data = { full_name: this.state.search.value, limit: perPage, offset: 0 };
                UtilService.handleDebouncing("accountsDebouncing", () => this.getSalaries(data), 500);
            }
        }

        if (prevState.currentPage !== this.state.currentPage) {
            const offset = (parseInt(currentPage) - 1) * perPage;
            let data = { limit: perPage, offset };
            if (this.state.search.value) {
                data["full_name"] = this.state.search.value;
            }

            this.getSalaries(data);
        }
    }

    handleInput = (name, data) => {
        if (name === "search") {
            this.setState({
                search: {
                    value: data.value,
                    errors: data.errors,
                },
            });
        } else {
            this.setState((prevState: any) => ({
                addSalary: {
                    ...prevState.addSalary,
                    [name]: {
                        value: data.value,
                        errors: data.errors,
                    },
                },
            }));
        }
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleSort = (sortableName) => {
        let { currentPage, perPage, sorterDirection } = this.state;
        if (sorterDirection === "asc") {
            sorterDirection = "desc";
        } else {
            sorterDirection = "asc";
        }
        const token = StoreService.getStoreProperty("token");
        const offset = (parseInt(currentPage) - 1) * perPage;
        let data = { limit: perPage, offset };
        data[sortableName] = sorterDirection;

        ApiService.getExpenses(data, token).then((res) => {
            if (res.success) {
                const salaries = res.data;
                const totalResults = res.total;
                this.setState({
                    salaries,
                    totalResults,
                    sorterDirection,
                    sorterName: sortableName,
                    showLoader: false,
                });
            }
        });
    };
    // addSalary = () => {
    //     const { amount, entry_date, type, employee, description } = this.state.addSalary;
    //     const token = StoreService.getStoreProperty("token");
    //     const form = { amount, type };
    //     const errors = {};
    //     Object.keys(form).forEach((e) => {
    //         if (!form[e].value) {
    //             errors[e] = {
    //                 value: form[e].value,
    //                 errors: ["INPUT_REQUIRED"],
    //             };
    //         }
    //     });

    //     if (Object.keys(errors).length !== 0) {
    //         this.setState((prevState: any) => ({
    //             salary: {
    //                 ...prevState.salary,
    //                 ...errors,
    //             },
    //         }));
    //     } else {
    //         ApiService.createSalary(
    //             {
    //                 employee_id: employee.value,
    //                 amount: amount.value,
    //                 entry_date: parseDateForMySQL(entry_date.value),
    //                 type: type.value,
    //             },
    //             token
    //         ).then((res) => {
    //             if (res.status === "OK") {
    //                 this.setState((prevState: any) => ({
    //                     salaries: [...prevState.salaries, res.data],
    //                     salary: {
    //                         id: {
    //                             value: "",
    //                             errors: [],
    //                         },
    //                         type: {
    //                             value: 1,
    //                             errors: [],
    //                         },
    //                         employee: {
    //                             value: 1,
    //                             errors: [],
    //                         },
    //                         amount: {
    //                             value: 0,
    //                             errors: [],
    //                         },
    //                         entry_date: {
    //                             value: new Date(),
    //                             errors: [],
    //                         },
    //                     },
    //                     showAddSalaryModal: false,
    //                     addSalarySuccess: true,
    //                 }));
    //             } else {
    //                 this.setState({
    //                     showAddSalaryModal: false,
    //                     addSalaryFailure: true,
    //                 });
    //             }
    //         });
    //     }
    // };
    addSalary = () => {
        const { editSalary, employeeID } = this.state;
        const { amount, entry_date, type, id, employee, description } = this.state.addSalary;
        const token = StoreService.getStoreProperty("token");
        const form = { amount, type };
        const errors = {};

        Object.keys(form).forEach((e) => {
            if (!form[e].value) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
        });

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                salary: {
                    ...prevState.salary,
                    ...errors,
                },
            }));
        } else {
            if (!editSalary) {
                ApiService.createSalary(
                    {
                        employee_id: employee.value,
                        amount: amount.value,
                        entry_date: parseDateForMySQL(entry_date.value),
                        type: type.value,
                        description: description.value,
                    },
                    token
                ).then((res) => {
                    if (res.status === "OK") {
                        this.setState((prevState: any) => ({
                            salaries: [...prevState.salaries, res.data],
                            addSalary: {
                                id: {
                                    value: "",
                                    errors: [],
                                },
                                employee: {
                                    value: 0,
                                    errors: [],
                                },
                                type: {
                                    value: 1,
                                    errors: [],
                                },
                                amount: {
                                    value: 0,
                                    errors: [],
                                },
                                entry_date: {
                                    value: new Date(),
                                    errors: [],
                                },
                                description: {
                                    value: "",
                                    errors: [],
                                },
                            },
                            showAddSalaryModal: false,
                            addSalarySuccess: true,
                        }));
                    } else {
                        this.setState({
                            showAddSalaryModal: false,
                            addSalaryFailure: true,
                        });
                    }
                });
            } else {
                ApiService.updateSalary(
                    {
                        employee_id: employee.value,
                        amount: amount.value,
                        entry_date: parseDateForMySQL(entry_date.value),
                        description: description.value,
                        type: type.value,
                        id: id.value,
                    },
                    token
                ).then((res) => {
                    if (res.status === "OK") {
                        ApiService.getExpenses({ id: employeeID }, token).then((res) => {
                            if (res.success) {
                                const salaries = res.data;
                                this.setState(() => ({
                                    salaries,
                                    showAddSalaryModal: false,
                                    updateSalarySuccess: true,
                                }));
                            }
                        });
                    } else {
                        this.setState({
                            showAddSalaryModal: false,
                            updateSalaryFailure: true,
                        });
                    }
                });
            }
        }
    };

    submitSearch = (e) => {
        e.preventDefault();
        const { search, perPage } = this.state;

        const data = { full_name: search.value, limit: perPage, offset: 0 };
        this.getSalaries(data);
    };

    getSalaries = (data) => {
        const token = StoreService.getStoreProperty("token");
        const { sorterDirection, sorterName } = this.state;

        if (sorterName || sorterDirection) {
            data[sorterName] = sorterDirection;
        }

        ApiService.getExpenses(data, token).then((res) => {
            if (res.success) {
                const salaries = res.data;
                const totalResults = res.total;
                this.setState({
                    salaries,
                    totalResults,
                    showLoader: false,
                });
            }
        });
    };
    handleEdit = (e) => {
        this.setState({
            addSalary: {
                id: {
                    value: e.id,
                    errors: [],
                },
                type: {
                    value: e.type,
                    errors: [],
                },
                amount: {
                    value: e.amount,
                    errors: [],
                },
                entry_date: {
                    value: new Date(e.entry_date),
                    errors: [],
                },
                employee: {
                    value: e.employee_id,
                    errors: [],
                },
                description: {
                    value: e.description ? e.description : "",
                    errors: [],
                },
            },
            showAddSalaryModal: true,
            editSalary: true,
        });
    };
    handleNewSalary = () => {
        this.setState({
            addSalary: {
                id: {
                    value: "",
                    errors: [],
                },
                type: {
                    value: 1,
                    errors: [],
                },
                amount: {
                    value: "",
                    errors: [],
                },
                entry_date: {
                    value: new Date(),
                    errors: [],
                },
                employee: {
                    value: 0,
                    errors: [],
                },
                description: {
                    value: "",
                    errors: [],
                },
            },
            showAddSalaryModal: true,
            editSalary: false,
        });
    };
    deleteSalary = () => {
        const id = this.state.deleteID;
        const token = StoreService.getStoreProperty("token");
        const employeeId = this.state.employeeID;

        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            deleteID: "",
        });
        ApiService.deleteSalary({ id }, token).then((res) => {
            if (res.status === "OK") {
                ApiService.getExpenses({ id: employeeId }, token).then((res) => {
                    if (res.success) {
                        const salaries = res.data;
                        this.setState(() => ({
                            salaries,
                            showSuccessModal: true,
                        }));
                    }
                });
            }
        });
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };
    cloneSalary = (data) => {
        this.setState({
            addSalary: {
                id: {
                    value: "",
                    errors: [],
                },
                type: {
                    value: data.type,
                    errors: [],
                },
                amount: {
                    value: data.amount,
                    errors: [],
                },
                entry_date: {
                    value: new Date(),
                    errors: [],
                },
                employee: {
                    value: data.employee_id,
                    errors: [],
                },
                description: {
                    value: data.description ? data.description : "",
                    errors: [],
                },
            },
            showAddSalaryModal: true,
            editSalary: false,
        });
    };
    render() {
        const { addSalary, salaries, totalResults, currentPage, perPage, showLoader } = this.state;
        const { isMobile } = this.context;
        return (
            <div className="salariesWrapper">
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        text="DELETE_SALARY_?"
                        modalName="showDeleteModal"
                        title="DELETE_SALARY"
                        toggleModal={this.toggleModal}
                        deleteItem={this.deleteSalary}
                        cancelDelete={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessModal"
                    className={this.state.showSuccessModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="DELETE_SALARY_SUCCESS"
                        modalName="showSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteFailure"
                    className={this.state.showDeleteFailure ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="DELETE_SALARY_FAILURE"
                        modalName="showDeleteFailure"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="addSalarySuccess"
                    className={this.state.addSalarySuccess ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="ADD_SALARY_SUCCESS"
                        modalName="addSalarySuccess"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="addSalaryFailure"
                    className={this.state.addSalaryFailure ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="ADD_SALARY_FAILURE"
                        modalName="addSalaryFailure"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="updateSalarySuccess"
                    className={this.state.updateSalarySuccess ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="UPDATE_SALARY_SUCCESS"
                        modalName="updateSalarySuccess"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="updateSalaryFailure"
                    className={this.state.updateSalaryFailure ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="UPDATE_SALARY_FAILURE"
                        modalName="updateSalaryFailure"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showAddSalaryModal"
                    className={this.state.showAddSalaryModal ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <div className="d-flex flex-column align-items-center">
                        <h2>
                            <Translate text={!this.state.editSalary ? "ADD_SALARY" : "EDIT_SALARY"} />{" "}
                        </h2>
                        <CustomForm className="d-flex flex-column">
                            <div>
                                <div className="my-30">
                                    <CustomInput
                                        name="amount"
                                        label="AMOUNT"
                                        type="number"
                                        handleInput={this.handleInput}
                                        value={addSalary.amount.value ? addSalary.amount.value : ""}
                                        errors={addSalary.amount.errors}
                                    />
                                </div>
                                <div className="mb-30">
                                    <CustomDropdown
                                        data={this.state.salary_types}
                                        name="type"
                                        value={this.state.salary_types.find(
                                            (el) => el.value === this.state.addSalary.type.value
                                        )}
                                        errors={addSalary.type.errors}
                                        handleChange={this.handleInput}
                                        label="TYPE"
                                    />
                                </div>
                                <div className="mb-30">
                                    <CustomDropdown
                                        data={this.state.users}
                                        name="employee"
                                        value={this.state.users.find(
                                            (el) => el.value === this.state.addSalary.employee.value
                                        )}
                                        errors={addSalary.employee.errors}
                                        handleChange={this.handleInput}
                                        label="EMPLOYEE"
                                    />
                                </div>
                                <div className="mb-30">
                                    <CustomDatePicker
                                        name="entry_date"
                                        handleInput={this.handleInput}
                                        value={addSalary.entry_date.value}
                                        errors={addSalary.entry_date.errors}
                                        label="ENTRY_DATE"
                                    />
                                </div>
                                <div className="mb-30">
                                    <CustomTextArea
                                        name="description"
                                        label="DETAILS"
                                        handleInput={this.handleInput}
                                        value={addSalary.description.value}
                                        errors={addSalary.description.errors}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <CustomButton type="button" className="btn-primary mr-10" onClick={this.addSalary}>
                                    <Translate text={!this.state.editSalary ? "ADD_SALARY" : "EDIT_SALARY"} />
                                </CustomButton>

                                <CustomButton
                                    type="button"
                                    className="btn-primary"
                                    onClick={() => this.toggleModal("showAddSalaryModal")}
                                >
                                    <Translate text="CANCEL" />
                                </CustomButton>
                            </div>
                        </CustomForm>
                    </div>
                </Modal>
                <div className={`${isMobile ? "mobFooterOverview" : ""} d-flex justify-content-md-between mb-20`}>
                    <div className="navigation d-flex">
                        <NavLink
                            to={`/accounts/1`}
                            className={`action-title d-flex align-items-center justify-content-center tab border-radius-br-0 border-radius-bl-0 ${
                                this.props.accounts && "active"
                            }`}
                            style={{
                                textDecoration: "none",
                            }}
                            activeClassName="active"
                        >
                            <Translate text={"EMPLOYEES"} />
                        </NavLink>
                        <NavLink
                            to={`/salaries/1`}
                            style={{
                                textDecoration: "none",
                            }}
                            className={`action-title d-flex align-items-center justify-content-center tab border-radius-br-0 border-radius-bl-0 mx-10 ${
                                !this.props.accounts && "active"
                            }`}
                            activeClassName="active"
                        >
                            <Translate text={"SALARIES"} />
                        </NavLink>
                        <NavLink
                            to={`/time-track/1`}
                            style={{
                                textDecoration: "none",
                            }}
                            className={`action-title d-flex align-items-center justify-content-center tab border-radius-br-0 border-radius-bl-0  ${
                                this.props.timetrack && "active"
                            }`}
                            activeClassName="active"
                        >
                            <Translate text={"Time-track"} />
                        </NavLink>
                    </div>

                    <div className="controlButtons">
                        <div>
                            <CustomButton
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleModal("showAddSalaryModal");
                                }}
                                className="btn-primary"
                            >
                                <Translate text={"ADD_SALARY"} />
                            </CustomButton>
                        </div>
                    </div>
                </div>
                <div className="filters">
                    <CustomForm className="row mb-30 d-flex align-items-center">
                        <div className="col-8 col-sm-6 col-md-4">
                            <CustomInput
                                value={this.state.search.value}
                                handleInput={this.handleInput}
                                name="search"
                                type="text"
                                label="Search"
                            />
                        </div>
                        {isMobile && (
                            <CustomButton onClick={this.submitSearch} className="btn-primary ml-15 h-45 align-self-end">
                                <span>
                                    <Translate text="SEARCH" />
                                </span>
                            </CustomButton>
                        )}
                    </CustomForm>
                </div>
                {showLoader ? (
                    <div className="loaderContainer">
                        <Loader width={200} />
                    </div>
                ) : (
                    <>
                        <div className="overflow-auto pb-20">
                            <Table
                                theads={this.state.salariesTableHeads}
                                theadsClassname="customThead"
                                handleSort={this.handleSort}
                                sortName="salaries"
                                arrayToSort={this.state.salaries}
                            >
                                {!!salaries &&
                                    salaries.map((e: any, index) => (
                                        <tr key={index} className="pointer f-s-14">
                                            <td className="name">{e.full_name}</td>
                                            <td className="date">
                                                {e.type === 1 || e.type === 2
                                                    ? parseDate(e.entry_date)
                                                    : parseDate(e.created_at)}
                                            </td>
                                            <td className="type">
                                                {e.type === 1 ? (
                                                    <Translate text="MONTHLY_SALARY" />
                                                ) : e.type === 2 ? (
                                                    <Translate text="PART_TIME_SALARY" />
                                                ) : (
                                                    <Translate text="costEstimate" />
                                                )}
                                            </td>
                                            <td className="amount">{e.amount}</td>
                                            <td>
                                                {e.type === 1 || e.type === 2 ? (
                                                    <Translate text="no_details" />
                                                ) : e.action && e.action.title ? (
                                                    e.action.title +
                                                    ", " +
                                                    e.title +
                                                    ", " +
                                                    e.total_amount +
                                                    "€ * " +
                                                    e.percentage +
                                                    "% = " +
                                                    e.amount +
                                                    "€"
                                                ) : (
                                                    e.title +
                                                    ", " +
                                                    e.total_amount +
                                                    "€ * " +
                                                    e.percentage +
                                                    "% = " +
                                                    e.amount +
                                                    "€"
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </Table>
                        </div>

                        {!!this.state.totalResults && this.state.totalResults > 0 && (
                            <div className="d-flex justify-content-center">
                                <Paginate
                                    totalItemsCount={totalResults}
                                    onChange={this.handlePageClick}
                                    activePage={parseInt(currentPage)}
                                    perPage={perPage}
                                    pageRangeDisplayed={5}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}
export default SalariesForAllEmployees;
