import { PlusIcon } from "components/Icons/Icons";
import ShowMore from "devkit/ShowMore/ShowMore";
import Translate from "functions/utilFunctions/translate";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Action from "./Action";
const Column = (props) => {
    const {
        title,
        actions,
        id,
        handleTitleChange,
        handleDeleteColumnModal,
        unassigned,
        columnDragOver,
        columnDragStart,
        columnDragEnd,
        columnDragEnter,
        columnDragLeave,
        actionDragStart,
        actionDragEnd,
        actionDragEnter,
        actionDragLeave,
    } = props;

    const [name, setName] = useState(title);

    const inputRef = useRef<HTMLInputElement>(null);

    const history = useHistory();

    useEffect(() => {
        setName(title);
    }, [title]);

    const onTitleChange = (e) => {
        const value = e.currentTarget.value;

        if (name !== value) {
            handleTitleChange(value, id);
        }

        setName(value);
    };

    const setFocusOnTitleInput = () => {
        const input = document.querySelector(`.titleInput[value="${name}"]`) as HTMLElement;

        input.focus();
    };

    const redirectToNewAction = (id) => {
        history.push({
            pathname: `/new-action`,
            state: {
                columnID: id,
                pathname: history.location.pathname,
            },
        });
    };

    const redirectToExistingAction = (id) => {
        history.push({
            pathname: `/action/${id}`,
            state: {
                agile: true,
                pathname: history.location.pathname,
            },
        });
    };

    const mouseDownHandler = (ev, id) => {
        if (ev.button === 1) {
            if (ev.button === 1) {
                window.open(`/action/${id}`, "_blank");
            }
        }
    };

    return (
        <div
            className="column py-10 px-5 pointer"
            draggable={title !== "Nedodijeljeno" ? true : false}
            onDragOver={(e) => columnDragOver(e, id)}
            onDragStart={(e) => {
                if (title !== "Nedodijeljeno") {
                    columnDragStart(e, id);
                }
            }}
            onClick={() => {
                inputRef.current?.focus();
                inputRef.current!.style.pointerEvents = "auto";
                document.getElementById(`c${id}`)!.draggable = false;
            }}
            onDragEnd={(e) => {
                if (title !== "Nedodijeljeno") {
                    columnDragEnd(e, id);
                }
            }}
            onDragEnter={columnDragEnter}
            onDragLeave={columnDragLeave}
            id={`c${id}`}
        >
            <header className="f-s-16 mb-10 px-5 d-flex justify-content-between align-items-center">
                <input
                    ref={inputRef}
                    className="titleInput active px-5 d-flex align-items-center h-30"
                    value={name}
                    onBlur={() => {
                        inputRef.current!.style.pointerEvents = "none";
                        document.getElementById(`c${id}`)!.draggable = true;
                    }}
                    style={{ pointerEvents: "none" }}
                    onChange={(e) => onTitleChange(e)}
                    disabled={unassigned}
                />
                {!unassigned && (
                    <ShowMore className="w-20 mr-5">
                        <div
                            onClick={() => handleDeleteColumnModal(id)}
                            style={{ color: "red" }}
                            className="w-100-perc d-flex justify-content-center"
                        >
                            <Translate text="DELETE_COLUMN" />
                        </div>
                        <div onClick={setFocusOnTitleInput} className="w-100-perc d-flex justify-content-center">
                            <Translate text="CHANGE_TITLE" />
                        </div>
                    </ShowMore>
                )}
            </header>
            <div className="d-flex flex-column action-list">
                {actions &&
                    actions[0] &&
                    actions.map((action) => {
                        return (
                            <div
                                className="action action-hover mb-10"
                                draggable
                                id={`a${action.action_id}`}
                                onDragStart={(e) => {
                                    e.stopPropagation();
                                    actionDragStart(e, action.action_id);
                                }}
                                onDragEnd={(e) => {
                                    e.stopPropagation();
                                    actionDragEnd();
                                }}
                                onDragEnter={actionDragEnter}
                                onDragLeave={actionDragLeave}
                                onClick={() => redirectToExistingAction(action.action_id)}
                                onMouseDown={(ev) => mouseDownHandler(ev, action.action_id)}
                            >
                                <Action action={action} id={action.action_id} />
                            </div>
                        );
                    })}
            </div>

            <footer className="px-5">
                <button className="addCardBtn d-flex align-items-center" onClick={() => redirectToNewAction(id)}>
                    <PlusIcon width={12} className="icon mr-10"></PlusIcon>
                    <div>
                        <Translate text="ADD_ACTION_TO_COLUMN" />
                    </div>
                </button>
            </footer>
        </div>
    );
};

export default Column;
