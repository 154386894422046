export const actionTasksState = {
    form: {
        search: {
            value: "",
            errors: [],
        },
        searchTemplate: {
            value: "",
            errors: [],
        },
        startDate: {
            value: null,
            errors: [],
        },
        endDate: {
            value: null,
            errors: [],
        },
        user_id: {
            value: 0,
            errors: [],
        },
        customer_id: {
            value: 0,
            errors: [],
        },
        label_id: {
            value: 0,
            errors: [],
        },
    },
    tableHeads: [
        {
            value: "actionName",
            sortable: true,
            sortableType: "string",
            sortableName: "action_name",
        },
        {
            value: "START_DATE",
            sortable: true,
            sortableType: "date",
            sortableName: "start_date_sorter",
        },
        {
            value: "END_DATE",
            sortable: true,
            sortableType: "date",
            sortableName: "end_date_sorter",
        },
        {
            value: "CLIENTS",
            sortable: false,
            sortableType: "string",
            sortableName: "customer_name",
        },
        {
            value: "EMPLOYEES",
            sortable: false,
            sortableType: "string",
            sortableName: "user_firstName",
        },
        {
            value: "PRIORITY",
            sortable: true,
            sortableType: "number",
            sortableName: "priority",
        },
        {
            value: "STATE",
            sortable: true,
            sortableType: "number",
            sortableName: "status",
        },
        {
            value: "",
            sortable: false,
        },
    ],
    templateTableHeads: [
        {
            value: "templateName",
            sortable: true,
            sortableType: "string",
            sortableName: "action_name",
        },
        {
            value: "TEMPLATE_DESCRIPTION",
            sortable: false,
            sortableType: "string",
            sortableName: "remark",
        },
        {
            value: "PRIORITY",
            sortable: true,
            sortableType: "number",
            sortableName: "priority",
        },
        {
            value: "",
            sortable: false,
        },
    ],
    showDeleteModal: false,
    showSuccessModal: false,
    showDeleteFailure: false,
    deleteID: "",
    actions: [],
    users: [],
    labelsArray: [],
    customers: [],
    templates: [],
    isLoading: true,
    perPage: 10,
    totalResults: 0,
    sorterDirection: "",
    sorterName: "",
    templateColumns: [],
    showFilterSidebar: false,
};
