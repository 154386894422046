import React, { Component } from "react";
import { Translate } from "../../functions/utilFunctions/translate";
import { CloseIcon } from "../../components/Icons/Icons";
import { CustomButton } from "../../devkit/Form/Form";

interface IProps {
    opened: boolean;
    onClose: () => void;
    sidebarClasses?: string;
    wrapperClasses?: string;
    titleMargin?: string;
    submitFilters: (e: React.MouseEvent<HTMLButtonElement>) => void;
    resetFilters: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export default class FilterSidebar extends Component<IProps> {
    render() {
        const { opened, onClose, sidebarClasses, wrapperClasses, titleMargin, submitFilters, resetFilters } =
            this.props;

        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
                className={`${wrapperClasses ? wrapperClasses : ""} ${opened ? "open" : ""} filter-sidebar-wrapper`}
            >
                <div
                    className={`${sidebarClasses ? sidebarClasses : ""} ${
                        opened ? "open" : ""
                    } filter-sidebar d-flex flex-column form-box-shadow`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={`d-flex justify-content-between ${titleMargin ? titleMargin : ""} `}>
                        <h1>
                            <Translate text="FILTERS"></Translate>
                        </h1>
                        <div
                            onClick={onClose}
                            className="d-flex align-items-center justify-content-center pointer close-icon"
                        >
                            <CloseIcon width={18} className=""></CloseIcon>
                        </div>
                    </div>
                    <div className="main h-100-perc d-flex justify-content-between flex-column">
                        <div className="filterBody mt-20">{this.props.children}</div>
                        <div className="mb-30 controlButtons ">
                            <CustomButton
                                className="w-100-perc  btn-secondary color-2 mb-15"
                                onClick={(e) => submitFilters(e)}
                            >
                                <Translate text="FILTER!"></Translate>
                            </CustomButton>
                            <CustomButton className="w-100-perc btn-secondary" onClick={(e) => resetFilters(e)}>
                                <Translate text="RESET_FILTERS"></Translate>
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
