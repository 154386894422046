import {
    BackIcon,
    ConvertIcon,
    SaveIcon,
    UploadIcon,
    CopyIcon,
    ShowPasswordIcon,
    FilesIcon,
    ActivitiesIcon,
} from "components/Icons/Icons";
import { CloudFolderIcon, FileDeleteIcon, FileManagerIcons } from "components/Icons/FileManagerIcons";
import {
    CustomButton,
    CustomDateTimePicker,
    CustomDropdown,
    CustomFileInput,
    CustomForm,
    CustomInput,
    CustomTextArea,
} from "devkit/Form/Form";
import React, { Component } from "react";
import { parseDateForMySQL, parseDateWithoutSeconds } from "../../../functions/utilFunctions/parsingFunctions";
import ApiService from "services/api.service";
import FolderStructure from "components/FolderStructure/FolderStructure";
import { Loader } from "devkit/Loader/Loader";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "../../../devkit/Modal/ModalContent";
import ShowMore from "../../../devkit/ShowMore/ShowMore";
import SortableList from "../../../devkit/Sortable/SortableList";
import { StoreService } from "services/store.service";
import Translate from "functions/utilFunctions/translate";
import { UtilService } from "services/util.service";
import ClientsSummaries from "components/ClientsSummaries/ClientsSummaries";
import { Devkit } from "oykos-development-devkit";
import Comments from "./Comments";
import LogsSidebar from "components/LogsSidebar/LogsSidebar";
import { DISABLE_FIREBASE } from "../../../services/constants.service";

class ActionDetails extends Component<any> {
    state = {
        form: {
            id: {
                value: "",
                errors: [],
            },
            title: {
                value: "",
                errors: [],
            },
            customers: {
                value: [] as any,
                errors: [""],
            },
            customer_name: { value: "", errors: [] },
            endDate: {
                value: new Date().setMinutes(0),
                errors: [],
            },
            remark: {
                value: "",
                errors: [],
            },
            startDate: {
                value: new Date().setMinutes(0),
                errors: [],
            },
            priority: { value: 0, errors: [] },
            status: { value: 1, errors: [] },
            user_firstName: {
                value: "",
                errors: [],
            },
            user_lastName: {
                value: "",
                errors: [],
            },
            actionPoints: {
                value: 0,
                errors: [],
            },
            users: {
                value: [] as any,
                errors: [""],
            },
            labels: {
                value: [] as any,
                errors: [""],
            },
            groups: {
                value: [] as any,
                errors: [""],
            },
            steps: [
                // {
                //     end_date: null,
                //     title: "",
                //     // title: {
                //     //     value: '',
                //     //     errors: []
                //     // },
                //     // errors: [],
                //     step_state_id: 0,
                //     id: null,
                //     updated_at: null,
                // },
            ] as any,
            costs: [
                // {
                //     title: "",
                //     payerName: "",
                //     recipientName: "",
                //     amount: "",
                //     user_groups: [
                //         {
                //             selectedEmployees: [
                //                 {
                //                     // value: [] as any,
                //                     // errros: [],
                //                     value: 0,
                //                     label: "",
                //                 },
                //             ],
                //             percentage: "",
                //         },
                //     ],
                //     user_id: false,
                //     id: null,
                //     errors: [],
                // },
            ] as any,
            files: [{ title: "", errors: [], id: null, parent_id: null, type: "" }],
            cost: { value: 0, label: "troskovi" },
            step: { value: 3, label: "koraci" },
            frequency: { value: 0, errors: [] },
            default_path: {
                value: "",
                errors: [],
            },
            parent_folder_id: {
                value: "",
                errors: [],
            },
            default_path_level: {
                value: "",
                errors: [],
            },
            comment: {
                value: "",
                errors: [],
            },
            editComment: {
                value: "",
                errors: [],
            },
        },
        folderStructure: {
            sub: [
                {
                    sub: [],
                    created_at: null,
                    file_size: null,
                    id: 0,
                    level: 0,
                    parent_id: 0,
                    path: "",
                    title: "",
                    type: "",
                    updated_at: null,
                },
            ],
            created_at: null,
            file_size: null,
            id: 0,
            level: 0,
            parent_id: null,
            path: "",
            title: "",
            type: "",
            updated_at: null,
        },
        steps_states: [
            { value: 3, label: "U toku" },
            { value: 1, label: "Gotov korak" },
            { value: 2, label: "Nepotreban korak" },
        ] as any,
        states: [
            { value: 0, label: <Translate text="CHOOSE_PRIORITY" /> },
            { value: 1, label: <Translate text="IMPORTANT" /> },
            { value: 2, label: <Translate text="VERY_IMPORTANT" /> },
            { value: 3, label: <Translate text="PRIORITY" /> },
            { value: 4, label: <Translate text="URGENT" /> },
        ] as any,
        action_states: [
            { value: 1, label: <Translate text="PLANNED_ACTION" /> },
            { value: 2, label: <Translate text="IN_PROGRESS_ACTION" /> },
            { value: 3, label: <Translate text="COMPLETED_ACTION" /> },
        ] as any,
        action_repetition: [
            { value: 0, label: <Translate text="ONCE" /> },
            { value: 1, label: <Translate text="WEEKLY" /> },
            { value: 2, label: <Translate text="MONTHLY" /> },
            { value: 3, label: <Translate text="QUARTERLY" /> },
            { value: 4, label: <Translate text="YEARLY" /> },
        ] as any,
        costName: {
            value: "",
            errors: [],
        },
        payerName: {
            value: "",
            errors: [],
        },
        recipientName: {
            value: "",
            errors: [],
        },
        amountCost: {
            value: "",
            errors: [],
        },
        percentage: {
            value: "",
            errors: [],
        },
        expenses: { value: "", errors: [] },
        action: {},
        template: {},
        clickedCustomer: {
            PIB: "",
            address: "",
            name: "",
            PDVno: "",
            default_path: "",
            parent_folder_id: "",
            default_path_level: "",
        },
        users: [],
        usersArray: [],
        customersArray: [],
        labelsArray: [],
        groupsArray: [],
        customers: [],
        lastCreatedCustomer: 0,
        actions: [],
        changedCustomer: false,
        newActionId: "",
        newTemplateId: "",
        newAction: !this.props.match.params.id,
        showDeleteModal: false,
        showActionModal: false,
        showNewActionModal: false,
        updateAction: false,
        successNewAction: false,
        showUpdateSuccessModal: false,
        showUpdateErrorModal: false,
        showDeleteFailure: false,
        showDeleteSuccess: false,
        showNewActionErrorModal: false,
        showFileUpload: false,
        showDeleteStepModal: false,
        showDeleteCostModal: false,
        showConvertModal: false,
        showUpdateStepCostErrorModal: false,
        fileUploadErrorModal: false,
        showDeleteFileModal: false,
        tooBigPercentageModal: false,
        isOver: false,
        completed: 0,
        uploadComplete: false,
        uploading: false,
        templateConvert: false,
        actionConvert: false,
        file: {
            value: null,
            errors: [],
        },
        files: [],
        filesBulk: [] as any,
        fileNames: [],
        isLoading: true,
        itemIndex: null,
        costItemIndex: null,
        fileIndex: null,
        changeFolderModal: false,
        contextItemID: 0,
        contextItemName: {
            value: "",
            errors: [],
        },
        contextItemExt: "",
        destinationID: 1,
        destinationPath: "",
        destinationLevel: 0,
        miniLoader: false,
        cloneAction: false,
        showCloneModal: false,
        showCloneErrorModal: false,
        showCloneSuccessModal: false,
        endDateErrorModal: false,
        allGroups: [] as any,
        actionComments: [] as any,
        stepsErrorModal: false,
        costsErrorModal: false,
        showIFrameModalContent: false,
        IFrameURL: "",
        fileName: "",
        logs: [],
        showLogsSidebar: false,
    };

    componentDidMount() {
        const users = StoreService.getStoreProperty("users");
        const actions = StoreService.getStoreProperty("actions");
        const token = StoreService.getStoreProperty("token");

        ApiService.filterCustomers({ limit: 1000, offset: 0 }, token).then((response) => {
            if (response.success === 1) {
                const customers = response.data.customers;
                this.checkGroups();

                if (this.props.location?.client) {
                    const { client } = this.props.location;

                    this.setState((prevState: any) => ({
                        form: {
                            ...prevState.form,
                            customers: {
                                value: [
                                    {
                                        value: client.id,
                                        label: client.name,
                                    },
                                ],
                                errors: [],
                            },
                            users: {
                                value: client.employees.value,
                                errors: [],
                            },
                            default_path: {
                                value: client.default_path,
                                errors: [],
                            },
                            default_path_level: {
                                value: client.default_path_level,
                                errors: [],
                            },
                            parent_folder_id: {
                                value: client.parent_folder_id,
                                errors: [],
                            },
                        },
                    }));
                }

                const customersArray = customers.map((e) => ({
                    value: e.id,
                    label: e.name,
                }));

                customersArray.unshift({
                    value: 0,
                    label: "Izaberi klijenta",
                });

                if (this.state.newAction) {
                    this.props.template &&
                        ApiService.showAction(
                            {
                                id: this.props.match.params.id,
                                template: true,
                            },
                            token
                        ).then((response) => {
                            if (response.status === "OK") {
                                const expenses = response.data.expenses;
                                this.setState({
                                    expenses,
                                });
                            }
                        });
                    !this.props.template &&
                        ApiService.showAction({ limit: 10, offset: 0 }, token).then((response) => {
                            if (response.status === "OK") {
                                const labelsArray = response.data.labels.map((el) => ({
                                    value: el.id,
                                    label: el.title,
                                }));

                                this.setState({
                                    labelsArray,
                                });
                            }
                        });
                    let loggedUser = StoreService.getStoreProperty("user");
                    let loggedUserArray = [
                        { value: loggedUser.id, label: `${loggedUser.firstName} ${loggedUser.lastName}` },
                    ];

                    this.setState((prevState: any) => ({
                        form: {
                            ...prevState.form,
                            users: {
                                value: loggedUserArray,
                                errors: [],
                            },
                        },
                        users,
                        customers,
                        actions,
                        customersArray,
                        isLoading: false,
                    }));

                    this.handleGetStructure();

                    if (!this.state.form.default_path.value) {
                        this.getDefaultPath();
                    }
                } else if (!this.state.newAction) {
                    const actionFormated = {};
                    if (!this.props.template) {
                        ApiService.findActionByID(
                            {
                                id: this.props.match.params.id,
                            },
                            token
                        ).then((response) => {
                            if (response.status === "OK") {
                                const action = response.data.action;
                                Object.keys(action).forEach((e) => {
                                    if (e === "startDate" || e === "endDate") {
                                        actionFormated[e] = {
                                            value: new Date(action[e]),
                                            errors: [],
                                        };
                                    } else if (
                                        e !== "customers" &&
                                        e !== "default_path" &&
                                        e !== "default_path_level" &&
                                        e !== "parent_folder_id"
                                    ) {
                                        actionFormated[e] = {
                                            value: action[e],
                                            errors: [],
                                        };
                                    }
                                });
                                const steps = response.data.steps.map((step) => ({
                                    end_date: step.end_date ? new Date(step.end_date) : null,
                                    title: step.title,
                                    errors: [],
                                    step_state_id: step.step_state_id,
                                    id: step.id,
                                    updated_at: step.updated_at,
                                }));

                                const costs = response.data.costs.map((cost) => ({
                                    title: cost.title,
                                    payerName: cost.payerName,
                                    recipientName: cost.recipientName,
                                    amount: cost.amount,
                                    user_groups: cost.user_groups.map((user) => ({
                                        percentage: user.percentage,
                                        selectedEmployees: user.users.map((employee) => ({
                                            value: employee.id,
                                            label: employee.firstName + " " + employee.lastName,
                                        })),
                                    })),
                                    id: cost.id,
                                }));

                                const files = response.data.files;
                                const users = response.data.action.users.map((e: any) => ({
                                    value: e.id,
                                    label: `${e.firstName} ${e.lastName}`,
                                }));

                                const labels = response.data.action.labels.map((el) => ({
                                    value: el.id,
                                    label: el.title,
                                }));
                                const labelsArray = response.data.labels.map((el) => ({
                                    value: el.id,
                                    label: el.title,
                                }));
                                const groups =
                                    response.data.action.groups &&
                                    response.data.action.groups.map((e: any) => ({
                                        value: e.id,
                                        label: e.title,
                                    }));

                                const customers = response.data.action.customers.map((customer) => {
                                    return {
                                        value: customer.id,
                                        label: customer.name,
                                    };
                                });

                                const actionComments = response.data.action.comments;

                                this.setState((prevState: any) => ({
                                    form: {
                                        ...prevState.form,
                                        ...actionFormated,
                                        actionPoints: {
                                            value: response.data.action.point,
                                            errors: [],
                                        },
                                        customers: {
                                            value: customers,
                                            errors: [],
                                        },
                                        users: {
                                            value: users,
                                            errors: [],
                                        },
                                        groups: {
                                            value: groups,
                                            errors: [],
                                        },
                                        labels: {
                                            value: labels,
                                            errors: [],
                                        },
                                        steps: steps,
                                        costs: costs,
                                        files: files,
                                        default_path: {
                                            value: action.default_path,
                                            errors: [],
                                        },
                                        default_path_level: {
                                            value: action.default_path_level,
                                            errors: [],
                                        },
                                        parent_folder_id: {
                                            value: action.parent_folder_id,
                                            errors: [],
                                        },
                                    },
                                    action,
                                    labelsArray: labelsArray,
                                    customersArray,
                                    isLoading: false,
                                    actionComments,
                                }));

                                this.getDefaultStructure(response.data.action.id);
                            }
                        });
                    } else if (this.props.template) {
                        // this.setState((prevState: any) => ({
                        //     form: {
                        //         ...prevState.form,
                        //     },
                        // }));
                        ApiService.findActionByID(
                            {
                                id: this.props.match.params.id,
                            },
                            token
                        ).then((response) => {
                            if (response.status === "OK") {
                                const template = response.data.action;
                                const steps = response.data.steps;
                                const costs = response.data.costs;
                                const files = response.data.files;
                                Object.keys(template).forEach((e) => {
                                    if (e === "startDate" || e === "endDate") {
                                        actionFormated[e] = {
                                            value: new Date(template[e]),
                                            errors: [],
                                        };
                                    } else {
                                        actionFormated[e] = {
                                            value: template[e],
                                            errors: [],
                                        };
                                    }
                                });
                                this.setState((prevState: any) => ({
                                    form: {
                                        ...prevState.form,
                                        ...actionFormated,
                                        steps: steps,
                                        costs: costs,
                                        files: files,
                                    },
                                    template,
                                    isLoading: false,
                                }));

                                this.getDefaultStructure(template.id);
                            }
                        });
                    } else {
                        this.props.history.push("/actions");
                    }
                } else if (this.state.newAction || this.state.templateConvert) {
                    this.setState({
                        isLoading: false,
                    });
                } else {
                    if (this.props.match.params.cloneID) {
                        const actions = StoreService.getStoreProperty("recurringActionMap");
                        const actionToClone = actions.find(
                            (action: any) =>
                                action.rootID.toString() === this.props.match.params.cloneID &&
                                action.startDate === this.props.match.params.cloneStartDate
                        );
                        if (actionToClone) {
                            let actionFormated = {};
                            Object.keys(actionToClone).forEach((e) => {
                                if (e === "startDate" || e === "endDate") {
                                    actionFormated[e] = {
                                        value: new Date(actionToClone[e]),
                                        errors: [],
                                    };
                                } else {
                                    actionFormated[e] = {
                                        value: actionToClone[e],
                                        errors: [],
                                    };
                                }
                            });
                            this.setState((prevState: any) => ({
                                action: actionToClone,
                                form: {
                                    ...prevState.form,
                                    ...actionFormated,
                                },
                                isLoading: false,
                            }));
                        }
                    }
                    this.handleGetStructure();
                }
            }
        });
    }

    handleBack = () => {
        if (this.props.location?.state?.agile) {
            this.props.history.push({
                pathname: this.props.location.state.pathname,
                state: {
                    agile: true,
                },
            });
        } else {
            this.props.history.goBack();
        }
    };

    addBulkFilesOnCreate = (id) => {
        const { filesBulk } = this.state;

        for (let i = 0; i <= filesBulk.length - 1; i++) {
            this.handleAddDocument(filesBulk[i].value, id);
        }
    };

    handleAddDocument = (file?, id?) => {
        const token = StoreService.getStoreProperty("token");
        const fileToUpload = file ? file : this.state.file.value || "";

        const {
            form: { parent_folder_id, default_path, default_path_level },
        } = this.state;

        const formData = new FormData();
        formData.append("files[]", fileToUpload);
        formData.append("type", "file");
        formData.append("parent_id", parent_folder_id.value);
        !this.state.form.default_path.value && formData.append("path", default_path.value);
        formData.append("level", default_path_level.value);

        if (id) {
            formData.append("action_id", id);
        } else {
            formData.append("action_id", this.state.form.id.value);
        }

        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };

        ApiService.uploadFile(formData, token, config).then((res) => {
            if (res.status === "OK") {
                const notifications = res.data.notifications;

                if (notifications && Object.values(notifications).length) {
                    Object.values(notifications).forEach((notification: any) => {
                        if (!DISABLE_FIREBASE) {
                            Devkit.FirebaseService.sendNotification(
                                notification.action,
                                notification.item,
                                notification.link,
                                notification.read,
                                null,
                                notification.users
                            );
                        }
                    });
                }

                res.data = res.data.files[0];

                if (!this.state.newAction) {
                    this.setState((prevState: any) => ({
                        files: [...prevState.files, res.data.id],
                        fileNames: [...prevState.fileNames, res.data.title],
                        completed: 0,
                        uploadComplete: false,
                        uploading: false,
                        file: {
                            value: null,
                            errors: [],
                        },
                        form: {
                            ...prevState.form,
                            files: [...prevState.form.files, res.data],
                        },
                    }));
                }
            } else {
                this.setState({
                    fileUploadErrorModal: true,
                    file: {
                        value: null,
                        errors: [],
                    },
                });
            }
        });
    };

    getDefaultPath = () => {
        const token = StoreService.getStoreProperty("token");
        ApiService.getDefaultPathOfAttachments(token).then((response) => {
            if (response.status === "OK") {
                this.setState((prev: any) => ({
                    form: {
                        ...prev.form,
                        default_path: {
                            value: response.data.path,
                            errors: [],
                        },
                        parent_folder_id: {
                            value: response.data.id,
                            errors: [],
                        },
                        default_path_level: {
                            value: response.data.level,
                            errors: [],
                        },
                    },
                }));
            }
        });
    };
    handleInput = (name, data, index?) => {
        if (index === undefined) {
            if (name === "endDate" || name === "startDate") {
                const formattedTime = new Date(data.value).setMinutes(0);
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        [name]: {
                            value: new Date(formattedTime),
                            errors: data.errors,
                        },
                    },
                }));
            } else {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        [name]: {
                            value: data.value,
                            errors: data.errors,
                        },
                    },
                }));
            }
        } else if (typeof index === "number") {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    [name]: [...prevState.form[name].slice(0, index), data, ...prevState.form[name].slice(index + 1)],
                },
            }));
        }
    };

    handleCostInputEmployees = (name, data, index?, parentIndex?) => {
        if (index !== undefined && parentIndex !== undefined) {
            const {
                form: { costs },
            } = this.state;
            // costs[parentIndex].user_groups[index].selectedEmployees = data.value;
            costs[parentIndex].user_groups[index].percentage = data.value;
            this.setState(
                (prevState: any) => ({
                    form: {
                        ...prevState.form,
                        costs,
                    },
                }),
                () => {
                    if (!this.state.newAction) {
                        const user = StoreService.getStoreProperty("user");
                        const id = this.state.form.costs[parentIndex].id;
                        const title = this.state.form.costs[parentIndex].title;
                        const payerName = this.state.form.costs[parentIndex].payerName;
                        const recipientName = this.state.form.costs[parentIndex].recipientName;
                        const emplooyes_percentages =
                            this.state.form.costs[parentIndex].user_groups.length &&
                            this.state.form.costs[parentIndex].user_groups.map((group) => {
                                const percentage = group.percentage;
                                const employees =
                                    group.selectedEmployees.length &&
                                    group.selectedEmployees.map((employee) => {
                                        return employee.value;
                                    });
                                return {
                                    percentage: percentage,
                                    employees: employees,
                                };
                            });
                        const amount = parseInt(this.state.form.costs[parentIndex].amount);
                        const user_id = this.state.form.costs[parentIndex].user_id ? user.id : null;
                        const action_id = this.state.form.id.value;
                        const token = StoreService.getStoreProperty("token");

                        UtilService.handleDebouncing(
                            "updateCostDebouncing",
                            () =>
                                ApiService.updateCost(
                                    {
                                        id,
                                        title,
                                        payerName,
                                        recipientName,
                                        emplooyes_percentages,
                                        amount,
                                        user_id,
                                        action_id,
                                    },
                                    token
                                ).then((response) => {
                                    if (response.status !== "OK") {
                                        // alert("Something went wrong!");
                                    } else {
                                        if (response.data.warning) {
                                            this.setState({
                                                tooBigPercentageModal: true,
                                            });
                                        }
                                        const notifications = response.data.notifications;
                                        this.updateCostNotifications(notifications);
                                    }
                                }),
                            300
                        );
                    }
                }
            );
        }
    };
    updateCostNotifications = (notifications) => {
        if (notifications && Object.values(notifications).length) {
            Object.values(notifications).forEach((notification: any) => {
                if (!DISABLE_FIREBASE) {
                    Devkit.FirebaseService.sendNotification(
                        notification.action,
                        notification.item,
                        notification.link,
                        notification.read,
                        null,
                        notification.users
                    );
                }
            });
        }
    };
    handleCostDropdownEmployees = (name, data, index?, parentIndex?) => {
        if (index !== undefined && parentIndex !== undefined) {
            const {
                form: { costs },
            } = this.state;
            // costs[parentIndex].user_groups[index].selectedEmployees = data.value;
            costs[parentIndex].user_groups[index].selectedEmployees = data.value;
            this.setState(
                (prevState: any) => ({
                    form: {
                        ...prevState.form,
                        costs,
                    },
                }),
                () => {
                    if (!this.state.newAction) {
                        const user = StoreService.getStoreProperty("user");
                        const id = this.state.form.costs[parentIndex].id;

                        const title = this.state.form.costs[parentIndex].title;
                        const payerName = this.state.form.costs[parentIndex].payerName;
                        const recipientName = this.state.form.costs[parentIndex].recipientName;
                        const emplooyes_percentages =
                            this.state.form.costs[parentIndex].user_groups.length &&
                            this.state.form.costs[parentIndex].user_groups.map((group) => {
                                const percentage = group.percentage;
                                const employees =
                                    group?.selectedEmployees?.length &&
                                    group.selectedEmployees.map((employee) => {
                                        return employee.value;
                                    });
                                return {
                                    percentage: percentage,
                                    employees: employees,
                                };
                            });
                        const amount = parseInt(this.state.form.costs[parentIndex].amount);
                        const user_id = this.state.form.costs[parentIndex].user_id ? user.id : null;
                        const action_id = this.state.form.id.value;
                        const token = StoreService.getStoreProperty("token");

                        UtilService.handleDebouncing(
                            "updateCostDebouncing",
                            () =>
                                ApiService.updateCost(
                                    {
                                        id,
                                        title,
                                        payerName,
                                        recipientName,
                                        emplooyes_percentages,
                                        amount,
                                        user_id,
                                        action_id,
                                    },
                                    token
                                ).then((response) => {
                                    if (response.status !== "OK") {
                                        // alert("Something went wrong!");
                                    } else {
                                        const notifications = response.data.notifications;
                                        this.updateCostNotifications(notifications);
                                    }
                                }),
                            300
                        );
                    }
                }
            );
        }
    };
    handleCostInput = (name, data, index?) => {
        if (index !== undefined) {
            this.setState(
                (prevState: any) => ({
                    form: {
                        ...prevState.form,
                        costs: [
                            ...prevState.form.costs.slice(0, index),
                            {
                                ...prevState.form.costs[index],
                                [name]: data.value,
                            },
                            ...prevState.form.costs.slice(index + 1),
                        ],
                    },
                }),
                () => {
                    if (!this.state.newAction) {
                        const user = StoreService.getStoreProperty("user");
                        const id = this.state.form.costs[index].id;
                        const title = this.state.form.costs[index].title;
                        const payerName = this.state.form.costs[index].payerName;
                        const recipientName = this.state.form.costs[index].recipientName;
                        const emplooyes_percentages =
                            this.state.form.costs[index].user_groups.length &&
                            this.state.form.costs[index].user_groups.map((group) => {
                                const percentage = group.percentage;
                                const employees =
                                    group.selectedEmployees.length &&
                                    group.selectedEmployees.map((employee) => {
                                        return employee.value;
                                    });
                                return {
                                    percentage: percentage,
                                    employees: employees,
                                };
                            });
                        const amount = parseInt(this.state.form.costs[index].amount);
                        const user_id = this.state.form.costs[index].user_id ? user.id : null;
                        const action_id = this.state.form.id.value;
                        const token = StoreService.getStoreProperty("token");

                        UtilService.handleDebouncing(
                            "updateCostDebouncing",
                            () =>
                                ApiService.updateCost(
                                    {
                                        id,
                                        title,
                                        payerName,
                                        recipientName,
                                        emplooyes_percentages,
                                        amount,
                                        user_id,
                                        action_id,
                                    },
                                    token
                                ).then((response) => {
                                    if (response.status !== "OK") {
                                        // alert("Something went wrong!");
                                    } else {
                                        const notifications = response.data.notifications;
                                        this.updateCostNotifications(notifications);
                                    }
                                }),
                            300
                        );
                    }
                }
            );
        }
    };

    handleAddCost = () => {
        const { costs } = this.state.form;
        if (costs.length > 0) {
            if (
                costs[costs.length - 1].title === "" ||
                costs[costs.length - 1].title === null ||
                costs[costs.length - 1].amount === "" ||
                costs[costs.length - 1].amount === null ||
                costs[costs.length - 1].user_groups.length === 0 ||
                costs[costs.length - 1].user_groups[0]?.percentage === "" ||
                costs[costs.length - 1].user_groups[0]?.selectedEmployees[0].value === 0
            ) {
                this.setState({
                    costsErrorModal: true,
                });
                return;
            }
        }

        const token = StoreService.getStoreProperty("token");
        const user = StoreService.getStoreProperty("user");
        if (this.state.newAction || user.role === 1) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    costs: [
                        ...prevState.form.costs,
                        {
                            title: "",
                            payerName: "",
                            recipientName: "",
                            amount: "",
                            user_groups: [
                                {
                                    selectedEmployees: [
                                        {
                                            value: 0,
                                            label: "",
                                            // value: [{ value: 0, label: "" }] as any,
                                            // errros: [],
                                        },
                                    ],
                                    percentage: "",
                                },
                            ],
                            user_id: false,
                            errors: [],
                        },
                    ],
                },
            }));
        } else {
            ApiService.createCost(
                {
                    action_id: this.state.form.id.value,
                },
                token
            ).then((response) => {
                if (response.status === "OK") {
                    const notifications = response.data.notifications;
                    this.updateCostNotifications(notifications);

                    this.setState((prevState: any) => ({
                        form: {
                            ...prevState.form,
                            costs: [
                                ...prevState.form.costs,
                                {
                                    title: "",
                                    payerName: "",
                                    recipientName: "",
                                    amount: "",
                                    user_groups: [
                                        {
                                            selectedEmployees: [
                                                {
                                                    value: 0,
                                                    label: "",
                                                    // value: [{ value: 0, label: "" }] as any,
                                                    // errros: [],
                                                },
                                            ],
                                            percentage: "",
                                        },
                                    ],
                                    user_id: false,
                                    errors: [],
                                    id: response.data.expense.id,
                                    action_id: response.data.expense.action_id,
                                },
                            ],
                        },
                    }));
                } else {
                    this.setState({
                        showUpdateStepCostErrorModal: true,
                    });
                }
            });
        }
    };

    addUserGroup = (parentIndex) => {
        let costs = this.state.form.costs;
        costs[parentIndex].user_groups.push({
            selectedEmployees: [
                {
                    value: 0,
                    label: "",
                    // value: [{ value: 0, label: "" }] as any
                    // errros: [],
                },
            ],
            percentage: "",
        });

        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                costs,
            },
        }));
    };

    removeUserGroup = (parentIndex, index) => {
        let costs = this.state.form.costs;
        let user_groups = this.state.form.costs[parentIndex].user_groups;
        costs[parentIndex].user_groups = user_groups.filter((e, i) => i !== index);
        // // const filteredUserGroups = user_groups.splice(index, 1);
        this.setState(
            (prevState: any) => ({
                form: {
                    ...prevState.form,
                    costs,
                },
            }),
            () => {
                if (!this.state.newAction) {
                    const user = StoreService.getStoreProperty("user");
                    const id = this.state.form.costs[parentIndex].id;

                    const title = this.state.form.costs[parentIndex].title;
                    const payerName = this.state.form.costs[parentIndex].payerName;
                    const recipientName = this.state.form.costs[parentIndex].recipientName;
                    let emplooyes_percentages =
                        this.state.form.costs[parentIndex].user_groups.length &&
                        this.state.form.costs[parentIndex].user_groups.map((group) => {
                            const percentage = group.percentage;
                            const employees =
                                group.selectedEmployees.length &&
                                group.selectedEmployees.map((employee) => {
                                    return employee.value;
                                });
                            return {
                                percentage: percentage,
                                employees: employees,
                            };
                        });
                    const amount = parseInt(this.state.form.costs[parentIndex].amount);
                    const user_id = this.state.form.costs[parentIndex].user_id ? user.id : null;
                    const action_id = this.state.form.id.value;
                    const token = StoreService.getStoreProperty("token");
                    UtilService.handleDebouncing(
                        "updateCostDebouncing",
                        () =>
                            ApiService.updateCost(
                                {
                                    id,
                                    title,
                                    payerName,
                                    recipientName,
                                    emplooyes_percentages,
                                    amount,
                                    user_id,
                                    action_id,
                                },
                                token
                            ).then((response) => {
                                if (response.status !== "OK") {
                                    // alert("Something went wrong!");
                                } else {
                                    const notifications = response.data.notifications;
                                    this.updateCostNotifications(notifications);
                                }
                            }),
                        300
                    );
                }
            }
        );
    };

    handleStepInput = (name, data, index?) => {
        const user = StoreService.getStoreProperty("user");
        if (index !== undefined) {
            if (name !== "stepsDropdown") {
                this.setState(
                    (prevState: any) => ({
                        form: {
                            ...prevState.form,
                            steps: [
                                ...prevState.form.steps.slice(0, index),
                                {
                                    ...prevState.form.steps[index],
                                    [name]: data.value,
                                    errors: data.errors,
                                },
                                ...prevState.form.steps.slice(index + 1),
                            ],
                        },
                    }),
                    () => {
                        if (!this.state.newAction) {
                            const id = this.state.form.steps[index].id;
                            const title = this.state.form.steps[index].title;
                            const end_date =
                                this.state.form.steps[index].end_date &&
                                parseDateForMySQL(this.state.form.steps[index].end_date, true);
                            const token = StoreService.getStoreProperty("token");

                            user.role !== 1 &&
                                UtilService.handleDebouncing(
                                    "updateStepDebouncing",
                                    () =>
                                        ApiService.updateStep(
                                            {
                                                id,
                                                title,
                                                end_date,
                                            },
                                            token
                                        ).then((response) => {
                                            if (response.status !== "OK") {
                                                this.setState({
                                                    showUpdateStepCostErrorModal: true,
                                                });
                                            } else {
                                                const notifications = response.data.notifications;

                                                if (notifications && Object.values(notifications).length) {
                                                    Object.values(notifications).forEach((notification: any) => {
                                                        if (!DISABLE_FIREBASE) {
                                                            Devkit.FirebaseService.sendNotification(
                                                                notification.action,
                                                                notification.item,
                                                                notification.link,
                                                                notification.read,
                                                                null,
                                                                notification.users
                                                            );
                                                        }
                                                    });
                                                }
                                            }
                                        }),
                                    300
                                );
                        }
                    }
                );
            } else {
                this.setState(
                    (prevState: any) => ({
                        form: {
                            ...prevState.form,
                            steps: [
                                ...prevState.form.steps.slice(0, index),
                                {
                                    ...prevState.form.steps[index],
                                    step_state_id: data.value,
                                },
                                ...prevState.form.steps.slice(index + 1),
                            ],
                        },
                        miniLoader: true,
                    }),
                    () => {
                        if (!this.state.newAction) {
                            const id = this.state.form.steps[index].id;
                            const step_state_id = this.state.form.steps[index].step_state_id;
                            const token = StoreService.getStoreProperty("token");
                            user.role !== 1 &&
                                ApiService.updateStep(
                                    {
                                        id,
                                        step_state_id,
                                    },
                                    token
                                ).then((response) => {
                                    if (response.status === "OK") {
                                        const step = response.data.step;
                                        const notifications = response.data.notifications;

                                        if (notifications && Object.values(notifications).length) {
                                            Object.values(notifications).forEach((notification: any) => {
                                                if (!DISABLE_FIREBASE) {
                                                    Devkit.FirebaseService.sendNotification(
                                                        notification.action,
                                                        notification.item,
                                                        notification.link,
                                                        notification.read,
                                                        null,
                                                        notification.users
                                                    );
                                                }
                                            });
                                        }

                                        this.setState((prevState: any) => ({
                                            form: {
                                                ...prevState.form,
                                                steps: [
                                                    ...prevState.form.steps.slice(0, index),
                                                    {
                                                        ...prevState.form.steps[index],
                                                        step_state_id: data.value,
                                                        updated_at: step.updated_at,
                                                    },
                                                    ...prevState.form.steps.slice(index + 1),
                                                ],
                                            },
                                            miniLoader: false,
                                        }));
                                    } else {
                                        alert("Something went wrong!");
                                    }
                                });
                        }
                    }
                );
            }
        }
    };

    handleAddStep = () => {
        const token = StoreService.getStoreProperty("token");
        const user = StoreService.getStoreProperty("user");
        const { steps } = this.state.form;

        if (steps.length > 0) {
            if (steps[steps.length - 1].title === "") {
                this.setState({
                    stepsErrorModal: true,
                });
                return;
            }
        }

        if (this.state.newAction || user.role === 1) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    steps: [
                        ...prevState.form.steps,
                        {
                            title: "",
                            errors: [],
                            step_state_id: 3,
                            end_date: null,
                        },
                    ],
                },
            }));
            console.log("usao");
        } else {
            ApiService.createStep(
                {
                    step_state_id: 2,
                    action_id: this.state.form.id.value,
                },
                token
            ).then((response) => {
                if (response.status === "OK") {
                    const notifications = response.data.notifications;

                    if (notifications && Object.values(notifications).length) {
                        Object.values(notifications).forEach((notification: any) => {
                            if (!DISABLE_FIREBASE) {
                                Devkit.FirebaseService.sendNotification(
                                    notification.action,
                                    notification.item,
                                    notification.link,
                                    notification.read,
                                    null,
                                    notification.users
                                );
                            }
                        });
                    }

                    this.setState((prevState: any) => ({
                        form: {
                            ...prevState.form,
                            steps: [
                                ...prevState.form.steps,
                                {
                                    title: "",
                                    errors: [],
                                    step_state_id: response.data.step.step_state_id,
                                    id: response.data.step.id,
                                    action_id: response.data.step.action_id,
                                    end_date: null,
                                },
                            ],
                        },
                    }));
                } else {
                    this.setState({
                        showUpdateStepCostErrorModal: true,
                    });
                }
            });
        }
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
        });
    };

    handleSuccessModal = (name) => {
        if (this.props.location?.state?.columnID || this.props.location?.state?.pathname) {
            this.setState((prevState: any) => ({
                [name]: !prevState[name],
            }));
            this.props.history.push({
                pathname: this.props.location.state.pathname,
                state: {
                    agile: true,
                },
            });
        } else {
            this.setState((prevState: any) => ({
                [name]: !prevState[name],
            }));
            this.props.template ? this.props.history.push("/templates/1") : this.props.history.push("/actions/1");
        }
    };

    cloneSuccessModal = (name) => {
        this.setState((prevState: any) => ({
            [name]: !prevState[name],
            cloneAction: false,
            showCloneModal: false,
        }));
        this.props.template
            ? this.props.history.push(`/template/${this.state.newTemplateId}`)
            : this.props.history.push(`/action/${this.state.newActionId}`);
    };

    handleFailureModal = (name) => {
        this.setState((prevState: any) => ({
            [name]: !prevState[name],
        }));
    };

    handleSaveAction = () => {
        const {
            id,
            startDate,
            title,
            endDate,
            priority,
            status,
            users,
            labels,
            remark,
            groups,
            actionPoints,
            customers,
            frequency,
            steps,
            costs,
            parent_folder_id,
            default_path_level,
            default_path,
        } = this.state.form;
        const form = {
            id,
            startDate,
            title,
            endDate,
            priority,
            status,
            remark,
            customers,
            frequency,
        };
        if (new Date(this.state.form.endDate.value) < new Date(this.state.form.startDate.value)) {
            this.setState({
                endDateErrorModal: true,
            });
            return;
        }

        const formattedCustomers = customers.value
            ? customers.value.map((customer) => {
                  return { customer_id: customer.value };
              })
            : [];

        const formattedUsers = users.value ? users.value.map((e) => ({ user_id: e.value })) : [];
        const formattedGroups = groups.value ? groups.value.map((e) => ({ group_id: e.value })) : [];
        const formattedLabels =
            labels.value &&
            labels.value.map((label: any) => {
                if (label.__isNew__) {
                    return { title: label.value };
                } else {
                    return { id: label.value };
                }
            });

        const errors = {};
        if (this.props.template) {
            delete form.startDate;
            delete form.endDate;
            delete form.status;
            delete form.customers;
        }
        Object.keys(form).forEach((e) => {
            if (e === "id" || (e === "remark" && !remark.value)) {
                remark.errors = [];
                id.errors = [];
            } else if (e === "frequency" && (!frequency.value || frequency.value === 0)) {
                frequency.errors = [];
            } else if (e === "priority" && (!priority.value || priority.value === 0)) {
                priority.errors = [];
            } else if (e === "customers") {
                customers.errors = [];
            } else if (
                !form[e].value ||
                (form[e].value.length && form[e].value.length === 0) ||
                (form[e].value.length && form[e].value[0].value === 0)
            ) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
        });

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else {
            const token = StoreService.getStoreProperty("token");
            const user = StoreService.getStoreProperty("user");
            if (this.state.newAction || this.state.cloneAction) {
                const template = !!this.props.template;
                let column_id = "";

                if (this.props.location?.state?.columnID) {
                    column_id = this.props.location.state.columnID;
                }
                ApiService.createNewAction(
                    template,
                    {
                        start_date: parseDateForMySQL(startDate.value, true),
                        title: title.value,
                        end_date: parseDateForMySQL(endDate.value, true),
                        priority: priority.value,
                        status: status.value,
                        point: actionPoints.value,
                        remark: remark.value,
                        frequency: frequency.value,
                        parent_folder_id: parent_folder_id.value,
                        default_path_level: default_path_level.value,
                        default_path: default_path.value,
                    },
                    !template && formattedCustomers,
                    !template && formattedUsers,
                    !template && formattedGroups,
                    steps.map((e) => ({
                        title: e.title,
                        step_state_id: e.step_state_id,
                        end_date: parseDateForMySQL(e.end_date, true),
                    })),
                    costs.map((e) => ({
                        title: e.title,
                        payerName: e.payerName,
                        recipientName: e.recipientName,
                        amount: e.amount,
                        emplooyes_percentages: e.user_groups.map((group) => ({
                            percentage: group.percentage,
                            employees: group.selectedEmployees.map((employee) => employee.value),
                        })),
                        // percentage: e.percentage,
                        user_id: e.user_id ? user.id : null,
                    })),
                    this.state.files,
                    formattedLabels,
                    column_id !== "" && column_id,
                    token
                ).then((response) => {
                    if (response.status === "OK") {
                        const notifications = response.data.notifications;

                        if (notifications && notifications.length) {
                            notifications.forEach((notification: any) => {
                                if (!DISABLE_FIREBASE) {
                                    Devkit.FirebaseService.sendNotification(
                                        notification.action,
                                        notification.item,
                                        notification.link,
                                        notification.read,
                                        null,
                                        notification.users
                                    );
                                }
                            });
                        }

                        if (response.data[0].template === 0) {
                            const actions = StoreService.getStoreProperty("actions");
                            actions.push(response.data[0]);
                            StoreService.updateStoreProperty("actions", actions);
                            if (this.state.cloneAction) {
                                this.setState({
                                    showCloneSuccessModal: true,
                                    newActionId: response.data[0].id,
                                });
                            } else {
                                this.setState({
                                    newActionId: response.data[0].id,
                                    showNewActionModal: true,
                                });
                            }
                        } else {
                            const templates = StoreService.getStoreProperty("templates");
                            templates.push(response.data[0]);
                            StoreService.updateStoreProperty("templates", templates);
                            if (this.state.cloneAction) {
                                this.setState({
                                    showCloneSuccessModal: true,
                                    newTemplateId: response.data[0].id,
                                });
                            } else {
                                this.setState({
                                    newTemplateId: response.data[0].id,
                                    showNewActionModal: true,
                                });
                            }
                        }

                        this.addBulkFilesOnCreate(response.data["0"].id);
                    } else {
                        if (this.state.cloneAction) {
                            this.setState({
                                showCloneErrorModal: true,
                            });
                        } else {
                            this.setState({
                                showNewActionErrorModal: true,
                            });
                        }
                    }
                });
            } else {
                const template = !!this.props.template;
                ApiService.updateAction(
                    template,
                    {
                        id: id.value,
                        start_date: parseDateForMySQL(startDate.value, true),
                        title: title.value,
                        end_date: parseDateForMySQL(endDate.value, true),
                        priority: priority.value,
                        point: actionPoints.value,
                        status: status.value,
                        remark: remark.value,
                        frequency: frequency.value,
                        parent_folder_id: parent_folder_id.value,
                        default_path_level: default_path_level.value,
                        default_path: default_path.value,
                    },
                    !template && formattedCustomers,
                    !template && formattedUsers,
                    !template && groups.value && groups.value.map((e) => e.value),
                    formattedLabels ? formattedLabels : [],
                    token
                ).then((response) => {
                    if (response.status === "OK") {
                        const notifications = response.data.notifications;

                        if (notifications && Object.values(notifications).length) {
                            Object.values(notifications).forEach((notification: any) => {
                                if (!DISABLE_FIREBASE) {
                                    Devkit.FirebaseService.sendNotification(
                                        notification.action,
                                        notification.item,
                                        notification.link,
                                        notification.read,
                                        null,
                                        notification.users
                                    );
                                }
                            });
                        }

                        if (!template) {
                            const actions = StoreService.getStoreProperty("actions");
                            const newActions = actions.map((e) => {
                                if (e.id === this.state.form.id.value) {
                                    return {
                                        ...e,
                                        startDate: startDate.value,
                                        title: title.value,
                                        endDate: endDate.value,
                                        priority: priority.value,
                                        status: status.value,
                                        point: actionPoints.value,
                                        users: users,
                                        remark: remark.value,
                                        customers: customers,
                                        frequency: frequency.value,
                                        parent_folder_id: parent_folder_id.value,
                                        default_path_level: default_path_level.value,
                                        default_path: default_path.value,
                                    };
                                } else {
                                    return e;
                                }
                            });
                            StoreService.updateStoreProperty("actions", newActions);
                            this.setState({
                                showUpdateSuccessModal: true,
                            });
                        } else {
                            const templates = StoreService.getStoreProperty("templates");
                            const newTemplates = templates.map((e) => {
                                if (e.id === this.state.form.id.value) {
                                    return {
                                        ...e,
                                        startDate: startDate.value,
                                        title: title.value,
                                        endDate: endDate.value,
                                        priority: priority.value,
                                        users: users,
                                        point: actionPoints.value,
                                        remark: remark.value,
                                        customers: customers,
                                        frequency: frequency.value,
                                        status: status.value,
                                    };
                                } else {
                                    return e;
                                }
                            });
                            StoreService.updateStoreProperty("templates", newTemplates);
                        }
                        this.setState({
                            showUpdateSuccessModal: true,
                        });
                    } else {
                        this.setState({
                            showUpdateErrorModal: true,
                        });
                    }
                });
            }
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const {
            form: { customers },
        } = this.state;
        if (prevProps.match.params.id !== this.props.match.params.id && this.props.match.params.id) {
            this.setState({ newAction: false });
        }

        if (prevState.form.groups.value !== this.state.form.groups.value) {
            this.checkGroups();
        }

        if (!this.props.template && customers.value && prevState.form.customers.value !== customers.value) {
            const clickedCustomer: any =
                customers.value[0] &&
                this.state.customers.find((c: any) => c.id.toString() === customers.value[0].value.toString());

            if (clickedCustomer) {
                this.setState((prev: any) => ({
                    form: {
                        ...prev.form,
                        default_path: {
                            value: clickedCustomer.default_path,
                            errors: [],
                        },
                        default_path_level: {
                            value: clickedCustomer.default_path_level,
                            errors: [],
                        },
                        parent_folder_id: {
                            value: clickedCustomer.parent_folder_id,
                            errors: [],
                        },
                    },
                    clickedCustomer,
                }));
            }
        }

        if (!this.props.template && prevState.form.customers !== customers && !customers.value) {
            this.getDefaultPath();
        }

        if (prevState.file.value !== this.state.file.value && this.state.file.value) {
            this.setState({ uploading: true });
            this.handleAddDocument();
        }
    }

    checkGroups = () => {
        const token = StoreService.getStoreProperty("token");
        ApiService.getAllGroups(token).then((res) => {
            if (res.status === "OK") {
                const groupsArray = res.data.map((e) => ({
                    value: e.id,
                    label: e.title,
                }));
                let usersForDropdownArray: any = [];
                const dropdownGroupIDs =
                    this.state.form.groups.value &&
                    this.state.form.groups.value.map((e) => {
                        return e.value;
                    });
                let filteredGroups =
                    dropdownGroupIDs && dropdownGroupIDs.length
                        ? res.data.filter((e) => dropdownGroupIDs.includes(e.id))
                        : res.data;

                filteredGroups &&
                    filteredGroups.forEach((e: any) => {
                        usersForDropdownArray.push(...e.members);
                    });
                let uniqueUsers = usersForDropdownArray.reduce((acc: any, c: any) => {
                    if (acc.findIndex((el: any) => el.id === c.id) === -1) {
                        acc.push(c);
                        return acc;
                    }
                    return acc;
                }, []);
                let dropdownUsers = uniqueUsers.map((e) => {
                    return { value: e.id, label: `${e.firstName} ${e.lastName}` };
                });
                this.setState({
                    usersArray: dropdownUsers,
                    groupsArray,
                    allGroups: res.data,
                });
            }
        });
    };

    handleDeleteAction = () => {
        const token = StoreService.getStoreProperty("token");
        this.toggleModal("showDeleteModal");
        !this.props.template
            ? ApiService.deleteAction(
                  {
                      id: this.props.match.params.id,
                  },
                  token
              ).then((response) => {
                  if (response.status === "OK") {
                      const actions = StoreService.getStoreProperty("actions");
                      const newActions = Object.values(actions).filter((item: any) => {
                          return parseInt(item.id) !== parseInt(this.props.match.params.id);
                      });
                      const notifications = response.data.notifications;

                      if (notifications && Object.values(notifications).length) {
                          Object.values(notifications).forEach((notification: any) => {
                              if (!DISABLE_FIREBASE) {
                                  Devkit.FirebaseService.sendNotification(
                                      notification.action,
                                      notification.item,
                                      notification.link,
                                      notification.read,
                                      null,
                                      notification.users
                                  );
                              }
                          });
                      }

                      this.setState({
                          actions: newActions,
                          showDeleteSuccess: true,
                      });
                      StoreService.updateStoreProperty("actions", newActions);
                  } else {
                      this.setState({
                          showDeleteFailure: true,
                      });
                  }
              })
            : ApiService.deleteAction(
                  {
                      id: this.props.match.params.id,
                      template: true,
                  },
                  token
              ).then((response) => {
                  if (response.status === "OK") {
                      const templates = StoreService.getStoreProperty("templates");
                      const newTemplates = Object.values(templates).filter((item: any) => {
                          return parseInt(item.id) !== parseInt(this.props.match.params.id);
                      });
                      const notifications = response.data.notifications;

                      if (notifications && Object.values(notifications).length) {
                          Object.values(notifications).forEach((notification: any) => {
                              if (!DISABLE_FIREBASE) {
                                  Devkit.FirebaseService.sendNotification(
                                      notification.action,
                                      notification.item,
                                      notification.link,
                                      notification.read,
                                      null,
                                      notification.users
                                  );
                              }
                          });
                      }

                      this.setState({
                          templates: newTemplates,
                          showDeleteSuccess: true,
                      });
                      StoreService.updateStoreProperty("templates", newTemplates);
                      this.setState({
                          templates: newTemplates,
                          showDeleteSuccess: true,
                      });
                  } else {
                      this.setState({
                          showDeleteFailure: true,
                      });
                  }
              });
    };

    deleteStep = (index) => {
        const token = StoreService.getStoreProperty("token");
        const user = StoreService.getStoreProperty("user");
        if (this.state.newAction || user.role === 1) {
            const steps = this.state.form.steps.filter((e, i) => i !== index);
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    steps,
                },
                showDeleteStepModal: false,
            }));
        } else {
            ApiService.deleteStep(
                {
                    id: this.state.form.steps[index].id,
                },
                token
            ).then((response) => {
                if (response.status === "OK") {
                    const steps = this.state.form.steps.filter((e, i) => i !== index);
                    const notifications = response.data.notifications;

                    if (notifications && Object.values(notifications).length) {
                        Object.values(notifications).forEach((notification: any) => {
                            if (!DISABLE_FIREBASE) {
                                Devkit.FirebaseService.sendNotification(
                                    notification.action,
                                    notification.item,
                                    notification.link,
                                    notification.read,
                                    null,
                                    notification.users
                                );
                            }
                        });
                    }

                    this.setState((prevState: any) => ({
                        form: {
                            ...prevState.form,
                            steps,
                        },
                        showDeleteStepModal: false,
                    }));
                }
            });
        }
    };

    deleteCost = (index) => {
        const token = StoreService.getStoreProperty("token");
        const user = StoreService.getStoreProperty("user");
        if (this.state.newAction || user.role === 1) {
            const costs = this.state.form.costs.filter((e, i) => i !== index);
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    costs,
                },
                showDeleteCostModal: false,
            }));
        } else {
            ApiService.deleteCost(
                {
                    id: this.state.form.costs[index].id,
                },
                token
            ).then((response) => {
                if (response.status === "OK") {
                    const costs = this.state.form.costs.filter((e, i) => i !== index);
                    const notifications = response.data.notifications;

                    if (notifications && Object.values(notifications).length) {
                        Object.values(notifications).forEach((notification: any) => {
                            if (!DISABLE_FIREBASE) {
                                Devkit.FirebaseService.sendNotification(
                                    notification.action,
                                    notification.item,
                                    notification.link,
                                    notification.read,
                                    null,
                                    notification.users
                                );
                            }
                        });
                    }

                    this.setState((prevState: any) => ({
                        form: {
                            ...prevState.form,
                            costs,
                        },
                        showDeleteCostModal: false,
                    }));
                }
            });
        }
    };

    deleteFile = (index) => {
        const token = StoreService.getStoreProperty("token");

        if (this.state.newAction) {
            const files = this.state.form.files.filter((e, i) => i !== index);
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    files,
                },
                showDeleteFileModal: false,
            }));
        } else {
            ApiService.deleteFile(
                {
                    file_id: this.state.form.files[index].id,
                    action_id: this.state.form.id.value,
                },
                token
            ).then((response) => {
                if (response.status === "OK") {
                    const notifications = response.data.notifications;

                    if (notifications && Object.values(notifications).length) {
                        Object.values(notifications).forEach((notification: any) => {
                            if (!DISABLE_FIREBASE) {
                                Devkit.FirebaseService.sendNotification(
                                    notification.action,
                                    notification.item,
                                    notification.link,
                                    notification.read,
                                    null,
                                    notification.users
                                );
                            }
                        });
                    }

                    const files = this.state.form.files.filter((e, i) => i !== index);
                    this.setState((prevState: any) => ({
                        form: {
                            ...prevState.form,
                            files,
                        },
                        showDeleteFileModal: false,
                    }));
                }
            });
        }
    };

    handleRedirectToNew = () => {
        this.setState({
            templateConvert: true,
            newAction: true,
            showConvertModal: false,
        });
        this.props.history.push("/new-action");
    };

    convertToTemplate = () => {
        this.setState({
            actionConvert: true,
            newAction: true,
            showConvertModal: false,
        });
        this.props.history.push("/new-template");
    };

    handleFileChange = (name, e, drop?) => {
        const { newAction } = this.state;

        if (newAction && name === "file") {
            const formFile = UtilService.handleAddFile(name, e, drop)!;
            const type = formFile.value.name.split(".").pop();

            this.setState((prev: any) => ({
                filesBulk: [...prev.filesBulk, formFile],
                form: {
                    ...prev.form,
                    files: [...prev.form.files, { title: formFile.value.name, type, id: 1 }],
                },
            }));
        } else {
            this.setState({
                [name]: UtilService.handleAddFile(name, e, drop),
            });
        }
    };

    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };

    handleIcon = (type) => {
        return FileManagerIcons.getIcon(type, { width: 25 });
    };

    renderSteps = (e, index) => {
        return (
            <div className="step d-flex flex-column" key={index}>
                <div className="row">
                    <div className="col-6 mt-30">
                        <CustomInput
                            name="title"
                            label="action"
                            type="text"
                            handleInput={this.handleStepInput}
                            value={e.title}
                            errors={e.errors}
                            //required
                            labelClass="label"
                            errorClass="error error-s"
                            index={index}
                        />
                    </div>
                    <div className="col-6 mt-30">
                        <CustomDropdown
                            data={this.state.steps_states}
                            name="stepsDropdown"
                            value={this.state.steps_states.find((el) => el.value === e.step_state_id)}
                            handleChange={this.handleStepInput}
                            label={"STEP_STATE"}
                            index={index}
                            disabled={this.props.template}
                        />
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-6 mt-30">
                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
                        <CustomDateTimePicker
                            name="end_date"
                            handleInput={this.handleStepInput}
                            value={e.end_date}
                            label={"END_DATE"}
                            disabled={this.props.template}
                            index={index}
                        />
                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
                    </div>
                    {/* <div className="mt-40 mr-15"> */}
                    <div className="col-1 d-flex align-items-center mt-30 justify-content-end">
                        {/* <i
                            className="fa fa-minus trash-icon p-5 border-1 pointer border-radius-6 align-self-center" */}
                        <i
                            className="fas  fa-trash trash-icon w-40 p-14 f-s-12 border-1 pointer border-radius-6 align-self-center"
                            onClick={() => {
                                this.setState({
                                    itemIndex: index,
                                });
                                this.toggleModal("showDeleteStepModal");
                            }}
                        />
                    </div>
                </div>
                {e.step_state_id === 1 && (
                    <div className="d-flex align-items-center f-s-12 mt-5">
                        <span className="finishedStep">
                            <Translate text="STEP_COMPLETED" />:
                        </span>
                        <span className="ml-5">
                            {this.state.miniLoader ? (
                                <Loader width={20} />
                            ) : !this.state.newAction ? (
                                parseDateWithoutSeconds(e.updated_at)
                            ) : (
                                parseDateWithoutSeconds(new Date())
                            )}
                        </span>
                    </div>
                )}
            </div>
        );
    };

    handleStepSort = (steps) => {
        this.setState(
            (prevState: any) => ({
                form: {
                    ...prevState.form,
                    steps,
                },
            }),
            () => {
                if (!this.state.newAction) {
                    const steps = this.state.form.steps.map((e, index) => ({
                        id: e.id,
                        order: index,
                    }));
                    const token = StoreService.getStoreProperty("token");
                    //TODO debounce api call
                    UtilService.handleDebouncing(
                        "sortStepDebouncing",
                        () => ApiService.sortSteps({ steps }, token).then(() => {}),
                        300
                    );
                }
            }
        );
    };

    changeFolder = () => {
        const parent_folder_id = this.state.destinationID;
        const default_path = this.state.destinationPath;
        const default_path_level = this.state.destinationLevel;
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                parent_folder_id: {
                    value: parent_folder_id,

                    errors: [],
                },
                default_path: {
                    value: default_path,
                    errors: [],
                },
                default_path_level: {
                    value: default_path_level,
                    errors: [],
                },
            },
            changeFolderModal: false,
        }));
    };

    handleDestinationID = (destinationID, destinationPath, destinationLevel) => {
        this.setState({ destinationID, destinationPath, destinationLevel });
    };

    handleGetStructure = () => {
        const token = StoreService.getStoreProperty("token");

        ApiService.getStructure(token).then((res) => {
            if ((res.status = "OK")) {
                this.setState({ folderStructure: res.data[0] });
            }
        });
    };

    getDefaultStructure = (id) => {
        const token = StoreService.getStoreProperty("token");

        ApiService.getDefaultFolderStructure(id, token).then((res) => {
            if ((res.status = "OK")) {
                this.setState({
                    folderStructure: res.data[0],
                });
            }
        });
    };

    cloneAction = () => {
        this.setState(
            {
                cloneAction: true,
            },
            () => {
                this.handleSaveAction();
            }
        );
    };

    getClientDetails = () => {
        const { form } = this.state;
        const customers = StoreService.getStoreProperty("customers");

        let actionCustomers = [] as any;

        if (form.customers.value && form.customers.value[0]) {
            const customerIds = form.customers.value.map((customer) => {
                return customer.value;
            });

            for (let i = 0; i <= customerIds.length; i++) {
                const current = customers.find((c: any) => c.id === customerIds[i]);

                if (current) {
                    actionCustomers.push(current);
                }
            }
        }

        return actionCustomers;
    };
    downloadFile = (e) => {
        const name = e.title;
        const id = e.id;
        const token = StoreService.getStoreProperty("token");

        this.setState({ activeDownload: [{ name }] }, () => {
            const config = {
                onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({
                        completedList: [{ completed: percentCompleted }],
                    });
                },
            };
            ApiService.downloadFile({ id }, config, token).then((res) => {
                if (!res) return;
                const blobURL = window.webkitURL.createObjectURL(res);
                const link = document.createElement("a");
                link.href = blobURL;
                link.setAttribute("download", name); //or any other extension
                document.body.appendChild(link);
                this.setState({ IFrameURL: link });
                link.click();
            });
        });
    };
    previewFile = (e) => {
        const name = e.title;
        const id = e.id;
        const type = e.type;
        const token = StoreService.getStoreProperty("token");
        this.setState({ activeDownload: [{ name }], fileName: name }, () => {
            const config = {
                onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({
                        completedList: [{ completed: percentCompleted }],
                    });
                },
            };
            ApiService.downloadFile({ id }, config, token).then((res) => {
                if (!res) return;
                const blobURL = window.webkitURL.createObjectURL(res);
                const link = document.createElement("a");
                link.href = blobURL;
                link.setAttribute("download", name);
                this.setState({ IFrameURL: link });
            });
        });
        if (type !== "docx") this.toggleModal("showIFrameModalContent");
    };

    showLogs = (e) => {
        e.preventDefault();

        const token = StoreService.getStoreProperty("token");
        let item_id = this.state.form.id.value;
        ApiService.getSpecificLogs({ item_id, table: 1 }, token).then((res) => {
            if (res.status === "OK") {
                this.setState({ logs: res.data, showLogsSidebar: !this.state.showLogsSidebar });
            }
        });
    };

    renderLogs = () => {
        return (
            <LogsSidebar
                opened={this.state.showLogsSidebar}
                onClose={() => this.setState({ showLogsSidebar: !this.state.showLogsSidebar })}
                sidebarClasses="clientsFilterSidebar"
                wrapperClasses="clientsFilterWrapper"
                items={this.state.logs}
                deleteLog={(id) => this.deleteLog(id)}
            ></LogsSidebar>
        );
    };

    deleteLog = (id) => {
        const token = StoreService.getStoreProperty("token");
        let item_id = this.state.form.id.value;
        ApiService.deleteLog({ id }, token).then(() => {
            ApiService.getSpecificLogs({ item_id, table: 1 }, token).then((res) => {
                if (res.status === "OK") {
                    this.setState({ logs: res.data });
                }
            });
        });
    };

    render() {
        const usersStorage = StoreService.getStoreProperty("users");
        const isLoading = this.state.isLoading;
        const user = StoreService.getStoreProperty("user");
        const { users, labels, customers, groups, files } = this.state.form;
        const { IFrameURL, showIFrameModalContent } = this.state;
        const employees = usersStorage.filter((user) => user.role !== 3);
        const formattedEmployees = employees.map((employee) => ({
            value: employee.id,
            label: employee.firstName + " " + employee.lastName,
        }));

        const usersFiltered = users.value && users.value[0] && users.value.filter((e) => e.label !== "");
        const groupsFiltered = groups.value && groups.value[0] && groups.value.filter((e) => e.label !== "");

        const customersFiltered =
            customers.value && customers.value[0] && customers.value.filter((customer) => customer.label !== "");

        let labelsFiltered = labels.value && labels.value.filter((e) => e.label !== "");

        if (isLoading) {
            return (
                <div className="loaderContainer">
                    <Loader width={200} />
                </div>
            );
        } else {
            return (
                <div id="action-details-wrapper" className="d-flex flex-column h-100vh p-30">
                    <div className="logs">{this.renderLogs()}</div>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showIFrameModalContent"
                        className={showIFrameModalContent ? "visible" : ""}
                        modalWrapperClasses="w-70-perc h-80-perc overflow-hidden d-flex flex-column pt-10"
                    >
                        <h3 className="align-self-center">{this.state.fileName}</h3>
                        <div className="justify-content-center align-items-center w-100-perc h-100-perc">
                            <iframe
                                src={IFrameURL}
                                width="100%"
                                height="100%"
                                className="p-10 border-none"
                                title="IFrame"
                            ></iframe>
                        </div>
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showCloneModal"
                        className={this.state.showCloneModal ? "visible " : ""}
                        modalWrapperClasses="max-width-750 padding-10 "
                    >
                        <ModalContent
                            type="warning"
                            text={this.props.template ? "CLONE_TEMPLATE_?" : "CLONE_ACTION_?"}
                            modalName="showCloneModal"
                            title={this.props.template ? "CLONE_TEMPLATE" : "CLONE_ACTION"}
                            toggleModal={this.toggleModal}
                            deleteItem={this.cloneAction}
                            cancelDelete={this.toggleModal}
                            buttonText="CLONE"
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showCloneSuccessModal"
                        className={this.state.showCloneSuccessModal ? "visible" : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            toggleModal={this.cloneSuccessModal}
                            text={this.props.template ? "CLONE_TEMPLATE_SUCCESS" : "CLONE_ACTION_SUCCESS"}
                            modalName="showCloneSuccessModal"
                            title={"SUCCESS"}
                            type={"success"}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showCloneErrorModal"
                        className={this.state.showCloneErrorModal ? "visible bg-info-3" : ""}
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text={this.props.template ? "CLONE_TEMPLATE_FAILURE" : "CLONE_ACTION_FAILURE"}
                            modalName="showCloneErrorModal"
                            title="FAILED"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showDeleteModal"
                        className={this.state.showDeleteModal ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="warning"
                            text={!this.props.template ? "areYouSureDeleteAction" : "areYouSureDeleteTemplate"}
                            modalName="showDeleteModal"
                            title={!this.props.template ? "DELETE_ACTION" : "DELETE_TEMPLATE"}
                            toggleModal={this.toggleModal}
                            deleteItem={this.handleDeleteAction}
                            cancelDelete={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showConvertModal"
                        className={this.state.showConvertModal ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="warning"
                            text={!this.props.template ? "CONVERT_ACTION_?" : "CONVERT_TEMPLATE_?"}
                            modalName="showConvertModal"
                            title={!this.props.template ? "CONVERT_ACTION" : "CONVERT_TEMPLATE"}
                            toggleModal={this.toggleModal}
                            buttonText="CONVERT"
                            deleteItem={this.props.template ? this.handleRedirectToNew : this.convertToTemplate}
                            cancelDelete={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showDeleteStepModal"
                        className={this.state.showDeleteStepModal ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="warning"
                            text="DELETE_STEP_?"
                            modalName="showDeleteStepModal"
                            title="DELETE_STEP"
                            toggleModal={this.toggleModal}
                            item={this.state.itemIndex}
                            deleteItem={this.deleteStep}
                            cancelDelete={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showDeleteCostModal"
                        className={this.state.showDeleteCostModal ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="warning"
                            text="DELETE_COST_?"
                            modalName="showDeleteCostModal"
                            title="DELETE_COST"
                            toggleModal={this.toggleModal}
                            item={this.state.costItemIndex}
                            deleteItem={this.deleteCost}
                            cancelDelete={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showDeleteFileModal"
                        className={this.state.showDeleteFileModal ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="warning"
                            text="DELETE_FILE_?"
                            modalName="showDeleteFileModal"
                            title="DELETE_FILE"
                            toggleModal={this.toggleModal}
                            item={this.state.fileIndex}
                            deleteItem={this.deleteFile}
                            cancelDelete={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.handleSuccessModal}
                        modalName="showDeleteSuccess"
                        className={this.state.showDeleteSuccess ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="success"
                            text={!this.props.template ? "ACTION_DELETE_SUCCESS" : "TEMPLATE_DELETE_SUCCESS"}
                            modalName="showDeleteSuccess"
                            title="SUCCESS"
                            toggleModal={this.handleSuccessModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.handleFailureModal}
                        modalName="showDeleteFailure"
                        className={this.state.showDeleteFailure ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="failure"
                            text={!this.props.template ? "ACTION_DELETE_FAILURE" : "TEMPLATE_DELETE_FAILURE"}
                            modalName="showDeleteFailure"
                            title="FAILURE"
                            toggleModal={this.handleFailureModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.handleFailureModal}
                        modalName="stepsErrorModal"
                        className={this.state.stepsErrorModal ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="failure"
                            text={"CANNOT_ADD_NEW_STEP_IF_PREVIOUS_STEP_IS_NOT_FILLED"}
                            modalName="stepsErrorModal"
                            title="FAILURE"
                            toggleModal={this.handleFailureModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.handleFailureModal}
                        modalName="costsErrorModal"
                        className={this.state.costsErrorModal ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="failure"
                            text={"CANNOT_ADD_NEW_COST_IF_PREVIOUS_COST_IS_NOT_FILLED"}
                            modalName="costsErrorModal"
                            title="FAILURE"
                            toggleModal={this.handleFailureModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showUpdateSuccessModal"
                        className={this.state.showUpdateSuccessModal ? "visible" : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            toggleModal={this.handleSuccessModal}
                            text={
                                this.state.showUpdateSuccessModal && !this.props.template
                                    ? "updateActionSuccess"
                                    : "updateTemplateSuccess"
                            }
                            modalName="showUpdateSuccessModal"
                            title={"SUCCESS"}
                            type={"success"}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.handleFailureModal}
                        modalName="showUpdateErrorModal"
                        className={this.state.showUpdateErrorModal ? "visible bg-info-3" : ""}
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text={!this.props.template ? "updateActionError" : "updateTemplateError"}
                            modalName="showUpdateErrorModal"
                            title="FAILURE"
                            toggleModal={this.handleFailureModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showNewActionModal"
                        className={this.state.showNewActionModal ? "visible" : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            toggleModal={this.handleSuccessModal}
                            text={!this.props.template ? "newActionSuccess" : "newTemplateSuccess"}
                            modalName="showNewActionModal"
                            title={"SUCCESS"}
                            type={"success"}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.handleFailureModal}
                        modalName="showNewActionErrorModal"
                        className={this.state.showNewActionErrorModal ? "visible bg-info-3" : ""}
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text={!this.props.template ? "newActionError" : "newTemplateError"}
                            modalName="showNewActionErrorModal"
                            title="FAILURE"
                            toggleModal={this.handleFailureModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.handleFailureModal}
                        modalName="showUpdateStepCostErrorModal"
                        className={this.state.showUpdateStepCostErrorModal ? "visible bg-info-3" : ""}
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text="FAILED"
                            modalName="showUpdateStepCostErrorModal"
                            title="FAILURE"
                            toggleModal={this.handleFailureModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.handleFailureModal}
                        modalName="fileUploadErrorModal"
                        className={this.state.fileUploadErrorModal ? "visible bg-info-3" : ""}
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text="FILE_UPLOAD_ERROR"
                            modalName="fileUploadErrorModal"
                            title="FAILURE"
                            toggleModal={this.handleFailureModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="changeFolderModal"
                        className={this.state.changeFolderModal ? "visible " : ""}
                        modalWrapperClasses="folderStructureWrapper"
                    >
                        <h2 className="text-center border-b-1 py-10 text-color-primary">
                            <Translate text="CHANGE_DEFAULT_FOLDER" />
                        </h2>
                        <FolderStructure
                            data={this.state.folderStructure}
                            selectedItem={`${this.state.contextItemName.value}${this.state.contextItemExt}`}
                            handleDestinationID={this.handleDestinationID}
                        />
                        <div className="button h-60 d-flex justify-content-center align-items-center border-t-1">
                            <CustomButton className="btn btn-save" onClick={this.changeFolder}>
                                <Translate text="CHANGE" />
                            </CustomButton>
                            <CustomButton
                                className="btn btn-cancel ml-30"
                                onClick={() => this.toggleModal("changeFolderModal")}
                            >
                                <Translate text="CANCEL" />
                            </CustomButton>
                        </div>
                    </Modal>
                    <Modal
                        toggleModal={this.handleFailureModal}
                        modalName="tooBigPercentageModal"
                        className={this.state.tooBigPercentageModal ? "visible bg-info-3" : ""}
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text="Total percentage exceeds 100%"
                            modalName="tooBigPercentageModal"
                            title="FAILURE"
                            toggleModal={this.handleFailureModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.handleFailureModal}
                        modalName="endDateErrorModal"
                        className={this.state.endDateErrorModal ? "visible bg-info-3" : ""}
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text="End date cannot be lower than start date!"
                            modalName="endDateErrorModal"
                            title="FAILURE"
                            toggleModal={this.handleFailureModal}
                        />
                    </Modal>
                    <div className="d-flex flex-row h-40 justify-content-between align-items-center mb-30">
                        <div className="d-flex align-items-center">
                            <button
                                onClick={this.handleBack}
                                className="pointer d-flex align-items-center mr-10 goBack"
                            >
                                <BackIcon width={25} />
                            </button>
                            <span className="actionTitle mr-30 f-s-30 bold theme-main-1">
                                {this.state.form.title.value}
                            </span>
                        </div>
                        <div className={`d-flex ${user.role === 1 ? "disabledDiv" : ""}`}>
                            <ShowMore>
                                <span className="one-line-text" onClick={this.handleSaveAction}>
                                    <SaveIcon width={16} className="mr-10 important-3" />
                                    <Translate
                                        text={
                                            this.state.newAction && !this.props.template
                                                ? "CREATE_ACTION"
                                                : this.state.newAction && this.props.template
                                                ? "CREATE_TEMPLATE"
                                                : "saveChanges"
                                        }
                                    />
                                </span>
                                <span
                                    onClick={() => {
                                        this.setState({
                                            showCloneModal: true,
                                        });
                                    }}
                                >
                                    <CopyIcon width={16} className="mr-10 important-3" />
                                    <Translate text="CLONE" />
                                </span>
                                <span
                                    onClick={() => {
                                        this.setState({
                                            showConvertModal: true,
                                        });
                                    }}
                                >
                                    <ConvertIcon width={16} className="mr-10" />
                                    <Translate text={"convert"} />
                                </span>
                                <span onClick={this.showLogs}>
                                    <ActivitiesIcon width={16} className="mr-10" />
                                    <Translate text="SHOW_LOGS" />
                                </span>
                                <span
                                    className={`showMoreDelete ${user.role !== 3 ? "disabledDiv" : ""}`}
                                    onClick={() => {
                                        this.toggleModal("showDeleteModal");
                                    }}
                                >
                                    <FileDeleteIcon width={16} className="mr-10" />
                                    <Translate text={"DELETE"} />
                                </span>
                            </ShowMore>
                        </div>
                    </div>
                    <div className="">
                        <CustomForm className="row">
                            <div className="rightSide d-flex col-xl-5 flex-column mt-30">
                                <div className="basicInfo d-flex row justify-content-between">
                                    <div className="d-flex flex-column col-8">
                                        <div className="mb-30">
                                            <CustomInput
                                                name="title"
                                                label={!this.props.template ? "actionName" : "templateName"}
                                                type="text"
                                                handleInput={this.handleInput}
                                                value={this.state.form.title.value}
                                                errors={this.state.form.title.errors}
                                                required
                                            />
                                        </div>
                                        <div className="mb-30">
                                            <CustomDateTimePicker
                                                name="startDate"
                                                handleInput={this.handleInput}
                                                errors={this.props.template ? [] : this.state.form.startDate.errors}
                                                value={new Date(this.state.form.startDate.value)}
                                                label={"startDate"}
                                                disabled={this.props.template}
                                            />
                                        </div>
                                        <div className="mb-30">
                                            <CustomDateTimePicker
                                                name="endDate"
                                                handleInput={this.handleInput}
                                                errors={this.props.template ? [] : this.state.form.endDate.errors}
                                                value={new Date(this.state.form.endDate.value)}
                                                label="executionDate"
                                                disabled={this.props.template}
                                            />
                                        </div>
                                        <div>
                                            <CustomDropdown
                                                data={this.state.labelsArray}
                                                className="multiple"
                                                name="labels"
                                                value={labelsFiltered}
                                                handleChange={this.handleInput}
                                                disabled={this.props.template}
                                                multiple
                                                label={"Labels"}
                                                creatable
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4 d-flex flex-column">
                                        <div className="mb-30">
                                            <CustomDropdown
                                                data={this.state.action_states}
                                                name="status"
                                                placeholder={<Translate text="STATE" />}
                                                value={this.state.action_states.find(
                                                    (e: any) => e.value === this.state.form.status.value
                                                )}
                                                handleChange={this.handleInput}
                                                label="STATE"
                                            />
                                        </div>
                                        <div className="mb-30">
                                            <CustomDropdown
                                                data={this.state.states}
                                                name="priority"
                                                placeholder={<Translate text="STATE" />}
                                                value={this.state.states.find(
                                                    (e: any) => e.value === this.state.form.priority.value
                                                )}
                                                handleChange={this.handleInput}
                                                label="PRIORITY"
                                            />
                                        </div>
                                        <div className="mb-30">
                                            <CustomDropdown
                                                data={this.state.action_repetition}
                                                name="frequency"
                                                placeholder={<Translate text="REPETITION" />}
                                                value={this.state.action_repetition.find(
                                                    (e: any) => e.value === this.state.form.frequency.value
                                                )}
                                                handleChange={this.handleInput}
                                                label="REPETITION"
                                            />
                                        </div>
                                        <div>
                                            {!this.props.template && (
                                                <CustomInput
                                                    name="actionPoints"
                                                    label="actionPoints"
                                                    type="number"
                                                    handleInput={this.handleInput}
                                                    value={this.state.form.actionPoints.value}
                                                    errors={this.state.form.actionPoints.errors}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={`row ${this.state.form.users.errors[0] === "" ? "mt-30" : "mt-30"} ${
                                        this.props.template ? "mt-30" : "mt-30"
                                    }`}
                                ></div>
                                <div className="row">
                                    <div className="col-12">
                                        <CustomDropdown
                                            data={this.state.usersArray}
                                            className="multiple"
                                            name="users"
                                            value={usersFiltered}
                                            handleChange={this.handleInput}
                                            errors={this.props.template ? [] : this.state.form.users.errors}
                                            disabled={this.props.template}
                                            multiple
                                            label={"assignedEmployee"}
                                            // isOpen
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`row ${this.state.form.users.errors[0] === "" ? "mt-10" : "mt-30"} ${
                                        this.props.template ? "mt-30" : ""
                                    }`}
                                >
                                    <div className="col-12">
                                        <CustomDropdown
                                            data={this.state.customersArray}
                                            name="customers"
                                            placeholder={<Translate text="CLIENTS" />}
                                            value={customersFiltered}
                                            className="multiple"
                                            handleChange={this.handleInput}
                                            errors={this.props.template ? [] : this.state.form.customers.errors}
                                            label={"assignedClients"}
                                            disabled={this.props.template}
                                            multiple
                                        />
                                    </div>
                                </div>

                                <div className="row mt-30">
                                    <div className="col-12">
                                        <CustomDropdown
                                            data={this.state.groupsArray}
                                            className="multiple"
                                            name="groups"
                                            value={groupsFiltered}
                                            handleChange={this.handleInput}
                                            errors={this.props.template ? [] : this.state.form.users.errors}
                                            disabled={this.props.template}
                                            multiple
                                            label="ASSIGNED_GROUPS"
                                            // isOpen
                                        />
                                    </div>
                                </div>

                                <ClientsSummaries customers={this.getClientDetails()}></ClientsSummaries>

                                {/* STEPS IN ACTION */}
                                <div className="row mt-30">
                                    <div className="col-12 d-flex justify-content-between">
                                        <span className="smallerFont f-s-16 bold theme-main-1">
                                            <Translate text={"stepsInAction"} />
                                        </span>
                                        <div className="align-self-center">
                                            <CustomButton
                                                className="btn-secondary small smallerButton"
                                                type="button"
                                                onClick={this.handleAddStep}
                                            >
                                                <Translate text="addStep" />
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                                <SortableList
                                    items={this.state.form.steps}
                                    render={this.renderSteps}
                                    handleSort={this.handleStepSort}
                                />
                                {/* Comments */}
                                <Comments
                                    actionID={this.props.match.params.id}
                                    allComments={this.state.actionComments}
                                />
                            </div>

                            <div className="col-xl-7 mt-30">
                                <div className="row">
                                    <div className="col-12 mb-30">
                                        <CustomTextArea
                                            name="remark"
                                            label="description"
                                            handleInput={this.handleInput}
                                            value={this.state.form.remark.value}
                                            errors={this.state.form.remark.errors}
                                            className="non-resizable"
                                        />
                                    </div>
                                </div>
                                <div className={`${user.role === 1 ? "disabledDiv" : ""}`}>
                                    <div className="d-flex mt-20 justify-content-between">
                                        <span className="smallerFont f-s-16 bold theme-main-1">
                                            <Translate text="attachedDocuments" />
                                        </span>
                                        <div className="">
                                            <CustomFileInput
                                                name="file"
                                                className="mb-10"
                                                handleInput={this.handleFileChange}
                                                value={this.state.file.value}
                                                label={"CHOOSE_FILE"}
                                                labelClass="small btn-secondary"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {!this.state.uploading && (
                                    <div
                                        onDrop={(e) => this.handleFileChange("file", e, true)}
                                        className={`dropImage d-flex justify-content-center align-items-center mt-10 pa-10 ${
                                            this.state.isOver ? "dotted" : ""
                                        } ${user.role === 1 ? "disabledDiv" : ""}`}
                                        onDragOver={(e) => {
                                            e.preventDefault();
                                            this.changeBorderType(true);
                                        }}
                                        onDragLeave={() => this.changeBorderType(false)}
                                    >
                                        <div className="text d-flex justify-content-center align-items-center pa-20">
                                            <span className="d-block mr-10">
                                                <UploadIcon width={40} className="uploadIcon" />
                                            </span>
                                            <span className="d-block f-s-20 f-w-400 text-color-font-l2">
                                                <Translate text="DRAG_AND_DROP" />
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {this.state.uploading && (
                                    <div className="progress text-center actionUpload">
                                        <span className="f-s-20 mb-20">
                                            <Translate text="UPLOAD_IN_PROGRESS" />
                                        </span>
                                        <span className="wrapper d-block">
                                            <Loader width={200} height={100} />
                                            <span className="loader-numbers">{this.state.completed}%</span>
                                        </span>
                                    </div>
                                )}
                                <div className="mt-10">
                                    <span className="f-s-14 d-flex align-items-center mb-10">
                                        <Translate text="DEFAULT_FOLDER" />:{" "}
                                        <CloudFolderIcon className="lightFill ml-10 mr-5" width={20} />
                                        <span className="f-s-14">{this.state.form.default_path.value}</span>
                                        <span
                                            className="f-s-12 ml-10 pointer fileLink"
                                            onClick={() => this.toggleModal("changeFolderModal")}
                                        >
                                            <Translate text="CHANGE" />
                                        </span>
                                    </span>
                                </div>
                                {files.length ? (
                                    <div className={`d-flex flex-column mt-10 ${user.role === 1 ? "disabledDiv" : ""}`}>
                                        {files.map((e: any, index) => {
                                            if (e.id) {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="d-flex align-items-center mb-5 f-s-12 pointer"
                                                    >
                                                        <span className="align-self-end mr-30">
                                                            {this.state.newAction ? (
                                                                <div>
                                                                    <span className="d-flex mr-10">
                                                                        {this.handleIcon(e.type)}
                                                                    </span>
                                                                    <span className="align-self-end">{e.title}</span>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    onClick={() => this.downloadFile(e)}
                                                                    className="fileLink decoration-none f-s-13 d-flex "
                                                                >
                                                                    <span className="d-flex mr-10">
                                                                        {this.handleIcon(e.type)}
                                                                    </span>
                                                                    <span className="align-self-end">{e.title}</span>
                                                                </div>
                                                            )}
                                                        </span>
                                                        <span
                                                            className="mx-15 tooltip"
                                                            onClick={() => this.previewFile(e)}
                                                        >
                                                            <ShowPasswordIcon
                                                                width={20}
                                                                className="showPassword pt-20"
                                                            />
                                                            <span className="tooltiptext">
                                                                <Translate text="PREVIEW" />
                                                            </span>
                                                        </span>
                                                        <span
                                                            className="tooltip fileLink decoration-none f-s-13 d-flex pt-10"
                                                            onClick={() => {
                                                                window.open(`/file-manager/${e.parent_id}`, "_blank");
                                                            }}
                                                        >
                                                            <FilesIcon
                                                                className="filesIcon .withEffect mr-15"
                                                                width={20}
                                                            />
                                                            <span className="tooltiptext">
                                                                <Translate text="GO_TO_FILES" />
                                                            </span>
                                                        </span>

                                                        <i
                                                            className="fas fa-trash trash-icon padding-5 border-1 pointer border-radius-6 w-23 mt-10"
                                                            onClick={() => {
                                                                this.setState({
                                                                    fileIndex: index,
                                                                });
                                                                this.toggleModal("showDeleteFileModal");
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            } else {
                                                return "";
                                            }
                                        })}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div className="costs d-flex flex-column">
                                    <div className={`row mt-30 ${this.props.template && "disabledDiv"}`}>
                                        <div className="col-12 d-flex mt-20 justify-content-between">
                                            <span className="smallerFont f-s-16 bold theme-main-1">
                                                <Translate text="costEstimate" />
                                            </span>
                                            <div className="align-self-center">
                                                <CustomButton
                                                    className="btn-secondary small"
                                                    type="button"
                                                    onClick={this.handleAddCost}
                                                >
                                                    <Translate text="addCost" />
                                                </CustomButton>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.form.costs.map((cost, index) => {
                                        // const costEmployees = cost.user_groups
                                        return (
                                            <div
                                                key={index}
                                                className={`costEstimate mb-50 d-flex flex-column ${
                                                    this.props.template && "disabledDiv"
                                                }`}
                                            >
                                                <div className="basicInfo d-flex">
                                                    <div className="row my-30 col-11">
                                                        <div className="col-6 mb-30">
                                                            <CustomInput
                                                                name="title"
                                                                label="costName"
                                                                className="f-s-15"
                                                                type="text"
                                                                handleInput={this.handleCostInput}
                                                                value={cost.title}
                                                                errors={cost.errors}
                                                                index={index}
                                                                // required
                                                            />
                                                        </div>
                                                        <div className="col-6 mb-30">
                                                            <CustomInput
                                                                name="payerName"
                                                                label="payerName"
                                                                className="f-s-15"
                                                                type="text"
                                                                handleInput={this.handleCostInput}
                                                                value={cost.payerName}
                                                                errors={cost.errors}
                                                                index={index}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <CustomInput
                                                                name="recipientName"
                                                                label="recipientName"
                                                                className="f-s-15"
                                                                type="text"
                                                                handleInput={this.handleCostInput}
                                                                value={cost.recipientName}
                                                                errors={cost.errors}
                                                                index={index}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <CustomInput
                                                                name="amount"
                                                                label="amount"
                                                                type="number"
                                                                handleInput={this.handleCostInput}
                                                                value={cost.amount}
                                                                errors={cost.errors}
                                                                // required
                                                                index={index}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column align-items-center justify-content-center mx-auto controlButtons">
                                                        <div className="d-flex justify-content-center mb-30">
                                                            <i
                                                                className="fas fa-user-plus w-40 p-14 f-s-12 border-1 pointer border-radius-6 align-self-center"
                                                                onClick={() => this.addUserGroup(index)}
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <i
                                                                className="fas  fa-trash trash-icon w-40 p-14 f-s-12 border-1 pointer border-radius-6 align-self-center"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        costItemIndex: index,
                                                                        showDeleteCostModal: true,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {cost.user_groups.length !== 0 && (
                                                    <div className="userGroups d-flex flex-column">
                                                        <h2 className="f-s-16 mb-15">
                                                            <Translate text="ASSIGNED_EMPLOYEES" />
                                                        </h2>
                                                        {cost.user_groups.map((user: any, number) => {
                                                            const employeesFiltered = user.selectedEmployees?.filter(
                                                                (el) => el.label !== ""
                                                            );

                                                            return (
                                                                <div
                                                                    className="row userGroup align-items-center mb-30 m-0"
                                                                    key={number}
                                                                >
                                                                    <div className="col-7">
                                                                        <CustomDropdown
                                                                            data={formattedEmployees}
                                                                            className="multiple"
                                                                            name="selectedEmployees"
                                                                            // value={user.selectedEmployees}
                                                                            value={employeesFiltered}
                                                                            handleChange={
                                                                                this.handleCostDropdownEmployees
                                                                            }
                                                                            disabled={this.props.template}
                                                                            multiple
                                                                            label={"assignedEmployee"}
                                                                            index={number}
                                                                            parentIndex={index}
                                                                        />
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <CustomInput
                                                                            name="percentage"
                                                                            label="percentCost"
                                                                            type="number"
                                                                            handleInput={this.handleCostInputEmployees}
                                                                            value={user.percentage}
                                                                            index={number}
                                                                            parentIndex={index}
                                                                        />
                                                                    </div>
                                                                    {number > 0 && (
                                                                        <div className="col-1 mr-15">
                                                                            <i
                                                                                className="fas fa-user-minus p-14 f-s-15 border-1 pointer border-radius-6 align-self-center"
                                                                                onClick={() =>
                                                                                    this.removeUserGroup(index, number)
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </CustomForm>
                    </div>
                </div>
            );
        }
    }
}

export default ActionDetails;
