import Translate from "functions/utilFunctions/translate";
import React from "react";

const ClientsSummaries = ({ customers }) => {
    return (
        <div className="d-flex flex-column">
            {customers[0] &&
                customers.map((customer) => {
                    return (
                        <div className="d-flex flex-column mt-20 f-s-12" key={customer.PIB}>
                            <div className="d-flex mb-5">
                                <span className="w-70 bold">
                                    <Translate text="NAME" />:
                                </span>
                                <span>{customer && customer.name}</span>
                            </div>
                            <div className="d-flex mb-5">
                                <span className="w-70 bold">
                                    <Translate text="PIB" />:
                                </span>
                                {customer && customer.PIB}
                            </div>
                            <div className="d-flex mb-5">
                                <span className="w-70 bold">
                                    <Translate text="ADRESS" />:
                                </span>
                                {customer && customer.address}
                            </div>
                            <div className="d-flex">
                                <span className="w-70 bold">
                                    <Translate text="PDVno" />:
                                </span>
                                {customer && customer.PDVno}
                            </div>
                            <hr className="mt-15" />
                        </div>
                    );
                })}
        </div>
    );
};

export default ClientsSummaries;
