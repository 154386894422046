import { CustomButton, CustomDateTimePicker, CustomForm, CustomInput } from "devkit/Form/Form";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Table from "devkit/Table/Table";
import Translate from "functions/utilFunctions/translate";
import Modal from "devkit/Modal/Modal";
import CustomDropdown from "../../../devkit/Form/CustomDropdown/CustomDropdown";
import { StoreService } from "../../../services/store.service";
import { parseDate, parseDatetime } from "../../../functions/utilFunctions/parsingFunctions";
import { UtilService } from "../../../services/util.service";
import ApiService from "../../../services/api.service";
import { ModalContent } from "devkit/Modal/ModalContent";
import { Loader } from "devkit/Loader/Loader";
import Paginate from "devkit/Pagination/Pagination";
import AgileOverview from "pages/ActionTaskCalendar/Calendar/AgileOverview.tsx/AgileOverview";
import { Devkit } from "oykos-development-devkit";
import { ScreenContext } from "components/ScreenContext/ScreenContext";
import FilterSidebar from "components/FilterSidebar/FilterSidebar";
import { FilterIcon } from "components/Icons/Icons";
import { actionTasksState } from "./State";
import { ActionUtilFunctions } from "functions/utilFunctions/actionUtilFunctions";
import { parseDateForMySQL } from "../../../functions/utilFunctions/parsingFunctions";
import ShowMore from "../../../devkit/ShowMore/ShowMore";
import { FileDeleteIcon } from "components/Icons/FileManagerIcons";
import { ConvertIcon, CopyIcon, ActivitiesIcon } from "components/Icons/Icons";
import { DISABLE_FIREBASE } from "../../../services/constants.service";

class ActionsTasks extends Component<any> {
    state = {
        form: {
            search: {
                value: "",
                errors: [],
            },
            searchTemplate: {
                value: "",
                errors: [],
            },
            startDate: {
                value: null,
                errors: [],
            },
            endDate: {
                value: null,
                errors: [],
            },
            user_id: {
                value: 0,
                errors: [],
            },
            customer_id: {
                value: 0,
                errors: [],
            },
            label_id: {
                value: 0,
                errors: [],
            },
        },
        tableHeads: [
            {
                value: "actionName",
                sortable: true,
                sortableType: "string",
                sortableName: "action_name",
            },
            {
                value: "START_DATE",
                sortable: true,
                sortableType: "date",
                sortableName: "start_date_sorter",
            },
            {
                value: "END_DATE",
                sortable: true,
                sortableType: "date",
                sortableName: "end_date_sorter",
            },
            {
                value: "CLIENTS",
                sortable: false,
                sortableType: "string",
                sortableName: "customer_name",
            },
            {
                value: "EMPLOYEES",
                sortable: false,
                sortableType: "string",
                sortableName: "user_firstName",
            },
            {
                value: "PRIORITY",
                sortable: true,
                sortableType: "number",
                sortableName: "priority",
            },
            {
                value: "STATE",
                sortable: true,
                sortableType: "number",
                sortableName: "status",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        templateTableHeads: [
            {
                value: "templateName",
                sortable: true,
                sortableType: "string",
                sortableName: "action_name",
            },
            {
                value: "TEMPLATE_DESCRIPTION",
                sortable: false,
                sortableType: "string",
                sortableName: "remark",
            },
            {
                value: "PRIORITY",
                sortable: true,
                sortableType: "number",
                sortableName: "priority",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        showDeleteModal: false,
        showSuccessModal: false,
        showDeleteFailure: false,
        deleteID: "",
        actions: [] as any,
        actionInfo: {
            action: {},
            costs: [],
            files: [],
            labels: [],
            steps: [],
        } as any,
        users: [],
        labelsArray: [],
        customers: [],
        templates: [],
        isLoading: true,
        totalResults: 0,
        currentPage: this.props.match.params.pageNo,
        perPage: 10,
        sorterDirection: "",
        sorterName: "",
        templateColumns: [],
        showCloneModal: false,
        showCloneErrorModal: false,
        showCloneSuccessModal: false,
        cloneAction: false,
        clickedID: 0,
        showConvertModal: false,
        showConvertSuccessModal: false,
        showConvertErrorModal: false,
        templateConvert: false,
        actionConvert: false,
        convert: false,
        showFilterSidebar: false,
    };

    static contextType = ScreenContext;

    componentDidMount() {
        // this.cachedFilters();
        document.addEventListener("mousedown", this.mouseDownHandler);
        const user = StoreService.getStoreProperty("user");
        // const actions = StoreService.getStoreProperty("actions");
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage } = this.state;
        const offset = (currentPage - 1) * perPage;
        const data = { limit: perPage, offset: offset };
        const actionsFilters = StoreService.getStoreProperty("actionsFilters");
        console.log(actionsFilters);
        this.setState(
            (prevState: any) => ({
                form: {
                    ...prevState.form,
                    searchTemplate: {
                        value: actionsFilters?.searchTemplate
                            ? actionsFilters.searchTemplate
                            : this.state.form.searchTemplate.value,
                        errors: [],
                    },
                    user_id: {
                        // value: 0,
                        value: actionsFilters?.user_id ? actionsFilters.user_id : this.state.form.user_id.value,
                        errors: [],
                    },
                    customer_id: {
                        // value: 0,
                        value: actionsFilters?.customer_id
                            ? actionsFilters.customer_id
                            : this.state.form.customer_id.value,
                        errors: [],
                    },
                    label_id: {
                        // value: 0,
                        value: actionsFilters?.label_id ? actionsFilters.label_id : this.state.form.label_id.value,
                        errors: [],
                    },
                    startDate: {
                        // value: 0,
                        value: actionsFilters?.startDate ? actionsFilters.startDate : this.state.form.startDate.value,
                        errors: [],
                    },
                    endDate: {
                        // value: 0,
                        value: actionsFilters?.endDate ? actionsFilters.endDate : this.state.form.endDate.value,
                        errors: [],
                    },
                },
            }),
            () => {
                if (!this.props.template) {
                    const { form } = this.state;

                    for (let f in form) {
                        if (form[f].value && f !== "searchTemplate" && f !== "search") {
                            if (f === "startDate" || f === "endDate") {
                                data[f] = parseDatetime(form[f].value);
                            } else {
                                data[f] = form[f].value;
                            }
                        }
                    }
                    console.log(data, "data for");
                    this.getActions(data).then((data) => {
                        const labelsArray = data.labels.map((el) => ({
                            value: el.id,
                            label: el.title,
                        }));
                        labelsArray.unshift({
                            value: 0,
                            label: <Translate text="CHOOSE_LABEL" />,
                        });
                        this.setState({
                            labelsArray,
                            isLoading: false,
                        });
                    });
                }
                // else {
                //     if (this.props.agile) {
                //         data.limit = 1000;
                //         data.offset = 0;
                //     }
                //     data["template"] = true;
                //     this.getTemplates(data);
                // }
            }
        );
        const templates = StoreService.getStoreProperty("templates");
        const usersArray = StoreService.getStoreProperty("users");
        const customersArray = StoreService.getStoreProperty("customers");
        const users = usersArray.map((e) => ({
            value: e.id,
            label: `${e.firstName} ${e.lastName}`,
        }));
        const customers = customersArray.map((e) => ({
            value: e.id,
            label: e.name,
        }));

        users.unshift({
            value: 0,
            label: <Translate text="CHOOSE_EMPLOYEE" />,
        });
        customers.unshift({
            value: 0,
            label: <Translate text="CHOOSE_CUSTOMER" />,
        });
        this.setState({
            users,
            customers,
            templates,
        });
        user.role === 2 &&
            ApiService.getAllGroups(token).then((res) => {
                if (res.status === "OK") {
                    let usersForDropdownArray: any = [];
                    res.data &&
                        res.data.forEach((e: any) => {
                            usersForDropdownArray.push(...e.members);
                        });
                    let uniqueUsers = usersForDropdownArray.reduce((acc: any, c: any) => {
                        if (acc.findIndex((el: any) => el.id === c.id) === -1) {
                            acc.push(c);
                            return acc;
                        }
                        return acc;
                    }, []);
                    let dropdownUsers = uniqueUsers.map((e) => {
                        return { value: e.id, label: `${e.firstName} ${e.lastName}` };
                    });
                    dropdownUsers.unshift({
                        value: 0,
                        label: <Translate text="CHOOSE_EMPLOYEE" />,
                    });
                    this.setState({
                        users: dropdownUsers,
                    });
                }
            });
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.mouseDownHandler);

        const { form } = this.state;
        StoreService.updateStoreProperty("actionsFilters", {
            customer_id: form.customer_id.value,
            user_id: form.user_id.value,
            label_id: form.label_id.value,
            startDate: form.startDate.value,
            endDate: form.endDate.value,
            searchTemplate: form.searchTemplate.value,
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage && !nextProps.agile) {
            return {
                currentPage: nextProps.match.params.pageNo,
                isLoading: true,
            };
        }
        return null;
    }

    mouseDownHandler = (e) => {
        if (e.button === 1) {
            this.props.template
                ? window.open(`/template/${e.currentTarget.id}`, "_blank")
                : window.open(`/action/${e.currentTarget.id}`, "_blank");
        }
    };

    handlePageClick = (pageNumber) => {
        const path = this.props.match.path.split("/");
        if (path[1] === "actions") {
            this.props.history.push(`/actions/${pageNumber}`);
        } else {
            this.props.history.push(`/templates/${pageNumber}`);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.form.searchTemplate !== this.state.form.searchTemplate) {
            const { currentPage, perPage, sorterDirection, sorterName } = this.state;
            let offset = (parseInt(currentPage) - 1) * perPage;
            console.log("did update 1");
            if (!offset) {
                offset = 0;
            }
            const data: any = { limit: perPage, offset };
            if (sorterName || sorterDirection) {
                data[sorterName] = sorterDirection;
            }
            data["template"] = true;
            data["text"] = this.state.form.searchTemplate.value;
            UtilService.handleDebouncing("templateDebouncing", () => this.getTemplates(data), 300);
        }
        if (prevState.clickedID !== this.state.clickedID) {
            this.handleFindActionByID();
            console.log("did update 2");
        }
        if (prevState.currentPage !== this.state.currentPage || prevProps.action !== this.props.action) {
            console.log("did update 3");
            const { currentPage, perPage, sorterDirection, sorterName } = this.state;
            let offset = (parseInt(currentPage) - 1) * perPage;

            if (!offset) {
                offset = 0;
            }
            const data: any = { limit: perPage, offset };
            if (sorterName || sorterDirection) {
                data[sorterName] = sorterDirection;
            }

            if (!this.props.template) {
                UtilService.handleDebouncing("actionDebouncing", () => this.getActions(data), 300);
            } else {
                data["template"] = true;
                if (this.state.form.searchTemplate.value) {
                    data["text"] = this.state.form.searchTemplate.value;
                }
                UtilService.handleDebouncing("templateDebouncing", () => this.getTemplates(data), 300);
            }
        }
    }

    handleSort = (sortableName) => {
        const { currentPage, perPage, sorterDirection, form } = this.state;
        const sorter_direction = sorterDirection ? (sorterDirection === "desc" ? "asc" : "desc") : "asc";
        const offset = (currentPage - 1) * perPage;
        let data = { limit: perPage, offset };
        data[sortableName] = sorter_direction;

        for (let f in form) {
            if (form[f].value && f !== "searchTemplate") {
                if (f === "startDate" || f === "endDate") {
                    data[f] = parseDatetime(form[f].value);
                } else {
                    data[f] = form[f].value;
                }
            }
        }

        if (!this.props.template) {
            this.getActions(data);
        } else {
            data["template"] = true;
            this.getTemplates(data);
        }

        this.setState({ sorterName: sortableName, sorterDirection: sorter_direction });
    };

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    handleDeleteAction = () => {
        const { currentPage, perPage, deleteID, sorterDirection, sorterName } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const data: any = { limit: perPage, offset };
        if (sorterName || sorterDirection) {
            data[sorterName] = sorterDirection;
        }
        const token = StoreService.getStoreProperty("token");
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            deleteID: "",
        });

        !this.props.template
            ? ApiService.deleteAction(
                  {
                      id: deleteID,
                  },
                  token
              ).then((response) => {
                  if (response.status === "OK") {
                      const token = StoreService.getStoreProperty("token");
                      const notifications = response.data.notifications;

                      if (notifications && Object.values(notifications).length) {
                          Object.values(notifications).forEach((notification: any) => {
                              if (!DISABLE_FIREBASE) {
                                  Devkit.FirebaseService.sendNotification(
                                      notification.action,
                                      notification.item,
                                      notification.link,
                                      notification.read,
                                      null,
                                      notification.users
                                  );
                              }
                          });
                      }

                      ApiService.filterActions(data, token).then((res) => {
                          const newActions = res.data.actions;

                          StoreService.updateStoreProperty("actions", newActions);
                          this.setState({
                              actions: res.data.actions,
                              showSuccessModal: true,
                          });
                      });
                  } else {
                      this.setState({
                          showDeleteFailure: true,
                      });
                  }
              })
            : ApiService.deleteAction(
                  {
                      id: deleteID,
                      template: true,
                  },
                  token
              ).then((response) => {
                  if (response.status === "OK") {
                      data["template"] = true;
                      const notifications = response.data.notifications;

                      if (notifications && Object.values(notifications).length) {
                          Object.values(notifications).forEach((notification: any) => {
                              if (!DISABLE_FIREBASE) {
                                  Devkit.FirebaseService.sendNotification(
                                      notification.action,
                                      notification.item,
                                      notification.link,
                                      notification.read,
                                      null,
                                      notification.users
                                  );
                              }
                          });
                      }

                      ApiService.filterActions(data, token).then((res) => {
                          const newTemplates = res.data.actions;
                          StoreService.updateStoreProperty("templates", newTemplates);
                          this.setState({
                              templates: res.data.actions,
                              //templates: newTemplates,
                              showSuccessModal: true,
                          });
                      });
                  } else {
                      this.setState({
                          showDeleteFailure: true,
                      });
                  }
              });
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleRedirect = (id) => {
        !this.props.template ? this.props.history.push(`/action/${id}`) : this.props.history.push(`/template/${id}`);
    };

    handleNewActionOrTemplate = () => {
        !this.props.template ? this.props.history.push("/new-action") : this.props.history.push("/new-template");
    };

    downloadFile = (blob, fileName) => {
        const link = document.createElement("a");
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    };

    generatePDF = () => {
        const data: any = {};

        if (!this.props.template && this.state.form.search.value) {
            data["text"] = this.state.form.search.value;
        } else if (this.props.template && this.state.form.searchTemplate.value) {
            data["text"] = this.state.form.searchTemplate.value;
        }
        if (this.state.form.startDate.value) {
            data["startDate"] = parseDatetime(this.state.form.startDate.value);
        }
        if (this.state.form.endDate.value) {
            data["endDate"] = parseDatetime(this.state.form.endDate.value);
        }
        if (this.state.form.customer_id.value !== 0) {
            data["customer_id"] = this.state.form.customer_id.value;
        }
        if (this.state.form.user_id.value !== 0) {
            data["user_id"] = this.state.form.user_id.value;
        }
        if (this.state.form.label_id.value !== 0) {
            data["label_id"] = this.state.form.label_id.value;
        }
        const token = StoreService.getStoreProperty("token");
        ApiService.generatePDF(data, token).then((res) => {
            this.downloadFile(res, "actions.pdf");
        });
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };

    handleNoModal = () => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            deleteID: "",
        });
    };

    toggleSidebar = (e) => {
        e.preventDefault();
        this.setState({ showFilterSidebar: !this.state.showFilterSidebar });
    };

    resetFilters = (e) => {
        e.preventDefault();
        const { currentPage, perPage, sorterName, sorterDirection } = this.state;
        this.setState({ form: { ...actionTasksState.form } });

        const offset = (parseInt(currentPage) - 1) * perPage;
        const data = { limit: perPage, offset };
        if (sorterName || sorterDirection) {
            data[sorterName] = sorterDirection;
        }

        if (this.props.template) {
            data["template"] = true;
            this.getTemplates(data);
        } else {
            this.getActions(data);
        }
    };

    submitFilters = (e?) => {
        if (e) {
            e.preventDefault();
        }

        const { form, currentPage, perPage, sorterName, sorterDirection } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const data = { limit: perPage, offset };
        if (sorterName || sorterDirection) {
            data[sorterName] = sorterDirection;
        }
        if (this.props.template) {
            data["template"] = true;
            data["text"] = form.searchTemplate.value;

            this.getTemplates(data);
        } else {
            for (let f in form) {
                if (form[f].value && f !== "searchTemplate") {
                    if (f === "startDate" || f === "endDate") {
                        data[f] = parseDatetime(form[f].value);
                    } else {
                        data[f] = form[f].value;
                    }
                }
            }

            this.getActions(data);
        }
    };
    cloneAction = () => {
        this.setState(
            {
                cloneAction: true,
            },
            () => {
                this.handleSaveAction();
            }
        );
    };
    handleFindActionByID = () => {
        const token = StoreService.getStoreProperty("token");
        const { clickedID } = this.state;

        ApiService.findActionByID({ id: clickedID }, token).then((response) => {
            if (response.status === "OK") {
                this.setState({ actionInfo: response.data });
            }
        });
    };
    handleSaveAction = () => {
        const { currentPage, perPage, actionInfo, convert } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const data: any = { limit: perPage, offset };
        const token = StoreService.getStoreProperty("token");
        const user = StoreService.getStoreProperty("user");
        const template = !!this.props.template;
        let column_id = "";
        const formattedCustomers = actionInfo.action.customers
            ? actionInfo.action.customers.map((customer) => {
                  return { customer_id: customer.id };
              })
            : [];
        const formattedUsers = actionInfo.action.users ? actionInfo.action.users.map((e) => ({ user_id: e.id })) : [];
        const formattedGroups = actionInfo.action.groups
            ? actionInfo.action.groups.map((e) => ({ group_id: e.id }))
            : [];
        const formattedLabels =
            actionInfo.labels &&
            actionInfo.labels.map((label: any) => {
                if (label.__isNew__) {
                    return { title: label.title };
                } else {
                    return { id: label.id };
                }
            });
        if (this.props.location && this.props.location.state && this.props.location.state.columnID) {
            column_id = this.props.location.state.columnID;
        }

        ApiService.createNewAction(
            convert ? !template : template,
            {
                start_date: actionInfo.action.startDate,
                title: actionInfo.action.title,
                end_date: actionInfo.action.endDate,
                priority: actionInfo.action.priority,
                status: actionInfo.action.status,
                point: actionInfo.action.actionPoints,
                remark: actionInfo.action.remark,
                frequency: actionInfo.action.frequency,
                parent_folder_id: actionInfo.action.parent_folder_id,
                default_path_level: actionInfo.action.default_path_level,
                default_path: actionInfo.action.default_path,
            },
            !template && formattedCustomers,
            !template && formattedUsers,
            !template && formattedGroups,
            actionInfo.steps.map((e) => ({
                title: e.title,
                step_state_id: e.step_state_id,
                end_date: parseDateForMySQL(e.end_date, true),
            })),

            convert
                ? actionInfo.costs.map((e) => ({
                      title: e.title,
                      payerName: e.payerName,
                      recipientName: e.recipientName,
                      amount: e.amount,
                      user_id: e.user_id ? user.id : null,
                  }))
                : [], //costs should not be cloned
            [], //chage later if with clone files should be cloned as well, but with convert they should be
            formattedLabels,
            column_id !== "" && column_id,
            token
        ).then((response) => {
            if (response.status === "OK") {
                const notifications = response.data.notifications;
                if (notifications && notifications.length) {
                    notifications.forEach((notification: any) => {
                        if (!DISABLE_FIREBASE) {
                            Devkit.FirebaseService.sendNotification(
                                notification.action,
                                notification.item,
                                notification.link,
                                notification.read,
                                null,
                                notification.users
                            );
                        }
                    });
                }
                const showModal = convert ? "showConvertSuccessModal" : "showCloneSuccessModal";
                if (response.data[0].template === 0) {
                    ApiService.filterActions(data, token).then((res) => {
                        const newActions = res.data.actions;
                        StoreService.updateStoreProperty("actions", newActions);
                        this.setState({
                            actions: res.data.actions,
                            showCloneModal: false,
                            showConvertModal: false,
                            [showModal]: true,
                            newActionId: response.data.id,
                            convert: false,
                        });
                    });
                } else {
                    data["template"] = true;
                    ApiService.filterActions(data, token).then((res) => {
                        if (res.status === "OK") {
                            this.setState({
                                templates: res.data.actions,
                                showCloneModal: false,
                                showConvertModal: false,
                                [showModal]: true,
                                newTemplateId: response.data.id,
                                convert: false,
                            });
                        }
                    });
                }
            } else {
                this.setState({
                    showCloneModal: false,
                    showCloneErrorModal: true,
                });
            }
        });
    };

    getActions = (data) => {
        const token = StoreService.getStoreProperty("token");

        return ApiService.filterActions(data, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    actions: res.data.actions,
                    isLoading: false,
                    totalResults: res.data.total,
                });

                return res.data;
            }
        });
    };

    getTemplates = (data) => {
        const token = StoreService.getStoreProperty("token");

        return ApiService.filterActions(data, token).then((res) => {
            if (res.status === "OK") {
                this.setState({
                    templates: res.data.actions,
                    isLoading: false,
                    totalResults: res.data.total,
                });

                return res.data;
            }
        });
    };

    render() {
        const { totalResults, currentPage, perPage, labelsArray, form, customers, users } = this.state;
        const user = StoreService.getStoreProperty("user");
        const isLoading = this.state.isLoading;
        const theads = !this.props.template ? this.state.tableHeads : this.state.templateTableHeads;
        const { screenSize } = this.context;

        if (isLoading) {
            return (
                <div className="loaderContainer">
                    <Loader width={200} />
                </div>
            );
        } else {
            return (
                <div className="actionsWrapper pb-120">
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showDeleteModal"
                        className={this.state.showDeleteModal ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="warning"
                            text={this.props.template ? "DELETE_TEMPLATE_?" : "DELETE_ACTION_?"}
                            modalName="showDeleteModal"
                            title={this.props.template ? "DELETE_TEMPLATE" : "DELETE_ACTION"}
                            toggleModal={this.toggleModal}
                            deleteItem={this.handleDeleteAction}
                            cancelDelete={this.handleNoModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showSuccessModal"
                        className={this.state.showSuccessModal ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="success"
                            text={this.props.template ? "TEMPLATE_DELETE_SUCCESS" : "ACTION_DELETE_SUCCESS"}
                            modalName="showSuccessModal"
                            title="SUCCESS"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showDeleteFailure"
                        className={this.state.showDeleteFailure ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="failure"
                            text={this.props.template ? "TEMPLATE_DELETE_FAILURE" : "ACTION_DELETE_FAILURE"}
                            modalName="showDeleteFailure"
                            title="FAILURE"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showCloneModal"
                        className={this.state.showCloneModal ? "visible " : ""}
                        modalWrapperClasses="max-width-750 padding-10 "
                    >
                        <ModalContent
                            type="warning"
                            text={this.props.template ? "CLONE_TEMPLATE_?" : "CLONE_ACTION_?"}
                            modalName="showCloneModal"
                            title={this.props.template ? "CLONE_TEMPLATE" : "CLONE_ACTION"}
                            toggleModal={this.toggleModal}
                            deleteItem={this.cloneAction}
                            cancelDelete={this.toggleModal}
                            buttonText="CLONE"
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showCloneSuccessModal"
                        className={this.state.showCloneSuccessModal ? "visible" : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            toggleModal={this.toggleModal}
                            text={this.props.template ? "CLONE_TEMPLATE_SUCCESS" : "CLONE_ACTION_SUCCESS"}
                            modalName="showCloneSuccessModal"
                            title={"SUCCESS"}
                            type={"success"}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showCloneErrorModal"
                        className={this.state.showCloneErrorModal ? "visible bg-info-3" : ""}
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text={this.props.template ? "CLONE_TEMPLATE_FAILURE" : "CLONE_ACTION_FAILURE"}
                            modalName="showCloneErrorModal"
                            title="FAILED"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showConvertModal"
                        className={this.state.showConvertModal ? "visible " : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="warning"
                            text={!this.props.template ? "CONVERT_ACTION_NOW" : "CONVERT_TEMPLATE_NOW"}
                            modalName="showConvertModal"
                            title={!this.props.template ? "CONVERT_ACTION" : "CONVERT_TEMPLATE"}
                            toggleModal={this.toggleModal}
                            buttonText="CONVERT"
                            deleteItem={() => this.handleSaveAction()}
                            cancelDelete={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showConvertSuccessModal"
                        className={this.state.showConvertSuccessModal ? "visible" : ""}
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            toggleModal={this.toggleModal}
                            text={this.props.template ? "CONVERT_TEMPLATE_SUCCESS" : "CONVERT_ACTION_SUCCESS"}
                            modalName="showConvertSuccessModal"
                            title={"SUCCESS"}
                            type={"success"}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showConvertErrorModal"
                        className={this.state.showConvertErrorModal ? "visible bg-info-3" : ""}
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text={this.props.template ? "CONVERT_TEMPLATE_FAILURE" : "CONVERT_ACTION_FAILURE"}
                            modalName="showConvertErrorModal"
                            title="FAILED"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <div
                        className={`${
                            screenSize <= 576 ? "mobFooterOverview" : ""
                        } d-flex justify-content-between align-items-end border-b-1 padding-0`}
                    >
                        <div className="navigation d-flex padding-0">
                            <NavLink
                                to={`/actions/1`}
                                className={`${
                                    screenSize <= 576 ? "footerNavBtn" : ""
                                } action-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 ${
                                    this.props.action && "active"
                                }`}
                                style={{
                                    textDecoration: "none",
                                }}
                                activeClassName="active"
                            >
                                <Translate text={"actions"} />
                            </NavLink>
                            <NavLink
                                to={`/templates/1`}
                                style={{
                                    textDecoration: "none",
                                }}
                                className={`${
                                    screenSize <= 576 ? "footerNavBtn" : ""
                                } action-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 ${
                                    user.role === 1 ? "disabledDiv" : ""
                                } ${this.props.template && "active"}`}
                                activeClassName="active"
                            >
                                <Translate text={"template"} />
                            </NavLink>
                        </div>
                        <div className="controlButtons d-flex">
                            <div>
                                <CustomButton
                                    type="button"
                                    className="btn-primary ml-20 border-radius-br-0 border-radius-bl-0"
                                    onClick={this.generatePDF}
                                >
                                    <Translate text="GENERATE_PDF" />
                                </CustomButton>
                            </div>
                            <div>
                                <CustomButton
                                    type="button"
                                    className="btn-primary ml-20 border-radius-br-0 border-radius-bl-0"
                                    onClick={this.handleNewActionOrTemplate}
                                >
                                    <Translate text={!this.props.template ? "addAction" : "addTemplate"} />
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                    <div className="filters">
                        <div className="mt-20 d-flex justify-content-end">
                            {this.props.template ? (
                                <div className="mr-15 d-flex justify-content-between w-100-perc row">
                                    <CustomForm className="col-8 col-md-6 col-lg-4 col-xl-3">
                                        <div>
                                            <CustomInput
                                                value={
                                                    !this.props.template ? form.search.value : form.searchTemplate.value
                                                }
                                                handleInput={this.handleInput}
                                                name={!this.props.template ? "search" : "searchTemplate"}
                                                type="text"
                                                label="Search"
                                            />
                                        </div>
                                    </CustomForm>

                                    <NavLink
                                        to={`${this.props.agile ? `/templates/1` : "/templates-agile"}`}
                                        style={{
                                            textDecoration: "none",
                                            width: "80px",
                                        }}
                                        className="btn btn-primary f-s-16 h-45 d-flex justify-content-center align-items-center"
                                        activeClassName="active"
                                    >
                                        <Translate text={`${this.props.agile ? "TABLE" : "AGILE"}`} />
                                    </NavLink>
                                </div>
                            ) : (
                                <CustomButton
                                    onClick={this.toggleSidebar}
                                    className="filterBtn btn btn-primary d-flex align-items-center mr-15"
                                >
                                    <FilterIcon className="icon" width={16}></FilterIcon>
                                    <span className="ml-10">Filteri</span>
                                </CustomButton>
                            )}
                        </div>
                        {this.props.action && (
                            <FilterSidebar
                                opened={this.state.showFilterSidebar}
                                onClose={() => this.setState({ showFilterSidebar: !this.state.showFilterSidebar })}
                                sidebarClasses="clientsFilterSidebar"
                                wrapperClasses="clientsFilterWrapper"
                                submitFilters={this.submitFilters}
                                resetFilters={this.resetFilters}
                            >
                                <CustomForm>
                                    <div className="w-100-perc mb-20">
                                        <CustomDropdown
                                            data={customers}
                                            name="customer_id"
                                            handleChange={this.handleInput}
                                            value={customers.find((e: any) => e.value === form.customer_id.value)}
                                            label={"assignedClient"}
                                        />
                                    </div>
                                    <div className="w-100-perc mb-20">
                                        <CustomDropdown
                                            data={users}
                                            name="user_id"
                                            handleChange={this.handleInput}
                                            value={users.find((e: any) => e.value === form.user_id.value)}
                                            label={"assignedEmployee"}
                                        />
                                    </div>
                                    <div className="w-100-perc mb-20">
                                        <CustomDropdown
                                            data={labelsArray}
                                            name="label_id"
                                            handleChange={this.handleInput}
                                            value={labelsArray.find((e: any) => e.value === form.label_id.value)}
                                            label={"Label"}
                                        />
                                    </div>
                                    <div className="w-100-perc mb-20">
                                        <CustomDateTimePicker
                                            name="startDate"
                                            handleInput={this.handleInput}
                                            value={form.startDate.value}
                                            errors={form.startDate.errors}
                                            label={"START_DATE"}
                                        />
                                    </div>
                                    <div className="w-100-perc mb-20">
                                        <CustomDateTimePicker
                                            name="endDate"
                                            handleInput={this.handleInput}
                                            value={form.endDate.value}
                                            errors={form.endDate.errors}
                                            label={"END_DATE"}
                                        />
                                    </div>
                                </CustomForm>
                            </FilterSidebar>
                        )}
                    </div>
                    <div className="margin-top-20 actionsTableWrapper f-s-14">
                        {this.props.agile ? (
                            <AgileOverview template={true} columns={this.state.templateColumns}></AgileOverview>
                        ) : (
                            <>
                                <div className="overflow-auto">
                                    <Table
                                        theads={theads.length > 0 ? theads : []}
                                        theadsClassname="customThead"
                                        className={`${!this.props.template ? "actionsTable" : "templateTable"}`}
                                        handleSort={this.handleSort}
                                        sortName={!this.props.template ? "actions" : "templates"}
                                        arrayToSort={!this.props.template ? this.state.actions : this.state.templates}
                                    >
                                        {!this.props.template
                                            ? this.state.actions.map((e: any, i) => (
                                                  <tr
                                                      className="pointer"
                                                      onClick={() => this.handleRedirect(e.id)}
                                                      key={i}
                                                      onMouseDown={(ev) => this.mouseDownHandler(ev)}
                                                  >
                                                      <td>{e.title}</td>

                                                      <td className="">{parseDate(e.startDate)}</td>
                                                      <td className="">{parseDate(e.endDate)}</td>
                                                      <td className="autoHeight">
                                                          {e.customers &&
                                                              e.customers.map((e: any, index) => {
                                                                  return (
                                                                      <div key={index} className="mr-10">
                                                                          {e.name}
                                                                      </div>
                                                                  );
                                                              })}
                                                      </td>

                                                      <td className="d-flex flex-column autoHeight">
                                                          {e.users &&
                                                              e.users.map((e: any, index) => {
                                                                  return (
                                                                      <div
                                                                          key={index}
                                                                      >{`${e.firstName} ${e.lastName}`}</div>
                                                                  );
                                                              })}
                                                      </td>
                                                      <td>{ActionUtilFunctions.renderPriority(e.priority)}</td>
                                                      <td>{ActionUtilFunctions.renderActionState(e.status)}</td>

                                                      <td>
                                                          <ShowMore>
                                                              <span onClick={() => this.handleRedirect(e.id)}>
                                                                  <ActivitiesIcon
                                                                      width={16}
                                                                      className="mr-10 important-3"
                                                                  />
                                                                  <Translate text="DETAILS" />
                                                              </span>
                                                              <span
                                                                  onClick={() => {
                                                                      this.setState({
                                                                          clickedID: e.id,
                                                                          showCloneModal: true,
                                                                      });
                                                                  }}
                                                              >
                                                                  <CopyIcon width={16} className="mr-10 important-3" />
                                                                  <Translate text="CLONE" />
                                                              </span>
                                                              <span
                                                                  onClick={() => {
                                                                      this.setState({
                                                                          showConvertModal: true,
                                                                          clickedID: e.id,
                                                                          convert: true,
                                                                      });
                                                                  }}
                                                              >
                                                                  <ConvertIcon width={16} className="mr-10" />
                                                                  <Translate text={"convert"} />
                                                              </span>
                                                              {user.role === 3 ? (
                                                                  <span
                                                                      className="showMoreDelete"
                                                                      onClick={() =>
                                                                          !this.props.template &&
                                                                          this.handleDeleteModal(e.id)
                                                                      }
                                                                  >
                                                                      <FileDeleteIcon width={16} className="mr-10" />
                                                                      <Translate text={"DELETE"} />
                                                                  </span>
                                                              ) : (
                                                                  <></>
                                                              )}
                                                          </ShowMore>
                                                      </td>
                                                  </tr>
                                              ))
                                            : this.state.templates.map((e: any, i) => (
                                                  <tr
                                                      className="pointer"
                                                      onClick={() => this.handleRedirect(e.id)}
                                                      key={i}
                                                  >
                                                      <td className="w-300">{e.title}</td>
                                                      <td className="w-650">
                                                          {UtilService.truncateString(e.remark, 80)}
                                                      </td>
                                                      <td className="w-450">
                                                          {ActionUtilFunctions.renderPriority(e.priority)}
                                                      </td>
                                                      {user.role === 3 && (
                                                          <td>
                                                              <ShowMore>
                                                                  <span onClick={() => this.handleRedirect(e.id)}>
                                                                      <ActivitiesIcon
                                                                          width={16}
                                                                          className="mr-10 important-3"
                                                                      />
                                                                      <Translate text="DETAILS" />
                                                                  </span>
                                                                  <span
                                                                      onClick={() => {
                                                                          this.setState({
                                                                              clickedID: e.id,
                                                                              showCloneModal: true,
                                                                          });
                                                                      }}
                                                                  >
                                                                      <CopyIcon
                                                                          width={16}
                                                                          className="mr-10 important-3"
                                                                      />
                                                                      <Translate text="CLONE" />
                                                                  </span>
                                                                  <span
                                                                      onClick={() => {
                                                                          this.setState({
                                                                              showConvertModal: true,
                                                                              clickedID: e.id,
                                                                              convert: true,
                                                                          });
                                                                      }}
                                                                  >
                                                                      <ConvertIcon width={16} className="mr-10" />
                                                                      <Translate text={"convert"} />
                                                                  </span>
                                                                  {user.role === 3 ? (
                                                                      <span
                                                                          className="showMoreDelete"
                                                                          onClick={() => this.handleDeleteModal(e.id)}
                                                                      >
                                                                          <FileDeleteIcon
                                                                              width={16}
                                                                              className="mr-10"
                                                                          />
                                                                          <Translate text={"DELETE"} />
                                                                      </span>
                                                                  ) : (
                                                                      <></>
                                                                  )}
                                                              </ShowMore>
                                                          </td>
                                                      )}
                                                  </tr>
                                              ))}
                                    </Table>
                                </div>

                                {!!this.state.totalResults && this.state.totalResults > 0 && (
                                    <div className="d-flex justify-content-center">
                                        <Paginate
                                            totalItemsCount={totalResults}
                                            onChange={this.handlePageClick}
                                            activePage={parseInt(currentPage)}
                                            perPage={perPage}
                                            pageRangeDisplayed={5}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            );
        }
    }
}

export default ActionsTasks;
