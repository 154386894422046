import { CustomButton } from "devkit/Form/Form";
import Modal from "devkit/Modal/Modal";
import Translate from "functions/utilFunctions/translate";
import React, { useState, Fragment } from "react";
import { Constants } from "services/constants.service";

const AccordionRealEstates = ({ realEstate, downloadFile, previewFile, toggleModal, previewFileModal }) => {
    const storage = Constants.STORAGE.slice(0, -1);
    const [isCollapsed, setIsCollapsed] = useState(true);
    return (
        <Fragment>
            <button className="accordion-button" onClick={() => setIsCollapsed(!isCollapsed)}>
                <Translate text="REAL_ESTATE" /> : {realEstate.uid}
            </button>
            <div className={`accordion-item ${isCollapsed ? "collapsed" : "expanded"}`}>
                {/* <p>
                    <Translate text="REAL_ESTATE_ID" /> : <b>{realEstate.id}</b>
                </p> */}
                <p>
                    <Translate text="REAL_ESTATE_JMBG" /> : <b>{realEstate.jmbg}</b>
                </p>
                <p>
                    <Translate text="REAL_ESTATE_OWNER" /> : <b>{realEstate.owner}</b>
                </p>
                <p>
                    <Translate text="REAL_ESTATE_SCHEMA_NAME" /> : <b>{realEstate.schema_name}</b>
                </p>
                <p>
                    <Translate text="REAL_ESTATE_PARTY_ID" /> : <b>{realEstate.party_id}</b>
                </p>
                <p>
                    <Translate text="REAL_ESTATE_UID" /> : <b>{realEstate.uid}</b>
                </p>
                <CustomButton
                    className="btn-primary mr-30 my-20"
                    onClick={() => downloadFile(realEstate.file.id, realEstate.file.title)}
                >
                    <Translate text="DOWNLOAD_FILE" />
                </CustomButton>
                <CustomButton className="btn-primary my-20" onClick={() => previewFile()}>
                    <Translate text="PREVIEW_FILE" />
                </CustomButton>
                <Modal
                    toggleModal={toggleModal}
                    modalName="previewFileModal"
                    className={previewFileModal ? "visible " : ""}
                    modalWrapperClasses="w-80-perc h-80-perc padding-20 "
                >
                    <iframe
                        title={realEstate?.file?.title}
                        //for now this is path to file
                        // src={`https://staging.hydra.api.oykos.us/storage/klijenti/listovi-nepokretnosti/${realEstate.file.title}`}
                        //when back change default path src need to be:
                        // src={`${storage}${this.props.defaultPath}/listovi-nepokretnosti/${realEstate.file.title}`}
                        src={`${storage}${realEstate?.file?.path}`}
                        width="100%"
                        height="100%"
                    ></iframe>
                </Modal>
            </div>
        </Fragment>
    );
};

export default AccordionRealEstates;
