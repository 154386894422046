import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import AccountDetails from "./Accounts/AccountDetails/AccountDetails";
import Accounts from "./Accounts/Accounts";
import ActionDetails from "./Action/ActionDetails/ActionDetails";
import ActionsTasks from "./ActionTask/ActionsTasks/ActionsTasks";
import ActivityLog from "./ActivityLog/ActivityLog";
import ApiService from "services/api.service";
import Calendar from "./ActionTaskCalendar/Calendar/Calendar";
import Clients from "./Clients/Clients";
import ClientsDetails from "./Clients/ClientsDetails";
import FileManager from "./FileManager/FileManager";
import Header from "../components/Header/Header";
import Login from "./Login/Login";
import MonthlyOverview from "./ActionTaskCalendar/Calendar/MonthlyOverview/MonthlyOverview";
import NewPassword from "./Login/NewPassword";
import PersonalAccount from "./PersonalAccount/PersonalAccount";
import Pin from "./Pin/Pin";
import React from "react";
import Registration from "./Registration/Registration";
import ResetPassword from "./Login/ResetPassword";
import Sidebar from "../components/Sidebar/Sidebar";
import { StoreService } from "../services/store.service";
import { UtilService } from "../services/util.service";
import i18next from "i18next";
import { parseDateForMySQL } from "functions/utilFunctions/parsingFunctions";
import SalariesForAllEmployees from "./Accounts/SalariesForAllEmployees";
import { Constants, DISABLE_FIREBASE } from "../services/constants.service";
import { Devkit } from "oykos-development-devkit";
import ChatModule from "./Chat/ChatModule";
import Groups from "./Groups/Groups";
import TimeTrackTable from "./Accounts/TimeTrackTable";
import { ScreenContext } from "components/ScreenContext/ScreenContext";

class App extends React.Component {
    constructor(props) {
        super(props);

        StoreService.initialize();

        if (!DISABLE_FIREBASE) {
            if (!window["firebaseInitialized"]) {
                let firebaseConfig = {
                    apiKey: "AIzaSyBqIkx-PtHUPn6WqcVW9uzOsHAb99aUCB4",
                    authDomain: "hydra-chat-2021.firebaseapp.com",
                    projectId: "hydra-chat-2021",
                    storageBucket: "hydra-chat-2021.appspot.com",
                    messagingSenderId: "657994029619",
                    appId: "1:657994029619:web:7b359e10fb4fc2de116146",
                    measurementId: "G-LM60LSSM6Q",
                };

                StoreService.updateStoreProperty("chatProfiles", {});

                Devkit.FirebaseService.initialize(
                    firebaseConfig,
                    "hydra-chat-password",
                    () => {
                        return StoreService.getStoreProperty("user");
                    },
                    (cachedUser: any) => {
                        StoreService.updateStoreProperty("user", cachedUser);
                    },
                    (chatProfilesData: any, isChange = false) => {
                        const chatProfile = isChange ? chatProfilesData.doc.data() : chatProfilesData.data();
                        let cachedChatProfiles = StoreService.getStoreProperty("chatProfiles");

                        if (isChange && chatProfilesData.type === "removed") {
                            delete cachedChatProfiles[chatProfile.uid];
                        } else {
                            cachedChatProfiles[chatProfile.uid] = chatProfile;
                        }

                        StoreService.updateStoreProperty("chatProfiles", cachedChatProfiles, true);
                    },
                    {
                        firstName: "firstName",
                        lastName: "lastName",
                        profilePicUrl: "profile_image",
                        email: "email",
                        phone: "phone_number",
                        userId: "id",
                        role: "role",
                    },
                    Constants.STORAGE,
                    () => {}
                );
            }

            window["firebaseInitialized"] = true;
        }
    }

    state = {
        isLoggedIn: false,
        isPinValid: false,
        token: "",
        user: {},
        users: [],
        actions: [],
        customers: [],
        templates: [],
        recurringActions: [],
        lateActions: [],
        lang: localStorage.getItem("lang"),
        fileShareUrl: window.location.href,
        language: true,
        day: new Date(),
        // agile: { search: "", priority: "", status: 2, customer_id: "", user_id: "", period: "day" },
    };

    static contextType = ScreenContext;

    toggleLanguage = () => {
        const lang = localStorage.getItem("lang");
        const token = StoreService.getStoreProperty("token");
        if (lang === "en") {
            ApiService.changeLanguage(
                {
                    language: "me",
                },
                token
            ).then(() => {
                i18next.changeLanguage("me");
                localStorage.setItem("lang", "me");
                this.setState({ lang: "me", language: false });
            });
        } else {
            ApiService.changeLanguage(
                {
                    language: "en",
                },
                token
            ).then(() => {
                i18next.changeLanguage("en");
                localStorage.setItem("lang", "en");
                this.setState({ lang: "en", language: true });
            });
        }
    };

    handlePinChange = (isPinValid) => {
        this.setState({ isPinValid });
    };

    handleLogin = (isLoggedIn, token, user, data) => {
        this.setState({ isLoggedIn, token, user, ...data, isPinValid: true }, () => {
            StoreService.updateStoreProperty("loginSuccess", true);
            StoreService.updateStoreProperty("user", this.state.user);
            StoreService.updateStoreProperty("token", this.state.token);
            StoreService.updateStoreProperty("users", this.state.users);
            StoreService.updateStoreProperty("actions", this.state.actions);

            StoreService.updateStoreProperty("customers", this.state.customers);
            StoreService.updateStoreProperty("templates", this.state.templates);
            StoreService.updateStoreProperty("recurringActions", this.state.recurringActions);
            StoreService.updateStoreProperty("lateActions", this.state.lateActions);
            StoreService.updateStoreProperty("isPinValid", new Date());
            StoreService.updateStoreProperty("calendarFilters", {
                search: "",
                priority: "",
                status: "",
                customer_id: "",
                user_id: "",
                period: "day",
            });
        });
    };

    handleThrottledEvent = () => {
        const isPinValid = this.checkIfPinValid();
        UtilService.handleThrottle(
            "allEvents",
            () => {
                if (isPinValid) {
                    StoreService.updateStoreProperty("isPinValid", new Date());
                } else {
                    this.setState({ isPinValid: false });
                }
            },
            30000
        );
    };

    checkIfLogged = () => {
        setInterval(() => {
            if (!this.checkIfPinValid()) {
                this.setState({ isPinValid: false });
            }
        }, 60000);
    };

    listenToAllUserEvents = () => {
        Object.keys(window).forEach((key) => {
            if (/^on(key|mouse)/.test(key)) {
                window.addEventListener(key.slice(2), this.handleThrottledEvent);
            }
        });
    };

    checkIfPinValid = () => {
        try {
            const pin = StoreService.getStoreProperty("isPinValid");
            if (!pin) {
                return false;
            }
            const pinTime: any = new Date(pin);
            const currTime: any = new Date();
            const difMS = currTime - pinTime;
            const diffMins = Math.round(((difMS % 86400000) % 3600000) / 60000);
            return diffMins <= 10;
        } catch (e) {
            console.log(e);
        }
    };

    componentDidMount() {
        this.listenToAllUserEvents();
        this.checkIfLogged();
        StoreService.hookOnStoreUpdate("updateStoreProperty", (e) => {
            if (!e.loginSuccess) {
                this.setState({ isLoggedIn: false });
            }
            if (!e.isPinValid) {
                this.setState({ isPinValid: false });
            }
        });
        const isLoggedIn = StoreService.getStoreProperty("loginSuccess");

        const token = StoreService.getStoreProperty("token");
        this.setState({ isLoggedIn, token });
        const isPinValid = this.checkIfPinValid();
        if (isPinValid) {
            this.setState({
                isPinValid: true,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isPinValid !== this.state.isPinValid && this.state.isPinValid) {
            StoreService.updateStoreProperty("isPinValid", new Date());
        }
    }

    logout = () => {
        ApiService.logout(this.state.token).then(() => {
            StoreService.clearStoreData();
            this.setState({
                isLoggedIn: false,
                isPinValid: false,
            });
        });
    };

    lockApp = () => {
        StoreService.updateStoreProperty("isPinValid", false);
    };

    render() {
        const user = StoreService.getStoreProperty("user");
        const isLoggedIn = StoreService.getStoreProperty("loginSuccess");
        const isPinValid = this.checkIfPinValid();
        let url = this.state.fileShareUrl;
        let fileUrl: any = "";
        let filePath = "";
        if (url.toString().includes("share=true")) {
            fileUrl = url.split("?")[0].split("/");
            filePath = fileUrl[fileUrl.length - 1];
        }

        const { isMobile } = this.context;

        if (!isLoggedIn) {
            return (
                <BrowserRouter>
                    <div className={`${isMobile ? "mobile" : ""}`}>
                        <main>
                            <Switch>
                                <Route
                                    exact
                                    path="/login"
                                    render={(props) => <Login handleLogin={this.handleLogin} {...props} />}
                                />
                                <Route
                                    exact
                                    path="/reset-password"
                                    render={(props) => <ResetPassword {...props} logout={this.logout} />}
                                />
                                <Route
                                    exact
                                    path="/newPassword/:token"
                                    render={(props) => <NewPassword {...props} />}
                                />
                                <Route exact path="/registration">
                                    <Registration />
                                </Route>
                                <Redirect from="/*" to="/login" />
                            </Switch>
                        </main>
                    </div>
                </BrowserRouter>
            );
        } else if (isLoggedIn && !isPinValid) {
            return (
                <BrowserRouter>
                    <div className={`${isMobile ? "mobile" : ""}`}>
                        <main>
                            <Switch>
                                <Route path="/pin">
                                    <Pin
                                        handlePinChange={this.handlePinChange}
                                        token={this.state.token}
                                        logout={this.logout}
                                    />
                                </Route>
                                <Redirect from="/*" to="/pin" />
                            </Switch>
                        </main>
                    </div>
                </BrowserRouter>
            );
        } else {
            return (
                <BrowserRouter>
                    <div className={`${isMobile ? "mobile" : ""}`}>
                        {!isMobile && <Sidebar day={parseDateForMySQL(this.state.day)} />}

                        <Route
                            render={(props) => (
                                <Header
                                    breadcrumbs=""
                                    lockApp={this.lockApp}
                                    logout={this.logout}
                                    language={this.toggleLanguage}
                                    lang={this.state.lang}
                                    {...props}
                                />
                            )}
                        />
                        <main className="app">
                            <section className="content">
                                <Switch>
                                    <Route
                                        exact
                                        path="/file-manager/:id?"
                                        render={(props) => <FileManager {...props} />}
                                    />
                                    {user.role === 3 && (
                                        <Route
                                            exact
                                            path="/new-account"
                                            render={(props) => <AccountDetails {...props} />}
                                        />
                                    )}
                                    {user.role === 3 && (
                                        <Route
                                            exact
                                            path="/accountsDetails/:id"
                                            render={(props) => {
                                                return <AccountDetails {...props} />;
                                            }}
                                        />
                                    )}
                                    {user.role === 3 && (
                                        <Route
                                            exact
                                            path="/accounts/:pageNo"
                                            render={(props) => <Accounts {...props} accounts />}
                                        />
                                    )}
                                    {user.role === 3 && (
                                        <Route
                                            exact
                                            path="/salaries/:pageNo"
                                            render={(props) => <SalariesForAllEmployees {...props} salaries />}
                                        />
                                    )}
                                    {user.role === 3 && (
                                        <Route
                                            exact
                                            path="/time-track/:pageNo"
                                            render={(props) => <TimeTrackTable {...props} timetrack />}
                                        />
                                    )}
                                    <Route
                                        exact
                                        path="/action/:id"
                                        render={(props) => {
                                            return <ActionDetails {...props} />;
                                        }}
                                    />
                                    <Route exact path="/new-action/" render={(props) => <ActionDetails {...props} />} />
                                    <Route
                                        exact
                                        path="/new-action/:cloneID/:cloneStartDate"
                                        render={(props) => <ActionDetails {...props} />}
                                    />
                                    {user.role !== 1 && (
                                        <Route
                                            exact
                                            path="/template/:id"
                                            render={(props) => {
                                                return <ActionDetails {...props} template />;
                                            }}
                                        />
                                    )}
                                    {user.role !== 1 && (
                                        <Route
                                            exact
                                            path="/new-template"
                                            render={(props) => {
                                                return <ActionDetails {...props} template />;
                                            }}
                                        />
                                    )}
                                    <Route
                                        exact
                                        path="/actions/:pageNo"
                                        render={(props) => {
                                            return <ActionsTasks {...props} action />;
                                        }}
                                    />
                                    <Route
                                        exact
                                        path="/customerActions/:customerId"
                                        render={(props) => {
                                            return <ActionsTasks {...props} />;
                                        }}
                                    />
                                    {user.role !== 1 && (
                                        <Route
                                            exact
                                            path="/templates/:pageNo"
                                            render={(props) => {
                                                return <ActionsTasks {...props} template />;
                                            }}
                                        />
                                    )}
                                    {user.role !== 1 && (
                                        <Route
                                            exact
                                            path="/templates-agile"
                                            render={(props) => {
                                                return <ActionsTasks {...props} template agile />;
                                            }}
                                        />
                                    )}
                                    {user.role === 3 && (
                                        <Route
                                            exact
                                            path="/activities/:pageNo"
                                            render={(props) => {
                                                return <ActivityLog {...props} />;
                                            }}
                                        />
                                    )}
                                    {user.role === 3 && (
                                        <Route
                                            exact
                                            path="/file-activities/:pageNo"
                                            render={(props) => {
                                                return <ActivityLog {...props} files />;
                                            }}
                                        />
                                    )}
                                    <Route
                                        exact
                                        path="/personal-account"
                                        render={(props) => <PersonalAccount {...props} />}
                                    />
                                    <Route exact path="/tasks">
                                        <ActionsTasks />
                                    </Route>
                                    <Route
                                        // exact
                                        path="(/|/daily|/monthly)"
                                        render={(props) => <Calendar {...props} language={this.state.lang} />}
                                    />
                                    {/* new route za params calendar */}
                                    <Route
                                        // exact
                                        path="/calendar/:date"
                                        render={(props) => (
                                            <Calendar
                                                {...props}
                                                language={this.state.lang}
                                                //day={this.state.day}
                                                // changeDay={(date) => this.changeDay(date)}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/calendar"
                                        render={(props) => <Calendar {...props} language={this.state.lang} />}
                                    />
                                    <Route
                                        exact
                                        path="/calendar/:employeeId"
                                        render={(props) => <Calendar {...props} language={this.state.lang} />}
                                    />
                                    <Route
                                        exact
                                        path="/calendar/:employeeId"
                                        render={(props) => <MonthlyOverview {...props} />}
                                    />
                                    <Route
                                        exact
                                        path="/calendarCustomer/:customerId"
                                        render={(props) => <Calendar {...props} language={this.state.lang} />}
                                    />
                                    <Route
                                        exact
                                        path="/calendarCustomer/:customerId"
                                        render={(props) => <MonthlyOverview {...props} />}
                                    />
                                    <Route exact path="/clients/:pageNo" render={(props) => <Clients {...props} />} />
                                    <Route
                                        exact
                                        path="/clientDetails/:id"
                                        render={(props) => <ClientsDetails {...props} />}
                                    />
                                    <Route
                                        exact
                                        path="/new-client"
                                        render={(props) => <ClientsDetails {...props} newClient />}
                                    />
                                    <Route exact path="/chat" render={() => <ChatModule />} />
                                    {user.role === 3 && (
                                        <Route
                                            exact
                                            path="/groups"
                                            render={(props) => {
                                                return <Groups {...props} />;
                                            }}
                                        />
                                    )}

                                    <Redirect
                                        from="/*"
                                        to={fileUrl !== "" ? `/file-manager/${filePath}` : '/actions/1'}
                                    />
                                </Switch>
                            </section>
                        </main>
                    </div>
                </BrowserRouter>
            );
        }
    }
}

export default App;
