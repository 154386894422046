import { CustomDatePicker, CustomForm, CustomInput } from "devkit/Form/Form";
import React, { Component } from "react";

class PersonalAccountAfter extends Component<any> {
    render() {
        const { email, phone_number, dateOfBirth, dateOfEmployment, firstName, lastName } = this.props.data;
        return (
            <div className="col-12 h-100perc">
                <CustomForm className="d-flex col-12 flex-column padding-0 margin-t-10">
                    <div className="mb-30">
                        <CustomInput
                            name="firstName"
                            label="NAME"
                            value={firstName.value}
                            type="text"
                            errors={firstName.errors}
                            handleInput={this.props.handleInput}
                            required={true}
                            edit={this.props.edit}
                        />
                    </div>
                    <div className="mb-30">
                        <CustomInput
                            name="lastName"
                            label="LAST_NAME"
                            value={lastName.value}
                            type="text"
                            errors={lastName.errors}
                            handleInput={this.props.handleInput}
                            required={true}
                            edit={this.props.edit}
                        />
                    </div>
                    <div className="mb-30">
                        <CustomInput
                            name="email"
                            label="Email"
                            value={email.value}
                            type="email"
                            errors={email.errors}
                            handleInput={this.props.handleInput}
                            required={true}
                            edit={this.props.edit}
                        />
                    </div>
                    <div className="mb-30">
                        <CustomInput
                            name="phone_number"
                            label="Phone"
                            value={phone_number.value ? phone_number.value : ""}
                            type="text"
                            errors={phone_number.errors}
                            handleInput={this.props.handleInput}
                            edit={this.props.edit}
                        />
                    </div>
                    <div className="mb-30">
                        <CustomDatePicker
                            name="dateOfBirth"
                            handleInput={this.props.handleInput}
                            value={dateOfBirth.value}
                            errors={dateOfBirth.errors}
                            label="DATE_OF_BIRTH"
                        />
                    </div>
                    <div>
                        <CustomDatePicker
                            name="dateOfEmployment"
                            handleInput={this.props.handleInput}
                            value={dateOfEmployment.value}
                            errors={dateOfEmployment.errors}
                            label="DATE_OF_EMPLOYMENT"
                        />
                    </div>
                </CustomForm>
            </div>
        );
    }
}

export default PersonalAccountAfter;
