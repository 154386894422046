import { Loader } from "devkit/Loader/Loader";
import Table from "devkit/Table/Table";
import { parseDate, parseDateForMySQL } from "functions/utilFunctions/parsingFunctions";
import React, { Component } from "react";
import ApiService from "services/api.service";
import { StoreService } from "services/store.service";
import {
    CustomButton,
    CustomForm,
    CustomInput,
    CustomDropdown,
    CustomDatePicker,
    CustomTextArea,
} from "devkit/Form/Form";
import Translate from "functions/utilFunctions/translate";
import Modal from "../../../devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";

export class Salaries extends Component<any, any> {
    state = {
        costEstimateValues: [],
        salaries: [],
        costEstimateTableHeads: [
            {
                value: "actionName",
                sortable: true,
                sortableType: "string",
                sortableName: "title",
            },
            {
                value: "START_DATE",
                sortable: true,
                sortableType: "date",
                sortableName: "startDate",
            },
            {
                value: "costName",
                sortable: true,
                sortableType: "date",
                sortableName: "endDate",
            },
            {
                value: "costAmount",
                sortable: true,
                sortableType: "string",
                sortableName: "customer_name",
            },
            {
                value: "costPercent",
                sortable: true,
                sortableType: "string",
                sortableName: "firstName",
            },
            {
                value: "costFinal",
                sortable: true,
                sortableType: "number",
                sortableName: "priority",
            },
        ],
        salariesTableHeads: [
            {
                value: "ENTRY_DATE",
                sortableType: "date",
                sortableName: "entry_date",
            },
            {
                value: "TYPE",
                sortableType: "date",
                sortableName: "type",
            },
            {
                value: "AMOUNT",
                sortableType: "string",
                sortableName: "amount",
            },
            {
                value: "DETAILS",
                sortableType: "details",
                sortableName: "details",
            },
            { value: "" },
        ],
        salary: {
            id: {
                value: "",
                errors: [],
            },
            type: {
                value: 1,
                errors: [],
            },
            amount: {
                value: 0,
                errors: [],
            },
            entry_date: {
                value: new Date(),
                errors: [],
            },
            description: {
                value: "",
                errors: [],
            },
            employee_id: {
                value: "",
                errors: [],
            },
        },
        salary_types: [
            { value: 1, label: <Translate text="MONTHLY_SALARY" /> },
            { value: 2, label: <Translate text="PART_TIME_SALARY" /> },
        ] as any,
        isLoading: false,
        showAddSalaryModal: false,
        employeeID: this.props.employeeID,
        addSalarySuccess: false,
        addSalaryFailure: false,
        showDeleteModal: false,
        showSuccessModal: false,
        showDeleteFailure: false,
        deleteID: "",
        editSalary: false,
        updateSalarySuccess: false,
        updateSalaryFailure: false,
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        const employeeId = this.state.employeeID;
        ApiService.getExpenses({ id: employeeId }, token).then((res) => {
            if (res.success) {
                const salaries = res.data;
                this.setState(() => ({
                    costEstimateValues: res.data,
                    salaries,
                    isLoading: true,
                }));
            }
        });
    }

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            salary: {
                ...prevState.salary,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    addSalary = () => {
        const { editSalary, employeeID } = this.state;
        const { amount, entry_date, type, id, employee_id, description } = this.state.salary;
        const token = StoreService.getStoreProperty("token");
        const form = { amount, type };
        const errors = {};

        Object.keys(form).forEach((e) => {
            if (!form[e].value) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
        });

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                salary: {
                    ...prevState.salary,
                    ...errors,
                },
            }));
        } else {
            if (!editSalary) {
                ApiService.createSalary(
                    {
                        employee_id: employeeID,
                        amount: amount.value,
                        entry_date: parseDateForMySQL(entry_date.value),
                        type: type.value,
                        description: description.value,
                    },
                    token
                ).then((res) => {
                    if (res.status === "OK") {
                        this.setState((prevState: any) => ({
                            salaries: [...prevState.salaries, res.data],
                            salary: {
                                id: {
                                    value: "",
                                    errors: [],
                                },
                                type: {
                                    value: 1,
                                    errors: [],
                                },
                                amount: {
                                    value: 0,
                                    errors: [],
                                },
                                entry_date: {
                                    value: new Date(),
                                    errors: [],
                                },
                                description: {
                                    value: "",
                                    errors: [],
                                },
                            },
                            showAddSalaryModal: false,
                            addSalarySuccess: true,
                        }));
                    } else {
                        this.setState({
                            showAddSalaryModal: false,
                            addSalaryFailure: true,
                        });
                    }
                });
            } else {
                ApiService.updateSalary(
                    {
                        employee_id: employee_id.value,
                        amount: amount.value,
                        entry_date: parseDateForMySQL(entry_date.value),
                        description: description.value,
                        type: type.value,
                        id: id.value,
                    },
                    token
                ).then((res) => {
                    if (res.status === "OK") {
                        ApiService.getExpenses({ id: employeeID }, token).then((res) => {
                            if (res.success) {
                                const salaries = res.data;
                                this.setState(() => ({
                                    costEstimateValues: res.data.expenses,
                                    salaries,
                                    showAddSalaryModal: false,
                                    updateSalarySuccess: true,
                                }));
                            }
                        });
                    } else {
                        this.setState({
                            showAddSalaryModal: false,
                            updateSalaryFailure: true,
                        });
                    }
                });
            }
        }
    };

    deleteSalary = () => {
        const id = this.state.deleteID;
        const token = StoreService.getStoreProperty("token");
        const employeeId = this.state.employeeID;

        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            deleteID: "",
        });
        ApiService.deleteSalary({ id }, token).then((res) => {
            if (res.status === "OK") {
                ApiService.getExpenses({ id: employeeId }, token).then((res) => {
                    if (res.success) {
                        const salaries = res.data;
                        this.setState(() => ({
                            costEstimateValues: res.data.expenses,
                            salaries,
                            showSuccessModal: true,
                        }));
                    }
                });
            }
        });
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };

    handleEdit = (e) => {
        this.setState({
            salary: {
                id: {
                    value: e.id,
                    errors: [],
                },
                type: {
                    value: e.type,
                    errors: [],
                },
                amount: {
                    value: e.amount,
                    errors: [],
                },
                entry_date: {
                    value: new Date(e.entry_date),
                    errors: [],
                },
                description: {
                    value: e.description ? e.description : "",
                    errors: [],
                },
            },
            showAddSalaryModal: true,
            editSalary: true,
        });
    };

    handleNewSalary = () => {
        this.setState({
            salary: {
                id: {
                    value: "",
                    errors: [],
                },
                type: {
                    value: 1,
                    errors: [],
                },
                amount: {
                    value: "",
                    errors: [],
                },
                entry_date: {
                    value: new Date(),
                    errors: [],
                },
                // employee_id: {
                //     value: "",
                //     errors: [],
                // },
                description: {
                    value: "",
                    errors: [],
                },
            },
            showAddSalaryModal: true,
            editSalary: false,
        });
    };

    cloneSalary = (data) => {
        console.log(data);

        this.setState({
            salary: {
                id: {
                    value: "",
                    errors: [],
                },
                type: {
                    value: data.type,
                    errors: [],
                },
                amount: {
                    value: data.amount,
                    errors: [],
                },
                entry_date: {
                    value: new Date(),
                    errors: [],
                },
                description: {
                    value: data.description ? data.description : "",
                    errors: [],
                },
            },
            showAddSalaryModal: true,
            editSalary: false,
        });
    };
    render() {
        if (!this.state.isLoading) {
            return <Loader width={200} />;
        }
        return (
            <div>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        text="DELETE_SALARY_?"
                        modalName="showDeleteModal"
                        title="DELETE_SALARY"
                        toggleModal={this.toggleModal}
                        deleteItem={this.deleteSalary}
                        cancelDelete={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessModal"
                    className={this.state.showSuccessModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="DELETE_SALARY_SUCCESS"
                        modalName="showSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteFailure"
                    className={this.state.showDeleteFailure ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="DELETE_SALARY_FAILURE"
                        modalName="showDeleteFailure"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="addSalarySuccess"
                    className={this.state.addSalarySuccess ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="ADD_SALARY_SUCCESS"
                        modalName="addSalarySuccess"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="addSalaryFailure"
                    className={this.state.addSalaryFailure ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="ADD_SALARY_FAILURE"
                        modalName="addSalaryFailure"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="updateSalarySuccess"
                    className={this.state.updateSalarySuccess ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="UPDATE_SALARY_SUCCESS"
                        modalName="updateSalarySuccess"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="updateSalaryFailure"
                    className={this.state.updateSalaryFailure ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="UPDATE_SALARY_FAILURE"
                        modalName="updateSalaryFailure"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showAddSalaryModal"
                    className={this.state.showAddSalaryModal ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <div className="d-flex flex-column align-items-center">
                        <h2>
                            <Translate text={!this.state.editSalary ? "ADD_SALARY" : "EDIT_SALARY"} />
                        </h2>
                        <CustomForm className="d-flex flex-column">
                            <div>
                                <div className="my-30">
                                    <CustomInput
                                        name="amount"
                                        label="AMOUNT"
                                        type="number"
                                        handleInput={this.handleInput}
                                        value={this.state.salary.amount.value}
                                        errors={this.state.salary.amount.errors}
                                    />
                                </div>
                                <div className="mb-30">
                                    <CustomDropdown
                                        data={this.state.salary_types}
                                        name="type"
                                        value={this.state.salary_types.find(
                                            (el) => el.value === this.state.salary.type.value
                                        )}
                                        errors={this.state.salary.type.errors}
                                        handleChange={this.handleInput}
                                        label="TYPE"
                                    />
                                </div>
                                <div className="mb-30">
                                    <CustomDatePicker
                                        name="entry_date"
                                        handleInput={this.handleInput}
                                        value={this.state.salary.entry_date.value}
                                        errors={this.state.salary.entry_date.errors}
                                        label="ENTRY_DATE"
                                    />
                                </div>
                                <div className="mb-30">
                                    <CustomTextArea
                                        name="description"
                                        label="DETAILS"
                                        handleInput={this.handleInput}
                                        value={this.state.salary.description.value}
                                        errors={this.state.salary.description.errors}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <CustomButton type="button" className="btn-primary mr-10" onClick={this.addSalary}>
                                    <Translate text={!this.state.editSalary ? "ADD_SALARY" : "EDIT_SALARY"} />
                                </CustomButton>

                                <CustomButton
                                    type="button"
                                    className="btn-primary"
                                    onClick={() => this.toggleModal("showAddSalaryModal")}
                                >
                                    <Translate text="CANCEL" />
                                </CustomButton>
                            </div>
                        </CustomForm>
                    </div>
                </Modal>
                <div className="mb-30 d-flex justify-content-between">
                    <h1>
                        <Translate text="SALARIES" />
                    </h1>
                    <CustomButton
                        type="button"
                        className="btn-primary"
                        onClick={this.handleNewSalary}
                        //onClick={() => this.toggleModal("showAddSalaryModal")}
                    >
                        <Translate text="ADD_SALARY" />
                    </CustomButton>
                </div>
                <Table
                    theads={this.state.salariesTableHeads}
                    theadsClassname="customThead"
                    className={"actionsTable f-s-14"}
                >
                    {this.state.salaries &&
                        this.state.salaries.map((e: any, i) => (
                            <tr
                                className="pointer"
                                // onClick={() => this.props.handleRedirect(e.id)}
                                key={i}
                            >
                                <td>
                                    {e.type === 1 || e.type === 2 ? parseDate(e.entry_date) : parseDate(e.created_at)}
                                </td>
                                <td>
                                    {e.type === 1 ? (
                                        <Translate text="MONTHLY_SALARY" />
                                    ) : e.type === 2 ? (
                                        <Translate text="PART_TIME_SALARY" />
                                    ) : (
                                        <Translate text="costEstimate" />
                                    )}
                                </td>
                                <td>{e.amount}</td>
                                <td>
                                    {/* {e.type === 1 || e.type === 2 ? (
                                        <Translate text="no_details" />
                                    ) : e.action && e.action.title ? (
                                        e.action.title +
                                        " - " +
                                        e.title +
                                        " - " +
                                        e.total_amount +
                                        "€ * " +
                                        e.percentage +
                                        "% = " +
                                        e.amount +
                                        "€"
                                    ) : (
                                        e.title +
                                        " - " +
                                        e.total_amount +
                                        "€ * " +
                                        e.percentage +
                                        "% = " +
                                        e.amount +
                                        "€"
                                    )} */}
                                    {e.type === 1 || e.type === 2 ? (
                                        e.description ? (
                                            e.description
                                        ) : (
                                            <Translate text="no_details" />
                                        )
                                    ) : e.action && e.action.title ? (
                                        e.action.title +
                                        " - " +
                                        e.title +
                                        " - " +
                                        e.total_amount +
                                        "€ * " +
                                        e.percentage +
                                        "% = " +
                                        e.amount +
                                        "€"
                                    ) : (
                                        e.title +
                                        " - " +
                                        e.total_amount +
                                        "€ * " +
                                        e.percentage +
                                        "% = " +
                                        e.amount +
                                        "€"
                                    )}
                                </td>
                                <td>
                                    {e.type === 1 || e.type === 2 ? (
                                        <div className="d-flex padding-5 justify-content-end">
                                            <i
                                                className="far fa-clone edit-icon mr-20 padding-10 border-1 pointer border-radius-6 w-35"
                                                onClick={() => this.cloneSalary(e)}
                                            ></i>
                                            <i
                                                className="fas mr-20 fa-edit edit-icon padding-10 border-1 pointer border-radius-6 w-35"
                                                onClick={() => {
                                                    this.handleEdit(e);
                                                }}
                                            />
                                            <i
                                                className="fas fa-trash trash-icon padding-10 border-1 pointer border-radius-6 w-35"
                                                onClick={() => {
                                                    this.handleDeleteModal(e.id);
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                </Table>
                {/* <h1 className="my-30">
                    <Translate text="ACTION_EXPENSES" />
                </h1>
                <Table
                    theads={this.state.costEstimateTableHeads}
                    theadsClassname="customThead"
                    className={"actionsTable f-s-14"}
                    // handleSort={this.props.handleSort}
                    sortName="actions"
                    arrayToSort={this.state.costEstimateValues}
                >
                    {this.state.costEstimateValues &&
                        this.state.costEstimateValues.map((e: any, i) => (
                            <tr
                                className="pointer"
                                // onClick={() => this.props.handleRedirect(e.id)}
                                key={i}
                            >
                                <td>{e.action && e.action.title}</td>
                                <td>
                                    {e.action && parseDate(e.action.created_at)}
                                </td>
                                <td>{e.title}</td>
                                <td>{e.amount}</td>
                                <td>{e.percentage}</td>
                                <td>{e.calculation}</td>
                            </tr>
                        ))}
                </Table> */}
            </div>
        );
    }
}

export default Salaries;
