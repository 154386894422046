import React, { Component } from "react";

import { Constants } from "../../../../services/constants.service";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Loader } from "devkit/Loader/Loader";

class MonthlyOverview extends Component<any, any> {
    state = {
        actions: this.props.actions,
        form: {
            search: {
                value: "",
                errors: [],
            },
        },
        language: false,
        isLoading: false,
        monthly: false,
        userDaily: 0,
        customer: 0,
        status: 0,
        priority: 0,
    };

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
            actions: [],
        }));
    };

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    componentDidUpdate(prevProps) {
        if (this.props.employee === this.state.userDaily && this.state.userDaily !== 0 && !this.state.monthly) {
            this.setState((prevState) => ({
                isLoading: false,
                actons: !prevState.actions,
            }));
        }
        if (prevProps.actions !== this.props.actions && (this.props.user !== 0 || prevProps.user === this.props.user)) {
            this.setState({
                isLoading: false,
                actions: this.props.actions,
            });
        }
    }

    handleSelect = (selectedInfo) => {
        this.props.handleDatePicked(selectedInfo.start);
    };

    handleMonth = (selectedInfo) => {
        this.props.handleMonth(selectedInfo.start, selectedInfo.end);
    };

    eventSelectHandle = (selectedInfo) => {
        this.props.handleDatePicked(selectedInfo.event.start);
    };

    groupByFinished = (actions) => {
        let helperArray: any = [];
        let events: any = [];
        let newActions = actions;
        if (this.props.status && this.props.status !== 5) {
            newActions = actions.filter((e: any) => e.status === this.props.status);
        } else if (this.props.status === 5) {
            newActions = actions.filter((action) => {
                return action.users.length === 0;
            });
        }
        for (let i = 0; i < newActions.length; i++) {
            let actionsStart = newActions[i].startDate.split(" ")[0];
            if (helperArray.indexOf(actionsStart) === -1) {
                helperArray.push(actionsStart);
                let numberFinished = 0;
                let numberInProgress = 0;
                let numberLate = 0;
                let numberUrgent = 0;
                let numberPriority = 0;
                let numberImportant = 0;
                let numberNormal = 0;
                let numberVeryImportant = 0;
                let numberPlanned = 0;

                for (let j = 0; j < newActions.length; j++) {
                    // Search for other actions that have same `startDate`
                    if (newActions[j].startDate.split(" ")[0] === actionsStart) {
                        // Group actions by status
                        if (newActions[j].status === Constants.ACTION_STATUS_COMPLETED) {
                            numberFinished++;
                        } else if (newActions[j].status === Constants.ACTION_STATUS_DELAY) {
                            numberLate++;
                        } else if (newActions[j].status === Constants.ACTION_STATUS_IN_PROGRESS) {
                            numberInProgress++;
                        } else {
                            numberPlanned++;
                        }
                        // Group actions by priority
                        if (newActions[j].priority === 4) {
                            numberUrgent++;
                        } else if (newActions[j].priority === 3) {
                            numberPriority++;
                        } else if (newActions[j].priority === 2) {
                            numberVeryImportant++;
                        } else if (newActions[j].priority === 1) {
                            numberImportant++;
                        } else {
                            numberNormal++;
                        }
                    }
                }

                if (numberFinished) {
                    events.push({
                        title:
                            this.props.language === "me" ? "Zavrsene:" + numberFinished : "Finished: " + numberFinished,
                        date: actionsStart,
                        borderColor: "#00cc00",
                        //borderLeft: "10px solid #00cc00",
                    });
                }
                if (numberLate) {
                    events.push({
                        title: this.props.language === "me" ? "U zakasnjenju: " + numberLate : "Late: " + numberLate,
                        date: actionsStart,
                        borderColor: "#ff4d4d",
                        //borderLeft: "10px solid #ff4d4d",
                    });
                }
                if (numberInProgress) {
                    events.push({
                        title:
                            this.props.language === "me"
                                ? "U toku:" + numberInProgress
                                : "In progress: " + numberInProgress,
                        date: actionsStart,
                        borderColor: "#e69757",
                        // className: "progress",
                    });
                }
                if (numberPlanned) {
                    events.push({
                        title:
                            this.props.language === "me" ? "Planirane:" + numberPlanned : "Planned: " + numberPlanned,
                        date: actionsStart,
                        borderColor: "#e6e600",
                    });
                }

                events.push(
                    {
                        title:
                            this.props.language === "me"
                                ? "Ukupan broj:" + (numberFinished + numberLate + numberPlanned + numberInProgress)
                                : "Total: " + (numberFinished + numberLate + numberPlanned + numberInProgress),
                        date: actionsStart,
                    },
                    {
                        title: "",
                        date: actionsStart,
                        backgroundColor: "transparent",
                    }
                );

                if (numberUrgent) {
                    events.push({
                        title: this.props.language === "me" ? "Urgentne: " + numberUrgent : "Urgent: " + numberUrgent,
                        date: actionsStart,
                        borderColor: "#ff1100",
                    });
                }
                if (numberPriority) {
                    events.push({
                        title:
                            this.props.language === "me"
                                ? "Prioritetne: " + numberPriority
                                : "Priority: " + numberPriority,
                        date: actionsStart,
                        borderColor: "#ff9800",
                    });
                }
                if (numberVeryImportant) {
                    events.push({
                        title:
                            this.props.language === "me"
                                ? "Veoma važne: " + numberVeryImportant
                                : "Very important: " + numberVeryImportant,
                        date: actionsStart,
                        borderColor: "#d5bf05",
                    });
                }
                if (numberImportant) {
                    events.push({
                        title:
                            this.props.language === "me"
                                ? "Važne: " + numberImportant
                                : "Important: " + numberImportant,
                        date: actionsStart,
                        borderColor: "#f8f23bbd",
                    });
                }
                if (numberNormal) {
                    events.push({
                        title: this.props.language === "me" ? "Obične: " + numberNormal : "Normal: " + numberNormal,
                        date: actionsStart,
                        borderColor: "#43425d",
                    });
                }
            }
        }
        return events;
    };

    static getDerivedStateFromProps(props, state) {
        if (props.user !== state.userDaily) {
            return {
                userDaily: props.user,
            };
        }
        if (props.customer !== state.customer) {
            return {
                customer: props.customer,
            };
        }
        if (props.status !== state.status) {
            return {
                status: props.status,
            };
        }
        if (props.priority !== state.priority) {
            return {
                priority: props.priority,
            };
        }
        if (props.language !== state.language) {
            return {
                language: props.language,
            };
        }

        if (props.state === state.monthly) {
            return {
                monthly: props.state,
            };
        }
    }

    render() {
        const { actions } = this.props;
        for (let i = 0; i < actions.length; i++) {
            actions[i].startDate = actions[i].startDate.split("T").join(" ");
            actions[i].endDate = actions[i].endDate.split("T").join(" ");
        }
        let events = this.groupByFinished(actions);
        const { isLoading } = this.props;

        return (
            <div className="monthlyCalendar">
                <div
                    className={`${isLoading ? "p-r" : ""}`}
                    style={{
                        height: `${isLoading ? "calc(100vh - 380px)" : ""}`,
                    }}
                >
                    {isLoading ? (
                        <div className="loaderContainer d-flex h-100-perc w-100-perc bg-white" style={{ zIndex: 99 }}>
                            <Loader width={200} />
                        </div>
                    ) : (
                        ""
                    )}
                    <FullCalendar
                        initialView="dayGridMonth"
                        headerToolbar={{
                            left: "prev title next",
                            center: "",
                            right: "",
                        }}
                        locale={this.props.language === "en" ? "en-US" : "sr-latn-SR"}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        selectable={true}
                        select={this.handleSelect}
                        eventClick={this.eventSelectHandle}
                        editable={true}
                        eventDisplay="list-item"
                        dayMaxEvents={5}
                        events={events}
                        eventOrder={"color"}
                        navLinks={false}
                        datesSet={this.handleMonth}
                        // eventBackgroundColor={"#efefef"}
                        eventTextColor="#333"
                        // eventBorderColor="#ffffff"
                        firstDay={1}
                        showNonCurrentDates={true}
                        // dayHeaderFormat={{ weekday: "long" }}
                        displayEventTime={false}
                        fixedWeekCount={false}
                        viewClassNames={`${isLoading ? "d-none h-50" : "d-block"} padding-t-25`}
                        initialDate={this.props.startDate}
                    />
                </div>
            </div>
        );
    }
}

export default MonthlyOverview;
