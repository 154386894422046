import Table from "devkit/Table/Table";
import React, { Component } from "react";
import { parseDate } from "functions/utilFunctions/parsingFunctions";
import { CustomButton, CustomDateTimePicker, CustomForm, CustomInput } from "devkit/Form/Form";
import CustomDropdown from "devkit/Form/CustomDropdown/CustomDropdown";
import { ScreenContext } from "components/ScreenContext/ScreenContext";
import FilterSidebar from "components/FilterSidebar/FilterSidebar";
import { FilterIcon } from "components/Icons/Icons";
import { ActionUtilFunctions } from "functions/utilFunctions/actionUtilFunctions";

class ClientActions extends Component<any> {
    state = {
        showFilterSidebar: false,
    };

    static contextType = ScreenContext;

    toggleSidebar = (e) => {
        e.preventDefault();
        this.setState({ showFilterSidebar: !this.state.showFilterSidebar });
    };

    mouseDownHandler = (e) => {
        if (e.button === 1) {
            window.open(`/action/${e.currentTarget.id}`, "_blank");
        }
    };

    render() {
        const { actions, tableHeads, filters, users } = this.props;

        return (
            <div className="clientActions d-flex flex-column">
                <div className="filters">
                    <div className="col-xl-4 mt-20">
                        <CustomButton
                            onClick={this.toggleSidebar}
                            className="filterBtn btn btn-primary d-flex align-items-center"
                        >
                            <FilterIcon className="icon" width={16}></FilterIcon>
                            <span className="ml-10">Filteri</span>
                        </CustomButton>
                    </div>

                    <FilterSidebar
                        opened={this.state.showFilterSidebar}
                        onClose={() => this.setState({ showFilterSidebar: !this.state.showFilterSidebar })}
                        sidebarClasses="clientsFilterSidebar"
                        wrapperClasses="clientsFilterWrapper"
                        submitFilters={this.props.submitFilters}
                        resetFilters={this.props.resetFilters}
                    >
                        <CustomForm>
                            <div className="mb-20">
                                <CustomInput
                                    value={filters.search.value}
                                    handleInput={this.props.handleInput}
                                    name="search"
                                    type="text"
                                    label="Search"
                                />
                            </div>
                            <div className="mb-20">
                                <CustomDropdown
                                    data={users}
                                    name="user_id"
                                    handleChange={this.props.handleInput}
                                    value={users.find((e: any) => e.value === filters.user_id.value)}
                                    label={"assignedEmployee"}
                                />
                            </div>
                            <div className="mb-20">
                                <CustomDateTimePicker
                                    name="startDate"
                                    handleInput={this.props.handleInput}
                                    value={filters.startDate.value}
                                    errors={filters.startDate.errors}
                                    label={"START_DATE"}
                                />
                            </div>
                            <div className="mb-20">
                                <CustomDateTimePicker
                                    name="endDate"
                                    handleInput={this.props.handleInput}
                                    value={filters.endDate.value}
                                    errors={filters.endDate.errors}
                                    label={"END_DATE"}
                                />
                            </div>
                        </CustomForm>
                    </FilterSidebar>
                </div>

                <div className="overflow-auto margin-top-20 f-s-14 pb-20">
                    <Table
                        theads={tableHeads}
                        theadsClassname="customThead"
                        className={"actionsTable"}
                        handleSort={this.props.handleSort}
                        sortName="actions"
                        arrayToSort={actions}
                    >
                        {actions.map((e: any, i) => (
                            <tr
                                className="pointer"
                                onClick={() => this.props.handleRedirect(e.id)}
                                key={i}
                                id={e.id}
                                onMouseDown={(ev) => this.mouseDownHandler(ev)}
                            >
                                <td>{e.title}</td>

                                <td>{parseDate(e.startDate)}</td>
                                <td>{parseDate(e.endDate)}</td>
                                <td>{e.customers[0] && e.customers[0].name}</td>
                                <td className="d-flex flex-column autoHeight">
                                    {e.users &&
                                        e.users.map((e: any) => {
                                            return <span>{`${e.firstName} ${e.lastName}`}</span>;
                                        })}
                                </td>
                                <td>{ActionUtilFunctions.renderPriority(e.priority)}</td>
                                <td>{ActionUtilFunctions.renderActionState(e.status)}</td>
                            </tr>
                        ))}
                    </Table>
                </div>
            </div>
        );
    }
}

export default ClientActions;
