import { PlusIcon } from "components/Icons/Icons";
import { CustomButton } from "devkit/Form/Form";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import Translate from "functions/utilFunctions/translate";
import React, { Component } from "react";
import ApiService from "services/api.service";
import { StoreService } from "services/store.service";
import { UtilService } from "services/util.service";
import Column from "../../../../components/AgileOverview/Column";

export default class AgileOverview extends Component<any, any> {
    state = {
        columns: [] as any,
        scrollPos: {
            top: 0,
            left: 0,
            x: 0,
            y: 0,
        },
        columnDeleteSuccessModal: false,
        columnDeleteFailModal: false,
        columnDeleteModal: false,
        deleteID: "",
        columnID: "",
        draggedColumn: {} as any,
        draggedAction: {} as any,
        clientY: 0,
        interval: 0,
    };

    componentDidMount() {
        this.setState({ columns: this.props.columns });
        document.addEventListener("mousedown", this.mouseDownHandler);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.columns !== this.props.columns) {
            this.setState({ columns: this.props.columns });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.mouseDownHandler);
    }

    clearScrollIntervals = () => {
        if (this.state.interval) {
            clearInterval(this.state.interval);
            this.setState({ interval: 0 });
        }
    };

    mouseDownHandler = (e) => {
        const container = document.getElementById("agile-container")!;
        if (
            (e && e.target && e.target.id === "agile-container") ||
            (e && e.target && e.target.parentElement.id === "agile-container")
        ) {
            this.setState({
                scrollPos: {
                    left: container.scrollLeft,
                    top: container.scrollTop,
                    x: e.clientX,
                    y: e.clientY,
                },
            });

            container.style.userSelect = "none";

            document.addEventListener("mousemove", this.mouseMoveHandler);
            document.addEventListener("mouseup", this.mouseUpHandler);
        }
    };

    mouseMoveHandler = (e) => {
        const container = document.getElementById("agile-container")!;
        const { scrollPos } = this.state;

        const dx = e.clientX - scrollPos.x;
        const dy = e.clientY - scrollPos.y;

        container.scrollTop = scrollPos.top - dy;
        container.scrollLeft = scrollPos.left - dx;
    };

    mouseUpHandler = () => {
        const container = document.getElementById("agile-container")!;

        container.style.cursor = "default";
        container.style.removeProperty("user-select");

        document.removeEventListener("mousemove", this.mouseMoveHandler);
        document.removeEventListener("mouseup", this.mouseUpHandler);
    };

    addColumn = () => {
        const { columns } = this.state;
        const token = StoreService.getStoreProperty("token");
        const title = "Nova kolona";
        const template = this.props.template ? 1 : 0;

        ApiService.createColumn({ title, template }, token).then((response) => {
            if (response.status === "OK") {
                const container = document.getElementById("agile-container")!;
                const newColumnID = response.data.id;

                this.setState({ columns: [...columns, response.data] }, () => {
                    container.style.scrollBehavior = "smooth";

                    container.scrollTo({
                        top: 0,
                        left: container.scrollWidth,
                        behavior: "smooth",
                    });

                    const newColumnTitleInput = document.querySelector(
                        `[id="c${newColumnID.toString()}"].column .titleInput`
                    ) as HTMLElement;

                    newColumnTitleInput.focus();

                    container.style.scrollBehavior = "auto";
                });
            }
        });
    };

    handleTitleChange = (value, id) => {
        const token = StoreService.getStoreProperty("token");
        UtilService.handleDebouncing(
            "handleTitleUpdateDebouncing",
            () => {
                ApiService.updateColumn({ id, title: value }, token);
            },
            300
        );
    };

    handleDeleteColumn = () => {
        const { deleteID, columns } = this.state;
        const token = StoreService.getStoreProperty("token");
        this.setState({ columnDeleteModal: false });

        ApiService.deleteColumn(deleteID, token).then((response) => {
            if (response.status === "OK") {
                const newColumns = columns.filter((col) => col.id !== deleteID);

                this.setState({
                    columns: newColumns,
                    columnDeleteSuccessModal: true,
                });
            } else {
                this.toggleModal("columnDeleteFailModal");
            }
        });
    };

    handleDeleteColumnModal = (id) => {
        this.setState({
            deleteID: id,
            columnDeleteModal: true,
        });
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleNoModal = () => {
        this.setState({
            columnDeleteModal: !this.state.columnDeleteModal,
            deleteID: "",
        });
    };

    columnDragStart = (e, id) => {
        const draggedColumn = document.getElementById(`c${id}`)!;

        draggedColumn.classList.add("column-dragging");

        Array.from(document.getElementsByClassName("action")).forEach((action) =>
            action.classList.remove("action-hover")
        );
    };

    containerDragOver = (e) => {
        e.preventDefault();
        const token = StoreService.getStoreProperty("token");
        const container = document.getElementById("agile-container");

        const vw = window.innerWidth;

        const scrollLeftFunc = () => {
            container!.scrollLeft += 20;
        };
        const scrollRightFunc = () => {
            container!.scrollLeft -= 20;
        };

        let interval;
        if (e.clientX > vw / 2) {
            if (e.clientX > vw - 0.1 * vw) {
                if (!this.state.interval) {
                    interval = setInterval(scrollLeftFunc, 20);
                    this.setState({ interval: interval });
                }
            } else {
                if (this.state.interval) {
                    clearInterval(this.state.interval);
                    this.setState({ interval: 0 });
                }
            }
        } else {
            if (e.clientX < 0.1 * vw) {
                if (!this.state.interval) {
                    interval = setInterval(scrollRightFunc, 20);
                    this.setState({ interval: interval });
                }
            } else {
                if (this.state.interval) {
                    clearInterval(this.state.interval);
                    this.setState({ interval: 0 });
                }
            }
        }

        const draggedColumn = document.querySelector(".column-dragging")!;

        if (draggedColumn) {
            const afterElement = this.getDragAfterElementX(container, e.clientX).element?.parentElement;

            if (!afterElement) {
                container?.appendChild(draggedColumn.parentElement!);
            } else {
                container?.insertBefore(draggedColumn.parentElement!, afterElement);
            }

            UtilService.handleDebouncing(
                "column-drag-debounce",
                () => {
                    const columnIds = Array.from(container!.getElementsByClassName("column")).map((col) =>
                        parseInt(col.id.substring(1))
                    );

                    ApiService.sortColumns({ columns: columnIds }, token).then((response) => {
                        if (response.success) {
                        }
                    });
                },
                500
            );
        }
    };

    getDragAfterElementX(container, x) {
        const draggableElements = [...container.querySelectorAll(".column:not(.column-dragging)")];

        return draggableElements.reduce(
            (closest, child) => {
                const box = child.getBoundingClientRect();
                const offset = x - box.left - box.width / 2;
                if (offset < 0 && offset > closest.offset) {
                    return { offset, element: child };
                } else {
                    return closest;
                }
            },
            {
                offset: Number.NEGATIVE_INFINITY,
            }
        );
    }

    columnDragEnd = (e, id) => {
        this.clearScrollIntervals();
        const draggedColumn = document.getElementById(`c${id}`)!;

        draggedColumn.classList.remove("column-dragging");
        Array.from(document.getElementsByClassName("action")).forEach((action) => action.classList.add("action-hover"));
    };

    actionDragStart = (e, id) => {
        const draggedAction = document.getElementById(`a${id}`)!;

        draggedAction.classList.add("action-dragging");
        Array.from(document.getElementsByClassName("action")).forEach((action) =>
            action.classList.remove("action-hover")
        );
    };

    columnDragOver = (e, id) => {
        const column = document.getElementById(`c${id}`);
        const actionList = column?.querySelector(".action-list");
        const draggedAction = document.querySelector(".action-dragging");

        if (e.target.closest(".column").id === `c${id}`) {
            if (draggedAction) {
                const afterElement = this.getDragAfterElementY(column, e.clientY).element;

                if (!afterElement) {
                    actionList?.appendChild(draggedAction);
                } else {
                    actionList?.insertBefore(draggedAction, afterElement);
                }
            }
        }
    };

    getDragAfterElementY(container, y) {
        const draggableElements = [...container.querySelectorAll(".action:not(.action-dragging)")];

        return draggableElements.reduce(
            (closest, child) => {
                const box = child.getBoundingClientRect();
                const offset = y - box.top - box.height / 2;
                if (offset < 0 && offset > closest.offset) {
                    return { offset, element: child };
                } else {
                    return closest;
                }
            },
            {
                offset: Number.NEGATIVE_INFINITY,
            }
        );
    }

    actionDragEnd = () => {
        this.clearScrollIntervals();

        const token = StoreService.getStoreProperty("token");
        const draggedAction = document.querySelector(`.action-dragging`)!;

        const column = draggedAction.closest(".column");
        const actionIds = Array.from(column!.getElementsByClassName("action")).map((action) =>
            parseInt(action.id.substring(1))
        );

        Array.from(document.getElementsByClassName("action")).forEach((action) => action.classList.add("action-hover"));

        ApiService.addToColumn(
            {
                column_id: parseInt(column!.id.substring(1)),
                actions: actionIds,
                target_action: parseInt(draggedAction.id.substring(1)),
            },
            token
        ).then((res) => {
            if (res.status === "OK") {
            }
        });

        draggedAction.classList.remove("action-dragging");
    };

    columnDragEnter = (e) => {
        e.preventDefault(e);
    };

    columnDragLeave = (e) => {
        e.preventDefault(e);
    };

    redirectToNewAction = () => {
        this.props.props.history.push("/new-action");
    };

    render() {
        const { columnDeleteModal, columnDeleteSuccessModal, columnDeleteFailModal, columns } = this.state;
        console.log(this.props.props);

        return (
            <div className="agileOverview d-flex flex-column">
                <header className="mt-25 d-flex">
                    <CustomButton
                        onClick={this.addColumn}
                        className="d-flex addColBtn align-items-center btn-primary mr-20"
                    >
                        <PlusIcon width={14} className="icon mr-10"></PlusIcon>

                        <div>
                            <Translate text="ADD_COLUMN" />
                        </div>
                    </CustomButton>
                    <CustomButton
                        onClick={this.redirectToNewAction}
                        className="d-flex addColBtn align-items-center btn-primary"
                    >
                        <PlusIcon width={14} className="icon mr-10"></PlusIcon>

                        <div>
                            <Translate text="ADD_ACTION" />
                        </div>
                    </CustomButton>
                </header>
                <main className="mt-30 d-flex" id="agile-container" onDragOver={this.containerDragOver}>
                    {columns[0] &&
                        columns.map((col, i) => {
                            if (col.title === "Nedodijeljeno") {
                                return (
                                    <div className="mr-15 rotateColumn min-h-500" key={i}>
                                        <Column
                                            title="Nedodijeljeno"
                                            actions={col.actions}
                                            titleChange={this.handleTitleChange}
                                            id={col.id}
                                            index={i}
                                            columnDragOver={this.columnDragOver}
                                            columnDragEnter={this.columnDragEnter}
                                            columnDragLeave={this.columnDragLeave}
                                            actionDragStart={this.actionDragStart}
                                            actionDragEnd={this.actionDragEnd}
                                            unassigned
                                        ></Column>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="mr-15 min-h-500 rotateColumn" key={i}>
                                        <Column
                                            index={i}
                                            id={col.id}
                                            title={col.title}
                                            actions={col.actions}
                                            handleTitleChange={this.handleTitleChange}
                                            handleDeleteColumnModal={this.handleDeleteColumnModal}
                                            columnDragOver={this.columnDragOver}
                                            columnDragStart={this.columnDragStart}
                                            columnDragEnd={this.columnDragEnd}
                                            columnDragEnter={this.columnDragEnter}
                                            columnDragLeave={this.columnDragLeave}
                                            actionDragStart={this.actionDragStart}
                                            actionDragEnd={this.actionDragEnd}
                                        />
                                    </div>
                                );
                            }
                        })}
                </main>

                <Modal
                    toggleModal={this.toggleModal}
                    modalName="columnDeleteModal"
                    className={columnDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-650 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        text={"COLUMN_DELETE_?"}
                        modalName="columnDeleteModal"
                        title={"DELETE_COLUMN"}
                        toggleModal={this.toggleModal}
                        deleteItem={this.handleDeleteColumn}
                        cancelDelete={this.handleNoModal}
                        contentClass={"w-90-perc"}
                        iconWidth={100}
                    />
                </Modal>

                <Modal
                    toggleModal={this.toggleModal}
                    modalName="columnDeleteSuccessModal"
                    className={columnDeleteSuccessModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text={"COLUMN_DELETE_SUCCESS"}
                        modalName="columnDeleteSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="columnDeleteFailModal"
                    className={columnDeleteFailModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text={"COLUMN_DELETE_FAIL"}
                        modalName="columnDeleteFailModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
            </div>
        );
    }
}
