import React, { useState } from "react";
import { parseDatetime } from "functions/utilFunctions/parsingFunctions";
import { Constants } from "services/constants.service";
import { CustomTextArea } from "devkit/Form/Form";
import ApiService from "services/api.service";
import Translate from "functions/utilFunctions/translate";
import Modal from "devkit/Modal/Modal";
import { Avatar } from "components/Icons/Icons";
import { ModalContent } from "devkit/Modal/ModalContent";

const CommentComponent = ({ comment, token, user, deleteComment }) => {
    const pictureUrl = comment.user.profile_image && comment.user.profile_image.split("/");
    const [edit, setEdit] = useState(false);
    const [commentText, setCommentText] = useState(comment.text);
    const [editComment, setEditComment] = useState({ value: "", errors: [] });
    const [showDeleteCommentModal, setShowDeleteCommentModal] = useState(false);

    // useEffect(() => {
    //     document.addEventListener("mousedown", mouseDownHandler);
    // });

    // const mouseDownHandler = () => {
    //     setEdit(false);
    // };

    const handleInput = (name, data) => {
        setEditComment({ value: data.value, errors: data.errors });
    };
    const editCommentFunction = (commentID) => {
        const data = {
            text: editComment.value,
            id: commentID,
        };
        ApiService.updateActionComment(data, token).then((res) => {
            if (res.status === "OK") {
                setCommentText(editComment.value);
                setEditComment({ value: "", errors: [] });
                setEdit(false);
            } else {
                alert("Error");
            }
        });
    };

    const toggleModal = () => {
        setShowDeleteCommentModal(!showDeleteCommentModal);
    };

    return (
        <div className="comment mt-15">
            <div className="commentWrapper d-flex align-items-center mb-10">
                <div className="d-flex justify-content-center align-items-center mr-20">
                    {pictureUrl && pictureUrl[pictureUrl.length - 1] !== "null" ? (
                        <img
                            src={`${Constants.STORAGE}${comment.user.profile_image}`}
                            alt="user"
                            className="w-50 h-50 border-radius-50-perc"
                        />
                    ) : (
                        <Avatar className="w-50 h-50 border-radius-50-perc" />
                    )}
                </div>
                <div className="w-100perc">
                    <div className="d-flex align-items-center mb-10 justify-content-between">
                        <span>
                            <span className="f-s-14 f-w-600 nameSurname">
                                {comment.user.firstName + " " + comment.user.lastName}
                            </span>
                            <span className="f-s-11 ml-30 mr-10">{parseDatetime(comment.created_at)}</span>
                        </span>
                        <span
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            className={`text-center ${user.role === 1 ? "disabledDiv" : ""}`}
                        >
                            {!edit && parseInt(comment.user.id) === parseInt(user.id) && (
                                <div className="mt-5 d-flex ml-20 mr-10">
                                    <span
                                        className="edit mr-15"
                                        onClick={() => {
                                            setEdit(true);
                                            // editComment.value = comment.text;
                                            setEditComment({ value: commentText, errors: [] });
                                        }}
                                    >
                                        {/* <Translate text={"EDIT"} /> */}
                                        <i className="mr-5 fas fa-edit edit-icon padding-5 border-1 pointer border-radius-6 w-25" />
                                    </span>
                                    <span className="deleteSpan" onClick={toggleModal}>
                                        <i className="fas fa-trash trash-icon padding-5 border-1 pointer border-radius-6 w-25" />
                                        {/* <Translate text={"DELETE"} /> */}
                                    </span>
                                </div>
                            )}
                        </span>
                    </div>
                    {!edit ? (
                        // <div className="textComment p-10 f-s-12">{comment.text}</div>
                        <div className="textComment p-10 f-s-12">{commentText}</div>
                    ) : (
                        <div
                            className="editCommentDiv p-10"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <CustomTextArea
                                name="editComment"
                                handleInput={handleInput}
                                value={editComment.value}
                                errors={editComment.errors}
                                className="non-resizable"
                                classNameInputC="mb-10"
                                autoGrowTextarea
                            />
                            <div className="d-flex align-items-center ">
                                <span onClick={() => editCommentFunction(comment.id)} className="save f-s-12">
                                    <Translate text={"SAVE"} />
                                </span>
                                <span onClick={() => setEdit(false)} className="cancel pointer ml-15 f-s-11">
                                    <Translate text={"CANCEL"} />
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Modal
                toggleModal={toggleModal}
                modalName="showDeleteFileModal"
                className={showDeleteCommentModal ? "visible " : ""}
                modalWrapperClasses="w-400 padding-10 "
            >
                <ModalContent
                    type="warning"
                    text="DELETE_COMMENT_?"
                    modalName="showDeleteFileModal"
                    title="DELETE_FILE"
                    toggleModal={toggleModal}
                    // item={this.state.fileIndex}
                    deleteItem={() => deleteComment(comment.id)}
                    cancelDelete={toggleModal}
                />
            </Modal>
        </div>
    );
};

export default CommentComponent;
