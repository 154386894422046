import React, { Component } from "react";
import {
    CustomDropdown,
    CustomFileInput,
    CustomForm,
    CustomTextArea
} from "../../devkit/Form/Form";
import { Devkit } from "oykos-development-devkit";
import {
    PhotoIcon,
    AddPhoto,
    SendMessageIcon,
    ChatIcon as Chat
} from "../../components/Icons/Icons";
import { FileManagerIcons } from "../../components/Icons/FileManagerIcons";
import { UtilService } from "../../services/util.service";
import { StoreService } from "../../services/store.service";
import ChatConversationPreview
    from "../../components/Chat/ChatConversationPreview";
import { Loader } from "../../devkit/Loader/Loader";
import ApiService
    from "../../services/api.service";
import Translate
    from "../../functions/utilFunctions/translate";
import { DISABLE_FIREBASE } from "../../services/constants.service";

const FirebaseService = Devkit.FirebaseService;

class ChatModule extends Component {
    constructor(props) {
        super(props);

        this.sendMessage = this.sendMessage.bind(this);
        this.handleDeleteMessage = this.handleDeleteMessage.bind(this);
        this.handleDisplayMessage = this.handleDisplayMessage.bind(this);
        this.resetMessage = this.resetMessage.bind(this);
        this.newMessage = this.newMessage.bind(this);
    }

    state = {
        currentMessage: "",
        messages: {},
        isOver: false,
        currentlySelectedMessage: {
            messages: {},
            users: [],
            timestamp: {},
            docId: "",
            conversationId: ""
        },
        messageFile: {
            value: ""
        },
        allChatProfiles: [],
        pickedChatProfiles: [],
        screenLoading: true,
        messagesLoading: false,
        showFilesRight: false
    };

    componentDidMount(): void {
        const component = this;

        this.setInitialData().then((success: boolean | undefined) => {
            if (success) {
                if (!DISABLE_FIREBASE) {
                    FirebaseService.authUser(
                        (conversationId: string | number) => {
                            this.handleDeleteMessage(conversationId);
                        },
                        (
                            conversationId: string | number,
                            conversationData: any
                        ) => {
                            this.handleDisplayMessage(
                                conversationId,
                                conversationData
                            );
                        }
                    ).then(() => {
                        component.setState({
                            screenLoading: false
                        });
                    });
                }
            }
        });

        document.addEventListener("click", () => {
            this.setState({ showFilesRight: false });
        });
    }

    setInitialData() {
        const token = StoreService.getStoreProperty("token");

        return ApiService.getAllUsers(
            {
                limit: 9999999,
                offset: 0
            },
            token
        ).then((res) => {
            if (res.status === "OK") {
                return true;
            }

            return false;
        });
    }

    handleDeleteMessage(id) {
        let messages = this.state.messages;

        delete messages[id];

        this.setState({
            messages
        });
    }

    handleDisplayMessage(id, message) {
        let messages = this.state.messages;

        messages[id] = message;

        this.setState({
            messages
        });
    }

    handleFileChange = (name, e, drop?) => {
        const newFile = UtilService.handleAddFile(name, e, drop);
        this.setState({
            messageFile: newFile
        });
    };

    handleTargetChatProfile = (name, data) => {
        this.setState({
            pickedChatProfiles: data.value
        });
    };

    selectConversation(conversation) {
        const component = this;
        const allChatProfiles =
            !this.state.allChatProfiles || !this.state.allChatProfiles.length
                ? StoreService.getStoreProperty("chatProfiles")
                : this.state.allChatProfiles;
        let pickedUids = {};
        let pickedChatProfiles: any = [];

        Object.values(allChatProfiles).forEach((profile: any) => {
            const profileUid = profile.uid ? profile.uid : profile.value;
            const profileName = profile.uid ? profile.name : profile.label;

            if (
                !pickedUids[profileUid] &&
                conversation.users.indexOf(profileUid) >= 0
            ) {
                pickedUids[profileUid] = true;

                pickedChatProfiles.unshift({
                    value: profileUid,
                    label: profileName
                });
            }
        });

        if (
            !this.state.currentlySelectedMessage.messages ||
            !Object.values(this.state.currentlySelectedMessage.messages).length
        ) {
            component.setState({
                messagesLoading: true
            });
        }


        if (!DISABLE_FIREBASE) {
            FirebaseService.getMessagesForConversation(conversation, 50).then(
                () => {
                    const chatProfiles =
                        StoreService.getStoreProperty("chatProfiles");

                    component.setState({
                        currentlySelectedMessage: conversation,
                        allChatProfiles: Object.values(chatProfiles).map(
                            (profile: any) => {
                                return {
                                    value: String(profile.uid),
                                    label: profile.name
                                };
                            }
                        ),
                        pickedChatProfiles,
                        messagesLoading: false
                    });
                }
            );
        }
    }

    sendMessage(e) {
        if (DISABLE_FIREBASE) return;

        const component = this;

        e.preventDefault();
        e.stopPropagation();

        // Mandatory data is at lest text or attachment, and mandatory is at least one picked chat profile (receiver)
        if (
            (!this.state.currentMessage &&
                !(this.state.messageFile && this.state.messageFile.value)) ||
            !this.state.pickedChatProfiles.length
        )
            return;

        const conversationId = this.state.currentlySelectedMessage.docId
            ? this.state.currentlySelectedMessage.docId
            : this.state.currentlySelectedMessage.conversationId;

        if (this.state.messageFile && this.state.messageFile.value) {
            // @TODO ovdje dodajte loader dok se file ucita
            return FirebaseService.sendFileMessage(
                this.state.messageFile.value,
                this.state.currentMessage,
                Object.values(this.state.pickedChatProfiles).map(
                    (profile: any) => {
                        return String(profile.value);
                    }
                ),
                conversationId
            ).then((conversationId: any) => {
                    // @TODO ovdje maknite loader jer se sve ucitalo
                    // @TODO ovdje treba napraviti logiku koja negdje prikazuje mali thumbnail fajla
                    // ako je u pitanju slika onda da se vidi, ako je neki drugi dokument neka bude neka ikonica (spajalica npr.)
                    component.resetMessage();

                    if (
                        component.state.messages &&
                        component.state.messages[conversationId]
                    ) {
                        component.selectConversation(
                            component.state.messages[conversationId]
                        );
                    }
                });
        }

        return FirebaseService.sendMessage(
            this.state.currentMessage,
            Object.values(this.state.pickedChatProfiles).map((profile: any) => {
                return String(profile.value);
            }),
            conversationId
        ).then((conversationId: any) => {
            component.resetMessage();

            if (
                component.state.messages &&
                component.state.messages[conversationId]
            ) {
                component.selectConversation(
                    component.state.messages[conversationId]
                );
            }
        });
    }

    resetMessage(resetChatProfile = false, resetPickedConversation = false) {
        let newState = this.state;

        newState.messageFile = {
            value: ""
        };
        newState.currentMessage = "";

        if (resetChatProfile) {
            newState.pickedChatProfiles = [];
        }

        if (resetPickedConversation) {
            newState.currentlySelectedMessage = {
                messages: {},
                users: [],
                timestamp: {},
                docId: "",
                conversationId: ""
            };
        }

        this.setState({
            newState
        });
    }

    newMessage() {
        const chatProfiles = StoreService.getStoreProperty("chatProfiles");

        this.setState({
            allChatProfiles: Object.values(chatProfiles).map((profile: any) => {
                return {
                    value: String(profile.uid),
                    label: profile.name
                };
            })
        });

        this.resetMessage(true, true);
    }

    handleIcon = (type) => {
        return FileManagerIcons.getIcon(type, { width: 25 });
    };

    showFileOrExtension = (file, fileName?) => {
        console.log(file, fileName);
        const fileLowercase = file.toLowerCase();

        const name = fileName?.split("/");

        if (
            fileLowercase?.includes(".jpg") ||
            fileLowercase?.includes(".jpeg") ||
            fileLowercase?.includes(".png") ||
            fileLowercase?.includes(".apng") ||
            fileLowercase?.includes(".avif") ||
            fileLowercase?.includes(".gif") ||
            fileLowercase?.includes(".svg") ||
            fileLowercase?.includes(".webp")
        ) {
            return (
                <div
                    className="images mb-20">
                    {fileName && (
                        <span>
                            <a href={file}
                               target="_blank"
                               rel="noreferrer">
                                {name[2]}
                            </a>
                        </span>
                    )}
                    <a href={file}
                       target="_blank"
                       rel="noreferrer">
                        <img
                            src={file}
                            alt="file?"
                            className="files d-flex" />
                    </a>
                </div>
            );
        }
        else if (fileLowercase?.includes(".pdf")) {
            return (
                <div
                    className="otherFiles">
                    {fileName && (
                        <span>
                            <a href={file}
                               target="_blank"
                               rel="noreferrer">
                                {name[2]}
                            </a>
                        </span>
                    )} {this.handleIcon("pdf")}
                </div>
            );
        }
        else if (fileLowercase?.includes(".txt")) {
            return (
                <div
                    className="otherFiles">
                    {fileName && (
                        <span>
                            <a href={file}
                               target="_blank"
                               rel="noreferrer">
                                {name[2]}
                            </a>
                        </span>
                    )} {this.handleIcon("txt")}
                </div>
            );
        }
        else if (fileLowercase?.includes(".doc") || fileLowercase?.includes(".docx")) {
            return (
                <div
                    className="otherFiles">
                    {fileName && (
                        <span>
                            <a href={file}
                               target="_blank"
                               rel="noreferrer">
                                {name[2]}
                            </a>
                        </span>
                    )} {this.handleIcon("doc")}
                </div>
            );
        }
        else if (fileLowercase?.includes(".zip") || fileLowercase?.includes(".rar")) {
            return (
                <div
                    className="otherFiles">
                    {fileName && (
                        <span>
                            <a href={file}
                               target="_blank"
                               rel="noreferrer">
                                {name[2]}
                            </a>
                        </span>
                    )} {this.handleIcon("zip")}
                </div>
            );
        }
    };

    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };

    render() {
        console.log(this.state.messageFile?.value["name"]);

        const component = this;
        const currentConversation =
            this.state.currentlySelectedMessage.messages &&
            Object.values(this.state.currentlySelectedMessage.messages)
                ? this.state.currentlySelectedMessage
                : { messages: {} };

        let filesArray = [] as any;

        Object.values(currentConversation.messages).forEach((message: any) => {
            if (message.imageUrl) {
                const fileUrl = {
                    url: message.imageUrl,
                    name: message.storageUri
                };
                if (fileUrl) {
                    filesArray.push(fileUrl);
                }
            }
        });

        const currentUser = StoreService.getStoreProperty("user");
        const allUsers = StoreService.getStoreProperty("users");

        return (
            <div
                className="chat-screen d-flex ">
                {/* LOADER WHILE WAITING FOR ALL CONVERSATIONS AND CHAT PROFILES TO LOAD */} {!!this.state.screenLoading && (
                <div
                    className="flex-grow-1 d-flex align-items-center justify-content-center">
                    <Loader
                        className="w-200" />
                </div>
            )}

                {/* ALL CONVERSATIONS AND CHAT PROFILES LOADED */} {!this.state.screenLoading && (
                <>
                    {/* ovo je levi sidebar za sve konverzacije  */}
                    <div
                        className="leftSideNav ">
                        {/* BUTTON FOR NEW CONVERSATION */}
                        <div
                            className="chat-target-wrapper d-flex align-items-center "
                            onMouseEnter={() => {
                                if (
                                    !this.state.allChatProfiles ||
                                    !this.state.allChatProfiles.length
                                ) {
                                    const chatProfiles =
                                        StoreService.getStoreProperty(
                                            "chatProfiles"
                                        );

                                    this.setState({
                                        allChatProfiles: Object.values(
                                            chatProfiles
                                        ).map((profile: any) => {
                                            return {
                                                value: String(profile.uid),
                                                label: profile.name
                                            };
                                        })
                                    });
                                }
                            }}
                        >
                            <div
                                className="newMessage"
                                onClick={this.newMessage}
                            >
                                <Chat
                                    className="icon"
                                    width={20} />
                                <span
                                    className="f-s-16 ml-10">
                                        Start New Chat
                                    </span>
                            </div>
                        </div>

                        {
                            !!this.state.messages && !!Object.values(this.state.messages).length ? (<div
                                    className="conversationsWrapperLeftSide">
                                    {Object.values(this.state.messages).map(
                                        (message: any, index) => {
                                            return (
                                                <ChatConversationPreview
                                                    allUsers={allUsers}
                                                    conversation={message}
                                                    clickHandle={() => {
                                                        component.selectConversation(
                                                            message
                                                        );
                                                    }}
                                                    currentConversation={
                                                        currentConversation
                                                    }
                                                    key={index}
                                                />
                                            );
                                        }
                                    )}
                                </div>) : (
                                <div
                                    className="padding-t-5 text-center">
                                    <p className="medium-text">
                                        <Translate text="NO_MESSAGES"/>
                                    </p>
                                </div>)
                        }
                    </div>
                    {/* ovo je wrapper za poruke za trenutni chat */}
                    <div
                        className={`messageWrapper ${
                            this.state.isOver ? "dotted" : ""
                            } `}
                        onDrop={(e) =>
                            this.handleFileChange("file", e, true)
                        }
                        onDragOver={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.changeBorderType(true);
                        }}
                        onDragLeave={() => this.changeBorderType(false)}
                    >
                        <div
                            className={`showFilesRight ${
                            this.state.showFilesRight && "active"
                                }`}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {/* ovde treba fajlovi da se izlistaju */}
                            {filesArray.length > 0 ? (
                                    <div
                                        className="d-flex flex-column align-items-center">
                                        {filesArray.map((file) => {
                                            return this.showFileOrExtension(
                                                file.url,
                                                file.name
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div>
                                        <p className="f-s-14 text-center">
                                            <Translate text="NO_FILES"/>
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                        <div
                            className="chat-target-wrapper d-flex align-items-center"
                            onMouseEnter={() => {
                                if (
                                    !this.state.allChatProfiles ||
                                    !this.state.allChatProfiles.length
                                ) {
                                    const chatProfiles =
                                        StoreService.getStoreProperty(
                                            "chatProfiles"
                                        );

                                    this.setState({
                                        allChatProfiles: Object.values(
                                            chatProfiles
                                        ).map((profile: any) => {
                                            return {
                                                value: String(profile.uid),
                                                label: profile.name
                                            };
                                        })
                                    });
                                }
                            }}
                        >
                            {/* dropdown gde se bira kome poslati poruku receivers */}
                            <div
                                className="chooseReceiversDropdown">
                                <CustomDropdown
                                    data={this.state.allChatProfiles}
                                    multiple
                                    value={this.state.pickedChatProfiles}
                                    name="chatProfiles"
                                    handleChange={
                                        this.handleTargetChatProfile
                                    }
                                    filter={false}
                                    placeholder="Search chat"
                                    labelClass="f-w-600"
                                    disabled={
                                        !!currentConversation &&
                                        !!currentConversation.messages &&
                                        !!Object.values(
                                            currentConversation.messages
                                        ).length
                                    }
                                />
                            </div>
                        </div>

                        {!!currentConversation &&
                        !this.state.messagesLoading && (
                            <div
                                className="current-conversation">
                                {Object.values(
                                    currentConversation.messages
                                )
                                    .sort((a: any, b: any) => {
                                        return (
                                            Number(a.messageId) -
                                            Number(b.messageId)
                                        );
                                    })
                                    .map((message: any, index) => {
                                        let conversationMessageClasses =
                                            "conversation-message ";

                                        if (
                                            message.senderUid ===
                                            currentUser.uid
                                        ) {
                                            conversationMessageClasses +=
                                                " this-sender ";
                                        }

                                        return (
                                            <div
                                                className={`outgoingReceivingMessages ${
                                                    message.senderUid ===
                                                    currentUser.uid
                                                        ? "myMessage"
                                                        : "theirMessage"
                                                    }`}
                                                key={index}
                                            >
                                                {message.senderUid !==
                                                currentUser.uid && (
                                                    <div
                                                        style={{
                                                            width: "35px",
                                                            height: "35px",
                                                            backgroundImage:
                                                                "url(" +
                                                                message.profilePicUrl +
                                                                ")",
                                                            backgroundSize:
                                                                "cover",
                                                            backgroundPosition:
                                                                "50% 50%",
                                                            borderRadius:
                                                                "50%"
                                                        }}
                                                    />
                                                )}
                                                <div
                                                    className={
                                                        conversationMessageClasses
                                                    }
                                                >
                                                    {/* <div className="d-flex">
                                                                    <div className="d-flex flex-column"></div>
                                                                </div> */}

                                                    {!!message.text && (
                                                        <p className="message-text">
                                                            {
                                                                message.text
                                                            }
                                                        </p>
                                                    )} {
                                                    !!message.imageUrl &&
                                                    this.showFileOrExtension(
                                                        message.imageUrl,
                                                        message.storageUri
                                                    )
                                                    // <div
                                                    //     style={{
                                                    //         width: "150px",
                                                    //         height: "80px",
                                                    //         backgroundImage:
                                                    //             "url(" +
                                                    //             message.imageUrl +
                                                    //             ")",
                                                    //         backgroundSize:
                                                    //             "contain",
                                                    //         backgroundRepeat:
                                                    //             "no-repeat",
                                                    //         backgroundPosition:
                                                    //             "50% 50%",
                                                    //     }}
                                                    // />
                                                }
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}

                        {!!this.state.messagesLoading && (
                            <div
                                className="flex-grow-1 d-flex align-items-center justify-content-center">
                                <Loader
                                    className="w-100" />
                            </div>
                        )}

                        <div
                            className={`writeMessageWrapper`}
                        >
                            <div
                                className="writeMessageDiv d-flex">
                                <div
                                    className={`toggleFiles ${
                                    this.state.showFilesRight &&
                                    "active"
                                        }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState((prevState: any) => ({
                                            showFilesRight:
                                                prevState.showFilesRight ===
                                                false
                                                    ? true
                                                    : false
                                        }));
                                    }}
                                >
                                    <PhotoIcon
                                        width={20} />
                                </div>
                                {this.state.messageFile.value !== "" && (
                                    <div
                                        className="fileSending">
                                        <p>
                                            Your file is inserted and ready for send!
                                        </p>
                                    </div>
                                )}
                                <CustomForm
                                    className="d-flex align-items-center"
                                    handleSubmit={() => this.sendMessage}
                                >
                                    <CustomTextArea
                                        name="currentMessage"
                                        handleInput={(name, data) => {
                                            this.setState({
                                                [name]: data.value
                                            });
                                        }}
                                        placeholder="Write message"
                                        value={this.state.currentMessage}// icon={true}
                                        handleSend={this.sendMessage}
                                        handleFile={this.handleFileChange}
                                        classNameInputC="w-100-perc"
                                        className="inputMessages"
                                        autoGrowTextarea
                                    />
                                    <CustomFileInput
                                        name="document"
                                        classNameComponent="mr-10"
                                        className="mb-10"
                                        handleInput={this.handleFileChange}
                                        value={null}
                                        icon={
                                            <AddPhoto
                                                width={20} />}
                                    />
                                </CustomForm>
                                <div
                                    className="icons d-flex align-items-center justify-content-center">
                                    <div
                                        className="send d-flex justify-content-center align-items-center pointer"
                                        onClick={this.sendMessage}
                                    >
                                        <SendMessageIcon
                                            width={15} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </>
            )}
            </div>
        );
    }
}

export default ChatModule;
