export const parseDate = (date) => {
    // const dateArray = date.split(".");
    // const formatedDate = `${dateArray[1]}.${dateArray[0]}.${dateArray[2]}`;
    const datum = new Date(date);
    const dan = datum.toLocaleDateString("sr-latn-SR", { day: "2-digit" });
    const mjesec = datum.toLocaleDateString("sr-latn-SR", { month: "2-digit" });
    const godina = datum.toLocaleDateString("sr-latn-SR", { year: "numeric" }).replace(".", "");

    return `${dan}.${mjesec}.${godina}`;
};

export const newDateFromString = (date, format = null) => {
    let dateObj = new Date();

    if (!format || format === "DD.MM.YYYY") {
        const parts = date.split(".");

        dateObj.setDate(parts[0]);
        dateObj.setMonth(Number(parts[1]) - 1);
        dateObj.setFullYear(parts[2]);
    }

    return dateObj;
};

export const dayDifference = (firstDate, secondDate) => {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((secondDate.valueOf() - firstDate.valueOf()) / (1000 * 60 * 60 * 24));
};

export const parseDateForMySQL = (date, time = false) => {
    const datum = new Date(date);
    const dan = datum.toLocaleDateString("sr-latn-SR", { day: "2-digit" });
    const mjesec = datum.toLocaleDateString("sr-latn-SR", { month: "2-digit" });
    const godina = datum.toLocaleDateString("sr-latn-SR", { year: "numeric" }).replace(".", "");
    const sati = datum.toLocaleTimeString("sr-latn-SR");

    return `${godina}-${mjesec}-${dan}${time ? ` ${sati}` : ""}`;
};
export const parseDatetime = (date) => {
    const datum = new Date(date);
    const dan = datum.toLocaleDateString("sr-latn-SR", { day: "2-digit" });
    const mjesec = datum.toLocaleDateString("sr-latn-SR", { month: "2-digit" });
    const godina = datum.toLocaleDateString("sr-latn-SR", { year: "numeric" }).replace(".", "");
    const hour = datum.getHours();
    const minute = datum.getMinutes();
    const second = datum.getSeconds();

    return `${dan}.${mjesec}.${godina}. ${hour}:${minute}:${second < 10 ? `0${second}` : second}`;
};
export const parseDateWithoutSeconds = (date) => {
    const datum = new Date(date);
    const dan = datum.toLocaleDateString("sr-latn-SR", { day: "2-digit" });
    const mjesec = datum.toLocaleDateString("sr-latn-SR", { month: "2-digit" });
    const godina = datum.toLocaleDateString("sr-latn-SR", { year: "numeric" }).replace(".", "");
    const hour = datum.getHours();
    const minute = datum.getMinutes();

    return `${dan}.${mjesec}.${godina}. ${hour}:${minute}
    `;
};
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0 || !bytes) return "";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getTimeRemaining = (end: string) => {
    const total = Date.parse(end) - Date.parse(new Date().toISOString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
        total,
        days,
        hours,
        minutes,
        seconds,
    };
};
