import React, { Component } from "react";
import { ShowPasswordIcon } from "../../components/Icons/Icons";
import Translate from "functions/utilFunctions/translate";
import { parseDateWithoutSeconds } from "functions/utilFunctions/parsingFunctions";
import { Constants } from "../../services/constants.service";
import { StoreService } from "services/store.service";
export default class Action extends Component<any> {
    state = {
        priorityVisible: false,
    };

    redirectToExistingAction = (id, e) => {
        e.stopPropagation();
        window.open(`/action/${id}`, "_blank");
    };
    redirectToEmployee = (id) => {
        window.open(`/accountsDetails/${id}`, "_blank");
    };
    renderPriorityColors = (priority) => {
        if (priority === 1) {
            return "bg-important-2";
        } else if (priority === 2) {
            return "bg-important-3";
        } else if (priority === 3) {
            return "bg-important-4";
        } else return "bg-important-5";
    };
    renderPriorityText = (priority) => {
        if (priority === 1) {
            return <Translate text="IMPORTANT" />;
        } else if (priority === 2) {
            return <Translate text="VERY_IMPORTANT" />;
        } else if (priority === 3) {
            return <Translate text="PRIORITY" />;
        } else return <Translate text="URGENT" />;
    };
    renderStatusText = (status) => {
        if (status === 1) {
            return <Translate text="PLANNED_ACTION" />;
        } else if (status === 2) {
            return <Translate text="IN_PROGRESS_ACTION" />;
        } else if (status === 3) {
            return <Translate text="COMPLETED_ACTION" />;
        }
    };
    renderStatusColor = (status) => {
        if (status === 1) {
            return "purple";
        } else if (status === 2) {
            return "orange";
        } else if (status === 3) {
            return "green";
        }
    };
    toggleClass = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ priorityVisible: !this.state.priorityVisible });
    };
    render() {
        const { className, action } = this.props;
        const { priorityVisible } = this.state;
        const employees = action.employees;
        const length = employees.length;
        const role = StoreService.getStoreProperty("user").role;

        return (
            <div className={` ${className ? className : ""} d-flex`}>
                <div
                    className={`${this.renderPriorityColors(action.priority)} ${
                        priorityVisible ? "visiblePriority" : ""
                    } priority`}
                    onClick={this.toggleClass}
                >
                    {priorityVisible && (
                        <span className={` ${priorityVisible ? "visible" : ""}`}>
                            {this.renderPriorityText(action.priority)}
                        </span>
                    )}
                </div>
                <div className="info d-flex flex-column ml-10 p-5 w-75perc">
                    <div className="title bolder">{action.title}</div>
                    <div className="photo d-flex my-10 h-20">
                        {length > 0 &&
                            employees.map((e) => {
                                return e.profile_image ? (
                                    <img
                                        src={Constants.STORAGE + e.profile_image}
                                        className="pointer img mr-10 w-20 h-20 border-radius-50-perc"
                                        onClick={() => this.redirectToEmployee(e.id)}
                                        alt="employee"
                                    ></img>
                                ) : (
                                    <span
                                        className="pointer noImg mr-5"
                                        style={{ backgroundColor: "grey" }}
                                        onClick={() => role === 3 && this.redirectToEmployee(e.id)}
                                    >
                                        {e.firstName.charAt(0).toUpperCase() + e.lastName.charAt(0).toUpperCase()}
                                    </span>
                                );
                            })}
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="endDate">{parseDateWithoutSeconds(action.endDate)}</span>
                    </div>
                </div>

                <div
                    onClick={(e) => this.redirectToExistingAction(action.action_id, e)}
                    className="pointer pt-5 d-flex flex-column rightSide"
                >
                    <ShowPasswordIcon width={25} className="pointer showPassword pt-6 ml-10" />
                    <span
                        className={`status mr-10 mt-30 ${action.status === 1 ? "pl-15" : ""}`}
                        style={{ color: this.renderStatusColor(action.status) }}
                    >
                        {this.renderStatusText(action.status)}
                    </span>
                </div>
            </div>
        );
    }
}
