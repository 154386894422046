import { LockIcon, LogoutIcon, MNEFlag, NotificationsIcon, SettingsIcon, UKFlag } from "../Icons/Icons";
import React, { Component } from "react";
import { Avatar } from "components/Icons/Icons";
import { Constants, DISABLE_FIREBASE } from "../../services/constants.service";
import { CustomButton } from "../../devkit/Form/Form";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { RouteComponentProps } from "react-router-dom";
import { StoreService } from "../../services/store.service";
import ToastNotifications from "components/ToastNotifications/ToastNotifications";
import Translate from "../../functions/utilFunctions/translate";
import { Devkit } from "oykos-development-devkit";
import { ScreenContext } from "components/ScreenContext/ScreenContext";
import HamburgerMenu from "components/HamburgerMenu/HamburgerMenu";

interface IHeader extends RouteComponentProps {
    breadcrumbs: string;
    lockApp: () => void;
    logout: () => void;
    language: () => void;
    lang: string | null;
}

class Header extends Component<IHeader, any> {
    state = {
        logout: false,
        lang: localStorage.getItem("lang"),
        settings: "",
        notificationsPanel: "",
        notifications: [] as any,
    };

    static contextType = ScreenContext;

    getNotifications = () => {
        const user = StoreService.getStoreProperty("user");

        Devkit.FirebaseService.getNotificationsForUser(user.id, null, (doc) => {
            if (doc) {
                const notifications = doc.docs.map((doc) => doc.data());

                this.setState({ notifications });
            }
        });
    };

    handleNotificationClick = (link, id, userId) => {
        Devkit.FirebaseService.markNotificationAsRead(id, userId);

        if (link) {
            this.setState({
                notificationsPanel: "",
            });

            this.props.history.push(`/${link}`);
        }
    };

    markAllNotificationsAsRead = (userId) => {
        Devkit.FirebaseService.markAllUserNotificationsAsRead(userId);
    };

    showSettings = (e) => {
        e.stopPropagation();
        this.setState((prevState: any) => ({
            settings: prevState.settings === "visible" ? "" : "visible",
            notificationsPanel: "",
        }));
    };

    showNotifications = (e) => {
        e.stopPropagation();
        this.setState((prevState: any) => ({
            settings: "",
            notificationsPanel: prevState.notificationsPanel === "visible" ? "" : "visible",
        }));
    };

    componentDidMount() {
        if (!DISABLE_FIREBASE) {
            const component = this;

            setTimeout(() => {
                component.getNotifications();
            }, 2000);
        }

        document.addEventListener("click", () => {
            this.setState({
                settings: "",
                notificationsPanel: "",
            });
        });
    }

    splitFileName = (item) => {
        let splitItem = item.split("/");
        return splitItem[splitItem.length - 1];
    };

    handleRedirect = (link: string) => {
        this.props.history.push(link);
    };

    renderNotificationIcon(type) {
        const height = 18;
        let icon = <NotificationsIcon height={height} />;

        switch (type) {
            case "default":
                break;
        }

        return icon;
    }

    render() {
        const { settings, notificationsPanel, notifications } = this.state;
        const user = StoreService.getStoreProperty("user");
        const unreadNotifications = notifications.filter((notification) => notification.read === false);

        return (
            <div id="header" className="d-flex justify-content-between">
                <ToastNotifications handleRedirect={this.handleRedirect} />

                {/* <div className="breadcrumbs p-r d-inline-block ml-40"> */}
                {/* <span className="center-r-v d-inline-block f-s-14"><Translate text="EXAMPLES" /></span> */}
                {/* </div> */}
                <div className="headerInfo d-flex p-r align-items-center justify-content-end">
                    <CustomButton
                        className="btn-header fill settingsButton f-s-16 p-r"
                        onClick={this.showNotifications}
                    >
                        <NotificationsIcon height={25} />
                        {unreadNotifications.length === 0 ? (
                            <></>
                        ) : (
                            <span className="numberNotifications bg-important-5 border-radius-50-perc">
                                {unreadNotifications.length}
                            </span>
                        )}
                    </CustomButton>
                    <CustomButton className="btn-header fill settingsButton f-s-16" onClick={this.showSettings}>
                        <SettingsIcon height={25} />
                    </CustomButton>
                    {this.context.isMobile && <HamburgerMenu></HamburgerMenu>}
                </div>
                <div className={`settings ${settings}`}>
                    <ul>
                        <li className="pointer h-70">
                            <Link
                                className="h-100-perc w-100-perc d-block d-flex align-items-center"
                                to={"/personal-account"}
                            >
                                <div className="imgContainer">
                                    <img
                                        src={
                                            user.profile_image
                                                ? Constants.STORAGE + user.profile_image
                                                : "images/user.png"
                                        }
                                        alt={user.firstName}
                                    />
                                </div>
                                <div className="ml-10 d-flex flex-column">
                                    <span className="f-s-16 f-w-600">
                                        {user.firstName} {user.lastName}
                                    </span>
                                    <span className="f-s-14 mt-5">
                                        <Translate text="SEE_PROFILE" />
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li onClick={this.props.language} className="d-flex align-items-center pointer">
                            {this.props.lang === "en" ? (
                                <div className="item d-flex align-items-center justify-content-center">
                                    <UKFlag height={15} />
                                </div>
                            ) : (
                                <MNEFlag width={30} />
                            )}
                            <span className="f-s-14 ml-10">
                                <Translate text="CHANGE_LANGUAGE" />
                            </span>
                        </li>

                        <li onClick={this.props.lockApp} className="d-flex align-items-center pointer lock">
                            <span className="w-30 text-center">
                                <LockIcon height={20} className="fill" />
                            </span>
                            <span className="f-s-14 ml-10">
                                <Translate text="LOCK_APP" />
                            </span>
                        </li>
                        <li onClick={this.props.logout} className="d-flex align-items-center pointer logout">
                            <span className="w-30 text-center">
                                <LogoutIcon height={20} className="fill" />
                            </span>
                            <span className="f-s-14 ml-10">
                                <Translate text="LOGOUT" />
                            </span>
                        </li>
                    </ul>
                </div>
                <div className={`notifications ${notificationsPanel} `} onClick={(e) => e.stopPropagation()}>
                    <div className="d-flex justify-content-between align-items-center mb-20 px-20 py-20">
                        <h4 className="f-s-18 text-color-font lh">
                            <Translate text="NOTIFICATIONS" />
                        </h4>
                        <span
                            className="f-s-12 text-color-font markAsRead"
                            onClick={() => this.markAllNotificationsAsRead(user.id)}
                        >
                            <Translate text="MARK_ALL_AS_READ" />
                        </span>
                    </div>
                    <ul>
                        {notifications.length > 0 ? (
                            notifications.map((e, index) => {
                                let notificationDate = e.updated_at;
                                let pictureUrl = e.profilePictureUrl && e.profilePictureUrl.split("/");

                                notificationDate =
                                    notificationDate && notificationDate.seconds
                                        ? new Date(notificationDate.seconds * 1000)
                                        : notificationDate;

                                return (
                                    <li className="d-flex align-items-center" key={index}>
                                        <div
                                            onClick={() => this.handleNotificationClick(e.link, e.id, user.id)}
                                            className="notification f-s-16 px-20 d-flex align-items-center pointer"
                                        >
                                            <div className="info d-flex flex-column w-100perc">
                                                <div className="d-flex align-items-center justify-content-between max-w-300">
                                                    <span className="f-w-600 d-flex align-items-center">
                                                        <span className="imgContainer mr-5">
                                                            {pictureUrl &&
                                                            pictureUrl[pictureUrl.length - 1] !== "null" ? (
                                                                <img
                                                                    src={
                                                                        e.profilePictureUrl
                                                                            ? e.profilePictureUrl
                                                                            : "images/user.png"
                                                                    }
                                                                    alt={e.profilePictureUrl}
                                                                />
                                                            ) : (
                                                                <Avatar className="avatar" />
                                                            )}
                                                        </span>
                                                        <span className="f-s-15">{e.user}</span>
                                                    </span>
                                                    <span className="d-flex f-s-14 f-w-100 justify-content-between align-items-center mt-1">
                                                        <span className="mr-10 f-s-13">
                                                            <ReactTimeAgo
                                                                date={notificationDate}
                                                                locale={this.props.lang === "me" ? "sr-Latn" : "en-US"}
                                                            />
                                                        </span>

                                                        {e.read || <span className="circle" />}
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-column pb-3">
                                                    <span
                                                        className="f-s-15 d-flex align-items-center"
                                                        style={{
                                                            paddingLeft: "5px",
                                                        }}
                                                    >
                                                        <span
                                                            className="d-flex align-items-center"
                                                            style={{
                                                                paddingRight: "10px",
                                                            }}
                                                        >
                                                            {this.renderNotificationIcon(e.icon)}
                                                        </span>
                                                        <Translate text={e.action} /> {" :"}
                                                    </span>
                                                    <span
                                                        className="f-s-14"
                                                        style={{
                                                            paddingLeft: "35px",
                                                        }}
                                                    >
                                                        {this.splitFileName(e.item)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })
                        ) : (
                            <h4 className="text-center f-s-16 text-color-font-l2">
                                <Translate text="NO_NEW_NOTIFICATIONS" />
                            </h4>
                        )}
                    </ul>
                </div>
                {this.context.isMobile && (
                    <div className="header-logo h-50 align-self-start d-flex justify-content-center align-items-center py-10">
                        <Link to="/" className="d-flex align-items-center">
                            <img src="/images/Terra_development_logo_H-white.png" alt="terra-logo" />
                        </Link>
                    </div>
                )}
            </div>
        );
    }
}

export default Header;
