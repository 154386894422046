import React, { Component } from "react";
import CustomButton from "devkit/Form/CustomButton/CustomButton";
import { StoreService } from "services/store.service";
import { Avatar } from "components/Icons/Icons";
import Translate from "functions/utilFunctions/translate";
import { Constants } from "services/constants.service";

class PersonalAccountLeftSide extends Component<any> {
    state = {
        handleChange: false,
        changePin: true,
    };
    renderRole = (role) => {
        if (parseInt(role) === 1) {
            return <Translate text="contractor" />;
        }
        if (parseInt(role) === 2) {
            return <Translate text="employee" />;
        }
        if (parseInt(role) === 3) {
            return <Translate text="admin" />;
        }
    };
    render() {
        const loggedUser = StoreService.getStoreProperty("user");

        return (
            <div>
                <div className="personal personal-shadow d-flex justify-content-between flex-column">
                    <div className="d-flex flex-column align-items-center justify-content-center h-300">
                        <div id="personal" className="d-flex justify-content-center p-r">
                            {this.props.image !== null ? (
                                <img
                                    className="image"
                                    src={this.props.image ? Constants.STORAGE + this.props.image : "images/user.png"}
                                    alt={this.props.image}
                                />
                            ) : (
                                <Avatar className="image" />
                            )}

                            <div className="d-flex camera" onClick={() => this.props.toggleModal("imageUploadModal")}>
                                <i
                                    className="fas fa-camera fa-2x align-self-center margin-t--1"
                                    style={{
                                        color: "white",
                                    }}
                                />
                            </div>
                        </div>
                        <span className="d-flex justify-content-center user mt-20">
                            {this.props.firstName} {this.props.lastName}
                        </span>{" "}
                        <span className="d-flex justify-content-center admin">{this.renderRole(this.props.role)}</span>
                    </div>
                    <div className="d-flex flex-column border-top-lightgray">
                        <div
                            className="d-flex col-12 justify-content-between pointer modal-hover"
                            onClick={() => this.props.toggleModal("showPasswordModal")}
                        >
                            <div className="d-flex flex-column password ">
                                <Translate text="PASSWORD" />
                                <span>********</span>
                            </div>
                            <div className="d-flex ">
                                <i
                                    className="fas fa-chevron-right align-self-center"
                                    style={{
                                        color: "gray",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column border-top-lightgray">
                            <div
                                className="d-flex col-12 justify-content-between pointer modal-hover"
                                onClick={() => this.props.toggleModal("showPinModal")}
                            >
                                <div className="d-flex flex-column pin">
                                    <Translate text="PIN_CODE" />
                                    <span>********</span>
                                </div>
                                <div className="d-flex ">
                                    <i
                                        className="fas fa-chevron-right align-self-center"
                                        style={{
                                            color: "gray",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loggedUser.role === 3 && (
                    <div className="buttonGroup d-flex justify-content-center w-250">
                        <div className="mr-10">
                            <CustomButton
                                className="btn-secondary small mt-20"
                                type="button"
                                onClick={this.props.redirectToActions}
                            >
                                <Translate text="EMPLOYEE_ACTIONS" />
                            </CustomButton>
                        </div>
                        <div>
                            <CustomButton
                                className="btn-secondary small mt-20"
                                type="button"
                                onClick={this.props.redirectToCalendar}
                            >
                                <Translate text="ACTIONS_SCHEDULE" />
                            </CustomButton>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default PersonalAccountLeftSide;
