import Translate from "functions/utilFunctions/translate";
import React, { Component } from "react";
import { CloudFolderIcon, FileManagerIcons } from "../../components/Icons/FileManagerIcons";
import Table from "../../devkit/Table/Table";
import { formatBytes, parseDatetime } from "../../functions/utilFunctions/parsingFunctions";

class ClientFiles extends Component<any> {
    handleIcon = (type) => {
        return FileManagerIcons.getIcon(type, { width: 25 });
    };

    render() {
        const { default_path, files } = this.props.data;
        const filesTableHeads = this.props.tableHeads;
        return (
            <div className="clientFiles h-100perc w-100perc d-flex flex-column">
                <div>
                    {/* <div>
                        <CustomButton onClick={this.props.toggleModal}>
                            <Translate text="CHANGE_DEFAULT_FOLDER" />
                        </CustomButton>
                    </div> */}

                    <span className="f-s-14 d-flex align-items-center mb-10">
                        <Translate text="DEFAULT_FOLDER" />: <CloudFolderIcon className="lightFill ml-10" width={20} />
                        {/* {FileManagerIcons.getIcon("folder", { width: 20 })} */}
                        <span className="f-s-14">
                            {default_path.value ? default_path.value : `/klijenti/${this.props.data.name.value}`}
                        </span>
                        <span className="f-s-12 ml-10 pointer fileLink" onClick={() => this.props.toggleModal()}>
                            <Translate text="CHANGE" />
                        </span>
                    </span>
                </div>
                <div className="overflow-auto pb-20">
                    <Table theads={filesTableHeads} theadsClassname="customerFilesThead">
                        {files.length &&
                            files.map((e: any, index) => (
                                <tr
                                    key={index}
                                    className={`pointer `}
                                    id={e.id}
                                    onClick={() => this.props.handleRedirect(e.parent_id)}
                                >
                                    <td className="f-s-12 d-flex align-items-center">
                                        <span className="d-flex align-items-center mr-10">
                                            {this.handleIcon(e.type)}
                                        </span>
                                        <span>{e.title}</span>
                                    </td>
                                    <td className="f-s-12" style={{ width: "100px" }}>
                                        {formatBytes(e.file_size)}
                                    </td>
                                    <td className="f-s-12" style={{ width: "120px" }}>
                                        {e.type}
                                    </td>
                                    <td className="f-s-12" style={{ width: "175px" }}>
                                        {parseDatetime(e.updated_at)}
                                    </td>
                                </tr>
                            ))}
                    </Table>
                </div>
            </div>
        );
    }
}

export default ClientFiles;
